import { Row, Col, Divider, Card } from 'antd'

export function ShowDataBrokers(branch, carLoanDetails, dataBroker) {
    const currencyFormatOne = (amount) => {
        if (amount > 0) {
            return Number(amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        } else {
            return "-"
        }

    }
    return (
        <>
            {
                branch !== 'MIT' && branch !== 'S4' && branch !== 'S5' && branch !== 'UD' && branch !== 'LEX' ?
                    <>
                        {
                            carLoanDetails?.productType === "p-loan" ?
                                <>
                                    <Row justify={'left'}>
                                        <b><u>รายละเอียดค่าแนะนำ</u></b>
                                    </Row>
                                    <Row gutter={32} justify={'center'}>
                                        <aside style={{ width: '90%' }}>
                                            {
                                                dataBroker?.customerBroker?.idBroker > 0 ?
                                                    <div style={{ marginBottom: 0, }}>
                                                        <span>ชื่อผู้แนะนำ : </span>
                                                        <b>{dataBroker?.customerBroker?.snam + '   ' + dataBroker?.customerBroker?.firstname + '   ' + dataBroker?.customerBroker?.lastname}</b>
                                                    </div>
                                                    : null
                                            }
                                            {
                                                dataBroker?.broker ?
                                                    <>
                                                        {dataBroker?.broker.map((e, index) => {
                                                            return (
                                                                <>
                                                                    {
                                                                        e.payfor === "630" ?
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>จำนวนเงิน : </span>
                                                                                <b>{currencyFormatOne(e?.payamt)}</b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            : null
                                                                    }
                                                                    {
                                                                        e.payfor === "627" ?
                                                                            <div style={{ marginBottom: 0, }}>
                                                                                <span>ค่าอนุมัติ/บริการ : </span>
                                                                                <b>{currencyFormatOne(e?.payamt)}</b>
                                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                                    บาท
                                                                                </span>
                                                                            </div>
                                                                            : null
                                                                    }
                                                                </>
                                                            )
                                                        })}
                                                    </>
                                                    : null
                                            }
                                            {
                                                !dataBroker ?
                                                    <Row gutter={32} justify={'center'}>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>***ไม่พบข้อมูล***</span>
                                                        </div>
                                                    </Row>
                                                    : null
                                            }
                                        </aside>
                                    </Row>
                                    <Divider />
                                </>
                                : null
                        }
                    </>
                    : null
            }
        </>
    )
}


export function ShowDataBrokersAD(dataBroker) {
    const currencyFormatOne = (amount) => {
        if (amount > 0) {
            return Number(amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        } else {
            return "-"
        }

    }
    return (
        <Row gutter={32} justify={'center'}>
            <Card style={{ width: '1000px' }}>
                <Row justify={'left'}><b><u>รายละเอียดค่าแนะนำ</u></b></Row>
                <Row gutter={32} justify={'center'}>
                    <Col className='gutter-row' span={8}>
                        {
                            dataBroker[0]?.cusBroker !== "" ?
                                <div style={{ marginBottom: 0, }}>
                                    <span>ชื่อผู้แนะนำ : </span>
                                    <b>{dataBroker[0]?.snam + '   ' + dataBroker[0]?.firstname + '   ' + dataBroker[0]?.lastname}</b>
                                </div>
                                : null
                        }
                    </Col>
                    {
                        dataBroker ?
                            <>
                                {dataBroker.map((e, index) => {
                                    return (
                                        <>
                                            {
                                                e.payfor === "630" ?
                                                    <Col className='gutter-row' span={8}>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>จำนวนเงิน : </span>
                                                            <b>{currencyFormatOne(e?.payamt)}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </div>
                                                    </Col>
                                                    : null
                                            }
                                            {
                                                e.payfor === "627" ?
                                                    <Col className='gutter-row' span={8}>
                                                        <div style={{ marginBottom: 0, }}>
                                                            <span>ค่าอนุมัติ/บริการ : </span>
                                                            <b>{currencyFormatOne(e?.payamt)}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </div>
                                                    </Col>
                                                    : null
                                            }
                                        </>
                                    )
                                })}
                            </>
                            : null
                    }

                    {
                        !dataBroker ?
                            <Row gutter={32} justify={'center'}>
                                <div style={{ marginBottom: 0, }}>
                                    <span>***ไม่พบข้อมูล***</span>
                                </div>
                            </Row>
                            : null
                    }
                </Row>
            </Card>
        </Row>

    )
}