import React, { useState, useEffect } from "react";
import { FormOutlined, SearchOutlined, FileImageOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Table, Space, Card, Radio, Tag, notification, Statistic, Spin, Divider, Select, DatePicker, Typography, Form, Pagination } from 'antd';

import { addCustomer } from '../../redux/Customer';
import { addCar } from '../../redux/Car';
import { addGuarantor } from '../../redux/Guarantor';

import RockingWork from './modals/RockingWork';
import { backcAD, textcAD, colorApprovalStatus } from "../file_mid/status_color";
import { orderAD } from "../file_mid/all_options";
import dayjs from "dayjs";
import axios from "axios";

import { useDispatch } from 'react-redux';
import { pgmaindataksm, testfindCount, mainPGDataRocking } from "../file_mid/all_api";
const { Text } = Typography;
const { Countdown } = Statistic;

export default function Main() {
  const token = localStorage.getItem('token')
  const branch = localStorage.getItem('branch');
  const currentDateTime = dayjs()
  const showDay1 = dayjs("2023-01-01")
  const currentDateDay2 = dayjs().format("YYYY-MM-DD")
  const [dataPost, setDataPost] = useState({ sec: 0, days1: dayjs("2023-01-01"), days2: currentDateDay2, approvalStatus: "16" })
  // const guarantor = useSelector(state => state.guarantors)
  const dispatch = useDispatch()
  const user = localStorage.getItem('username');
  const [editData, setEditData] = useState();
  const [cData, setCData] = useState([]);
  const [AdData, setAdData] = useState([]);
  const [axiosData, setAxiosData] = useState([]);
  const [arrayTable, setArrayTable] = useState([]);
  const [keyWord, setKeyWord] = useState(16);
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false)
  // const [types, setTypes] = useState([])
  const [rockingWork, setRockingWork] = useState(false)
  const [testPage, setTestPage] = useState();
  const [ssPage, setSsPage] = useState({ numberPage: 1, pageSize: 10 });
  const [currentPage, setCurrentPage] = useState(1)
  const [query, setQuery] = useState("");

  useEffect(() => {
    // setTypes(guarantor?.data?.typeLoan)
    if (rockingWork === false) {
      loadData();
    }
  }, [keyWord, rockingWork, dataPost.approvalStatus, dataPost.days1, dataPost.days2, ssPage])
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      console.log(query)
      searchLoadData(query)
    }, 1500)
    return () => clearTimeout(delayDebounceFn)
  }, [query])
  const searchLoadData = async (data) => {
    console.log("searchLoadData data", data)
    if (data !== "") {
      console.log("OK searchLoadData data")
      setCurrentPage(1)
      await loadData(data)
    }
  }
  const search = async (data) => {
    if (data.target.value !== "") {
      setQuery(data.target.value)
    } else {
      setSsPage({ numberPage: 1, pageSize: 10 })
      setCurrentPage(1)
      setDataPost({ sec: 0, days1: dayjs("2023-01-01"), days2: currentDateDay2, approvalStatus: "16" })
      setQuery("")
    }
  };
  const onChangeKeyWord = (value) => {
    console.log("value", value)
    // setKeyWord(value)
    setQuery("")
    setDataPost({ ...dataPost, approvalStatus: value })
    setCurrentPage(1)
    setSsPage({ numberPage: 1, pageSize: 10 })
  }
  const onChangePagination = (e, pageSize) => {
    // console.log(e, pageSize)
    setCurrentPage(e)
    setSsPage({ numberPage: e, pageSize: pageSize })
  }

  const countReviewBy = async () => {
    var mData = { day1: dataPost.days1, day2: dataPost.days2 }
    const tk = JSON.parse(token)
    const headers = {
      "Authorization": `Bearer ${tk}`,
      "Menu": JSON.stringify("5")
    }
    await axios.post(testfindCount, mData, { headers: headers })
      //await axios.post('http://localhost:8080/AuthCase/testfindCount', mData, { headers: headers })
      .then((res) => {
        // console.log("TT", res.data)
        setAdData(res.data)
        if (res.data !== null) {
          // delete res.data.reviewedBy === "MIT00109"
          // delete res.data.reviewedBy === "MIT00115"
          // delete res.data.reviewedBy === "MIT00117"
          // delete res.data.reviewedBy === "MIT00122"
          res.data.map((data, index) => {
            //console.log("data", data)
            if (
              // data.name === "K0000001" ||
              // data.name === "K0000002" ||
              // data.name === "K0000003" ||
              // data.name === "K0000057" ||
              // data.name === "K0000063" ||
              // data.name === "K0000065" ||
              // data.name === "K0000074" ||
              // data.name === "K0000075" ||
              // data.name === "K0000076" ||
              // data.name === "MIT00034" ||
              // data.name === "MIT00026" ||
              // data.name === "MIT00075" ||
              data.reviewedBy === null ||
              data.reviewedBy === "" ||
              data.reviewedBy === "1234" ||
              data.reviewedBy === "pn" ||
              data.reviewedBy === "mk001"

            ) {

              var num = index
            }
            delete res.data[num]
            // setArrayTable(res.data)
            // setAxiosData(res.data)
          })

        } if (res.data === null) {
          setAdData([])
          setLoading(false)
        } else {
          setAdData(res.data)
          setLoading(false)
        }
      }).catch((err) => {
        console.log("err", err)
      });
  }
  const loadCountData = async (data) => {
    const ez = data.filter(
      (item) =>
        item.approvalStatusId === dataPost?.approvalStatus
    );
    console.log("ez", ez)
    console.log("data", data)
    setTestPage(...ez)
    setCData(data)
  }
  const loadData = async (data) => {
    setLoading(true)
    let mainData
    const tk = JSON.parse(token)
    const headers = {
      "Authorization": `Bearer ${tk}`,
      "Menu": JSON.stringify("5")
    }
    if (query !== "" && data !== "stop") {
      mainData = { reviewedBy: user, approvalStatus: dataPost.approvalStatus, numberPage: ssPage?.numberPage, pageSize: ssPage?.pageSize, keyword: query, day1: dataPost.days1, day2: dataPost.days2 }
    } else {
      if (data === "stop") {
        mainData = { reviewedBy: user, approvalStatus: "16", numberPage: ssPage?.numberPage, pageSize: ssPage?.pageSize, day1: dataPost.days1, day2: dataPost.days2 }
      } else {
        mainData = { reviewedBy: user, approvalStatus: dataPost.approvalStatus, numberPage: ssPage?.numberPage, pageSize: ssPage?.pageSize, day1: dataPost.days1, day2: dataPost.days2 }
      }
    }
    if (branch === "MIT" || branch === "UD" || branch === "LEX" || branch === "S4" || branch === "S5" ) {
      await axios.post(mainPGDataRocking, mainData, { headers: headers })
        .then(async (res) => {
          console.log("mainPGDataRocking", res.data)
          await countReviewBy();
          if (res.data !== null) {
            if (res.data.ShowTablePG) {
              // res.data.ShowTablePG.map((data, index) => {
              //   if (
              //     data.approvalStatus === 2 ||
              //     data.approvalStatus === 3 ||
              //     data.approvalStatus === 11 ||
              //     data.approvalStatus === 12 ||
              //     data.approvalStatus === 13 ||
              //     data.approvalStatus === 14 ||
              //     data.approvalStatus === 15 ||
              //     data.approvalStatus === 19 ||
              //     data.approvalStatus === 22 ||
              //     data.approvalStatus === 23 ||
              //     data.approvalStatus === 24 ||
              //     data.approvalStatus === 25 ||
              //     data.approvalStatus === 26 ||
              //     data.approvalStatus === 27 ||
              //     data.approvalStatus === "ทั้งหมด"
              //   ) {
              //     var num = index
              //   }
              //   delete res.data.ShowTablePG[num]
              // })
              if (res.data.ShowTablePG) {
                var newShowTablePG = res.data.ShowTablePG.filter((data) => {
                  return ![2, 3, 11, 12, 13, 14, 15, 19, 22, 23, 24, 25, 26, 27].includes(data.approvalStatusId);
                });
                // await loadCountData(newShowTablePG)
                // // await loadCountData(res.data.CountStatus)
                setArrayTable(newShowTablePG)
                setAxiosData(newShowTablePG)
              }
              // setArrayTable(res.data.ShowTablePG)
              // setAxiosData(res.data.ShowTablePG)
            } else {
              setArrayTable([])
              setAxiosData([])
            }
            if (res.data.Countstatus) {
              loadCountData(res.data.Countstatus)
            } else {
              setTestPage()
              setCData([])
            }
          } else {
            setArrayTable([])
            setAxiosData([])
          }
          setLoading(false)
        }).catch((err) => console.log(err))
      setLoading(false)
    } else {
      await axios.post(pgmaindataksm, mainData, { headers: headers })
        .then(async (res) => {
          console.log("mainPGDataRocking ksm", res.data)
          await countReviewBy();
          if (res.data !== null) {
            if (res.data.ShowTablePG) {
              // res.data.ShowTablePG.map((data, index) => {
              //   if (data.approvalStatus === 14 ||
              //     data.approvalStatus === 12 ||
              //     data.approvalStatus === 11 ||
              //     data.approvalStatus === 15 ||
              //     data.approvalStatus === 13 ||
              //     data.approvalStatus === 19 ||
              //     data.approvalStatus === 2 ||
              //     data.approvalStatus === 22 ||
              //     data.approvalStatus === 3 ||
              //     data.approvalStatus === 26 ||
              //     data.approvalStatus === 27 ||
              //     data.approvalStatus === 23 ||
              //     data.approvalStatus === 24 ||
              //     data.approvalStatus === 25 ||
              //     data.approvalStatus === "ทั้งหมด"
              //   ) {
              //     var num = index
              //   }
              //   delete res.data.ShowTablePG[num]
              // })
              if (res.data.ShowTablePG) {
                var newShowTablePG = res.data.ShowTablePG.filter((data) => {
                  return ![2, 3, 11, 12, 13, 14, 15, 19, 22, 23, 24, 25, 26, 27].includes(data.approvalStatusId);
                });
                // await loadCountData(newShowTablePG)
                // // await loadCountData(res.data.CountStatus)
                setArrayTable(newShowTablePG)
                setAxiosData(newShowTablePG)
              }
              // setArrayTable(res.data.ShowTablePG)
              // setAxiosData(res.data.ShowTablePG)
            } else {
              setArrayTable([])
              setAxiosData([])
            }
            if (res.data.Countstatus) {
              loadCountData(res.data.Countstatus)
            } else {
              setTestPage()
              setCData([])
            }
          } else {
            setArrayTable([])
            setAxiosData([])
            //await loadCountData();
          }
          setLoading(false)
        }).catch((err) => console.log(err))
      setLoading(false)
    }
  }
  // const search = (data) => {
  //   // อนาคต ควรหาใน database โดยตรง
  //   const greaterThanTen = axiosData.filter(
  //     (item) =>
  //       item.snam.toLowerCase().includes(data.target.value) ||
  //       item.firstName.toLowerCase().includes(data.target.value) ||
  //       item.carPlateNumber.toLowerCase().includes(data.target.value)
  //   );
  //   setArrayTable(greaterThanTen)
  // };


  // const handleviewImg = (record) => {
  //   localStorage.setItem('viewAD', JSON.stringify(record))
  //   window.open('/view-ad', '_blank');
  // }

  const columns = [
    {
      fixed: true,
      key: 'index',
      title: "ลำดับ",
      dataIndex: "index",
      width: "5%",
      align: 'center',
      render: (text, object, index) => index + 1
    },
    {
      key: 'carInput',
      title: "วันที่เสนอ",
      dataIndex: "carInput",
      width: "9%",
      align: 'center',
      defaultSortOrder: 'descend',
      sorter: {
        compare: (a, b) => dayjs(a.carInput) - dayjs(b.carInput),
        multiple: 1,
      },
      render: (text, record) => (
        <>{dayjs(record.carInput).format("DD-MM-YY HH:mm")}</>
      ),
    },
    {
      key: 'carInfo',
      title: "รายละเอียด",
      dataIndex: "carPlateNumber",
      width: "25%",
      align: 'center',
      render: (text, record) => (
        <>
          <div>{record.snam}{record.firstName} {record.lastName}</div>
          {/* <div>{record.carBrand} {record.carModel} </div> */}
          <div>
            {
              record.car === "car" ?
                <div>
                  <div>{record.carBrand} {record.carModel} ปีรถ {parseInt(record.carYear) + 543} เลขทะเบียน {record.carPlateNumber}</div>
                  <div>เสนอโดย <Space style={{ color: "red" }}><b>{record.proposalBy}</b></Space></div>
                  {
                    record.reviewedBy !== null ?
                      <>
                        <div>ธุรการที่ได้เคส <Space style={{ color: "red" }}><b>{record.reviewedBy}</b></Space></div>
                      </>
                      : null
                  }
                </div>
                :
                <div>
                  <div>{record.carBrand} {record.carModel} ตารางวา {record.carYear} เลขโฉนด {record.carPlateNumber}</div>
                  <div>เสนอโดย <Space style={{ color: "red" }}><b>{record.proposalBy}</b></Space></div>
                  {
                    record.reviewedBy !== null ?
                      <div>ผ่านการวิเคราะห์โดย <Space style={{ color: "red" }}><b>{record.reviewedBy}</b></Space></div>
                      : null
                  }
                </div>
            }
            {record.ISSUNO === "" ? null : <div>มาจากสัญญา <Space style={{ color: "red" }}><b>{record.ISSUNO}</b></Space></div>}
          </div >
        </>
      )
    },
    {
      key: 'productLoanType',
      title: "ประเภทขอกู้",
      dataIndex: "productLoanType",
      width: "11%",
      align: 'center',
      render: (text, record) => (
        <>
          <div>
            {
              record.car === "car" ?
                <Tag color="purple">รถ</Tag>
                :
                <Tag color="green">ที่ดิน</Tag>
            }
            {
              <Tag color="geekblue">{record.productLoanType}</Tag>
            }
          </div>
        </>
      ),
    },
    {
      key: 'loanAmount',
      title: "ยอดขอกู้",
      dataIndex: "loanAmount",
      width: "9%",
      align: 'center',
      // sorter: {
      //   compare: (a, b) => a.loanAmount - b.loanAmount,
      //   multiple: 2,
      // },
      render: (text, record) => (
        <Space size="middle">
          {
            <Space>
              <>{currencyFormatOne(record.loanAmount)}</>
            </Space>
          }
        </Space>
      ),
    },
    {
      key: 'approvalDate',
      title: "วันที่รับ",
      dataIndex: "approvalDate",
      width: "8%",
      align: 'center',
      // sorter: {
      //   compare: (a, b) => dayjs(a.approvalDate) - dayjs(b.approvalDate),
      //   multiple: 2,
      // },
      render: (text, record) => (
        <Space size="middle">
          {
            record.approvalDate !== "0001-01-01T00:00:00Z" ?
              <Space>
                <>{dayjs(record.approvalDate).format("DD-MM-YY HH:mm")}</>
              </Space>
              :
              <Text type="secondary">ยังไม่รับงาน</Text>
          }
        </Space>
      ),
    },
    {
      key: 'approvalDate',
      title: "ระยะเวลาที่คาดว่าจะเสร็จ",
      dataIndex: "",
      width: "10%",
      align: 'center',
      render: (text, record) => (
        <Space size="middle">
          {
            record.approvalDate !== "0001-01-01T00:00:00Z" ?
              <Space>
                <Countdown value={dayjs(record.approvalDate).add(3, 'hour')} />
              </Space>
              : <Text type="secondary">ยังไม่รับงาน</Text>
          }
        </Space>
      ),
    },
    {
      key: 'approvalStatus',
      title: "สถานะ",
      dataIndex: "approvalStatus",
      width: "10%",
      align: 'center',
      render: (text, record) => {
        let color
        let color2
        color = colorApprovalStatus(record?.productLoanType, record?.approvalStatus)
        return (
          <>
            <Tag color={color}>
              {record?.approvalStatusName}
            </Tag>
          </>
        );
      },
    },
    {
      key: 'Action',
      title: "Action",
      fixed: 'right',
      align: 'center',
      width: "10%",
      render: (record) => (
        <Space size="middle">
          <Button onClick={() => {
            //console.log("record",record)
            let dataCar = { carId: record.carId, typeLoan: record.car, loanId: record.loanId }
            setRockingWork(true)
            dispatch(addCustomer(record.customerId))
            dispatch(addCar(record.carId))
            dispatch(addGuarantor(dataCar))
            setEditData(record)
          }}><SearchOutlined> </SearchOutlined></Button>
        </Space>
      ),
    },
  ];

  const currencyFormatOne = (amount) => {
    return Number(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }


  return (
    <>
      <Card
        bordered={false}
        style={{
          width: 'auto',
        }}
      >
        <Form
          labelCol={{
            span: 12,
          }}
          wrapperCol={{
            span: 24,
          }}
          style={{
            width: '100%',
          }}
          // form={form}
          // onFinish={handleSubmit}
          autoComplete="off"
          initialValues={{ remember: true }}
        >
          <Row style={{ textAlign: 'center' }}>
            <Col span={24}>
              <Spin spinning={loading} size='large' tip=" Loading... ">
                <div className='text-center'>
                  <h2>โยกงาน - ย้ายงาน</h2>
                </div>
                <Row gutter={32} justify={'center'}>

                  <Row gutter={24} justify={'center'} style={{ margin: 10 }}>
                    <Col className='gutter-row' span={24} style={{ textAlign: 'center' }}>
                      <Space>
                        <>{"เลือกวันที่ต้องการแสดงเคส :"}</>
                        <DatePicker format={'YYYY-MM-DD'}
                          defaultValue={showDay1}
                          style={{ height: '40px' }}
                          onChange={(e) => {
                            if (e) {
                              setDataPost({ ...dataPost, days1: e.format('YYYY-MM-DD') })
                            }
                          }} />
                        <>{"ถึง"}</>
                        <DatePicker format={'YYYY-MM-DD'}
                          defaultValue={currentDateTime}
                          style={{ height: '40px' }}
                          onChange={(e) => {
                            if (e) {
                              setDataPost({ ...dataPost, days2: e.format('YYYY-MM-DD') })
                            }
                          }} />
                      </Space>
                    </Col>
                  </Row>

                  <Row gutter={24} justify={'center'} style={{ margin: 10 }}>
                    <Col className='gutter-row' span={24} style={{ textAlign: 'center' }}>
                      <Space>
                        <>{"เลือกสถานะ :"}</>
                        <Select
                          value={dataPost.approvalStatus}
                          style={{ width: 200 }}
                          onChange={(value) => onChangeKeyWord(value)}
                          options={[
                            // {
                            //   label: 'ทั้งหมด', value: 'ทั้งหมด',
                            // },
                            {
                              label: 'รอธุรการรับ', value: "16",
                            },
                            {
                              label: 'รอวิเคราะห์', value: "18",
                            },
                            {
                              label: 'ไม่ผ่านการวิเคราะห์', value: "20",
                            }
                          ]}
                        />
                      </Space>
                    </Col>
                  </Row>
                  <Divider style={{ margin: 5 }} />
                </Row>
                <Row justify={'center'} style={{ fontSize: '20px' }}><b>จำนวนเคส</b></Row>
                <Row justify={'center'}>
                  <Col span={24}>
                    <Radio.Group disabled >
                      {
                        AdData.sort((a, b) => {
                          const order = orderAD
                          return order.indexOf(a.reviewedBy) - order.indexOf(b.reviewedBy);
                        })
                          .map((item) => (
                            < Radio.Button
                              style={{
                                backgroundColor: backcAD[item.reviewedBy],
                                color: textcAD[item.reviewedBy]
                              }}
                              value={item.reviewedBy}
                            >

                              {item.reviewedBy}<b>({item.sumReviewedBy})</b>

                            </Radio.Button>
                            // console.log("item", item)
                          ))}

                    </Radio.Group>
                  </Col>
                </Row>
                <Row gutter={32} style={{ margin: 10 }}>
                  <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                    <Input.Search
                      style={{ width: '250px' }}
                      placeholder="ค้นหา...."
                      onChange={search}
                      name="search"
                      id="search"
                      value={query}
                    />
                  </Col>
                </Row>
                <Divider />
                <Row gutter={32}>
                  <Col span={24}>
                    <Table
                      pagination={false}
                      rowKey={(record) => record.uid}
                      scroll={{
                        x: 1500,
                        y: 400,
                      }}
                      dataSource={arrayTable}
                      columns={columns}
                    >
                    </Table>
                    <Divider />
                    <Pagination
                      current={currentPage}
                      onChange={onChangePagination}
                      onShowSizeChange={onChangePagination}
                      defaultPageSize={10}
                      defaultCurrent={1}
                      total={testPage?.totalCount}
                    />
                    <Divider />
                  </Col>
                </Row>
              </Spin>
            </Col>
          </Row>
        </Form>
      </Card >
      {
        rockingWork ?
          <RockingWork
            open={rockingWork}
            close={setRockingWork}
            dataFromTable={editData}
          />
          : null
      }
      {contextHolder}
    </>
  )
}
