import React, { useRef, useState, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import { Button, Card, Col, Divider, Form, Input, Row, Space, Spin, Table, notification, DatePicker, Select, Modal, InputNumber } from 'antd';
import axios from 'axios';
import dayjs from "dayjs";
import { PrinterOutlined } from '@ant-design/icons';
import { msOKMemo1, msConnt, msData, msCancel, currencyFormatOne, msOK, msError, msCal2, msErrorInsert2, msErrorInst, msPrint } from "../file_mid/allFormat";
// import { loadDataMainArothr } from "./load_data/data_other";
import { loadPayamtHD, confirmPayHD } from "../file_mid/all_api";
import { optionsSearchPay } from "../file_mid/all_options";
import { loadDataPayment, loadDataFindPAYTYP, funcInsertMemo1, loadPrintDataHD } from "../loan_history/load_data_print/data_pay";
import { mainColumnsHDPAYMENT } from '../loan_history/table/cm';
import { calPayHD } from "../loan_history/cal/Cal";
import PaymentJBF from "../receipt_multiple/ReportPaymentAuto/PaymentAutoJBF";
// import Payment from "../file_mid/reportPayment/PaymentOther";

export default function Main() {
  const blockedValues = ["99"];
  const { TextArea } = Input;
  const { confirm } = Modal
  const componentPDF = useRef();
  const generatePDF = useReactToPrint({ content: () => componentPDF.current, documentTitle: "ใบรับเงิน", }); // บิลปกติ
  const token = localStorage.getItem('token');
  const userFirstname = localStorage.getItem("firstname");
  const userLastname = localStorage.getItem("lastname");
  const userPrint = `${userFirstname} ${userLastname}`;
  // const currentToken = localStorage.getItem("currentToken");
  const currentDateTime = dayjs()
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [formKey, setFormKey] = useState(0);
  const [formKey2, setFormKey2] = useState(0);
  const [formKey3, setFormKey3] = useState(0);
  const [loading, setLoading] = useState(false);
  const [dataRec, setDataRec] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [isClickedTy, setIsClickedTy] = useState(1); // เลือกค้นหาจาก
  const [checkContnoNew, setCheckContnoNew] = useState(false);
  const [checkDataInsert, setCheckDataInsert] = useState(false);
  // const [reload, setReload] = useState(1); // reload
  const [api, contextHolder] = notification.useNotification();
  const [dataCode, setDataCode] = useState({ codeQ: "" })
  const [dataSend, setDataSend] = useState({
    textData: "",
    textData2: "",
    CONTNO: "",
    money: 0,
    sale: 0,
    saleFollow: 0,
    date: currentDateTime,
    payType: "จ่ายเข้าตารางดิว",
    PAYCODE: "01",
    selectPay: 1,
    MEMO1: "",
  });
  const [sum, setSum] = useState({ amt: 0, GRDCOD: "", LOCAT: "" });
  const [checkCt, setCheckCt] = useState(true);
  const [checkAgain, setCheckAgain] = useState(true);
  const [getPAYCODE, setGetPAYCODE] = useState([]);
  const [findPAYTYP, setFindPAYTYP] = useState([]);
  const [dataHDPAYMENT, setDataHDPAYMENT] = useState([]);
  const [dataPay, setDataPay] = useState([]);
  // const [dataAll, setDataAll] = useState(null);

  useEffect(() => {
    loadData()
  }, [])
  const loadData = async () => {
    setLoading(true)
    try {
      const result = await loadDataPayment();
      setGetPAYCODE(result)
    } catch (error) {
      console.error("Error in loadData: ", error);
      setGetPAYCODE([])
    } finally {
      setLoading(false);
    }
  }

  const showConfirm = () => {
    confirm({
      title: (
        <Row>
          <Form form={form}>
            <Col span={24}>
              <Form.Item label="" style={{ margin: 0 }}>
                <b>{"คอมเฟิร์มทำรายการ"}</b>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="เลขที่สัญญา" style={{ margin: 0 }}>
                <b style={{ color: 'blue' }}>{dataSend?.CONTNO}</b>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="จำนวนเงิน" style={{ margin: 0 }}>
                <b style={{ color: 'blue' }}>{currencyFormatOne(dataSend?.money)}</b>
                <span style={{ marginLeft: '10px' }}>บาท</span>
              </Form.Item>
            </Col>
          </Form>
        </Row>
      ),
      content: 'กด OK เพื่อยืนยัน',
      onOk() {
        setLoading(true);
        setFormKey(prevKey => prevKey + 1);
        setFormKey2(prevKey => prevKey + 1);
        setFormKey3(prevKey => prevKey + 1);
        callHandleSubmitPay();
      },
      onCancel() {
        setFormKey(prevKey => prevKey + 1);
        setFormKey2(prevKey => prevKey + 1);
        setFormKey3(prevKey => prevKey + 1);
        msCancel(api, "top");
      },
    });
  };
  const callHandleSubmitPay = async () => {
    let mix = {}
    setFormKey2(prevKey => prevKey + 1);
    if (checkContnoNew === false) {
      if (checkDataInsert === false) {
        // setReload(2)
        const tk = JSON.parse(token)
        const headers = {
          "Authorization": `Bearer ${tk}`,
          "Menu": JSON.stringify("26")
        }
        mix = { PAYCODE: dataSend?.PAYCODE, money: dataSend?.money, CONTNO: dataSend?.CONTNO, LOCAT: sum?.LOCAT, result: dataPay?.result }
        console.log("mix", mix)
        // await axios.post("http://localhost:8070/auth-pay/confirm-pay", mix, { headers: headers })
        await axios.post(confirmPayHD, mix, { headers: headers })
          .then(async (res) => {
            console.log("ok insert", res.data)
            if (res.status === 200) {
              setDataCode({ codeQ: res.data })
              form3.setFieldsValue({ codeQ: res.data });
              setCheckDataInsert(true);
              setLoading(false)
              msOK(api, "top")
            } else {
              setDataCode({ codeQ: "" })
              form3.setFieldsValue({ codeQ: "" });
              msErrorInst(api, "top")
              setLoading(false)
            }
          })
          .catch((err) => {
            console.log("err", err)
            msError(api, "top")
            setDataCode({ codeQ: "" })
            form3.setFieldsValue({ codeQ: "" });
            setLoading(false)
          })
      } else {
        msErrorInsert2(api, "top");
        setLoading(false);
      }
    } else {
      msCal2(api, "top");
      setLoading(false);
    }
  }

  const handleSelectCONTNO = async (value) => {
    console.log(value)
    if (value) {
      setLoading(true);
      setTimeout(() => {
        setDataSend({ ...dataSend, CONTNO: value })
        setCheckDataInsert(false)
        setCheckContnoNew(false)
        setLoading(false);
      }, 1000);
    }
  }
  const handleCONTNO = (e, number) => {
    console.log(number, e.target.value)
    setDataHDPAYMENT([]);
    setCheckContnoNew(true);
    setCheckDataInsert(false);
    form2.setFieldsValue({ money: "", });
    if (number === 31 || number === 32) {
      if (number === 31) {
        setDataSend({ ...dataSend, CONTNO: "", textData: e.target.value, money: 0 })
      } else {
        setDataSend({ ...dataSend, CONTNO: "", textData2: e.target.value, money: 0 })
      }
      setFindPAYTYP([])
      form.setFieldsValue({ CONTNO: "", })
    } else {
      if (isClickedTy === 1) {
        console.log("e.target.value", e.target.value)
        setDataSend({ ...dataSend, CONTNO: e.target.value, textData: "", money: 0 })
      } else {
        console.log("e.target.value", e.target.value)
        // setLoading(true);
        // setTimeout(() => {
        setDataSend({ ...dataSend, CONTNO: "", textData: e.target.value, money: 0 })
        setFindPAYTYP([])
        //   setLoading(false);
        // }, 1000);
        form.setFieldsValue({ CONTNO: "", })
      }
    }
  }

  const handleMEMO1 = (value) => {
    // console.log("value MEMO1", value)
    setDataSend((prevData) => ({ ...prevData, MEMO1: value }));
  }
  const funcMoney = (value) => {
    console.log("value =:", value)
    if (value) {
      setDataSend({ ...dataSend, money: parseFloat(value) })
    } else {
      setDataSend({ ...dataSend, money: 0 })
    }
  }
  const changePAYCODE = (value) => {
    console.log("value =:", value)
    setDataSend({ ...dataSend, PAYCODE: value })
  }

  const handleSubmitMemo = async () => {
    setFormKey3(prevKey => prevKey + 1);
    console.log("memo")
    setLoading(true)
    try {
      const result = await funcInsertMemo1(dataSend?.CONTNO, dataSend?.MEMO1);
      if (result === 200) {
        msOKMemo1(api, "top")
      } else {
        msErrorInst(api, "top")
      }
    } catch (error) {
      console.error("Error in funcInsertMemo1: ", error);
      msErrorInst(api, "top")
    } finally {
      setLoading(false);
    }
  }

  const handleSubmit = async () => { // ค้นหา
    console.log(dataSend?.CONTNO, isClickedTy, dataSend.textData)
    setFormKey(prevKey => prevKey + 1);
    if (dataSend?.CONTNO !== "") {
      setCheckContnoNew(false);
      setCheckCt(false);
      setCheckDataInsert(false);
      setDataRec(null);
      // setDataAll(null);
      setShowTable(false);
      let resultCalPayHD = 0;
      setLoading(true)
      await axios.post(loadPayamtHD, dataSend)
        .then(async (res) => {
          console.log("res", res.data)
          if (res.status === 200) {
            if (res.data?.HDPAYMENT) {
              resultCalPayHD = await calPayHD(res.data?.HDPAYMENT[0]?.TOTAMT);
              console.log("resultCalPayHD", resultCalPayHD)
            }
            if (res.data?.result) {
              setSum({ amt: res.data.result[0]?.DUEAMT, GRDCOD: res.data.result[0]?.GRDCOD, LOCAT: res.data.result[0]?.LOCAT })
            }
            setDataPay(res.data)
          } else {
            setDataPay([])
            setSum({ amt: 0, GRDCOD: "", LOCAT: "" })
            msConnt(api, "top")
          }
          setTimeout(() => {
            setCheckAgain(true);
            setDataHDPAYMENT(res.data?.HDPAYMENT)
            setDataSend({ ...dataSend, money: 0, TOTAMT: 0, sale: 0, saleFollow: 0, date: currentDateTime, payType: "จ่ายเข้าตารางดิว", PAYCODE: "01", selectPay: 1, MEMO1: res.data?.MEMO1 })
            setDataCode({ codeQ: "", codeCCB: "" })
            form3.setFieldsValue({ codeQ: "", codeCCB: "" })
            form2.setFieldsValue({
              money: "",
              sale: "",
              saleFollow: "",
              PAYCODE: "01",
              selectPay: 1,
            });
            setLoading(false);
          }, 1000);
        })
        .catch((err) => {
          console.log("err", err)
          clearData(0)
          msData(api, "top")
          setLoading(false)
        })
    } else if (isClickedTy === 2 || isClickedTy === 3 || isClickedTy === 4) {
      if (dataSend.textData !== "") {
        setLoading(true)
        try {
          const results = await loadDataFindPAYTYP(dataSend.textData, dataSend.textData2, isClickedTy);
          if (!results) {
            msData(api, "top")
            setFindPAYTYP([])
          } else {
            setFindPAYTYP(results)
          }
        } catch (error) {
          console.error("Error in loadData: ", error);
          msData(api, "top")
          setFindPAYTYP([])
        } finally {
          setCheckCt(true)
          setDataPay([])
          setCheckAgain(true);
          setDataHDPAYMENT()
          setSum({ amt: 0, GRDCOD: "", LOCAT: "" })
          setDataSend({ ...dataSend, money: 0, TOTAMT: 0, sale: 0, saleFollow: 0, date: currentDateTime, payType: "จ่ายเข้าตารางดิว", PAYCODE: "01", selectPay: 1, MEMO1: "" })
          setDataCode({ codeQ: "", codeCCB: "" })
          form3.setFieldsValue({ codeQ: "", codeCCB: "" })
          form2.setFieldsValue({
            money: "",
            sale: "",
            saleFollow: "",
            PAYCODE: "01",
            selectPay: 1,
          });
          setLoading(false);
        }
      }
    } else {
      msConnt(api, "top")
    }
  }

  const showConfirmClear = () => {
    confirm({
      title: 'คุณต้องการที่ล้างข้อมูลนี้ใช่หรือไม่ ?',
      content: 'กด OK เพื่อยืนยัน',
      onOk() {
        clearData(0)
      },
      onCancel() {
        msCancel(api, "top")
      },
    });
  }
  const clearData = async (value) => {
    setLoading(true);
    console.log("clearData", value)
    if (value > 0) {
      setIsClickedTy(value)
      form.setFieldsValue({ CONTNO: "", textData: "", textData2: "", searchPay: value })
    } else {
      setIsClickedTy(1)
      form.setFieldsValue({ CONTNO: "", textData: "", textData2: "", searchPay: 1 })
    }
    form2.setFieldsValue({ money: "", });
    form3.setFieldsValue({ codeQ: "" });
    setTimeout(() => {
      // setReload(1)
      setDataCode({ codeQ: "" });
      setDataRec(null);
      setCheckCt(true);
      setCheckContnoNew(false);
      setCheckDataInsert(false);
      setShowTable(false);
      setFindPAYTYP([])
      setSum({ amt: 0, GRDCOD: "", LOCAT: "" })
      setDataSend({ textData: "", textData2: "", CONTNO: "", money: 0, sale: 0, saleFollow: 0, date: currentDateTime, payType: "จ่ายเข้าตารางดิว", PAYCODE: "01", selectPay: 1, MEMO1: "", });
      // setDataAll(null);
      setDataHDPAYMENT([])
      setLoading(false);
    }, 1000);
  }

  const printpay = async () => {
    if (dataSend.CONTNO !== "" && dataCode.codeQ !== "") {
      let printData = {}
      let result = {}
      console.log("บิลปกติ")
      setLoading(true)
      printData = { "CONTNO": dataSend.CONTNO, "TMBILL": dataCode.codeQ }
      try {
        result = await loadPrintDataHD(printData);
        setDataRec(result)
      } catch (error) {
        console.error("Error in handleInsert: ", error);
      } finally {

      }
      if (result === null) {
        console.log("เกิดข้อผิดพลาด");
        msPrint(api, "top")
        setDataRec(null)
        setLoading(false);
      } else {
        setShowTable(true);
        setTimeout(async () => {
          setLoading(false);
          generatePDF();
        }, 1000);
      }
    } else {
      msData(api, "top")
    }
  }

  return (
    <>
      <Spin spinning={loading} size='large' tip="Loading...">
        <Card style={{ height: '100%' }}>
          {/* <Col> */}
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <div className='text-center'>
                <h2>รับฝาก</h2>
              </div>
              <div className='text-right'>
                <Space>
                  <Button onClick={printpay} type="primary" icon={<PrinterOutlined style={{ fontSize: '16px' }} />}
                    style={{ backgroundColor: '#5ceb97', borderColor: '#5ceb97' }}
                  >
                    พิมพ์ใบเสร็จ</Button>
                </Space>
              </div>
            </Col>
            <Divider />
            {/* <Row gutter={32} justify={'center'}> */}
            <Col xs={24} sm={12} md={8}>
              <Card title={<div style={{ textAlign: "center" }}>รายละเอียดสัญญา</div>} bordered={false}>
                <Form
                  form={form}
                  key={formKey}
                  onFinish={handleSubmit}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.Item label="วันที่ชำระ" name="date">
                        <DatePicker
                          format="YYYY-MM-DD"
                          disabled
                          defaultValue={currentDateTime}
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            if (e) {
                              setDataSend({ ...dataSend, date: e.format("YYYY-MM-DDTHH:mm:ssZ") });
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="ค้นหาจาก" name="searchPay">
                        <Select
                          style={{ width: "100%" }}
                          defaultValue={isClickedTy}
                          onChange={clearData}
                          options={optionsSearchPay}
                        />
                      </Form.Item>
                    </Col>
                    {
                      isClickedTy === 1 ?
                        <Col span={24}>
                          <Form.Item label="เลขสัญญา" name="CONTNO">
                            <Input onChange={(e) => handleCONTNO(e, 0)}></Input>
                          </Form.Item>
                        </Col>
                        :
                        <>
                          {
                            isClickedTy === 3 ?
                              <>
                                <Col span={24}>
                                  <Form.Item label="ชื่อ-สกุล" name="textData"
                                    rules={[{ required: true, message: 'กรุณากรอกชื่อ-สกุล !' },]}
                                  >
                                    <Input onChange={(e) => handleCONTNO(e, 31)}></Input>
                                  </Form.Item>
                                  <div style={{ textAlign: "center", marginTop: "5px" }}>
                                    <b style={{ fontSize: "14px", color: "red" }}>
                                      ***ไม่จำเป็นต้องใส่คำนำหน้าชื่อ***
                                    </b>
                                  </div>
                                </Col>
                              </>
                              :
                              <Col span={24}>
                                <Form.Item label="ข้อความ" name="textData">
                                  <Input onChange={(e) => handleCONTNO(e, 0)}></Input>
                                </Form.Item>
                              </Col>
                          }
                          {
                            findPAYTYP?.length > 0 ?
                              <Col span={24}>
                                <Form.Item label="เลือกสัญญา" name="CONTNO">
                                  <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    onChange={handleSelectCONTNO}
                                    optionFilterProp="children"
                                    placeholder="Search to Select"
                                    filterOption={(inputValue, option) =>
                                      option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                    }
                                  >
                                    {findPAYTYP?.map((e) => {
                                      return (
                                        <Select.Option key={e.keyId} value={e.CONTNO}>
                                          {e.CONTNO} (ทะเบียน {e.REGNO}) (ชื่อ {e.NAME1} {e.NAME2})
                                        </Select.Option>
                                      )
                                    })}
                                  </Select>
                                </Form.Item>
                              </Col>
                              : null
                          }
                        </>
                    }
                    <Col span={12}>
                      <Button disabled={loading} type="primary" htmlType="submit" block>
                        ค้นหา
                      </Button>
                    </Col>
                    <Col span={12}>
                      <Button disabled={loading} onClick={showConfirmClear} block>
                        Clear
                      </Button>
                    </Col>
                    <Divider />
                    <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
                      <Card style={{ width: "80%", textAlign: "center", border: "1px solid #ddd", boxShadow: "2px 2px 10px rgba(0,0,0,0.1)", padding: "15px" }}>
                        <div style={{ marginBottom: "10px" }}>
                          <span style={{ fontWeight: "bold" }}>เกรด:</span>
                          <b style={{ marginLeft: "8px", fontSize: "16px", color: "red" }}>{sum?.GRDCOD}</b>
                        </div>
                        <Divider />
                        <div>
                          <span style={{ fontWeight: "bold" }}>ค่างวด:</span>
                          <b style={{ marginLeft: "8px", fontSize: "16px", color: "red" }}>{currencyFormatOne(sum?.amt)}</b>
                          <span style={{ marginLeft: "5px" }}>บาท</span>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </Form >
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Card title={<div style={{ textAlign: "center" }}>รับเงิน</div>} bordered={false}>
                <Form form={form2} key={formKey2} onFinish={showConfirm}>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.Item label="ประเภท" name="PAYCODE">
                        <Select
                          showSearch
                          style={{ width: '100%' }}
                          onChange={changePAYCODE}
                          optionFilterProp="children"
                          placeholder="Search to Select"
                          filterOption={(inputValue, option) =>
                            option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                          }
                        >
                          {getPAYCODE?.map((e) => {
                            return (
                              <Select.Option key={e.keyId} value={e.PAYCODE} disabled={blockedValues.includes(e.PAYCODE)}>
                                {e.PAYCODE} ({e.PAYDESC})
                              </Select.Option>
                            )
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="จำนวนเงิน" name="money" rules={[{ required: true, message: 'กรุณากรอกจำนวนเงิน !' },]}>
                        <InputNumber
                          addonAfter="บาท"
                          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                          size="large"
                          placeholder="กรอกจำนวนเงิน"
                          style={{ width: '100%', color: 'black' }}
                          onChange={(value) => funcMoney(value)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="" name="sm">
                        <div style={{ textAlign: "center", }}>
                          <Button disabled={loading || checkDataInsert || !checkAgain || checkCt} type='primary' htmlType="submit" >บันทึกข้อมูล</Button>
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form >
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Card title={<div style={{ textAlign: "center" }}>รายละเอียดที่รับเข้า</div>} bordered={false}>
                <Form form={form3} key={formKey3} >
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.Item label="เลขที่บิล" name="codeQ">
                        <Input
                          variant="borderless"
                          disabled
                          size="large"
                          value={dataCode.codeQ}
                          style={{ width: "100%", color: "black" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} style={{ textAlign: "center" }}>
                      <div>
                        <span style={{ fontWeight: "bold" }}>เลขที่สัญญา:</span>
                        <b style={{ color: "blue", marginLeft: "8px" }}>{dataSend?.CONTNO}</b>
                      </div>
                    </Col>
                    <Col span={24} style={{ textAlign: "center" }}>
                      <div>
                        <span style={{ fontWeight: "bold" }}>จำนวนเงิน:</span>
                        <b style={{ marginLeft: "8px" }}>{currencyFormatOne(dataSend?.money)}</b>
                        <span style={{ marginLeft: "5px" }}>บาท</span>
                      </div>
                    </Col>
                    {/* <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
                      <Card style={{ borderColor: "#000", padding: "10px", background: "#fafafa", width: "80%" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                          <span style={{ fontWeight: "bold" }}>เข้ารับฝาก:</span>
                          <b style={{ fontSize: "14px", color: "red" }}>{currencyFormatOne(dataCal.sumAmt)}</b>
                          <span style={{ marginLeft: "5px" }}>บาท</span>
                        </div>
                      </Card>
                    </Col> */}
                    <Divider />
                    <Col span={24} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                      <Form.Item label="" style={{ width: "80%" }}>
                        <TextArea
                          rows={12}
                          cols={60}
                          type="text"
                          disabled={checkCt}
                          value={dataSend?.MEMO1 || ""}
                          onChange={(e) => handleMEMO1(e.target.value)}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                      <Button disabled={checkCt} type="primary" htmlType="submit" style={{ marginTop: "10px" }} onClick={handleSubmitMemo}>
                        บันทึกโน๊ต
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
            {/* </Row> */}
            <Divider />
            <Table
              rowKey={(record) => record.keyId}
              dataSource={dataHDPAYMENT}
              columns={mainColumnsHDPAYMENT}
              scroll={{
                x: 1500,
                y: 400,
              }}
              style={{ padding: 20 }}
            >
            </Table>
          </Row >
          {/* </Col> */}
        </Card >
      </Spin >
      {contextHolder}
      {
        showTable && dataRec &&
        <div className="print-only" ref={componentPDF} style={{ textAlign: 'center' }}>
          <PaymentJBF tableSearch={dataRec} userPrint={userPrint} />
        </div>
      }
    </>
  )
}
