import React, { useState, useEffect } from "react";
import { Button, Card, Col, Divider, Form, Input, Row, Space, Spin, Table, notification, Modal, Select } from 'antd'
import axios from 'axios';
import dayjs from "dayjs";
import { msError, msConnt, msData, msCancel, msErrorInst, msOK } from "../file_mid/allFormat";
import { loadDataCancelHD, updateCancelHD } from "../file_mid/all_api";
import { optionsSearchPay } from "../file_mid/all_options";
import { loadDataFindPAYTYP } from "../loan_history/load_data_print/data_pay";

export default function MainCancel() {
  const token = localStorage.getItem('token')
  // const currentDateTime = dayjs()
  const { confirm } = Modal
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [formKey, setFormKey] = useState(0);
  const [formKey2, setFormKey2] = useState(0);
  const [isClickedTy, setIsClickedTy] = useState(1); // เลือกค้นหาจาก
  const [findPAYTYP, setFindPAYTYP] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [dataSend, setDataSend] = useState({ CONTNO: "", textData: "", textData2: "" });
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataHDPAYMENT, setDataHDPAYMENT] = useState([]);
  useEffect(() => {
    if (reload) {
      handleSubmit()
    }
  }, [reload])
  const currencyFormatOne = (amount) => {
    if (amount) {
      return Number(amount)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    } else {
      return "-"
    }
  }
  const dayFormatOne = (value) => {
    if (value) {
      return dayjs(value).format('DD-MM-YYYY');
    } else {
      return ""
    }
  }
  const dataHDPAYMENTTable = [
    {
      title: "ลำดับ",
      dataIndex: "index",
      key: 'index',
      width: "auto",
      align: 'center',
      render: (text, object, index) => index + 1
    },
    {
      title: "ใบเสร็จ",
      dataIndex: "TEMPBILL",
      key: 'TEMPBILL',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <div>
          <div>{record.TEMPBILL}</div>
          <div>{dayFormatOne(record.TEMPDATE)}</div>
        </div>
      ),
    },
    {
      title: "เลขที่สัญญา",
      dataIndex: "CONTNO",
      key: 'CONTNO',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <>{record.CONTNO}</>
      ),
    },
    {
      title: "สาขา",
      dataIndex: "LOCAT",
      key: 'LOCAT',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <>{record.LOCAT}</>
      ),
    },
    {
      title: "รหัสชำระ",
      dataIndex: "TYPCODE",
      key: 'TYPCODE',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <>{record.TYPCODE}</>
      ),
    },
    {
      title: "ยอดรับฝาก",
      dataIndex: "TOTAMT",
      key: 'TOTAMT',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <>{currencyFormatOne(record.TOTAMT)}</>
      ),
    },
    {
      title: "ถูกใช้แล้ว",
      dataIndex: "TOTAMT",
      key: 'TOTAMT',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <>{currencyFormatOne(record.BILLAMT)}</>
      ),
    },
    {
      title: "คงเหลือ",
      dataIndex: "TOTAMT",
      key: 'TOTAMT',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <>{currencyFormatOne(record.TOTAMT - record.BILLAMT)}</>
      ),
    },
    {
      key: 'Action',
      title: "Action",
      align: 'center',
      width: "auto",
      render: (record) => (
        <Space size="middle">
          {
            record.BILLAMT === 0 ?
              <Button type="primary" onClick={() => showConfirm(record)}>ยกเลิกใบเสร็จ</Button>
              :
              null
          }
        </Space>
      ),
    },
  ]

  const showConfirm = (value) => {
    // console.log(value)
    confirm({
      title: (
        <Row>
          <Form form={form}>
            <Col span={24}>
              <Form.Item label="" style={{ margin: 0 }}>
                <b>{"คอมเฟิร์มทำรายการ"}</b>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="เลขที่สัญญา" style={{ margin: 0 }}>
                <b style={{ color: 'blue' }}>{value?.CONTNO}</b>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="ใบเสร็จ" style={{ margin: 0 }}>
                <b style={{ color: 'blue' }}>{value?.TEMPBILL}</b>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="จำนวนเงิน" style={{ margin: 0 }}>
                <b style={{ color: 'blue' }}>{currencyFormatOne(value?.TOTAMT)}</b>
                <span style={{ marginLeft: '10px' }}>บาท</span>
              </Form.Item>
            </Col>
          </Form>
        </Row>
      ),
      content: 'กด OK เพื่อยืนยัน',
      onOk() {
        setLoading(true);
        setFormKey(prevKey => prevKey + 1);
        setFormKey2(prevKey => prevKey + 1);
        UpdateHD(value);
      },
      onCancel() {
        setFormKey(prevKey => prevKey + 1);
        setFormKey2(prevKey => prevKey + 1);
        msCancel(api, "top");
      },
    });
  };

  const UpdateHD = async (value) => {
    setFormKey(prevKey => prevKey + 1);
    setFormKey2(prevKey => prevKey + 1);
    console.log("value : ", value)
    if (value) {
      const tk = JSON.parse(token)
      const headers = {
        "Authorization": `Bearer ${tk}`,
        "Menu": JSON.stringify("26")
      }
      await axios.post(updateCancelHD, value, { headers: headers })
        .then(async (res) => {
          console.log("update : ", res.data)
          if (res.status === 200) {
            setLoading(false)
            setReload(true)
            msOK(api, "top")
          } else {
            msErrorInst(api, "top")
            setLoading(false)
          }
        })
        .catch((err) => {
          console.log("err", err)
          msError(api, "top")
          setLoading(false)
        })
    } else {
      msErrorInst(api, "top");
      setLoading(false);
    }
  }

  const handleSubmit = async () => {
    setReload(false);
    setFormKey(prevKey => prevKey + 1);
    console.log("dataSend?.CONTNO =", dataSend?.CONTNO)
    if (dataSend?.CONTNO !== "") {
      setLoading(true)
      await axios.post(loadDataCancelHD, dataSend)
        .then((res) => {
          if (res.status === 200) {
            setDataHDPAYMENT(res.data)
          } else if (res.status === 201) {
            setDataHDPAYMENT([])
            msData(api, "top")
          } else {
            setDataHDPAYMENT([])
            msConnt(api, "top")
          }
          console.log("res.data", res.data)
          setLoading(false)
        })
        .catch((err) => {
          console.log("err", err)
          // setArrayTable([])
          msError(api, "top")
          setLoading(false)
        })
    } else if (isClickedTy === 2 || isClickedTy === 3 || isClickedTy === 4) {
      if (dataSend.textData !== "") {
        setLoading(true)
        try {
          const results = await loadDataFindPAYTYP(dataSend.textData, dataSend.textData2, isClickedTy);
          if (!results) {
            msData(api, "top")
            setFindPAYTYP([])
          } else {
            setFindPAYTYP(results)
          }
        } catch (error) {
          console.error("Error in loadData: ", error);
          msData(api, "top")
          setFindPAYTYP([])
        } finally {
          setLoading(false);
        }
      }
    } else {
      msConnt(api, "top")
    }
  }

  const handleSelectCONTNO = async (value) => {
    console.log(value)
    if (value) {
      setLoading(true);
      setTimeout(() => {
        setDataSend({ ...dataSend, CONTNO: value })
        setLoading(false);
      }, 1000);
    }
  }
  const handleCONTNO = (e, number) => {
    // console.log(number, e.target.value)
    if (number === 31 || number === 32) {
      console.log("e.target.value", e.target.value)
      if (number === 31) {
        setDataSend({ ...dataSend, CONTNO: "", textData: e.target.value })
      } else {
        setDataSend({ ...dataSend, CONTNO: "", textData2: e.target.value })
      }
      setFindPAYTYP([])
      form.setFieldsValue({ CONTNO: "", })
    } else {
      if (isClickedTy === 1) {
        console.log("e.target.value", e.target.value)
        setDataSend({ ...dataSend, CONTNO: e.target.value, textData: "" })
      } else {
        console.log("e.target.value", e.target.value)
        // setLoading(true);
        // setTimeout(() => {
        setDataSend({ ...dataSend, CONTNO: "", textData: e.target.value })
        setFindPAYTYP([])
        //   setLoading(false);
        // }, 1000);
        form.setFieldsValue({ CONTNO: "", })

      }
    }
  }

  const showConfirmClear = () => {
    confirm({
      title: 'คุณต้องการที่ล้างข้อมูลนี้ใช่หรือไม่ ?',
      content: 'กด OK เพื่อยืนยัน',
      onOk() {
        // setLoading(true)
        clearData(0)
      },
      onCancel() {
        msCancel(api, "top")
      },
    });
  }
  const clearData = async (value) => {
    setLoading(true);
    console.log("clearData", value)
    if (value > 0) {
      setIsClickedTy(value)
      form.setFieldsValue({ CONTNO: "", textData: "", textData2: "", searchPay: value })
    } else {
      setIsClickedTy(1)
      form.setFieldsValue({ CONTNO: "", textData: "", textData2: "", searchPay: 1 })
    }
    setTimeout(() => {
      setDataSend({ CONTNO: "", textData: "", textData2: "" });
      setFindPAYTYP([])
      setLoading(false);
    }, 1000);
  }

  return (
    <>
      <Spin spinning={loading} size='large' tip="Loading...">
        <Card style={{ height: '100%' }}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <div className='text-center'>
                <h2>ยกเลิกใบเสร็จ (รับฝาก)</h2>
              </div>
            </Col>
            <Divider />
            <Col xs={24} sm={24} md={12}>
              <Card title={<div style={{ textAlign: "center" }}>ค้นหา</div>} bordered={false}>
                <Form
                  form={form}
                  key={formKey}
                  onFinish={handleSubmit}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.Item label="ค้นหาจาก" name="searchPay">
                        <Select
                          style={{ width: "100%" }}
                          defaultValue={isClickedTy}
                          onChange={clearData}
                          options={optionsSearchPay}
                        />
                      </Form.Item>
                    </Col>
                    {
                      isClickedTy === 1 ?
                        <Col span={24}>
                          <Form.Item label="เลขสัญญา" name="CONTNO">
                            <Input onChange={(e) => handleCONTNO(e, 0)}></Input>
                          </Form.Item>
                        </Col>
                        :
                        <>
                          {
                            isClickedTy === 3 ?
                              <>
                                <Col span={24}>
                                  <Form.Item label="ชื่อ-สกุล" name="textData"
                                    rules={[{ required: true, message: 'กรุณากรอกชื่อ-สกุล !' },]}
                                  >
                                    <Input onChange={(e) => handleCONTNO(e, 31)}></Input>
                                  </Form.Item>
                                  <div style={{ textAlign: "center", marginTop: "5px" }}>
                                      <b style={{ fontSize: "14px", color: "red" }}>
                                        ***ไม่จำเป็นต้องใส่คำนำหน้าชื่อ***
                                      </b>
                                    </div>
                                </Col>
                              </>
                              :
                              <Col span={24}>
                                <Form.Item label="ข้อความ" name="textData">
                                  <Input onChange={(e) => handleCONTNO(e, 0)}></Input>
                                </Form.Item>
                              </Col>
                          }
                          {
                            findPAYTYP?.length > 0 ?
                              <Col span={24}>
                                <Form.Item label="เลือกสัญญา" name="CONTNO">
                                  <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    onChange={handleSelectCONTNO}
                                    optionFilterProp="children"
                                    placeholder="Search to Select"
                                    filterOption={(inputValue, option) =>
                                      option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                    }
                                  >
                                    {findPAYTYP?.map((e) => {
                                      return (
                                        <Select.Option key={e.keyId} value={e.CONTNO}>
                                          {e.CONTNO} (ทะเบียน {e.REGNO}) (ชื่อ {e.NAME1} {e.NAME2})
                                        </Select.Option>
                                      )
                                    })}
                                  </Select>
                                </Form.Item>
                              </Col>
                              : null
                          }
                        </>
                    }
                    <Col span={12}>
                      <Button disabled={loading} type="primary" htmlType="submit" block>
                        ค้นหา
                      </Button>
                    </Col>
                    <Col span={12}>
                      <Button disabled={loading} onClick={showConfirmClear} block>
                        Clear
                      </Button>
                    </Col>
                    <Divider />
                    {/* <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
                      <Card style={{ width: "80%", textAlign: "center", border: "1px solid #ddd", boxShadow: "2px 2px 10px rgba(0,0,0,0.1)", padding: "15px" }}>
                        <div style={{ marginBottom: "10px" }}>
                          <span style={{ fontWeight: "bold" }}>เกรด:</span>
                          <b style={{ marginLeft: "8px", fontSize: "16px", color: "red" }}>{sum?.GRDCOD}</b>
                        </div>
                        <Divider />
                        <div>
                          <span style={{ fontWeight: "bold" }}>ค่างวด:</span>
                          <b style={{ marginLeft: "8px", fontSize: "16px", color: "red" }}>{currencyFormatOne(sum?.amt)}</b>
                          <span style={{ marginLeft: "5px" }}>บาท</span>
                        </div>
                      </Card>
                    </Col> */}
                  </Row>
                </Form>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Card title={<div style={{ textAlign: "center" }}>รายละเอียดสัญญา</div>} bordered={false}>
                {
                  dataHDPAYMENT && dataHDPAYMENT?.length > 0 ?
                    <Form form={form2} key={formKey2}>
                      <Row gutter={[16, 16]}>
                        <Col span={24} style={{ textAlign: "center" }}>
                          <div>
                            <span style={{ fontWeight: "bold" }}>เลขที่สัญญา:</span>
                            <b style={{ color: "blue", marginLeft: "8px" }}>{dataHDPAYMENT[0]?.CONTNO}</b>
                          </div>
                        </Col>
                        <Col span={24} style={{ textAlign: "center" }}>
                          <div>
                            <span style={{ fontWeight: "bold" }}>สาขาสัญญา:</span>
                            <b style={{ color: "blue", marginLeft: "8px" }}>{dataHDPAYMENT[0]?.LOCAT}</b>
                          </div>
                        </Col>
                        <Divider />
                      </Row>
                    </Form>
                    :
                    null
                }
              </Card>
            </Col>
          </Row>
          <Divider />
          {
            dataHDPAYMENT && dataHDPAYMENT?.length > 0 ?
              <Table
                rowKey={(record) => record.keyId}
                dataSource={dataHDPAYMENT}
                columns={dataHDPAYMENTTable}
                scroll={{
                  x: 1500,
                  y: 400,
                }}
                style={{ padding: 20 }}
              >
              </Table>
              :
              <><Divider orientation="center"><b style={{ fontSize: "16px", color: 'red' }}>*** ไม่พบข้อมูล ***</b></Divider></>
          }
        </Card >
      </Spin >
      {contextHolder}
    </>
  )
}
