import { useState, useEffect } from "react";
import { Row, Col, Button, Input, Drawer, Space, Dropdown, Menu, Form } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Link, useNavigate } from 'react-router-dom';
import './SlideNav.css'

const logsetting = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4892 3.17094C11.1102 1.60969 8.8898 1.60969 8.51078 3.17094C8.26594 4.17949 7.11045 4.65811 6.22416 4.11809C4.85218 3.28212 3.28212 4.85218 4.11809 6.22416C4.65811 7.11045 4.17949 8.26593 3.17094 8.51078C1.60969 8.8898 1.60969 11.1102 3.17094 11.4892C4.17949 11.7341 4.65811 12.8896 4.11809 13.7758C3.28212 15.1478 4.85218 16.7179 6.22417 15.8819C7.11045 15.3419 8.26594 15.8205 8.51078 16.8291C8.8898 18.3903 11.1102 18.3903 11.4892 16.8291C11.7341 15.8205 12.8896 15.3419 13.7758 15.8819C15.1478 16.7179 16.7179 15.1478 15.8819 13.7758C15.3419 12.8896 15.8205 11.7341 16.8291 11.4892C18.3903 11.1102 18.3903 8.8898 16.8291 8.51078C15.8205 8.26593 15.3419 7.11045 15.8819 6.22416C16.7179 4.85218 15.1478 3.28212 13.7758 4.11809C12.8896 4.65811 11.7341 4.17949 11.4892 3.17094ZM10 13C11.6569 13 13 11.6569 13 10C13 8.34315 11.6569 7 10 7C8.34315 7 7 8.34315 7 10C7 11.6569 8.34315 13 10 13Z"
      // fill="#111827"
      style={{ fill: 'white' }} // กำหนด fill เป็นสีขาว
    ></path>
  </svg>,
];

const profile = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
      // fill="#111827"
      style={{ fill: 'white' }} // กำหนด fill เป็นสีขาว
    ></path>
  </svg>,
];

const toggler = [
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    key={0}
  >
    <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
      style={{ fill: 'white' }} // กำหนด fill เป็นสีขาว
    ></path>
  </svg>,
];

function Header({
  placement,
  name,
  icon,
  subName,
  onPress,
  handleSidenavColor,
  handleSidenavType,
  handleFixedNavbar,
}) {
  const [visible, setVisible] = useState(false);
  // const [sidenavType, setSidenavType] = useState("transparent");

  useEffect(() => window.scrollTo(0, 0));

  const showDrawer = () => setVisible(true);
  const hideDrawer = () => setVisible(false);

  const navigate = useNavigate()
  const user = localStorage.getItem('username');

  const handleLogout = () => {
    localStorage.removeItem('username')
    localStorage.removeItem('menu')
    localStorage.removeItem('token')
    localStorage.removeItem('nickname')
    localStorage.removeItem('nicknameSalcod')
    localStorage.removeItem('branch')
    localStorage.removeItem('firstname')
    localStorage.removeItem('lastname')
    localStorage.removeItem('idSep')
    localStorage.removeItem('idDepartment')
    localStorage.removeItem('roleId')
    // localStorage.removeItem('currentToken')
    window.location.reload(false)
    //navigate('/')
  };
  
  const handleResetPass = () => {
    navigate('/resetpass')
  };
  const handleToProfile = () => {
    navigate('/profiles')
  };

  const items = [
    {
      key: '1',
      label: (
        <Link to="/profiles" >
          หน้า profile
        </Link>
      ),
    },
    {
      key: '2',
      label: (
        <Link to="/resetpass">
          เปลี่ยนรหัสผ่านใหม่
        </Link>
      ),
    },
    {
      key: '3',
      label: (
        <a onClick={handleLogout}>
          ออกจากระบบ
        </a>
      ),
    },
    {
      key: '4',
      label: (
        <></>
        // <a onClick={handleLogout}>
        // ออกจากระบบ
        // </a>
      ),
    },
  ];
  return (
    <>
      <div className="main5" >
        {/* <aside className="sum"> */}
        <div className="start">
          <div className="menu-start" style={{ lineHeight: '32px', color: 'white', fontSize: '20px', marginLeft: '20px' }}><b>{name}</b></div>
          <div className="menu-start" style={{ lineHeight: '32px', color: 'white', fontSize: '20px', marginLeft: '20px' }}><b>{subName}</b></div>
          {/* แก้ไขเวอร์ชั่นตรงนี้ */}
          <div className="menu-start" style={{ lineHeight: '32px', color: 'white', fontSize: '20px', marginLeft: '20px' }}><b><u>V. 6.6++</u></b></div>
        </div>
        <div className="end-new">
          {/* <Input
              className="menu-end"
              style={{ width: '50%', }}
              placeholder="ค้นหา...."
              prefix={<SearchOutlined />}
              name="search"
              id="search"
            /> */}
          <b className="menu-end" style={{ fontSize: '18px', color: 'white' }}>
            <i id="icon" style={{ color: 'white' }}>{profile}</i>
          </b>
          <b className="menu-end" style={{ fontSize: '18px', color: 'white' }}>
            <span id="text" style={{ color: 'white' }}>{user}</span>
          </b>
          <b style={{ fontSize: '18px', color: 'white' }}>
            <Link className="menu-end" type="link" onClick={showDrawer}>
              <i id="icon" style={{ color: 'white' }}> {logsetting}</i>
            </Link>
          </b>

        </div>
        {/* </aside> */}
      </div >
      <Drawer
        className="settings-drawer"
        mask={true}
        width={360}
        onClose={hideDrawer}
        placement={placement}
        open={visible}
        title={user}
      >
        <Row gutter={24}>
          <Col className="gutter-row" span={24} style={{ margin: 3 }}>
            <Form.Item style={{ margin: 0 }}>
              <Button style={{ width: '100%' }} onClick={handleToProfile}>Profile</Button>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={24} style={{ margin: 3 }}>
            <Form.Item style={{ margin: 0 }}>
              <Button style={{ width: '100%' }} onClick={handleResetPass}>ResetPassword</Button>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={24} style={{ margin: 3 }}>
            <Form.Item style={{ margin: 0 }}>
              <Button style={{ width: '100%' }} onClick={handleLogout}>Logout</Button>
            </Form.Item>
          </Col>
        </Row>
      </Drawer>
      {/* </Row> */}
    </>
  );
}

export default Header;
