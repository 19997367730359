import React, { useState, useEffect } from "react";
import { EditOutlined, SearchOutlined, ReadOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Table, Divider, Space, Card, Radio, Tag, notification, Select, DatePicker, Statistic, Typography, Spin, Pagination } from 'antd';

import { useDispatch } from 'react-redux';
import { addCustomer } from '../../redux/Customer';
import { addCar } from '../../redux/Car';
import { addGuarantor } from '../../redux/Guarantor';

import dayjs from "dayjs";
import axios from "axios";
import ModalEditCarRate from "./modals/ModalEditCarRate";
import ModalPrint from "./modals/ModalPrint";
import ModalPreApprove from "./modals/ModalPreApprove";
import ModalAccept from "./modals/ModalAccept";

import { backc, textc, ApprovalStatusColor } from "../file_mid/status_color";
import { op } from "../file_mid/all_options";
import { pgmaindata, pgmaindataksm } from "../file_mid/all_api";
import ModalInfoADPGDC from "../file_mid/info/ModalInfoADPGDC";
import ModalNote from "../report_pg/Modal/Note";
const { Text } = Typography;
const { Countdown } = Statistic;

export default function Main() {

  const currentDateTime = dayjs()
  // const currentDateDay2 = dayjs().add(1,'day')
  const currentDateDay2 = dayjs().format("YYYY-MM-DD")
  const showDay1 = dayjs("2023-01-01")
  const [dataPost, setDataPost] = useState({ sec: 0, days1: dayjs("2023-01-01"), days2: currentDateDay2, approvalStatus: "19" })
  const dispatch = useDispatch()
  // const guarantor = useSelector(state => state.guarantors)
  const [editData, setEditData] = useState();
  const user = localStorage.getItem('username');
  const branch = localStorage.getItem('branch');
  const [modalCarRate, setModalCarRate] = useState(false); // Edit Car Rate
  const [modalPrint, setModalPrint] = useState(false); // Info Print
  // const [modalInfoLand, setModalInfoLand] = useState(false); // Info Land
  const [modalAccept, setModalAccept] = useState(false); // Approve
  const [isModalPreApprove, setIsModalPreApprove] = useState(false); // PreApprove
  const [cData, setCData] = useState([]);
  const [axiosData, setAxiosData] = useState([]); // Table
  const [arrayTable, setArrayTable] = useState(); // Array Table
  const [keyWord, setKeyWord] = useState("ผ่านการวิเคราะห์");
  const [api, contextHolder] = notification.useNotification();
  const [statusNoti, setStatusNoti] = useState("0");
  const token = localStorage.getItem('token')
  const [loading, setLoading] = useState(false)
  const [testPage, setTestPage] = useState();
  const [ssPage, setSsPage] = useState({ numberPage: 1, pageSize: 10 });
  const [currentPage, setCurrentPage] = useState(1)
  const [query, setQuery] = useState("");
  const [isModalInfoADPGDC, setIsModalInfoADPGDC] = useState(false);
  const [sendback, setSendBack] = useState(false);
  const [isModalNote, setIsModalNote] = useState(false)
  const [isOpenNewNote, setIsOpenNewNote] = useState()
  const [recordData, setRecordData] = useState()
  
  // console.log('arrayTable',arrayTable);
  

  useEffect(() => {
    loadData();
    openNotification('top')
  }, [keyWord, editData,sendback, modalCarRate, modalPrint, isModalInfoADPGDC, modalAccept, isModalPreApprove, dataPost.approvalStatus, dataPost.days1, dataPost.days2, ssPage])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchLoadData(query)
    }, 1500)
    return () => clearTimeout(delayDebounceFn)
  }, [query])
  const searchLoadData = async (data) => {
    if (data !== "") {
      setCurrentPage(1)
      await loadData(data)
    }
  }
  const search = async (data) => {
    if (data.target.value !== "") {
      setQuery(data.target.value)
    } else {
      setSsPage({ numberPage: 1, pageSize: 10 })
      setCurrentPage(1)
      setDataPost({ sec: 0, days1: dayjs("2023-01-01"), days2: currentDateDay2, approvalStatus: "19" })
      setQuery("")
    }
  };
  const onChangeKeyWord = (value) => {
    setQuery("")
    setDataPost({ ...dataPost, approvalStatus: value })
    setCurrentPage(1)
    setSsPage({ numberPage: 1, pageSize: 10 })
  }
  const onChangePagination = (e, pageSize) => {
    // console.log(e, pageSize)
    setCurrentPage(e)
    setSsPage({ numberPage: e, pageSize: pageSize })
  }

  const loadCountData = async (data) => {
    // const ez = data.filter(
    //   (item) =>
    //     item.approvalStatus === dataPost?.approvalStatus
    // );
    // setTestPage(...ez)
    // setCData(data)


    if (!data) {
      setCData([])
    } else {
      const ez = data.filter(
        (item) =>
          item.approvalStatusId === dataPost?.approvalStatus
        // item.approvalStatusId === keyWord
      );
      setTestPage(...ez)
      setCData(data)
    }

  }
  const loadData = async (data) => {
    setLoading(true)
    let mainData
    const tk = JSON.parse(token)
    const headers = {
      "Authorization": `Bearer ${tk}`,
      "Menu": JSON.stringify("5")
    }
    if (query !== "" && data !== "stop") {
      mainData = { reviewedBy: user, approvalStatus: dataPost.approvalStatus, numberPage: ssPage?.numberPage, pageSize: ssPage?.pageSize, keyword: query, day1: dataPost.days1, day2: dataPost.days2 }
    } else {
      if (data === "stop") {
        mainData = { reviewedBy: user, approvalStatus: "19", numberPage: ssPage?.numberPage, pageSize: ssPage?.pageSize, day1: dataPost.days1, day2: dataPost.days2 }
      } else {
        mainData = { reviewedBy: user, approvalStatus: dataPost.approvalStatus, numberPage: ssPage?.numberPage, pageSize: ssPage?.pageSize, day1: dataPost.days1, day2: dataPost.days2 }
      }
    }
    if (branch === "MIT" || branch === "UD" || branch === "LEX" || branch === "S4" || branch === "S5" ) {
      await axios.post(pgmaindata, mainData, { headers: headers })
        .then(async (res) => {
          console.log("res.data", res.data)
          if (res.data.ShowTablePG) {
            setArrayTable(res.data.ShowTablePG)
            setAxiosData(res.data.ShowTablePG)
          } else {
            setArrayTable([])
            setAxiosData([])
          }
          if (res.data.Countstatus) {
            loadCountData(res.data.Countstatus)
          } else {
            setTestPage()
            setCData([])
          }
          setLoading(false)
        }).catch((err) => console.log(err))
      setLoading(false)
    } else {
      await axios.post(pgmaindataksm, mainData, { headers: headers })
        .then(async (res) => {
          if (res.data.ShowTablePG) {
            setArrayTable(res.data.ShowTablePG)
            setAxiosData(res.data.ShowTablePG)
          } else {
            setArrayTable([])
            setAxiosData([])
          }
          if (res.data.Countstatus) {
            loadCountData(res.data.Countstatus)
          } else {
            setTestPage()
            setCData([])
          }
          setLoading(false)
        }).catch((err) => console.log(err))
      setLoading(false)
    }
  }

  // const search = (data) => {
  //   // อนาคต ควรหาใน database โดยตรง
  //   const greaterThanTen = axiosData.filter(
  //     (item) =>
  //       item.firstName.toLowerCase().includes(data.target.value) ||
  //       item.lastName.toLowerCase().includes(data.target.value) ||
  //       item.carPlateNumber.toLowerCase().includes(data.target.value)
  //   );
  //   setArrayTable(greaterThanTen)
  // };

  const columns = [
    {
      fixed: true,
      title: "ลำดับ",
      dataIndex: "index",
      key: 'index',
      width: "5%",
      align: 'center',
      render: (text, object, index) => index + 1
    },
    {
      key: 'carInfo',
      title: "รายละเอียด",
      dataIndex: "carPlateNumber",
      width: "25%",
      align: 'center',
      render: (text, record) => (
        <>
          <div>{record.snam}{record.firstName} {record.lastName}</div>
          <div>
            {
              record.car === "car" ?
                <div>
                  <div>{record.carBrand} {record.carModel} ปีรถ {parseInt(record.carYear) + 543} เลขทะเบียน {record.carPlateNumber}</div>
                  <div>เสนอโดย <Space style={{ color: "red" }}><b>{record.proposalBy}</b></Space></div>
                  {
                    record.reviewedBy !== null ?
                      <>
                        <div>ธุรการที่ได้เคส <Space style={{ color: "red" }}><b>{record.reviewedBy}</b></Space></div>
                        <div>เวลาผ่านการวิเคราะห์ <Space style={{ color: "red" }}><b>{dayjs(record.acceptPassDate).format("DD-MM-YYYY HH:mm:ss")}</b></Space></div>
                        {record.ISSUNO === "" ? null : <div>มาจากสัญญา <Space style={{ color: "red" }}><b>{record.ISSUNO}</b></Space></div>}
                      </>
                      : null
                  }
                </div>
                :
                <div>
                  <div>{record.carBrand} {record.carModel} ตารางวา {record.carYear} เลขโฉนด {record.carPlateNumber}</div>
                  <div>เสนอโดย <Space style={{ color: "red" }}><b>{record.proposalBy}</b></Space></div>
                  {
                    record.reviewedBy !== null ?
                      <>
                        <div>ผ่านการวิเคราะห์โดย <Space style={{ color: "red" }}><b>{record.reviewedBy}</b></Space></div>
                        <div>เวลาผ่านการวิเคราะห์ <Space style={{ color: "red" }}><b>{dayjs(record.acceptPassDate).format("DD-MM-YYYY HH:mm:ss")}</b></Space></div>
                        {record.ISSUNO === "" ? null : <div>มาจากสัญญา <Space style={{ color: "red" }}><b>{record.ISSUNO}</b></Space></div>}
                      </>
                      : null
                  }
                </div>
            }
          </div >
        </>
      )
    },
    {
      key: 'productLoanType',
      title: "ประเภทขอกู้",
      dataIndex: "productLoanType",
      width: "10%",
      align: 'center',
      render: (text, record) => (
        <>
          <div>
            {
              record.car === "car" ?
                <Tag color="purple">รถ</Tag>
                :
                <Tag color="green">ที่ดิน</Tag>
            }
            {
              <Tag color="geekblue">{record.productLoanType}</Tag>
            }
          </div>
        </>
      ),
    },
    {
      key: 'loanAmount',
      title: "ยอดขอกู้",
      dataIndex: "loanAmount",
      width: "10%",
      align: 'center',
      sorter: {
        compare: (a, b) => a.loanAmount - b.loanAmount,
        multiple: 2,
      },
      render: (text, record) => (
        <Space size="middle">
          {
            <Space>
              <>{currencyFormatOne(record.loanAmount)}</>
            </Space>
          }
        </Space>
      ),
    },
    {
      title: "เรท",
      dataIndex: "carPrice",
      align: 'center',
      width: "10%",
      sorter: {
        compare: (a, b) => a.carPrice - b.carPrice,
        multiple: 2,
      },
      render: (text, record) => (
        <Space size="middle">
          {
            <Space>
              <>{currencyFormatOne(record.carPrice)}</>
            </Space>
          }
        </Space>
      ),
    },
    {
      title: "วันที่เสนอ",
      dataIndex: "carInput",
      align: 'center',
      width: "10%",
      // defaultSortOrder: 'descend',
      sorter: {
        compare: (a, b) => dayjs(a.carInput) - dayjs(b.carInput),
        multiple: 1,
      },
      render: (text, record) => (
        <>{dayjs(record.carInput).format("DD-MM-YYYY HH:mm")}</>
      ),

    },
    {
      title: "วันที่รับ",
      dataIndex: "acceptDatePg",
      width: "10%",
      align: 'center',
      sorter: {
        compare: (a, b) => dayjs(a.acceptDatePg) - dayjs(b.acceptDatePg),
        multiple: 2,
      },
      render: (text, record) => (
        <Space size="middle">
          {
            record.acceptDatePg !== "0001-01-01T00:00:00Z" ?
              <Space>
                <>{dayjs(record.acceptDatePg).format("DD-MM-YYYY HH:mm")}</>
              </Space>
              : <Text type="secondary">ยังไม่รับงาน</Text>
          }
        </Space>
      ),
    },
    {
      title: "ระยะเวลาที่คาดว่าจะเสร็จ",
      dataIndex: "",
      width: "10%",
      align: 'center',
      render: (text, record) => (
        <Space size="middle">
          {
            record.acceptDate !== "0001-01-01T00:00:00Z" ?
              <Space>
                <Countdown value={dayjs(record.acceptDate).add(3, 'hour')} />
              </Space>
              : <Text type="secondary">ยังไม่รับงาน</Text>
          }
        </Space>
      ),
    },
    {
      key: 'approvalStatus',
      title: "สถานะ",
      dataIndex: "approvalStatus",
      width: "10%",
      align: 'center',
      render: (text, record) => {
        let color
        color = ApprovalStatusColor(record.approvalStatus)
        // color = colorApprovalStatus(record.productLoanType, record.approvalStatus)
        return (
          <>
            <Tag color={color}>
              {record.approvalStatusName}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Action",
      align: 'center',
      width: "10%",
      render: (record) => (
        <Space size="middle">
          {
            record.approvalStatus === 9 ?
              <Tag color="geekblue">กรุณาติดต่อเร่งรัดภาคสนาม</Tag>
              : record.approvalStatus === 19 ?
                <Space>
                  <Button type="primary" style={{ backgroundColor: 'green' }} onClick={() => {
                    setModalAccept(true)
                    setEditData(record)
                  }}>รับงาน</Button>
                </Space>
                : record.approvalStatus === 2 ?
                  <Space>
                    <Button onClick={() => {
                      setIsModalPreApprove(true)
                      let dataCar = { carId: record.carId, typeLoan: record.car, loanId: record.loanId }
                      setIsModalPreApprove(true)
                      dispatch(addCustomer(record.customerId))
                      dispatch(addCar(record.carId))
                      dispatch(addGuarantor(dataCar))
                      setRecordData(record)
                    }}><EditOutlined /></Button>
                    <Button onClick={() => {
                      setIsModalInfoADPGDC(true)
                      setEditData(record)
                    }}><SearchOutlined /></Button>
                  </Space>
                  :
                  <>
                    <Button onClick={() => {
                      setEditData(record)
                      setIsModalInfoADPGDC(true)
                    }}><SearchOutlined /></Button>
                  </>
          }
        </Space>
      ),
    },
    {
      title: "หมายเหตุ",
      // dataIndex: "approvalStatus",
      align: 'center',
      width: "7%",
      render: (text, record) => (
        <Button
          onClick={() => {
            setIsOpenNewNote({ carLandId: record.carId, typeLoan: record.car })
            setIsModalNote(true)
          }}
        >
          <ReadOutlined />
        </Button>

      ),
    },
  ];

  const openNotification = (placement) => {
    if (statusNoti === "1") {
      api.success({
        message: <b>รับงานแล้ว</b>,
        description:
          'สถานะถูกเปลี่ยนจาก ผ่านการวิเคราะห์ เป็น รออนุมัติ',
        placement,
      });
      setStatusNoti("0")
    }
  };

  const currencyFormatOne = (amount) => {
    return Number(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  return (
    <>
      <Card
        bordered={false}
        style={{
          width: 'auto',
        }}
      >
        <Row style={{ textAlign: 'center' }}>
          <Col span={24}>
            <Spin spinning={loading} size='large' tip=" Loading... ">
              <div className='text-center'>
                <h2>ตรวจสอบข้อมูลการอนุมัติ</h2>
              </div>
              <Row gutter={32} justify={'center'}>
                <Row gutter={24} justify={'center'} style={{ margin: 10 }}>
                  <Col className='gutter-row' span={24} style={{ textAlign: 'center' }}>
                    <Space>
                      <>{"เลือกวันที่ต้องการแสดงเคส :"}</>
                      <DatePicker format={'YYYY-MM-DD'}
                        defaultValue={showDay1}
                        style={{ height: '40px' }}
                        onChange={(e) => {
                          if (e) {
                            setDataPost({ ...dataPost, days1: e.format('YYYY-MM-DD') })
                          }
                        }} />
                      <>{"ถึง"}</>
                      <DatePicker format={'YYYY-MM-DD'}
                        defaultValue={currentDateTime}
                        style={{ height: '40px' }}
                        onChange={(e) => {
                          if (e) {
                            setDataPost({ ...dataPost, days2: e.format('YYYY-MM-DD') })
                          }
                        }} />
                      {/* <span style={{ display: 'inline-block' }}>
                      <Button type="primary" onClick={(e) => { handleYG(e) }}>ตรวจสอบ</Button>
                    </span> */}
                    </Space>
                  </Col>
                </Row>

                <Row gutter={24} justify={'center'} style={{ margin: 10 }}>
                  <Col className='gutter-row' span={24} style={{ textAlign: 'center' }}>
                    <Space>
                      <>{"เลือกสถานะ :"}</>
                      <Select
                        // defaultValue={"ผ่านการวิเคราะห์"}
                        value={dataPost.approvalStatus}
                        style={{ width: 200 }}
                        // onChange={(value) => setDataPost({ ...dataPost, approvalStatus: value })}
                        onChange={(value) => onChangeKeyWord(value)}
                        options={op}
                      />
                    </Space>
                  </Col>
                </Row>
                <Divider style={{ margin: 5 }} />

                <Radio.Group disabled value={keyWord} onChange={(e) => setKeyWord(e.target.value)} >
                  {
                    cData
                      .sort((a, b) => {
                        const order = [
                          "รอธุรการรับ",
                          "รออนุมัติราคารถ",
                          "รอวิเคราะห์",
                          "ผ่านการวิเคราะห์",
                          "ไม่ผ่านการวิเคราะห์",
                          "รออนุมัติ",
                          "อนุมัติ",
                          "อนุมัติแบบมีเงื่อนไข",
                          "ไม่อนุมัติ",
                          "รอตรวจสอบเอกสาร",
                          "รอทำสัญญา",
                          "ลูกค้าปฏิเสธ",
                          "Reject",
                          "ทั้งหมด",
                        ];

                        return order.indexOf(a.approvalStatus) - order.indexOf(b.approvalStatus);
                      })
                      .map((item) => (
                        <Radio.Button
                          style={{
                            backgroundColor: backc[item.approvalStatus],
                            color: textc[item.approvalStatus]
                          }}
                          value={item.approvalStatus}
                        >
                          {item.approvalStatus} <b>{item.totalCount}</b>
                        </Radio.Button>
                      ))}
                </Radio.Group>
              </Row>
              <Row gutter={32} style={{ margin: 10 }}>
                <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                  <Input.Search
                    style={{ width: '250px' }}
                    placeholder="ค้นหา...."
                    onChange={search}
                    name="search"
                    id="search"
                    value={query}
                  />
                </Col>
              </Row>
              <Row gutter={32}>
                <Col span={24}>
                  <Table
                    pagination={false}
                    rowKey={(record) => record.uid}
                    dataSource={arrayTable}
                    columns={columns}
                    scroll={{
                      x: 1500,
                      y: 400,
                    }}
                    style={{ padding: 20 }}
                  >
                  </Table>
                  <Divider />
                  <Pagination
                    current={currentPage}
                    onChange={onChangePagination}
                    onShowSizeChange={onChangePagination}
                    defaultPageSize={10}
                    defaultCurrent={1}
                    total={testPage?.totalCount}
                  />
                  <Divider />
                </Col>
              </Row>
            </Spin>
          </Col>
        </Row>
      </Card>
      {
        modalCarRate ?
          <ModalEditCarRate
            open={modalCarRate}
            close={setModalCarRate}
            dataFromTable={editData}
          />
          : null
      }
      {
        modalPrint ?
          <ModalPrint
            open={modalPrint}
            close={setModalPrint}
            dataFromTable={editData}
          />
          : null
      }
      {
        modalAccept ?
          <ModalAccept
            open={modalAccept}
            close={setModalAccept}
            dataFromTable={editData}
            setStatusNoti={setStatusNoti}
          />
          : null
      }
      {
        isModalPreApprove ?
          <ModalPreApprove
            open={isModalPreApprove}
            close={setIsModalPreApprove}
            dataFromTable={recordData}
            SendBack={setSendBack}
          />
          : null
      }
      {
        isModalInfoADPGDC ?
          <ModalInfoADPGDC open={isModalInfoADPGDC} close={setIsModalInfoADPGDC} dataFromTable={editData} typeLoans={editData.car} checkPosi={"pg"} />
          : null
      }
      {
        isModalNote ?
          <ModalNote open={isModalNote} close={setIsModalNote} dataFromTable={isOpenNewNote} />
          : null
      }
      {contextHolder}
    </>
  )
}

