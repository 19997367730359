import React, { useState } from "react";
import { Layout, Button, Row, Col, Typography, Form, Input, Modal, Spin } from "antd";
import logo from "../assets/images/logo.png";
import axios from "axios";
import liff from '@line/liff';
import { loginline } from "./file_mid/all_api";


export default function SignInLine() {
    console.log("klogin")
    const { Title } = Typography;
    const [loading, setLoading] = useState(false)
    const error = () => {
        Modal.error({
            title: 'Error',
            content: 'ล็อคอินไม่สำเร็จ กรุณาตรวจสอบ username และ password',
        })
    }

    const onFinish = async (value) => {
       // const newWindow = window.open('https://2bee-180-183-246-15.ngrok-free.app/login');
        const user = {...value,useridLine:localStorage.getItem('useridLine')}

        setLoading(true)
        await axios.post(loginline, user)
            .then((res) => {
                //console.log(res.data) 
                if (res.data.status === "success") {                               
                    // localStorage.setItem('username', res.data.username)
                    // localStorage.setItem('menu', JSON.stringify(res.data.permission))                
                    setLoading(false)
                    liff.closeWindow();
                }
                else {
                    error()
                    setLoading(false)
                }
            })
            .catch((err) => {
                if (err.response.request.status === 404) {
                    error()
                }
                else {
                    error()
                }
                console.log("err", err)
                setLoading(false)
            })
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };


    return (
        <Row >

            <Col
                xs={{ span: 24, offset: 0 }}
                lg={{ span: 6, offset: 0 }}
                md={{ span: 12 }}
            >
                <Row>
                    <Col xs={2} md={6} lg={4}>

                    </Col>
                    <Col
                        xs={{ span: 20 }}
                        lg={{ span: 16 }}
                        md={{ span: 12 }}
                    >
                        <img style={{ width: '100%', height: '100%' }} src={logo} alt="" />
                    </Col>

                    <Col xs={2} md={6} lg={4}>

                    </Col>
                </Row>
                <Title className="mb-15" style={{ textAlign: 'center' }}>เข้าสู่ระบบ</Title>
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Form
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        layout="vertical"
                        className="row-col"
                    >
                        <Form.Item
                            className="username"
                            label="ชื่อผู้ใช้"
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: "กรุณากรอกรหัสผู้ใช้งาน",
                                },
                            ]}
                        >
                            <Input placeholder="Username" />
                        </Form.Item>

                        <Form.Item
                            className="username"
                            label="รหัสผ่าน"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: "กรุณากรอกพาสเวิร์ด",
                                },
                            ]}
                        >
                            <Input.Password placeholder="Password" />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{ width: "100%" }}
                            >
                                เข้าสู่ระบบ
                            </Button>

                        </Form.Item>
                    </Form>
                </Spin>

            </Col>

        </Row>
    )
}
