import './Index.css';
import React, { useEffect, useState } from 'react';
import { Button, Modal, Space, Row, Col, Input, Select, Form, Spin, DatePicker, message, notification } from 'antd';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { edituser, getdata } from '../../file_mid/all_api';

const { Option } = Select;

const ModalEdit = ({ open, close, member, members, updateMember }) => {
    // const { id } = useParams();
    const [formData, setFormData] = useState({ ...member });
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [department, setDepartment] = useState([]);
    const [position, setPosition] = useState([]);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState({ department: true, position: true });
    const [api, contextHolder] = notification.useNotification();

    // console.log('updateMember',updateMember)
    // console.log('member', member)
    // console.log('members', members)

    useEffect(() => {
        if (member) {
            // console.log('วันที่', member.created_at)
            form.setFieldsValue({
                ...member,
                name: member.name,
                lastname: member.lastname,
                nickname: member.nickname,
                branch: member.branch,
                username: member.username,
                created_at: member.created_at ? dayjs(member.created_at) : null,
                userid_line: member.userid_line,
                position: member.position,
                nickname_salcod: member.nickname_salcod,
                department: member.department,
            })
        }
        getData();
    }, [member]);

    const openNotification = (placement) => {
        api.info({
            message: 'บันทึกสำเร็จ',
            description: 'แก้ไขข้อมูลผู้ใช้สำเร็จ.',
            placement,
        });
    };
    // console.log('formData.department', formData.department)
    // console.log('formData.position', formData.position)
    // console.log('department', department)
    // console.log('position', position)
    // console.log('formData', formData)

    // const updateUser = async () => {
    //     const id = member.id;
    //     let updatedMember = { ...formData };
    //     // console.log('updatedMember', updatedMember)
    //     // console.log('updateMember', updatedMember)
    //     try {
    //         const response = await axios.put(`${edituser}${id}`, updatedMember);
    //         updateMember(response.data);
    //         if (response.data === response.data) {
    //             openNotification('top');
    //             setTimeout(() => {
    //                 close(false);
    //             }, 2000)

    //         }
    //     } catch (error) {
    //         console.error('Error updating user:', error);
    //     }
    // };

    const updateUser = async () => {
        // const id = member.id;
        let updatedMember = { ...formData };

        if (updatedMember.userid_line === '' || updatedMember.userid_line === null) {
            delete updatedMember.userid_line
        }

        console.log('updatedMember', updatedMember)

        const otherMembers = members.filter(member => member.id !== member.id);

        if (otherMembers.some(member => member.username === formData.username)) {
            message.error('Username นี้ถูกใช้งานไปแล้ว');
            return;
        } else {

            await axios.put(`${edituser}/${member.id}`, updatedMember)
                .then(res => {
                    updateMember(res.data)
                    if (res.data === res.data) {
                        openNotification('top');
                        setTimeout(() => {
                            close(false);
                        }, 2000)
                    }
                })
                .catch(error => {
                    if (error.response && error.response.status === 400) {
                        alert('ไม่สามารถเพิ่มข้อมูลได้');
                    }
                    console.error('Error creating user:', error);
                })
        }
    }

    const getData = async () => {
        setLoading(true);

        await axios.get(`${getdata}`)
            .then(res => {
                // console.log('response.data',response.data)
                setPosition(res.data.setEmployeePosition);
                setDepartment(res.data.setEmployeeDepartment);
                setLoading(false);
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    alert('ไม่สามารถแก้ไขข้อมูลได้');
                }
                console.error('Error fetching positions:', error);
                setLoading(false);
            });
    };

    const handleOk = async () => {
        setConfirmLoading(true);
        // console.log('formData ก่อนอัพเดต:', formData);  // ตรวจสอบค่าของ formData
        await updateUser();
        setConfirmLoading(false);
    };

    const handleCancel = () => {
        close(false);
    };

    const handleSelectChange = (name, value) => {
        setFormData({ ...formData, [name]: value });
    };

    const handleChange = (name, value) => {
        if (name === 'position') {
            setFormData(prevFormData => ({
                ...prevFormData,
                id_sep: value,
                position_description: value
            }));
        } else if (name === 'department') {
            setFormData(prevFormData => ({
                ...prevFormData,
                id_department: value,
                department_description: value
            }));
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: value
            }));
        }
    };

    const handleDateChange = (date) => {
        if (date && date.isValid()) {
            setFormData(prevFormData => ({
                ...prevFormData,
                created_at: date.toISOString()  // ส่งค่าในรูปแบบ ISO
            }));
        }
    };

    if (loading.department || loading.position) {
        return <Spin />;
    }
    // console.log("formData", formData)
    return (
        <>

            <Modal
                title={<div style={{ marginBottom: '30px' }}>แก้ไขข้อมูล</div>}
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                footer={null}
                onCancel={handleCancel}
                width={1000}
            >
                <Form layout="vertical"
                    name="basic"
                    labelCol={{
                        span: 12,
                    }}
                    // wrapperCol={{
                    //   span: 16,
                    // }}
                    style={{
                        width: '100%',
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    form={form}
                    // onFinish={handleSubmit}
                    autoComplete="off"
                >

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="name" label="ชื่อ:" style={{ marginBottom: 0 }}>
                                <Input
                                    placeholder="ชื่อ"
                                    name="name"
                                    onChange={e => handleChange('name', e.target.value)}
                                    className='input'
                                    style={{ height: '40px' }}
                                />
                            </Form.Item>
                            <Form.Item name="nickname" label="ชื่อเล่น:" style={{ marginBottom: 0 }}>
                                <Input
                                    placeholder="ชื่อเล่น"
                                    name="nickname"
                                    onChange={e => handleChange('nickname', e.target.value)}
                                    className='input'
                                    style={{ height: '40px' }}
                                />
                            </Form.Item>
                            <Form.Item name="username" label="Username:" style={{ marginBottom: 0 }}>
                                <Input
                                    placeholder="Username"
                                    name="username"
                                    onChange={e => handleChange('username', e.target.value)}
                                    className='input'
                                    style={{ height: '40px' }}
                                    disabled
                                />
                            </Form.Item>
                            {/* <Form.Item name="nickname_salcod" label="Nickname Salcod:" style={{ marginBottom: 0 }}>
                                <Input
                                    placeholder="Nickname Salcod"
                                    name="nickname_salcod"
                                    // value={formData.nickname_salcod}
                                    onChange={handleChange}
                                    // className='input'
                                    style={{ height: '40px' }}
                                />
                            </Form.Item> */}
                            <Form.Item name="username" label="User ID:" style={{ marginBottom: 0 }}>
                                <Input
                                    placeholder="User ID"
                                    name="username"
                                    onChange={e => handleChange('username', e.target.value)}
                                    className='input'
                                // disabled
                                />
                            </Form.Item>
                            <Form.Item name="userid_line" label="ID Line:" style={{ marginBottom: 0 }}>
                                <Input
                                    placeholder="ID Line"
                                    name="userid_line"
                                    onChange={e => handleChange('userid_line', e.target.value)}
                                    className='input'
                                    style={{ height: '40px' }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="lastname" label="นามสกุล:" style={{ marginBottom: 0 }} >
                                <Input
                                    placeholder="นามสกุล"
                                    name="lastname"
                                    onChange={e => handleChange('lastname', e.target.value)}
                                    className='input'
                                />
                            </Form.Item>
                            <Form.Item name="branch" label="สาขา:" style={{ marginBottom: 0 }}>
                                <Select
                                    placeholder="สาขา"
                                    name="branch"
                                    onChange={value => handleSelectChange('branch', value)}
                                    className='input'
                                // disabled={formData.position === 'MK'}
                                >
                                    <Option value="LEX">LEX</Option>
                                    <Option value="UD">UD</Option>
                                    <Option value="MIT">MIT</Option>
                                    <Option value="S4">S4</Option>
                                    <Option value="S5">S5</Option>
                                    <Option value="K1001">K1001-ประตูน้ำ</Option>
                                    <Option value="K1002">K1002-รื่นรมย์</Option>
                                    <Option value="K1003">K1003-บ้านไผ่</Option>
                                    <Option value="K1004">K1004-กระนวน</Option>
                                    <Option value="K1005">K1005-สารคาม</Option>
                                    <Option value="K1006">K1006-สกลนคร</Option>
                                    <Option value="K1007">K1007-สุรินทร์</Option>
                                    <Option value="K1008">K1008-โคราช</Option>
                                    <Option value="K1009">K1009-อุดร</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="position" label="ตำแหน่ง:" style={{ marginBottom: 0 }}>
                                <Select
                                    placeholder="ตำแหน่ง"
                                    name="position"
                                    onChange={value => handleChange('position', value)}
                                    className='input'
                                >
                                    {position?.map(item => (
                                        <Option key={item.id_sep} value={item.id_sep}>{item.description}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item name='department' label="แผนก:" style={{ marginBottom: 0 }}>
                                <Select
                                    placeholder="แผนก"
                                    name="department"
                                    onChange={value => handleChange('department', value)}
                                    className='input'
                                >
                                    {department?.map(item => (
                                        <Option key={item.id_department} value={item.id_department}>{item.description}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item name="created_at" label="วันที่เริ่มงาน:" style={{ marginBottom: 0 }}>
                                <Space>
                                    <DatePicker
                                        format="DD-MM-YYYY"
                                        value={formData.created_at ? dayjs(formData.created_at) : null}
                                        onChange={handleDateChange}
                                        className='input'
                                    />
                                </Space>
                            </Form.Item>

                        </Col>
                        <Col span={24}>
                            <Row justify="end" align="middle" style={{ height: '100%' }}>
                                <Space >
                                    <Button
                                        key="cancel"
                                        className='button-cancel'
                                        onClick={handleCancel}
                                    >
                                        ยกเลิก
                                    </Button>
                                    <Button
                                        key="save"
                                        className='button-save'
                                        type='submit'
                                        onClick={handleOk}
                                    >
                                        บันทึก
                                    </Button>
                                </Space>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            {contextHolder}
        </>
    );
};

export default ModalEdit;
