import React from "react";
import { Navigate, Route, Routes } from 'react-router-dom';
import OfferCase from "../../pages/offer_case/Main";
import CheckInfoCar from "../../pages/check_infoCar/Main";
import CheckInfoLand from "../../pages/check_info_Land/Main";
import MainAppLand from "../../pages/approved_land_pn/MainAppLand";
import Main_Re from "../../pages/re_restructure/Main_Re";
// import Main_Re_offer from "../../pages/re_restructure/Main_Re_offer";
import AddCustomer from "../../pages/add_customer/Main";
import AddProperty from "../../pages/add_property/Main";
import AddLoan from "../../pages/add_loan/Main";
import CheckBlackList from "../../pages/blacklist/Main";
import CustomerHistory from "../../pages/customer_history/Main";
import LoanHistory from "../../pages/loan_history/Main";
import LoanHistoryAuto from "../../pages/loan_history_auto/Main";
import PayCancel from "../../pages/loan_history_cancel/MainCancelPay";
import PaymentDialy from "../../pages/payment_daily/MainCancelPay";
import PayCCB from "../../pages/payment_daily/MainCcb";
import Receipt from "../../pages/receipt/Main";
import MainOther from "../../pages/loan_history_other/MainOther";
import MainOtherCl from "../../pages/loan_history_other_cl/MainOtherCl";
import MainReceiptOther from "../../pages/receipt_other/MainReceiptOther";
import MainHD from "../../pages/loan_history_hd/MainHD";
import MainHDCancel from "../../pages/loan_history_hd/MainCancel";
import ReceiptMultiple from "../../pages/receipt_multiple/Main";
import CheckInfoAd from "../../pages/check_info_ad/Main";
import CheckInfoPG from "../../pages/check_info_PG/Main";
import Main_Pg_Re from "../../pages/check_info_PG_RE/Main_Pg_Re";
import MainCheckInfoPGRECont from "../../pages/check_info_PG_RE_cont/MainCheckInfoPGRECont";
import MainLeaving from "../../pages/check_info_PG_MKLeaving/MainLeaving";
import MainChange from "../../pages/check_info_PG_MKLeaving/MainChange";
import CheckInfoPN from "../../pages/check_info_PN/MainPn";
import MainLandPnRe from "../../pages/approved_land_pn_re/MainLandPnRe";
import SignUp from "../../pages/SignUp";
import Permis from "../../pages/Permis";
import SignIn from "../../pages/SignIn";
import Home from "./Home";
import TableUser from "../../pages/user/TableUser";
import ResetPass from "../../pages/ResetPass";
import Main_Manager from "../../pages/check_info_Manager/Main_Manager";
import Main_image from "../../pages/check_info_ad/Main_image";
import Main_rate from "../../pages/boss/Main_rate";
import Main_Boss from "../../pages/boss/Main_Boss";
import SignInLine from "../../pages/SignInLine";
import Liff from "../../pages/Liff";
import Main_imagePn from "../../pages/check_info_PN/Main_imagePn";
import ApprovalRockingPG from "../../pages/approval_rocking_pg/Main";
import ApprovalStopWorkPG from "../../pages/approval_stop_work_pg/Main";
import ReportPG from "../../pages/report_pg/Main";
import MainMemo from "../../pages/memo/MainMemo";
import MainDocuments from "../../pages/check_documents/Main_Documents";
import MainApRateCar from "../../pages/approval_rate_car/MainApRateCar";
import Main_report from "../../pages/check_info_PN/Main_report";
import Main_Report2 from "../../pages/check_info_PN/Main_report2";
import Main_Report3 from "../../pages/check_info_PN/Main_Report3";
import Main_Report4 from "../../pages/check_info_PN/Main_Report4";
import Main_ReportMG from "../../pages/check_info_Manager/Main_Report";
import Main_ReportMG2 from "../../pages/check_info_Manager/Main_Report2";
import Main_ReportMG3 from "../../pages/check_info_Manager/Main_Report3";
import Main_ReportMG4 from "../../pages/check_info_Manager/Main_Report4";
import MainCar_Re from "../../pages/re_restructure_car/MainCar_Re";
import MainLand_Re from "../../pages/re_restructure_land/MainLand_Re";
import Profiles from "../../pages/profile/formDataMarkings/formDataMarkings"
import MainOperators from "../../pages/operators/MainOperators";
import MainManagerEmployee from "../../pages/manager_employee/Main_ManagerEmployee";
import MainFormLand from "../../pages/check_info_ad_formland/MainFormLand";
import Listofemployees from "../../pages/manager_employee/listofemployees/Listofemployees";
import CeateRights from "../../pages/manager_employee/createRights/CeateRights";

import MainexpediteTheDebt from "../../pages/expediteTheDebt/MainexpediteTheDebt";
import Main_Boss_Report from "../../pages/boss/Main_Boss_report";
import MainTabel from "../../pages/suspensioncase/checkStep6/Main";

import Cars from "../../pages/expediteTheDebt/Cars/Cars";
import MainFollow from "../../pages/expediteTheDebt/follow_case/MainFollow";
import MainLandRedemption from "../../pages/check_info_ad_redemption/MainLandRedemption";

import Cars_Expedit from "../../pages/expediteTheDebt/Cars_Expedite/Cars_Expedite";
import MainFollow_Exdite from "../../pages/expediteTheDebt/follow_Exdite/MainFollow";
import MainTabel_Exdite from "../../pages/suspensioncase/checkStep6_exdite/Main";

import MainFinance from "../../pages/finance/Main";
import MainResult from "../../pages/report_result/MainResult";

function canAccessMenu(menuNumber, userMenuPermissions) {
  // ตรวจสอบว่าผู้ใช้มีสิทธิ์เข้าถึงเมนู menuNumber หรือไม่
  // console.log(menuNumber)
  // console.log(userMenuPermissions) aa
  return userMenuPermissions.includes(menuNumber)
}

export default function Contents() {
  const menu = localStorage.getItem('menu')
  const list = JSON.parse(menu)
  const aa = Object.keys(list)
  //console.log("menu", aa)
  const userMenuPermissions = aa // รับข้อมูลสิทธิ์ของผู้ใช้

  return (
    <Routes>
      {/* หน้าหลัก */}
      <Route path="" element={canAccessMenu("1", userMenuPermissions) ? (<Home />) : (<Navigate to="/" />)} />
      {/* เสนอเคส */}
      <Route path="/offercase/*" element={canAccessMenu("2", userMenuPermissions) ? (<OfferCase />) : (<Navigate to="/" />)} />
      {/* ตารางการตลาด รถ ที่ดิน */}
      <Route path="/checkInfoCar" element={canAccessMenu("3", userMenuPermissions) ? (<CheckInfoCar />) : (<Navigate to="/" />)} />
      <Route path="/checkInfoLand" element={canAccessMenu("3", userMenuPermissions) ? (<CheckInfoLand />) : (<Navigate to="/" />)} />
      <Route path="/preLandPn" element={canAccessMenu("3", userMenuPermissions) ? (<MainAppLand />) : (<Navigate to="/" />)} />
      <Route path="/case-re" element={canAccessMenu("3", userMenuPermissions) ? (<Main_Re />) : (<Navigate to="/" />)} />
      <Route path="/offercase-re" element={canAccessMenu("3", userMenuPermissions) ? (<MainCar_Re />) : (<Navigate to="/" />)} />
      <Route path="/offercaseland-re" element={canAccessMenu("3", userMenuPermissions) ? (<MainLand_Re />) : (<Navigate to="/" />)} />
      {/* ตรวจสอบข้อมูลธุรการ + ดูรูป */}
      <Route path="/checkinfoad" element={canAccessMenu("4", userMenuPermissions) ? (<CheckInfoAd />) : (<Navigate to="/" />)} />
      <Route path="/view-ad/*" element={canAccessMenu("4", userMenuPermissions) ? (<Main_image />) : (<Navigate to="/" />)} />
      <Route path="/formLandAd/*" element={canAccessMenu("4", userMenuPermissions) ? (<MainFormLand />) : (<Navigate to="/" />)} />
      <Route path="/formLandRedemption/*" element={canAccessMenu("4", userMenuPermissions) ? (<MainLandRedemption />) : (<Navigate to="/" />)} />
      {/* พื่กั้ง ข้อมูล + โยกงาน + หยุดงาน*/}
      <Route path="/checkinfopg" element={canAccessMenu("5", userMenuPermissions) ? (<CheckInfoPG />) : (<Navigate to="/" />)} />
      <Route path="/checkinfopgRE" element={canAccessMenu("5", userMenuPermissions) ? (<Main_Pg_Re />) : (<Navigate to="/" />)} />
      <Route path="/checkInfoPGRECont" element={canAccessMenu("5", userMenuPermissions) ? (<MainCheckInfoPGRECont />) : (<Navigate to="/" />)} />

      <Route path="/checkInfoPGMKLeaving" element={canAccessMenu("5", userMenuPermissions) ? (<MainLeaving />) : (<Navigate to="/" />)} />
      <Route path="/checkInfoPGMKChange" element={canAccessMenu("5", userMenuPermissions) ? (<MainChange />) : (<Navigate to="/" />)} />

      <Route path="/approvalRockingPG" element={canAccessMenu("5", userMenuPermissions) ? (<ApprovalRockingPG />) : (<Navigate to="/" />)} />
      <Route path="/approvalStopWorkPG" element={canAccessMenu("5", userMenuPermissions) ? (<ApprovalStopWorkPG />) : (<Navigate to="/" />)} />
      <Route path="/reportWorkPG" element={canAccessMenu("5", userMenuPermissions) ? (<ReportPG />) : (<Navigate to="/" />)} />
      {/* พื่หนุ่ม */}
      <Route path="/checkinfopn" element={canAccessMenu("6", userMenuPermissions) ? (<CheckInfoPN />) : (<Navigate to="/" />)} />
      <Route path="/view-pn/*" element={canAccessMenu("6", userMenuPermissions) ? (<Main_imagePn />) : (<Navigate to="/" />)} />
      <Route path="/checkInfoRePN" element={canAccessMenu("6", userMenuPermissions) ? (<MainLandPnRe />) : (<Navigate to="/" />)} />
      <Route path="/report-pn/*" element={canAccessMenu("6", userMenuPermissions) ? (<Main_report />) : (<Navigate to="/" />)} />
      <Route path="/report-pn2/*" element={canAccessMenu("6", userMenuPermissions) ? (<Main_Report2 />) : (<Navigate to="/" />)} />
      <Route path="/report-pn3/*" element={canAccessMenu("6", userMenuPermissions) ? (<Main_Report3 />) : (<Navigate to="/" />)} />
      <Route path="/report-pn4/*" element={canAccessMenu("6", userMenuPermissions) ? (<Main_Report4 />) : (<Navigate to="/" />)} />
      {/* ผู้จัดการ */}
      <Route path="/checkInfoManager" element={canAccessMenu("7", userMenuPermissions) ? (<Main_Manager />) : (<Navigate to="/" />)} />
      <Route path="/report-mg/*" element={canAccessMenu("7", userMenuPermissions) ? (<Main_ReportMG />) : (<Navigate to="/" />)} />
      <Route path="/report-mg2/*" element={canAccessMenu("7", userMenuPermissions) ? (<Main_ReportMG2 />) : (<Navigate to="/" />)} />
      <Route path="/report-mg3/*" element={canAccessMenu("7", userMenuPermissions) ? (<Main_ReportMG3 />) : (<Navigate to="/" />)} />
      <Route path="/report-mg4/*" element={canAccessMenu("7", userMenuPermissions) ? (<Main_ReportMG4 />) : (<Navigate to="/" />)} />
      {/* บอส */} 
      <Route path="/apBossRate" element={canAccessMenu("8", userMenuPermissions) ? (<Main_rate />) : (<Navigate to="/" />)} />
      <Route path="/checkInfoBoss" element={canAccessMenu("8", userMenuPermissions) ? (<Main_Boss />) : (<Navigate to="/" />)} />
      <Route path="/checkInfoBossReport" element={canAccessMenu("8", userMenuPermissions) ? (<Main_Boss_Report />) : (<Navigate to="/" />)} />
      {/* เสนอเคส ธุรการ */}
      <Route path="/addcustomer/*" element={canAccessMenu("9", userMenuPermissions) ? (<AddCustomer />) : (<Navigate to="/" />)} />
      {/* เทสอัพรูป 1 */}
      <Route path="/addproperty" element={canAccessMenu("10", userMenuPermissions) ? (<AddProperty />) : (<Navigate to="/" />)} />
      {/* เทสอัพรูป 2 */}
      <Route path="/addloan/*" element={canAccessMenu("11", userMenuPermissions) ? (<AddLoan />) : (<Navigate to="/" />)} />
      {/* เทสเปลี่ยน อาชีพ */}
      <Route path="/blacklist" element={canAccessMenu("12", userMenuPermissions) ? (<CheckBlackList />) : (<Navigate to="/" />)} />
      {/* ทดสอบ การเงิน */}
      <Route path="/customer-history" element={canAccessMenu("13", userMenuPermissions) ? (<CustomerHistory />) : (<Navigate to="/" />)} />
      {/* ทดสอบ การเงิน */}
      <Route path="/loan-history" element={canAccessMenu("14", userMenuPermissions) ? (<PayCCB />) : (<Navigate to="/" />)} />
      {/* ทดสอบ การเงิน */}
      <Route path="/payment-daily" element={canAccessMenu("15", userMenuPermissions) ? (<PaymentDialy />) : (<Navigate to="/" />)} />
      {/* - */}
      <Route path="/receipt" element={canAccessMenu("16", userMenuPermissions) ? (<Receipt />) : (<Navigate to="/" />)} />
      {/* - */}
      <Route path="/receipt-multiple" element={canAccessMenu("29", userMenuPermissions) ? (<ReceiptMultiple />) : (<Navigate to="/" />)} />
      {/* สมัคร */}
      <Route path="/rush" element={canAccessMenu("17", userMenuPermissions) ? (<SignUp />) : (<Navigate to="/" />)} />
      {/* สิทธิ์ */}
      <Route path="/permis" element={canAccessMenu("18", userMenuPermissions) ? (<Permis />) : (<Navigate to="/" />)} />
      {/* ตารางยูส */}
      <Route path="/tableuser" element={canAccessMenu("19", userMenuPermissions) ? (<TableUser />) : (<Navigate to="/" />)} />
      {/* memo */}
      <Route path="/comment" element={canAccessMenu("20", userMenuPermissions) ? (<MainMemo />) : (<Navigate to="/" />)} />
      {/* ตรวจสอบเอกสาร */}
      <Route path="/checkDocuments" element={canAccessMenu("21", userMenuPermissions) ? (<MainDocuments />) : (<Navigate to="/" />)} />
      {/* อนุมัติเรทรถ */}
      <Route path="/apRateCar" element={canAccessMenu("22", userMenuPermissions) ? (<MainApRateCar />) : (<Navigate to="/" />)} />
      {/* Operator */}
      <Route path="/operators" element={canAccessMenu("23", userMenuPermissions) ? (<MainOperators />) : (<Navigate to="/" />)} />
      {/* Manager */}
      <Route path="/managers" element={canAccessMenu("24", userMenuPermissions) ? (<MainManagerEmployee />) : (<Navigate to="/" />)} />
      <Route path="/listofemployees" element={canAccessMenu("24", userMenuPermissions) ? (<Listofemployees />) : (<Navigate to="/" />)} />
      <Route path="/createRights" element={canAccessMenu("24", userMenuPermissions) ? (<CeateRights />) : (<Navigate to="/" />)} />

      {/* เร่งรัดหนี้สิน */}
      <Route path="/expeditethedebt" element={canAccessMenu("25", userMenuPermissions) ? (<MainexpediteTheDebt />) : (<Navigate to="/" />)} />
      <Route path="/expeditethedebtcars" element={canAccessMenu("25", userMenuPermissions) ? (<Cars />) : (<Navigate to="/" />)} />
      <Route path="/followCase" element={canAccessMenu("25", userMenuPermissions) ? (<MainFollow />) : (<Navigate to="/" />)} />
      <Route path="/suspensioncase" element={canAccessMenu("25", userMenuPermissions) ? (<MainTabel />) : (<Navigate to="/" />)} />

      {/* การเงิน */}
      <Route path="/checkInfoFinance" element={canAccessMenu("26", userMenuPermissions) ? (<MainFinance />) : (<Navigate to="/" />)} />
      <Route path="/payment" element={canAccessMenu("26", userMenuPermissions) ? (<LoanHistory />) : (<Navigate to="/" />)} />
      <Route path="/payment-auto" element={canAccessMenu("26", userMenuPermissions) ? (<LoanHistoryAuto />) : (<Navigate to="/" />)} />
      <Route path="/payment-cancel" element={canAccessMenu("26", userMenuPermissions) ? (<PayCancel />) : (<Navigate to="/" />)} />
      <Route path="/receipts" element={canAccessMenu("26", userMenuPermissions) ? (<Receipt />) : (<Navigate to="/" />)} />
      <Route path="/receipts-auto" element={canAccessMenu("26", userMenuPermissions) ? (<ReceiptMultiple />) : (<Navigate to="/" />)} />
      <Route path="/payment-other" element={canAccessMenu("26", userMenuPermissions) ? (<MainOther />) : (<Navigate to="/" />)} />
      <Route path="/payment-other-cancel" element={canAccessMenu("26", userMenuPermissions) ? (<MainOtherCl />) : (<Navigate to="/" />)} />
      <Route path="/receipts-other" element={canAccessMenu("26", userMenuPermissions) ? (<MainReceiptOther />) : (<Navigate to="/" />)} />
      <Route path="/payment-hd" element={canAccessMenu("26", userMenuPermissions) ? (<MainHD />) : (<Navigate to="/" />)} />
      <Route path="/cancel-hd" element={canAccessMenu("26", userMenuPermissions) ? (<MainHDCancel />) : (<Navigate to="/" />)} />


      <Route path="/infoResult" element={canAccessMenu("27", userMenuPermissions) ? (<MainResult />) : (<Navigate to="/" />)} />



      {/* <Route path="/expediteTheDebtsexdite" element={canAccessMenu("28", userMenuPermissions) ? (<Cars_Expedit />) : (<Navigate to="/" />)} /> */}
      <Route path="/expeditethedebtcarsexdite" element={canAccessMenu("28", userMenuPermissions) ? (<Cars_Expedit />) : (<Navigate to="/" />)} />
      {/* <Route path="/followCaseexdite" element={canAccessMenu("28", userMenuPermissions) ? (<MainFollow_Exdite />) : (<Navigate to="/" />)} />
      <Route path="/suspensioncaseexdite" element={canAccessMenu("28", userMenuPermissions) ? (<MainTabel_Exdite />) : (<Navigate to="/" />)} /> */}


      {/* Profile */}
      <Route path="/profiles" element={<Profiles />} />
      {/* รีพาส */}
      <Route path="/resetpass" element={<ResetPass />} />
      {/*line*/}
      <Route path="/login-line" element={<SignInLine />} />
      {/*liff*/}
      <Route path="/liff" element={<Liff />} />
      {/* <Route path="/offercase/*" element={<OfferCase />} />
      <Route path="/checkInfo" element={<CheckInfo />} />
      <Route path="/checkinfoad" element={<CheckInfoAd />} />
      <Route path="/checkinfopg" element={<CheckInfoPG />} />
      <Route path="/addcustomer/*" element={<AddCustomer />} />
      <Route path="/addproperty" element={<AddProperty />} />
      <Route path="/addloan" element={<AddLoan />} />
      <Route path="/blacklist" element={<CheckBlackList />} />
      <Route path="/customer-history" element={<CustomerHistory />} />
      <Route path="/loan-history" element={<LoanHistory />} />
      <Route path="/payment-daily" element={<PaymentDialy />} />
      <Route path="/receipt" element={<Receipt />} />
      <Route path="/signUp" element={<SignUp />} />
      <Route path="/permis" element={<Permis />} /> */}
    </Routes>
  );
}


