//function dotaLocat() {
  const dotaLocat = [
    {
      value: "ทั้งหมด",
      label: "ทั้งหมด",
    },
    // {
    //   value: "ALLONE",
    //   label: "สาขาONE",
    // },
    {
      value: "MIT",
      label: "MIT",
    },
    {
      value: "UD",
      label: "UD",
    },
    {
      value: "LEX",
      label: "LEX",
    },
    {
      value: "S4",
      label: "S4",
    },
    {
      value: "S5",
      label: "S5",
    },
    {
      value: "KSM",
      label: "KSM",
    },
  ];
  //return valueLocat;
//}

export default dotaLocat;
