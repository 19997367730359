import React, { useState, useEffect } from 'react'
import { Button, Form, Input, Select, Divider, Row, Col, Modal, Checkbox, Space, Card, Spin } from 'antd';
import axios from 'axios';
import '../css/Media.css'
import { Option } from 'antd/es/mentions';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { addCareerIncome } from '../../../redux/CareerIncome';
import { addOccupation } from '../../../redux/Occupation';
import { addGuarantor } from '../../../redux/Guarantor';
import { addImage } from '../../../redux/Image';
import { addEmail } from '../../../redux/Email';
import { addCustomer } from '../../../redux/Customer';
import { getocc, newgetsection, newgetsub, occupationIncom } from '../../file_mid/all_api';
function CareerIncome() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [form] = Form.useForm()

    const customers = useSelector(state => state.customers)
    const careerIncome = useSelector(state => state.careerIncome)
    const guarantor = useSelector(state => state.guarantor)
    const imgs = useSelector(state => state.imgs)
    const Email = useSelector(state => state.Email)

    const addCustomers = localStorage.getItem('addCustomer')
    const addCareerIncomes = localStorage.getItem('addCareerIncome')
    const addOccupations = localStorage.getItem('addOccupation')
    const branch = localStorage.getItem('branch');
    const addLoans = localStorage.getItem('addLoan')

    const dataAddCustomer = JSON.parse(addCustomers)
    const dataAddCareerIncome = JSON.parse(addCareerIncomes)
    const dataAddOccupation = JSON.parse(addOccupations)
    const dataAddLoans = JSON.parse(addLoans)

    const [loading, setLoading] = useState(false)
    const [occupation, setOccupation] = useState([]) // getdatabase occ
    const [sub, setSub] = useState([]) // getdatabase sub
    const [section, setSection] = useState([]) // getdatabase section
    const [cus, setCus] = useState({}) // customerId
    const [dataPost, setDataPost] = useState({ mainOccupation: true }) //เก็บอาชีพ
    const [countDataPost, setCountDataPost] = useState(0)
    const [checkLoan, setCheckLoan] = useState(dataAddLoans)

    const [money, setMoney] = useState({}) //เก็บรายได้
    const [expenses, setExpenses] = useState({}) //เก็บรายจ่าย
    const [getData, setGetData] = useState([]) //ดึงข้อมูลอาชีพ+รายได้ที่มีมา
    const [guarantors, setGuarantors] = useState(guarantor?.data);
    const [image, setImage] = useState([]);

    const [address, setAddress] = useState({
    });
    // const [isChecked, setChecked] = useState(false);

    // const handleCheckboxChange = (e) => {
    //     setChecked(e.target.checked);
    // };
    const [imagesomlong, setImageSomlong] = useState(Email?.data);
    const [dataPost1, setDataPost1] = useState({}) //customer
    const [selectProduct, setSelectProduct] = useState({})
    const [selectTypeCar, setSelectTypeCar] = useState()
    // const [numDetailImg, setNumDetailImg] = useState()
    // const [numImg, setNumImg] = useState()
    const [numCareerImg, setNumCareerImg] = useState({ numCareerImg: 0 })

    useEffect(() => {
        setCheckLoan(dataAddLoans)
        setImage(imgs?.data)
        setDataPost1(dataAddCustomer)
        setSelectProduct({ ...selectProduct, productTypeId: dataAddCustomer?.productTypeId })
        setSelectTypeCar({ ...selectTypeCar, productTypeCar: dataAddCustomer?.productTypeCar })
        // setNumDetailImg({ numDetailImg: customers?.data?.numDetailImg })
        // setNumImg({ numImg: customers?.data?.numImg })
        if (dataAddCustomer?.numCareerImg === 1) {
            setNumCareerImg({ numCareerImg: 1 })
        }
        if (dataAddCustomer?.customerId === undefined) {
            setAddress({
            })
        }
        if (dataAddCareerIncome) {
            setExpenses({ ...dataAddCareerIncome?.income?.expenses })
            setMoney({ ...dataAddCareerIncome?.income })
            setDataPost(dataAddCareerIncome)
            bpOccupation()
            bpSubOccupation(dataAddCareerIncome?.occupationalGroup)
            bpOccupationSection(dataAddCareerIncome?.governmentPrivate)
            form.setFieldsValue(
                {
                    ...dataAddCareerIncome,
                    ...dataAddCareerIncome?.income,
                    ...dataAddCareerIncome?.income?.expenses
                }
            )
        } else {
            loadOccupation()
            setExpenses({})
            setMoney({})
            setDataPost({})
            form.setFieldsValue(
                {
                    occupationalGroup: "",
                    governmentPrivate: "",
                    jobPosition: "",
                    companyName: "",
                    incomeMonth: '',
                    expensesMonth: "",
                }
            )
        }
        // console.log("checkLoan",checkLoan)
    }, [])

    //checkBox status ของที่อยู่
    const handleGoHome = () => {
        if (branch === 'MIT' || branch === 'S4' || branch === 'S5' || branch === 'UD' || branch === 'LEX') {
            dispatch(addImage(imagesomlong))
            navigate('/offercase/addDetail')
        } else {
            dispatch(addImage(imagesomlong))
            if (checkLoan?.productType === "p-loan") {
                navigate('/offercase/broker')
            } else {
                navigate('/offercase/addDetail')
            }
        }
    };

    //ปุ่ม Submit in Modal Add Data Address
    const handleSubmit = async () => {
        setLoading(true)
        // const totalChunks = 1; // จำนวนของกลุ่มที่คุณต้องการ
        // const chunkSize = Math.ceil(imgs?.data?.length / totalChunks);
        // const chunks = [];
        // for (let i = 0; i < imgs?.data?.length; i += chunkSize) {
        //     chunks.push(imgs.data.slice(i, i + chunkSize));
        // }
        // //console.log("chunks", chunks)
        // const result = { ...dataPost1, img: chunks[0] }
        // //console.log("resultCareer", result)
        // console.log("chunks", chunks.length)
        delete dataPost.income
        delete money.expenses

        // var imageGuarantors = [...image];
        // guarantors?.map((img, index) => {
        //     // console.log("img", img)
        //     // console.log("img11", img.imgs)
        //     imageGuarantors.push(img.imgs)
        //     setImage(imageGuarantors)
        // })
        // // dispatch(addEmail(imageGuarantors))
        // dispatch(addImage(imageGuarantors))

        // const req = { customer: { customerId: customers.data }, ojs: { ...dataPost, customerId: customers.data.customerId }, income: { ...money, incomesMainId: 0, incomeMainDescription: null }, expenses: { ...expenses, expensesId: 0, expensesMonthDescription: null } }
        // SHow
        // const req = { ojs: { ...dataPost, customerId: customers?.data.customerId }, income: { ...money, incomesMainId: careerIncome?.data?.income?.incomesMainId, incomeMainDescription: null }, expenses: { ...expenses, expensesId: careerIncome?.data?.income?.expenses.expensesId, expensesMonthDescription: null } }

        //ส่งไปหลังบ้าน
        if (dataAddCareerIncome) {
            console.log("1")
            if (countDataPost === 0) {
                var show = {
                    ...dataPost,
                    occupationalGroupTH: occupation?.find((e) => e.idOccupation === dataAddCareerIncome?.occupationalGroup)?.desc,
                    governmentPrivateTH: sub?.find((e) => e.idSub === dataAddCareerIncome?.governmentPrivate)?.descSub,
                    jobPositionTH: section?.find((e) => e.idSection === dataAddCareerIncome?.jobPosition)?.descSection,
                    customerId: dataAddCustomer?.customerId, income: { ...money, incomesMainId: dataAddCareerIncome?.income?.incomesMainId, incomeMainDescription: money.incomeMainDescription ? money.incomeMainDescription : null, expenses: { ...expenses, expensesId: dataAddCareerIncome?.income?.expenses.expensesId, expensesMonthDescription: expenses.expensesMonthDescription ? expenses.expensesMonthDescription : null } }
                }
                var req = {
                    ojs: {
                        ...dataPost,
                        occupationalGroupTH: occupation?.find((e) => e.idOccupation === dataAddCareerIncome?.occupationalGroup)?.desc,
                        governmentPrivateTH: sub?.find((e) => e.idSub === dataAddCareerIncome?.governmentPrivate)?.descSub,
                        jobPositionTH: section?.find((e) => e.idSection === dataAddCareerIncome?.jobPosition)?.descSection,
                        customerId: dataAddCustomer?.customerId
                    }, income: { ...money, incomesMainId: dataAddCareerIncome?.income?.incomesMainId, incomeMainDescription: money.incomeMainDescription ? money.incomeMainDescription : null }, expenses: { ...expenses, expensesId: dataAddCareerIncome?.income?.expenses.expensesId, expensesMonthDescription: expenses.expensesMonthDescription ? expenses.expensesMonthDescription : null }
                }
            }
            if (countDataPost === 1) {
                var show = {
                    ...dataPost, customerId: dataAddCustomer?.customerId,
                    income: { ...money, incomesMainId: dataAddCareerIncome?.income?.incomesMainId, incomeMainDescription: money.incomeMainDescription ? money.incomeMainDescription : null, expenses: { ...expenses, expensesId: dataAddCareerIncome?.income?.expenses.expensesId, expensesMonthDescription: expenses.expensesMonthDescription ? expenses.expensesMonthDescription : null } }
                }
                var req = {
                    ojs: {
                        ...dataPost, customerId: dataAddCustomer?.customerId
                    },
                    income: { ...money, incomesMainId: dataAddCareerIncome?.income?.incomesMainId, incomeMainDescription: money.incomeMainDescription ? money.incomeMainDescription : null }, expenses: { ...expenses, expensesId: dataAddCareerIncome?.income?.expenses.expensesId, expensesMonthDescription: expenses.expensesMonthDescription ? expenses.expensesMonthDescription : null }
                }
            }
            //console.log("show", show)
            //console.log("req", req)
            //setLoading(true)
            if (dataAddCustomer?.productTypeId === 1) {
                dispatch(addOccupation({ ...req }))
                dispatch(addCareerIncome({ ...show }))
                localStorage.setItem('addOccupation', JSON.stringify({ ...req }))
                localStorage.setItem('addCareerIncome', JSON.stringify({ ...show }))
                navigate('/offercase/result')
                // if (numCareerImg.numCareerImg === 0) {
                //     if (chunks.length > 0) {
                //         await axios.post('https://offercase-car-i67f6gaaqa-as.a.run.app/Api/page-career', result)
                //             //await axios.post('http://localhost:8080/Api/page-career', result)
                //             .then(res => {
                //                 delete res.data.img
                //                 if (res.status === 200) {
                //                     console.log("resCareer =", res.data)
                //                     dispatch(addCustomer({ ...dataPost1, ...selectProduct, ...selectTypeCar, numCareerImg: 1 }))
                //                     //dispatch(addCustomer({ ...res.data, ...selectProduct, ...selectTypeCar, ...numImg, ...numDetailImg, numCareerImg: 1 }))
                //                     setLoading(false)
                //                     navigate('/offercase/result')
                //                 }
                //             })
                //             .catch((err) => {
                //                 console.log("err", err)
                //                 setLoading(false)
                //             })
                //     } else {
                //         setLoading(false)
                //         navigate('/offercase/result')
                //     }
                // }
                // if (numCareerImg.numCareerImg === 1) {
                //     setLoading(false)
                //     navigate('/offercase/result')
                // }
            }
            if (dataAddCustomer?.productTypeId === 2) {
                dispatch(addOccupation({ ...req }))
                dispatch(addCareerIncome({ ...show }))
                localStorage.setItem('addOccupation', JSON.stringify({ ...req }))
                localStorage.setItem('addCareerIncome', JSON.stringify({ ...show }))
                navigate('/offercase/resultLand')
                // if (numCareerImg.numCareerImg === 0) {
                //     if (chunks.length > 0) {
                //         await axios.post('https://offercase-car-i67f6gaaqa-as.a.run.app/Api/page-career', result)
                //             //await axios.post('http://localhost:8080/Api/page-career', result)
                //             .then(res => {
                //                 delete res.data.img
                //                 if (res.status === 200) {
                //                     console.log("resCareer =", res.data)
                //                     dispatch(addCustomer({ ...dataPost1, ...selectProduct, ...selectTypeCar, numCareerImg: 1 }))
                //                     setLoading(false)
                //                     navigate('/offercase/resultLand')
                //                 }
                //             })
                //             .catch((err) => {
                //                 console.log("err", err)
                //                 setLoading(false)
                //             })
                //     } else {
                //         setLoading(false)
                //         navigate('/offercase/resultLand')
                //     }
                // }
                // if (numCareerImg.numCareerImg === 1) {
                //     setLoading(false)
                //     navigate('/offercase/resultLand')
                // }
            }
        } else {
            console.log("2")
            var show = { ...dataPost, customerId: dataAddCustomer?.customerId, mainOccupation: true, income: { ...money, mainOccupation: true, incomeMainDescription: money.incomeMainDescription ? money.incomeMainDescription : null, expenses: { ...expenses, expensesMonthDescription: expenses.expensesMonthDescription ? expenses.expensesMonthDescription : null } } }
            const req = { ojs: { ...dataPost, mainOccupation: true, customerId: dataAddCustomer?.customerId }, income: { ...money, incomeMainDescription: money.incomeMainDescription ? money.incomeMainDescription : null }, expenses: { ...expenses, expensesMonthDescription: expenses.expensesMonthDescription ? expenses.expensesMonthDescription : null } }
            //setLoading(true)
            if (dataAddCustomer?.productTypeId === 1) {
                dispatch(addOccupation({ ...req }))
                dispatch(addCareerIncome({ ...show }))
                localStorage.setItem('addOccupation', JSON.stringify({ ...req }))
                localStorage.setItem('addCareerIncome', JSON.stringify({ ...show }))
                navigate('/offercase/result')
                // if (numCareerImg.numCareerImg === 0) {
                //     if (chunks.length > 0) {
                //         await axios.post('https://offercase-car-i67f6gaaqa-as.a.run.app/Api/page-career', result)
                //             //await axios.post('http://localhost:8080/Api/page-career', result)
                //             .then(res => {
                //                 delete res.data.img
                //                 if (res.status === 200) {
                //                     console.log("resCareer =", res.data)
                //                     dispatch(addCustomer({ ...dataPost1, ...selectProduct, ...selectTypeCar, numCareerImg: 1 }))
                //                     setLoading(false)
                //                     navigate('/offercase/result')
                //                 }
                //             })
                //             .catch((err) => {
                //                 console.log("err", err)
                //                 setLoading(false)
                //             })
                //     } else {
                //         setLoading(false)
                //         navigate('/offercase/result')
                //     }
                // }
                // if (numCareerImg.numCareerImg === 1) {
                //     setLoading(false)
                //     navigate('/offercase/result')
                // }
            }
            if (dataAddCustomer?.productTypeId === 2) {
                dispatch(addOccupation({ ...req }))
                dispatch(addCareerIncome({ ...show }))
                localStorage.setItem('addOccupation', JSON.stringify({ ...req }))
                localStorage.setItem('addCareerIncome', JSON.stringify({ ...show }))
                navigate('/offercase/resultLand')
                // if (numCareerImg.numCareerImg === 0) {
                //     if (chunks.length > 0) {
                //         await axios.post('https://offercase-car-i67f6gaaqa-as.a.run.app/Api/page-career', result)
                //             //await axios.post('http://localhost:8080/Api/page-career', result)
                //             .then(res => {
                //                 delete res.data.img
                //                 if (res.status === 200) {
                //                     console.log("resCareer =", res.data)
                //                     dispatch(addCustomer({ ...dataPost1, ...selectProduct, ...selectTypeCar, numCareerImg: 1 }))
                //                     setLoading(false)
                //                     navigate('/offercase/resultLand')
                //                 }
                //             })
                //             .catch((err) => {
                //                 console.log("err", err)
                //                 setLoading(false)
                //             })
                //     } else {
                //         setLoading(false)
                //         navigate('/offercase/resultLand')
                //     }
                // }
                // if (numCareerImg.numCareerImg === 1) {
                //     setLoading(false)
                //     navigate('/offercase/resultLand')
                // }
            }
        }

        // console.log("money ", money)
        // console.log("รวม ", req)
        // console.log("show ", show)
        // setLoading(true)
        // if (customers.data.productTypeId === 1) {
        //     dispatch(addOccupation({ ...show }))
        //     dispatch(addCareerIncome({ ...show }))
        //     // dispatch(addCareerIncome({ ...show }))
        //     navigate('/offercase/result')
        // }
        // if (customers.data.productTypeId === 2) {
        //     dispatch(addOccupation({ ...show }))
        //     dispatch(addCareerIncome({ ...show }))
        //     // dispatch(addCareerIncome({ ...show }))
        //     navigate('/offercase/resultLand')
        // }

        // await axios.post(occupationIncom, req)
        //     .then(res => {
        //         if (res.status === 200) {
        //             success()
        //             if (customers.data.productTypeId === 1) {
        //                 navigator('/offercase/addDetail')
        //             }
        //             if (customers.data.productTypeId === 2) {
        //                 navigator('/offercase/resultLand')
        //             }
        //         }
        //     })
        //     .catch((err) => {
        //         if (err.response.request.status === 400) {
        //             //console.log(err)
        //             error()
        //         }
        //         else {
        //             //console.log(err)
        //             error()
        //         }
        //         setLoading(false)
        //     })
        setLoading(false)
    }
    // data input Form 
    const handleInputDataAddress = (e) => {
        const { name, value } = e.target;
        setAddress({
            ...address, [name]: value,
        }) // เป็นการกระจายข้อมูลเข้าไปตามชื่อ  name
    }
    // Data Select Form
    const loadOccupation = async () => {
        setLoading(true)
        await axios.get(getocc)
            .then((res) => {
                //console.log("occ", res.data)
                setOccupation(res.data)
                setLoading(false)
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }

    const loadSubOccupation = async (value) => {
        //console.log("sub",value)
        const idOccupation = { idOccupation: value }
        setLoading(true)
        await axios.post(newgetsub, idOccupation)
            .then((res) => {
                //console.log("sub", res.data)
                setSub(res.data)
                setLoading(false)
                form.setFieldsValue(
                    {
                        governmentPrivate: "",
                        jobPosition: "",
                        companyName: "",
                    }
                )
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }

    const loadOccupationSection = async (value) => {
        //console.log("section",value)
        const idSub = { idSub: value }
        setLoading(true)
        await axios.post(newgetsection, idSub)
            .then((res) => {
                //console.log("section", res.data)
                setSection(res.data)
                setLoading(false)
                form.setFieldsValue(
                    {
                        jobPosition: "",
                        companyName: "",
                    }
                )
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }
    // back page 
    const bpOccupation = async () => {
        setLoading(true)
        await axios.get(getocc)
            .then((res) => {
                //console.log("bp-occ", res.data)
                setOccupation(res.data)
                setLoading(false)
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }

    const bpSubOccupation = async (value) => {
        //console.log("sub",value)
        const idOccupation = { idOccupation: value }
        setLoading(true)
        await axios.post(newgetsub, idOccupation)
            .then((res) => {
                //console.log("bp-sub", res.data)
                setSub(res.data)
                setLoading(false)
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }

    const bpOccupationSection = async (value) => {
        //console.log("section",value)
        const idSub = { idSub: value }
        setLoading(true)
        await axios.post(newgetsection, idSub)
            .then((res) => {
                //console.log("bp-section", res.data)
                setSection(res.data)
                setLoading(false)
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }

    const getOccupation = (value) => {
        setCountDataPost(1)
        //console.log("value", {occupation: occupation.find((e) => e.idOccupation === value).desc})
        //setDataPost({ ...dataPost, occupationalGroup: occupation.find((e) => e.idOccupation === value).desc })
        setDataPost({
            ...dataPost,
            governmentPrivate: "",
            governmentPrivateTH: "",
            jobPosition: "",
            companyName: "",
            occupationalGroup: value,
            occupationalGroupTH: occupation.find((e) => e.idOccupation === value).desc
        })
        loadSubOccupation(value)
    }

    const getSub = (value) => {
        setCountDataPost(1)
        //console.log("value", value)
        //setDataPost({ ...dataPost, governmentPrivate: sub.find((e) => e.idSub === value).descSub })
        setDataPost({
            ...dataPost,
            jobPosition: 32,
            jobPositionTH: "",
            idPloccupation: 12,
            idArgroup: 11,
            companyName: "",
            governmentPrivate: value,
            governmentPrivateTH: sub.find((e) => e.idSub === value).descSub,
            idPljob: sub.find((e) => e.idSub === value).idPljob
        })
        loadOccupationSection(value)
    }

    const getSection = (value) => {
        setCountDataPost(1)
        //console.log("value", value)
        //setDataPost({ ...dataPost, jobPosition: section.find((e) => e.idSection === value).descSection })
        setDataPost({
            ...dataPost,
            jobPosition: value,
            jobPositionTH: section.find((e) => e.idSection === value).descSection,
            idPloccupation: section.find((e) => e.idSection === value).idPloccupation,
            idArgroup: section.find((e) => e.idSection === value).idArgroup
        })
    }

    return (
        <>
            <Row justify={'center'}>
                <Card style={{ width: '1100px' }}>
                    <Spin spinning={loading} size='large' tip=" Loading... ">
                        <Form
                            form={form}
                            name='add'
                            labelCol={{
                                span: 10,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            style={{
                                maxWidth: '100%',
                            }}
                            autoComplete="off"
                            initialValues={{ remember: true }}
                            onFinish={handleSubmit}

                        >
                            <Row gutter={32}>
                                <Col className='gutter-row' span={24} style={{ fontSize: '30px', textAlign: 'center' }} ><u>เพิ่มอาชีพ</u></Col>
                            </Row>
                            <Divider style={{ margin: 5 }} />
                            <Row className='main2' justify={'center'}>
                                <Card style={{ width: '100%' }} >
                                    <aside style={{ width: '90%', textAlign: 'center' }}>
                                        <div>
                                            <Form.Item label='กลุ่มอาชีพ' name='occupationalGroup' style={{ textAlign: 'left' }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input !',
                                                    },]}>
                                                <Select
                                                    placeholder={'เลือก'}
                                                    style={{ height: '40px', width: '250px' }}
                                                    onChange={getOccupation}
                                                >
                                                    {occupation?.map((item) => {
                                                        return (
                                                            <Select.Option key={item.idOccupation} value={item.idOccupation}>
                                                                {item.desc}
                                                            </Select.Option>
                                                        )
                                                    })}

                                                </Select>
                                            </Form.Item>

                                            <Form.Item label='เลือกอาชีพ' name='governmentPrivate' style={{ textAlign: 'left' }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input !',
                                                    },]}>
                                                <Select
                                                    placeholder={'เลือก'}
                                                    style={{ height: '40px', width: '250px' }}
                                                    onChange={getSub}
                                                >
                                                    {sub?.map((item) => {
                                                        return (
                                                            <Select.Option key={item.idSub} value={item.idSub}>
                                                                {item.descSub}
                                                            </Select.Option>
                                                        )
                                                    })}

                                                </Select>
                                            </Form.Item>

                                            <Form.Item label='สาขาอาชีพ' name='jobPosition' style={{ textAlign: 'left' }}>
                                                <Select
                                                    placeholder={'เลือก'}
                                                    style={{ height: '40px', width: '250px' }}
                                                    onChange={getSection}
                                                >
                                                    {section?.map((item) => {
                                                        return (
                                                            <Select.Option key={item.idSection} value={item.idSection}>
                                                                {item.descSection}
                                                            </Select.Option>
                                                        )
                                                    })}

                                                </Select>
                                            </Form.Item>

                                            <Form.Item label='ชื่อบริษัท สถานที่ทำงาน' name='companyName'
                                                style={{ textAlign: 'left' }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input !',
                                                    },]}>
                                                <Input type='text'
                                                    style={{ height: '40px', width: '250px' }}
                                                    onChange={(e) => setDataPost({ ...dataPost, companyName: e.target.value })}></Input>
                                            </Form.Item>

                                            <Form.Item label='หมายเหตุ' name='occupationDescription' style={{ textAlign: 'left' }}>
                                                <Input type='text'
                                                    style={{ height: '40px', width: '250px' }}
                                                    onChange={(e) => setDataPost({ ...dataPost, occupationDescription: e.target.value })}></Input>
                                            </Form.Item>

                                            <Form.Item label='เป็นอาชีพหลักหรือไม่' name='mainOccupation' style={{ textAlign: 'left' }}>
                                                <Checkbox defaultChecked={true} checked={dataPost} onChange={(e) => setDataPost({ ...dataPost, mainOccupation: e.target.checked })}>ใช่</Checkbox>
                                            </Form.Item>
                                        </div>

                                        <div>
                                            <Form.Item label='รายได้ต่อเดือน' name='incomeMonth'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input !',
                                                    },]}>
                                                <Input type='text' suffix='บาท'
                                                    style={{ color: 'black', height: '50px', width: '250px' }}
                                                    onChange={(e) => setMoney({ ...money, incomeMonth: parseInt(e.target.value) })}></Input>
                                            </Form.Item>

                                            {/* <Form.Item label='อื่นๆ' name='note' >
                                            <Input type='text' suffix='บาท'
                                                style={{ color: 'black', height: '50px' }}
                                                onChange={(e) => setMoney({ ...money, note: parseInt(e.target.value) })}></Input>
                                        </Form.Item> */}

                                            <Form.Item label='หมายเหตุ' name='incomeMainDescription' >
                                                <Input type='text'
                                                    style={{ color: 'black', height: '50px', width: '250px' }}
                                                    onChange={(e) => setMoney({ ...money, incomeMainDescription: e.target.value })}></Input>
                                            </Form.Item>

                                            {/* <Form.Item label='รายได้รวม / เดือน' name='totalIncomeMonth' >
                                        {currencyFormatOne(testM)} บาท
                                    </Form.Item>

                                    <Form.Item label='รายได้รวม / ปี' name='totalIncomeYear' >
                                        {currencyFormatOne(testY)} บาท
                                    </Form.Item> */}

                                            <Form.Item label='ค่าใช้จ่ายต่อ / เดือน' name='expensesMonth'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input !',
                                                    },]}>
                                                <Input type='text' suffix='บาท'
                                                    style={{ color: 'black', height: '50px', width: '250px' }}
                                                    onChange={(e) => setExpenses({ ...expenses, expensesMonth: parseInt(e.target.value) })}></Input>
                                            </Form.Item>

                                            <Form.Item label='หมายเหตุ' name='expensesMonthDescription' >
                                                <Input type='text'
                                                    style={{ color: 'black', height: '50px', width: '250px' }}
                                                    onChange={(e) => setExpenses({ ...expenses, expensesMonthDescription: e.target.value })}></Input>
                                            </Form.Item>

                                            {/* <Form.Item label='หักค่าใช้จ่าย / เดือน' name='summaryOfExpenses' >
                                        {currencyFormatOne(testResult)} บาท
                                    </Form.Item> */}
                                        </div>
                                    </aside>
                                </Card>
                            </Row>
                            <Divider style={{ margin: 5 }} />
                            <Row gutter={32}>
                                <Col span={12} style={{ textAlign: 'right' }}>
                                    <Button
                                        type="primary"
                                        style={{ background: '#bfbfbf', color: '#000000', }}
                                        onClick={(e) => { handleGoHome(e) }}>
                                        ย้อนกลับ
                                    </Button>
                                </Col>
                                <Col span={12}
                                    style={{ textAlign: 'left' }}>
                                    <Button htmlType="submit"
                                        type="primary"
                                    // onClick={handleSubmit}
                                    >
                                        ต่อไป
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </Card>
            </Row>
        </>
    )
}

export default CareerIncome
