import React, { useState, useEffect } from 'react'
import { Form, Input, Row, Col, Button, Select, Divider, Space, Spin, Card, Modal, notification, message } from 'antd'
import axios from 'axios'
import dayjs from 'dayjs';
import { AllData, createlandmap, GetAmphur1, GetProvince } from '../../file_mid/all_api';
import Currency from 'currency.js';
import { productLoanTypeLandRE, productLoanTypeLandPAP, productLoanTypeLandCHANGE } from '../../file_mid/all_options';

function DetailProductLand({ allData, onCancel, changepage, data, dataType, dataOld, againMode }) {
    const { confirm } = Modal
    const { Option } = Select;
    const [form] = Form.useForm();
    const currentTime = dayjs();
    const formattedTime = currentTime.format('YYYY-MM-DDTHH:mm:ssZ');
    // const [showCustmast, setShowCustmast] = useState(allData?.custmast[0]) // เกรด : showCustmast?.GRDCOD
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false)
    const [checkLand, setCheckLand] = useState(false)
    const addLands = localStorage.getItem('addLand')
    const dataAddLand = JSON.parse(addLands)

    const [dataPostLand, setDataPostLand] = useState({   // ที่ดิน
        // landTypeId: 2,
        // productTypeLand: 2,
        landInput: formattedTime,
        landDateStatus: formattedTime,
        rai: 0,
        workArea: 0,
        squareWaArea: 0,
        landPrice: 0,
        resultLandPrice: 0,
        landPriceStatus: false,
    })

    const [pvcodeS, setPvcodeS] = useState({});
    const [amcodeS, setAmcodeS] = useState({});

    const [provinces, setProvinces] = useState([]);
    const [district, setDistricts] = useState([]);

    console.log('dataOld', dataOld);
    console.log('data', data);
    console.log('allData', allData);
    // // console.log('datatype', dataType);
    // console.log('dataPostLand', dataPostLand);
    console.log('pvcodeS', pvcodeS);
    console.log('amcodeS', amcodeS);

    // useEffect(() => {
    //     if (dataOld) { 

    //     } 
    // }, []); // **เพิ่ม dataOld เป็น dependency**


    useEffect(() => {

        if (dataOld) {
            // console.log("dataOld updated:", dataOld);
            getProvinces(); // ดึงข้อมูลจังหวัด

            let newData = {
                ...dataPostLand,
                ...dataOld,
                reLoanId: dataOld?.landLoanDetailsRe?.reLoanId,
                productType: dataOld?.landLoanDetailsRe?.productType
            };

            if (dataType === "รี") {
                newData.productLoanLandType = "Pre-Aaprove-รี";
            } else if (dataType === "เปลี่ยนสัญญา") {
                newData.productLoanLandType = "Pre-Aaprove-เปลี่ยนสัญญา";
            } else if (dataType === "ปรับโครงสร้าง") {
                newData.productLoanLandType = "Pre-Aaprove-ปรับโครงสร้าง";
            }

            // console.log("New Data Post Land:", newData);

            setDataPostLand(newData);
            form.setFieldsValue(newData);

        } else {
            // console.log('fsdff55555');


            if (dataAddLand && !againMode) {
                // console.log('hhhhhhhhh');

                getProvinces()
                // PostProvince(dataAddLand.province)
                setTimeout(() => {
                    GetAmphur(dataAddLand.district)
                }, 2000)
                setDataPostLand(dataAddLand)
                form.setFieldsValue({
                    ...dataAddLand
                })
                setCheckLand(true)
            } else {
                if (againMode) {
                    setDataPostLand({
                        ...dataOld,
                        reLoanId: dataOld?.landLoanDetailsRe?.reLoanId,
                        productLoanLandType: dataOld?.landLoanDetailsRe?.productLoanLandType,
                        productType: dataOld?.landLoanDetailsRe?.productType
                    })
                    setCheckLand(true)
                    form.setFieldsValue({
                        ...dataOld,
                        ...dataOld?.landLoanDetailsRe,
                        productLoanLandType: ""
                    })
                } else {
                    // console.log('sdf/////////');

                    if (data?.DATA_TYPE !== "sfhp") {
                        // console.log('นส3 บัญชี1');
                        getProvinces();
                        if (allData) {
                            let Province = (allData[0]?.provinces);
                            Province = Province.replace(/\u0000/, '');
                            let District = (allData[0]?.district);
                            District = District.replace(/\u0000/, ' ');
                            // PostProvince(Province)
                            setTimeout(() => {
                                GetAmphur(District)
                            }, 2000)
                            let newNumberLand = (allData[0]?.numberLand);
                            newNumberLand = newNumberLand.replace(/\.\s*|\(.*?\)|,.*|\/.*/g, '');
                            if (dataType === "รี") {
                                if (allData[0]?.productTypeLand === "โฉนด") {
                                    setDataPostLand({
                                        ...dataPostLand,
                                        ...allData,
                                        productTypeLand: 2,
                                        province: Province,
                                        district: District,
                                        productLoanLandType: "Pre-Aaprove-รี",
                                        numberLand: newNumberLand,
                                    })
                                } else {
                                    setDataPostLand({
                                        ...dataPostLand,
                                        ...allData,
                                        productTypeLand: 1,
                                        province: Province,
                                        district: District,
                                        productLoanLandType: "Pre-Aaprove-รี",
                                        numberLand: newNumberLand,
                                        // amcode: amcodeS,
                                        // pvcode: pvcodeS,
                                    })
                                }
                                form.setFieldsValue({
                                    productTypeLand: allData[0]?.productTypeLand === "โฉนด" ? "นส.4 จ." : "นส.3 ก.",
                                    province: allData[0]?.provinces,
                                    district: allData[0]?.district,
                                    productLoanLandType: "Pre-Aaprove-รี",
                                    numberLand: newNumberLand,
                                })
                            } else if (dataType === "ปรับโครงสร้าง") {
                                if (allData[0]?.productTypeLand === "โฉนด") {
                                    setDataPostLand({
                                        ...dataPostLand,
                                        ...allData,
                                        productTypeLand: 2,
                                        province: Province,
                                        district: District,
                                        productLoanLandType: "Pre-Aaprove-ปรับโครงสร้าง",
                                        numberLand: newNumberLand,
                                    })
                                } else {
                                    setDataPostLand({
                                        ...dataPostLand,
                                        ...allData,
                                        productTypeLand: 1,
                                        province: Province,
                                        district: District,
                                        productLoanLandType: "Pre-Aaprove-ปรับโครงสร้าง",
                                        numberLand: newNumberLand,
                                        // amcode: amcodeS,
                                        // pvcode: pvcodeS,
                                    })
                                }
                                form.setFieldsValue({
                                    productTypeLand: allData[0]?.productTypeLand === "โฉนด" ? "นส.4 จ." : "นส.3 ก.",
                                    province: allData[0]?.provinces,
                                    district: allData[0]?.district,
                                    productLoanLandType: "Pre-Aaprove-ปรับโครงสร้าง",
                                    numberLand: newNumberLand,
                                })
                            } else if (dataType === "เปลี่ยนสัญญา") {
                                if (allData[0]?.productTypeLand === "โฉนด") {
                                    setDataPostLand({
                                        ...dataPostLand,
                                        ...allData,
                                        productTypeLand: 2,
                                        province: Province,
                                        district: District,
                                        productLoanLandType: "Pre-Aaprove-เปลี่ยนสัญญา",
                                        numberLand: newNumberLand,
                                    })
                                } else {
                                    setDataPostLand({
                                        ...dataPostLand,
                                        ...allData,
                                        productTypeLand: 1,
                                        province: Province,
                                        district: District,
                                        productLoanLandType: "Pre-Aaprove-เปลี่ยนสัญญา",
                                        numberLand: newNumberLand,
                                        // amcode: amcodeS,
                                        // pvcode: pvcodeS,
                                    })
                                }
                                form.setFieldsValue({
                                    productTypeLand: allData[0]?.productTypeLand === "โฉนด" ? "นส.4 จ." : "นส.3 ก.",
                                    province: allData[0]?.provinces,
                                    district: allData[0]?.district,
                                    productLoanLandType: "Pre-Aaprove-เปลี่ยนสัญญา",
                                    numberLand: newNumberLand,
                                })
                            }
                        }
                    } else {
                        // console.log('นส3 บัญชี8');

                        if (allData) {
                            let Province = (allData[0]?.provinces);
                            Province = Province.replace(/\u0000/, '');
                            let District = (allData[0]?.district);
                            District = District.replace(/\u0000/, ' ');
                            let newNumberLand = (allData[0]?.numberLand);
                            newNumberLand = newNumberLand.replace(/\.\s*|\(.*?\)|,.*|\/.*/g, '');
                            // console.log("GG")
                            if (dataType === "รี") {
                                if (allData[0]?.productTypeLand === "โฉนด") {
                                    setDataPostLand({
                                        ...dataPostLand,
                                        ...allData,
                                        productTypeLand: 2,
                                        province: Province,
                                        district: District,
                                        productLoanLandType: "Pre-Aaprove-รี",
                                        numberLand: newNumberLand,
                                    })
                                } else {
                                    setDataPostLand({
                                        ...dataPostLand,
                                        ...allData,
                                        productTypeLand: 1,
                                        province: Province,
                                        district: District,
                                        productLoanLandType: "Pre-Aaprove-รี",
                                        numberLand: newNumberLand,
                                        // amcode: amcodeS,
                                        // pvcode: pvcodeS,
                                    })
                                }
                                form.setFieldsValue({
                                    productTypeLand: allData[0]?.productTypeLand === "โฉนด" ? "นส.4 จ." : "นส.3 ก.",
                                    province: allData[0]?.provinces,
                                    district: allData[0]?.district,
                                    productLoanLandType: "Pre-Aaprove-รี",
                                    numberLand: newNumberLand,
                                })
                            } else if (dataType === "ปรับโครงสร้าง") {
                                if (allData[0]?.productTypeLand === "โฉนด") {
                                    setDataPostLand({
                                        ...dataPostLand,
                                        ...allData,
                                        productTypeLand: 2,
                                        province: Province,
                                        district: District,
                                        productLoanLandType: "Pre-Aaprove-ปรับโครงสร้าง",
                                        numberLand: newNumberLand,

                                    })
                                } else {
                                    setDataPostLand({
                                        ...dataPostLand,
                                        ...allData,
                                        productTypeLand: 1,
                                        province: Province,
                                        district: District,
                                        productLoanLandType: "Pre-Aaprove-ปรับโครงสร้าง",
                                        numberLand: newNumberLand,
                                        // amcode: amcodeS,
                                        // pvcode: pvcodeS,
                                    })
                                }
                                form.setFieldsValue({
                                    productTypeLand: allData[0]?.productTypeLand === "โฉนด" ? "นส.4 จ." : "นส.3 ก.",
                                    province: allData[0]?.provinces,
                                    district: allData[0]?.district,
                                    productLoanLandType: "Pre-Aaprove-ปรับโครงสร้าง",
                                    numberLand: newNumberLand,
                                })
                            } else if (dataType === "เปลี่ยนสัญญา") {
                                if (allData[0]?.productTypeLand === "โฉนด") {
                                    setDataPostLand({
                                        ...dataPostLand,
                                        ...allData,
                                        productTypeLand: 2,
                                        province: Province,
                                        district: District,
                                        productLoanLandType: "Pre-Aaprove-เปลี่ยนสัญญา",
                                        numberLand: newNumberLand,
                                    })
                                } else {
                                    setDataPostLand({
                                        ...dataPostLand,
                                        ...allData,
                                        productTypeLand: 1,
                                        province: Province,
                                        district: District,
                                        productLoanLandType: "Pre-Aaprove-เปลี่ยนสัญญา",
                                        numberLand: newNumberLand,
                                        // amcode: amcodeS,
                                        // pvcode: pvcodeS,
                                    })
                                }
                                form.setFieldsValue({
                                    productTypeLand: allData[0]?.productTypeLand === "โฉนด" ? "นส.4 จ." : "นส.3 ก.",
                                    province: allData[0]?.provinces,
                                    district: allData[0]?.district,
                                    productLoanLandType: "Pre-Aaprove-เปลี่ยนสัญญา",
                                    numberLand: newNumberLand,
                                })
                            }
                        }
                    }
                }
            }

        }


    }, [allData, dataOld])

    // const PostProvince = async (value) => {
    //     const pr = { pvcode: value }
    //     setLoading(true)
    //     await axios.post(PostProvince1, pr)
    //         .then((res) => {
    //             console.log("Province =", res.data)
    //             setPvcodeS(res.data[0].pvcode)
    //             // setProvinces(res.data)
    //             setLoading(false)

    //         }).catch((err) => {
    //             console.error(err);
    //         })
    //     setLoading(false)
    // }


    /////////////// ที่อยู่ ของ ที่ดิน //////////////////
    const getProvinces = async () => {
        setLoading(true)
        await axios.get(GetProvince)
            .then((res) => {
                setProvinces(res.data)
                if (dataOld) {
                    const found = res.data.result.find((e) =>
                        e?.pvnamethai?.includes(dataOld.province)
                    );
                    if (found) {
                        setPvcodeS(found.pvcode);
                    }
                    GetAmphur(dataOld.district);
                } else {
                    const found = res.data.result.find((e) => e?.pvnamethai.includes(allData[0]?.provinces));
                    if (found) {
                        setPvcodeS(found.pvcode);
                    }
                }
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        // setLoading(false)
    }

    const GetAmphur = async (value) => {
        const am = { pvcode: value }
        setLoading(true)
        await axios.post(GetAmphur1, am)
            .then(async(res) => {
                if (res.data) {
                    // console.log('datatatata', res.data);
                    if (dataOld) {
                        if (dataOld.productTypeLand === 2) { // นส4
                            const found = res.data.find((e) => e.amnamethai.includes(value));
                            if (found) {
                                setAmcodeS(found.amcode);
                                await handleCheckLandAgainMode(found.pvcode, found.amcode);
                            }
                        } else { // นส3
                            const found = res.data.find((e) => e.amnamethai.includes(value));
                            if (found) {
                                setAmcodeS(found.amcode);
                                await handleLandAgain3(found.amcode, found.pvcode);
                            }
                        }
                    } else if (allData[0].productTypeLand === "โฉนด") {
                        setDistricts(res.data)
                        setAmcodeS(res.data[0].amcode)
                        // handleLand3(res.data[0])
                    } else {
                        setDistricts(res.data)
                        setAmcodeS(res.data[0].amcode)
                        await handleLand3(res.data[0])
                    }
                }
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        // setLoading(false)
    }

    const handleSubmit = async () => {
        setLoading(true)
        if (againMode) {
            delete dataPostLand?.landLoanDetailsRe
            delete dataPostLand?.customer
            delete dataPostLand?.img
        }
        if (dataPostLand.productTypeLand === 1) {
            localStorage.setItem('addLand', JSON.stringify(dataPostLand))
            changepage('5')
        } else {
            if (checkLand) {
                localStorage.setItem('addLand', JSON.stringify(dataPostLand))
                changepage('5')
            } else {
                errorSubmit("top")
            }
        }
        setLoading(false)
    };

    const onTypeLoanChange = (value) => {
        setDataPostLand({
            ...dataPostLand,
            productLoanLandType: value
        })
        // setDataLoanTyped(value)
    }

    const currencyFormatOne = (amount) => {
        if (amount) {
            return Number(amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        }
    }
    const showConfirmCancel = () => {
        confirm({
            title: 'คุณต้องการยกเลิก...?',
            onOk() {
                onCancel(false);
            },
            onCancel() {
            },
        });
    };
    const onChangeBack = () => {
        changepage('1')
    }

    /////////////////// เลือก 1 = นส.3 กับ 2 = นส.4 //////////////////////
    const handleProductLand = (value) => {
        if (value === 1) {
            setDataPostLand({
                ...dataPostLand,
                productTypeLand: value,
                numberLand: "-",
                numberLandLawang: "-",
                rai: 0,
                workArea: 0,
                squareWaArea: 0,
                landPrice: 0,
                resultLandPrice: 0,
                landPriceStatus: false,
                subdistrict: null,
            })
            form.setFieldsValue(
                {
                    numberLand: "",
                    numberLandLawang: "",
                    rai: "",
                    workArea: "",
                    squareWaArea: "",
                    landPrice: "",
                    resultLandPrice: "",
                    province: "",
                    district: "",
                }
            )
        } else {
            setDataPostLand({
                ...dataPostLand,
                productTypeLand: value,
                numberLand: "-",
                numberLandLawang: "-",
                rai: 0,
                workArea: 0,
                squareWaArea: 0,
                landPrice: 0,
                resultLandPrice: 0,
                landPriceStatus: false,
            })
            form.setFieldsValue(
                {
                    numberLand: "",
                    numberLandLawang: "",
                    rai: "",
                    workArea: "",
                    squareWaArea: "",
                    landPrice: "",
                    resultLandPrice: "",
                    province: "",
                    district: "",
                }
            )
        }
    }
    ///////////////// กรณี นส.3 ///////////////////////////
    const handleProvinceChange1 = async (value, key) => {
        setPvcodeS(key["key"])

        setDataPostLand({ ...dataPostLand, province: value });
        form.setFieldsValue(
            {
                numberLand: "",
                numberLandLawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
                district: "",
            }
        )
        setLoading(true)
        const test = { pvcode: key["key"] }
        await axios.post(GetAmphur1, test)
            .then((res) => {
                setDistricts(res.data)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    };
    const handleDistrictChange2 = (value, key) => {
        setAmcodeS(key["key"])
        setDataPostLand({ ...dataPostLand, district: value });
        form.setFieldsValue(
            {
                numberLand: "",
                numberLandLawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
            }
        )

    };

    //นส.4
    const handleProvinceChange = async (value, key) => {
        setPvcodeS(key["key"])
        setDataPostLand({ ...dataPostLand, province: value });
        form.setFieldsValue(
            {
                numberLand: "",
                numberLandLawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
                landPrice: "",
                resultLandPrice: "",
                district: "",
            }
        )
        setLoading(true)
        const test = { pvcode: key["key"] }
        await axios.post(GetAmphur1, test)
            .then((res) => {
                setDistricts(res.data)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
            })
        setLoading(false)
    };
    const handleDistrictChange = (value, key) => {
        if (!key) {
            console.error("Invalid key:", key);
            return;
        }

        const amcode = typeof key.children === 'string'
            ? key.children.replace(/^(\d+-)/, "") // ลบเลขและ "-" ออก
            : key.children;
        // setAmcodeS(key["key"])
        // console.log("valueDistrict", value, key);

        setAmcodeS(key.key || ''); // ใช้ key.key ถ้ามีค่า
        setDataPostLand({ ...dataPostLand, district: amcode });
        form.setFieldsValue(
            {
                numberLand: "",
                numberLandLawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
                landPrice: "",
                resultLandPrice: "",
            }
        )

    };
    const handleNumberLand = (e) => {
        setCheckLand(false)
        setDataPostLand({ ...dataPostLand, numberLand: e.target.value })
    };

    const handleLand3 = async (data) => { //น.ส.3

        setLoading(true)
        data = {
            productTypeLand : 1,
            pv_code: data.pvcode,
            am_code: data.amcode,
            pv_name: allData[0].provinces,
            am_name: data.amnamethai,
            tb_code: null,
            tb_name: null,
        }
        await axios.post(createlandmap, data) //Insert Landmap
            .then(res => {
                console.log('fsdf', res.data);
                setDataPostLand(prev => ({
                    ...prev,
                    landmap_id: res.data.landmap_id
                }))
                setLoading(false)

            })
            .catch((err) => console.log(err))

    }
    const handleLandAgain3 = async (amcode, pvcode) => { //น.ส.3 รอบ 2

        setLoading(true)
        data = {
            productTypeLand : 1,
            pv_code: pvcode,
            am_code: amcode,
            pv_name: dataOld.province,
            am_name: dataOld.district,
            tb_code: null,
            tb_name: null,
        }
        await axios.post(createlandmap, data) //Insert Landmap
            .then(res => {
                console.log('fsdf', res.data);
                setDataPostLand(prev => ({
                    ...prev,
                    landmap_id: res.data.landmap_id
                }))
                setLoading(false)

            })
            .catch((err) => console.log(err))

    }

    const handleCheckLand = async () => { //ตรวจสอบ
        var provids = pvcodeS
        var amphurs = amcodeS
        var landNos = dataPostLand.numberLand
        //const tokens = "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjEiLCJyZWdpc3Rlcl90eXBlX3NlcSI6IjgiLCJ1c2VyX2dyb3VwX2RhdGFfbGlzdCI6IjEsMSwwLDAsMCwwLDAsMSIsInBlcnNvbmFsX2lkIjoiIiwianRpIjoiOTllYmE2OTAtMGFmOC00M2NhLTg2OTktYWUwZDI4N2ZjY2EzIiwiaWF0IjoiMDkvMjcvMjAyMyAwMzoyMDoxNSIsIm5iZiI6MTY5NTc4NDgxNSwiZXhwIjoxNjk1ODcxMjE1LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDozMDAwMS8iLCJhdWQiOiJMYW5kc01hcHMifQ.Lw3h4tjSbzjscWqkuA9FutwzXPY8UEb49D4ZdsdqKRfR0fwFunY7i4pg5Rdc70Vrw8jDR1qhtQLn5SEqxPguoQ"

        var result = { pvcode: provids, amcode: amphurs, landNo: landNos }
        // console.log("result =", result)
        const headers = {
            "Content-Type": "application/json",
            // "Authorization": `Bearer ${tokens}`,
        }
        setLoading(true)
        await axios.post(AllData, result, { headers: headers })
            .then(async (response) => {
                // console.log('fasdfasdfsf', response.data);

                if (response.data) {
                    const squareWaArea = parseFloat(response.data.result[0].wa + "." + response.data.result[0].subwa)
                    const landPricePerWa = parseInt(response.data.result[0].landprice.replace(/,/g, ""))
                    const totalWa = (
                        ((parseInt(response.data?.result[0].rai) * 400)) +
                        ((parseInt(response.data?.result[0].ngan) * 100)) +
                        parseFloat(response.data?.result[0].wa + "." + response.data?.result[0].subwa)
                    )
                    const totalPrice = Currency(totalWa * landPricePerWa).value
                    console.log("รวมตารางวาทั้งหมด =", totalPrice.toFixed(1));
                    if (landPricePerWa > 0) {
                        setDataPostLand({
                            ...dataPostLand,
                            subdistrict: response.data?.result[0].tumbolname,
                            parcellat: response.data?.result[0].parcellat,
                            parcellon: response.data?.result[0].parcellon,
                            numberLandLawang: response.data?.result[0].utm,
                            rai: parseInt(response.data?.result[0].rai),
                            workArea: parseInt(response.data?.result[0].ngan),
                            squareWaArea: parseFloat(squareWaArea),
                            landPrice: parseInt(response.data.result[0].landprice.replace(/,/g, "")) || 0,
                            resultLandPrice: totalPrice || 0,
                            landPriceStatus: true,
                            // amcode: amcodeS,
                            // pvcode: pvcodeS,
                            // landmap_id: landmapId,
                        });
                        form.setFieldsValue(
                            {
                                numberLandLawang: response.data?.result[0].utm,
                                rai: parseInt(response.data?.result[0].rai),
                                workArea: parseInt(response.data?.result[0].ngan),
                                squareWaArea: squareWaArea,
                                landPrice: currencyFormatOne(parseInt(response.data.result[0].landprice.replace(/,/g, ""))) || 0,
                                resultLandPrice: currencyFormatOne(totalPrice) || 0,
                            }
                        )
                    } else {
                        setDataPostLand({
                            ...dataPostLand,
                            subdistrict: response.data?.result[0].tumbolname,
                            parcellat: response.data?.result[0].parcellat,
                            parcellon: response.data?.result[0].parcellon,
                            numberLandLawang: response.data?.result[0].utm,
                            rai: parseInt(response.data?.result[0].rai),
                            workArea: parseInt(response.data?.result[0].ngan),
                            squareWaArea: parseFloat(squareWaArea),
                            landPrice: 0,
                            resultLandPrice: 0,
                            landPriceStatus: false,
                            // amcode: amcodeS,
                            // pvcode: pvcodeS,
                            // landmap_id: landmapId,
                        });
                        form.setFieldsValue(
                            {
                                numberLandLawang: response.data?.result[0].utm,
                                rai: parseInt(response.data?.result[0].rai),
                                workArea: parseInt(response.data?.result[0].ngan),
                                squareWaArea: squareWaArea,
                                landPrice: 0,
                                resultLandPrice: 0,
                            }
                        )
                    }

                    data = {
                        productTypeLand : 2,
                        pv_code: pvcodeS,
                        am_code: amcodeS,
                        pv_name: allData[0].provinces,
                        am_name: allData[0].district,
                        tb_code: response.data.result[0].tambol_id,
                        tb_name: response.data.result[0].tumbolname,
                    }
                    await axios.post(createlandmap, data) //Insert Landmap
                        .then(res => {
                            // console.log('fsdf', res.data);
                            setDataPostLand(prev => ({
                                ...prev,
                                landmap_id: res.data.landmap_id
                            }))

                        })
                        .catch((err) => console.log(err))

                    setLoading(false)
                } else {
                    console.log("DATA =", response.data)
                }
            })
            .catch((err) => {
                setDataPostLand({
                    ...dataPostLand,
                    parcellat: "-",
                    parcellon: "-",
                    numberLandLawang: "-",
                    rai: 0,
                    workArea: 0,
                    squareWaArea: 0,
                    landPrice: 0,
                    resultLandPrice: 0,
                    landPriceStatus: false
                });
                errorLand()
            })
            .finally(() => {
                setLoading(false);
            })
        setLoading(false)
        setCheckLand(true)
    }

    const handleCheckLandAgainMode = async (pv, am) => { //เสนอรอบ 2
        var provids = pv
        var amphurs = am
        var landNos = dataOld.numberLand

        var result = { pvcode: provids, amcode: amphurs, landNo: landNos }
        // console.log("result2 =", result)
        const headers = {
            "Content-Type": "application/json",
        }
        setLoading(true)
        await axios.post(AllData, result, { headers: headers })
            .then(async (response) => {
                if (response.data) {
                    // console.log('5555222', response.data);

                    const squareWaArea = parseFloat(response.data.result[0].wa + "." + response.data.result[0].subwa)
                    const landPricePerWa = parseInt(response.data.result[0].landprice.replace(/,/g, ""))
                    const totalWa = (
                        ((parseInt(response.data?.result[0].rai) * 400)) +
                        ((parseInt(response.data?.result[0].ngan) * 100)) +
                        parseFloat(response.data?.result[0].wa + "." + response.data?.result[0].subwa)
                    )
                    const totalPrice = Currency(totalWa * landPricePerWa).value
                    console.log("รวมตารางวาทั้งหมด =", totalPrice.toFixed(1));
                    if (landPricePerWa > 0) {
                        setDataPostLand(prev => ({
                            ...prev,
                            subdistrict: response.data?.result[0].tumbolname,
                            parcellat: response.data?.result[0].parcellat,
                            parcellon: response.data?.result[0].parcellon,
                            numberLandLawang: response.data?.result[0].utm,
                            rai: parseInt(response.data?.result[0].rai),
                            workArea: parseInt(response.data?.result[0].ngan),
                            squareWaArea: parseFloat(squareWaArea),
                            landPrice: parseInt(response.data.result[0].landprice.replace(/,/g, "")) || 0,
                            resultLandPrice: totalPrice || 0,
                            landPriceStatus: true,
                            // amcode: am,
                            // pvcode: pv,
                        }));
                        form.setFieldsValue(
                            {
                                numberLandLawang: response.data?.result[0].utm,
                                rai: parseInt(response.data?.result[0].rai),
                                workArea: parseInt(response.data?.result[0].ngan),
                                squareWaArea: squareWaArea,
                                landPrice: currencyFormatOne(parseInt(response.data.result[0].landprice.replace(/,/g, ""))) || 0,
                                resultLandPrice: currencyFormatOne(totalPrice) || 0,
                            }
                        )
                    } else {
                        // console.log('elseelseelseelseesl');
                        setDataPostLand(prev => ({
                            ...prev,
                            subdistrict: response.data?.result[0].tumbolname,
                            parcellat: response.data?.result[0].parcellat,
                            parcellon: response.data?.result[0].parcellon,
                            numberLandLawang: response.data?.result[0].utm,
                            rai: parseInt(response.data?.result[0].rai),
                            workArea: parseInt(response.data?.result[0].ngan),
                            squareWaArea: parseFloat(squareWaArea),
                            landPrice: 0,
                            resultLandPrice: 0,
                            landPriceStatus: false,
                            // amcode: am,
                            // pvcode: pv,
                        }));
                        form.setFieldsValue(
                            {
                                numberLandLawang: response.data?.result[0].utm,
                                rai: parseInt(response.data?.result[0].rai),
                                workArea: parseInt(response.data?.result[0].ngan),
                                squareWaArea: squareWaArea,
                                landPrice: 0,
                                resultLandPrice: 0,
                            }
                        )
                    }

                    data = {
                        productTypeLand : 2,
                        pv_code: pv,
                        am_code: am,
                        pv_name: dataOld.province,
                        am_name: dataOld.district,
                        tb_code: response.data.result[0].tambol_id,
                        tb_name: response.data.result[0].tumbolname,
                    }
                    await axios.post(createlandmap, data) //Insert Landmap
                        .then(res => {
                            // console.log('fsdf', res.data);
                            setDataPostLand(prev => ({
                                ...prev,
                                landmap_id: res.data.landmap_id
                            }))

                        })
                        .catch((err) => console.log(err))

                    setLoading(false)
                } else {
                    console.log("DATA =", response.data)
                }
            })
            .catch((err) => {
                setDataPostLand({
                    ...dataPostLand,
                    parcellat: "-",
                    parcellon: "-",
                    numberLandLawang: "-",
                    rai: 0,
                    workArea: 0,
                    squareWaArea: 0,
                    landPrice: 0,
                    resultLandPrice: 0,
                    landPriceStatus: false
                });
                errorLand()
            })
            .finally(() => {
                setLoading(false);
            })
        setLoading(false)
        setCheckLand(true)
    }
    const errorLand = () => {
        setDataPostLand({
            ...dataPostLand,
            numberLandLawang: null,
            rai: null,
            workArea: null,
            squareWaArea: null,
            landPrice: 0,
            resultLandPrice: 0,
            landPriceStatus: false
        });
        form.setFieldsValue(
            {
                numberLandLawang: "",
                rai: "",
                workArea: "",
                squareWaArea: "",
                landPrice: 0,
                resultLandPrice: 0,
            }
        )
        Modal.error({
            title: 'ไม่พบข้อมูล',
            content: 'กรุณาตรวจสอบข้อมูลที่กรอกอีกครั้ง',
        })
    }

    const errorSubmit = (placement) => {
        api.error({
            message: <b>ยังไม่กดตรวจสอบที่ดิน</b>,
            description: `กรณีที่มีการกรอก เลขโฉนดที่ดินต้องกตรวจสอบทุกครั้ง`,
            placement,
        });
    }

    return (
        <Row justify={'center'}>
            <Card style={{ width: '1100px' }}>
                <Col style={{ textAlign: 'center' }} >
                    <b><h2>รายละเอียดที่ดิน</h2></b>
                </Col>
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Form
                        name="basic"
                        labelCol={{
                            span: 8,
                        }}
                        // wrapperCol={{
                        //     span: 24,
                        // }}

                        initialValues={{
                            remember: true,
                        }}
                        form={form}
                        onFinish={handleSubmit}
                        autoComplete="off"
                    >
                        <div className='main'>
                            {/* <Card style={{ width: '100%' }}> */}
                            <Col span={24}>
                                <b>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>เลขสัญญา : </span>{<b style={{ fontSize: '20px', lineHeight: '32px' }}>{data?.CONTNO}</b>}
                                    </div>
                                    <div style={{ marginBottom: 0 }}>
                                        <span>เกรด : </span>{<b style={{ fontSize: '20px', lineHeight: '32px' }}>{data?.GRDCOD}</b>}
                                    </div>
                                </b>
                            </Col>
                            <Col span={24}>
                                {
                                    allData ?
                                        <>
                                            <Divider orientation={'left'}><b><u>สินค้า</u></b></Divider>
                                            <aside style={{ width: '100%' }}>
                                                {/* productTypeLand === โฉนด นส4 , นส3 คือ นส3 */}
                                                <div style={{ marginBottom: 3 }}>
                                                    <span>ประเภทเอกสาร : </span><b>{allData[0]?.productTypeLand === "โฉนด" ? "นส.4 จ." : "นส.3 ก."}</b>
                                                </div>
                                                <div style={{ marginBottom: 3 }}>
                                                    <span>จังหวัด : </span><b>{allData[0]?.provinces}</b>
                                                </div>
                                                <div style={{ marginBottom: 3 }}>
                                                    <span>อำเภอ : </span><b>{allData[0]?.district}</b>
                                                </div>
                                                <div style={{ marginBottom: 3 }}>
                                                    <span>เลขโฉนดที่ดิน : </span><b>{allData[0]?.numberLand}</b>
                                                </div>
                                                <div style={{ marginBottom: 3 }}>
                                                    <span>ระวาง : </span><b>{allData[0]?.numberLandlawang}</b>
                                                </div>
                                            </aside>

                                        </>
                                        : null
                                }
                            </Col>
                            <Col span={24}>
                                <Divider orientation={'left'}><b><u>รายละเอียดสินค้า</u></b></Divider>

                                <aside style={{ width: '100%' }}>
                                    <Form.Item label='ประเภทเอกสาร' name='productTypeLand'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input !'
                                            },]}>
                                        <Select placeholder={'เลือก'} style={{ height: '40px' }}
                                            disabled={againMode} onChange={(value) => handleProductLand(value)}
                                        >
                                            <Option value={1}>นส.3 ก.</Option>
                                            <Option value={2}>นส.4 จ.</Option>
                                        </Select>
                                    </Form.Item>

                                    {/* นส3 */}
                                    {
                                        dataPostLand?.productTypeLand === 1 ?
                                            <>
                                                <Form.Item name='province' label='จังหวัด'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Select
                                                        disabled={againMode}
                                                        style={{ height: '40px' }}
                                                        showSearch
                                                        loading={loading}
                                                        name='province'
                                                        onChange={handleProvinceChange1}
                                                    >
                                                        {provinces?.result?.map((pro, index) => (
                                                            <Option key={pro.pvcode} value={pro.pvnamethai}>
                                                                {pro.pvnamethai}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item name='district' label='อำเภอ'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Select
                                                        disabled={againMode}
                                                        style={{ height: '40px' }}
                                                        showSearch
                                                        loading={loading}
                                                        name='district'
                                                        placeholder="อำเภอ"
                                                        onChange={handleDistrictChange2}
                                                    >
                                                        {district?.map((dis, index) => (
                                                            <Option key={dis.amcode} value={dis.amnamethai}>
                                                                {dis.amnamethai}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item label='เลขที่โฉนด/เลขที่' name='numberLand'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='text'
                                                        disabled={againMode} onChange={(e) => setDataPostLand({ ...dataPostLand, numberLand: e.target.value })}></Input>
                                                </Form.Item>
                                                <Form.Item label='พื้นที่ไร่' name='rai'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='number' suffix="ไร่"
                                                        disabled={againMode} onChange={(e) => setDataPostLand({ ...dataPostLand, rai: parseInt(e.target.value) })}></Input>
                                                </Form.Item>
                                                <Form.Item label='พื้นที่งาน' name='workArea'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='number' suffix="งาน"
                                                        disabled={againMode} onChange={(e) => setDataPostLand({ ...dataPostLand, workArea: parseInt(e.target.value) })}></Input>
                                                </Form.Item>
                                                <Form.Item label='พื้นที่ตารางวา' name='squareWaArea'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='number' suffix="ตารางวา"
                                                        disabled={againMode} onChange={(e) => setDataPostLand({ ...dataPostLand, squareWaArea: parseFloat(e.target.value) })}></Input>
                                                </Form.Item>

                                            </>
                                            :
                                            <>
                                                <Form.Item name='province' label='จังหวัด'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Select
                                                        disabled={againMode}
                                                        style={{ height: '40px' }}
                                                        showSearch
                                                        loading={loading}
                                                        name='province'
                                                        onChange={handleProvinceChange}
                                                    >
                                                        {provinces?.result?.map((pro, index) => (
                                                            <Option key={pro.pvcode} value={pro.pvnamethai}>
                                                                {pro.pvnamethai}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item name='district' label='อำเภอ'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Select
                                                        disabled={againMode}
                                                        style={{ height: '40px' }}
                                                        showSearch
                                                        loading={loading}
                                                        name='district'
                                                        placeholder="อำเภอ"
                                                        onChange={handleDistrictChange}
                                                    >
                                                        {district?.map((dis, index) => (
                                                            // console.log("disdis", dis) 

                                                            <Option key={dis.amcode} value={dis.amcode}>
                                                                {dis?.amcode + '-' + dis.amnamethai}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item label='เลขโฉนดที่ดิน' name='numberLand'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input !'
                                                        },]}>
                                                    <Input type='text' disabled={againMode} onChange={(e) => handleNumberLand(e)}></Input>
                                                </Form.Item>
                                                <Form.Item label='ตรวจสอบ'>
                                                    {
                                                        againMode ?
                                                            <Button type="primary" disabled >ตรวจสอบ</Button>
                                                            :
                                                            <Button type="primary" disabled={loading} onClick={handleCheckLand}>ตรวจสอบ</Button>
                                                    }
                                                </Form.Item>
                                                <Form.Item label='ระวาง' name='numberLandLawang'>
                                                    <Input type='text' disabled style={{ color: 'black' }}
                                                        onChange={(e) => setDataPostLand({ ...dataPostLand, numberLandLawang: e.target.value })}></Input>
                                                </Form.Item>
                                                <Form.Item label='พื้นที่ไร่' name='rai'>
                                                    <Input type='text' suffix="ไร่" disabled style={{ color: 'black' }}
                                                        onChange={(e) => setDataPostLand({ ...dataPostLand, rai: parseInt(e.target.value) })}></Input>
                                                </Form.Item>
                                                <Form.Item label='พื้นที่งาน' name='workArea'>
                                                    <Input type='text' suffix="งาน" disabled style={{ color: 'black' }}
                                                        onChange={(e) => setDataPostLand({ ...dataPostLand, workArea: parseInt(e.target.value) })}></Input>
                                                </Form.Item>
                                                <Form.Item label='พื้นที่ตารางวา' name='squareWaArea'>
                                                    <Input type='text' suffix="ตารางวา" disabled style={{ color: 'black' }}
                                                        onChange={(e) => setDataPostLand({ ...dataPostLand, squareWaArea: parseFloat(e.target.value) })}></Input>
                                                </Form.Item>
                                                <Form.Item label='ราคาประเมินที่ดิน' name='landPrice'>
                                                    <Input type='text' suffix="บาทต่อตารางวา" disabled style={{ color: 'black' }}
                                                        onChange={(e) => setDataPostLand({ ...dataPostLand, landPrice: parseInt(e.target.value) })}></Input>
                                                </Form.Item>
                                                <Form.Item label='ราคารวม' name='resultLandPrice'>
                                                    <Input type='text' suffix="บาท" disabled style={{ color: 'black' }}
                                                        onChange={(e) => setDataPostLand({ ...dataPostLand, resultLandPrice: parseInt(e.target.value) })}></Input>

                                                </Form.Item>
                                            </>
                                    }
                                    {/* นส4 */}
                                </aside>
                            </Col>
                            <Col span={24} >
                                <Divider orientation={'left'}><b><u>ประเภทขอกู้</u></b></Divider>
                                <aside style={{ width: '100%' }}>
                                    {
                                        dataType === "รี" ?
                                            <Form.Item
                                                name="productLoanLandType"
                                                label="ประเภทขอกู้"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'โปรดเลือกประเภทขอกู้ !',
                                                    },]}
                                            >
                                                <Select
                                                    style={{ height: '40px' }}
                                                    placeholder={'เลือก'}
                                                    onChange={(value) => { onTypeLoanChange(value) }}
                                                    options={productLoanTypeLandRE}
                                                />
                                            </Form.Item>
                                            : dataType === "ปรับโครงสร้าง" ?
                                                < Form.Item
                                                    name="productLoanLandType"
                                                    label="ประเภทขอกู้"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'โปรดเลือกประเภทขอกู้ !',
                                                        },]}
                                                >
                                                    <Select
                                                        style={{ height: '40px' }}
                                                        placeholder={'เลือก'}
                                                        onChange={(value) => { onTypeLoanChange(value) }}
                                                        options={productLoanTypeLandPAP}
                                                    />
                                                </Form.Item>
                                                :
                                                <Form.Item
                                                    name="productLoanLandType"
                                                    label="ประเภทขอกู้"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'โปรดเลือกประเภทขอกู้ !',
                                                        },]}
                                                >
                                                    <Select
                                                        style={{ height: '40px' }}
                                                        placeholder={'เลือก'}
                                                        onChange={(value) => { onTypeLoanChange(value) }}
                                                        options={productLoanTypeLandCHANGE}
                                                    />
                                                </Form.Item>
                                    }
                                </aside>
                            </Col>
                            <Divider style={{ margin: 5 }} />
                            <Row justify={'center'} >
                                <Space>
                                    <Button type="primary" onClick={onChangeBack}>
                                        ย้อนกลับ
                                    </Button>
                                    <Button type="primary" style={{ background: "red" }} onClick={showConfirmCancel}>
                                        ยกเลิก
                                    </Button>
                                    <Button type="primary" htmlType="submit" style={{ background: "green" }} >
                                        ต่อไป
                                    </Button>
                                </Space>
                            </Row>
                            {/* </Card> */}
                        </div>
                    </Form>
                </Spin>
            </Card >
            {contextHolder}
        </Row >
    )
}


export default DetailProductLand