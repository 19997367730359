import React, { useState, useEffect } from "react";
import { Button, Col, Row, Tag, Space, Card, message, Form, Divider, Image, Modal, notification, Spin, Checkbox } from 'antd';
import { PrinterOutlined } from '@ant-design/icons'
import axios from "axios";
import dayjs from 'dayjs';
import TextArea from "antd/es/input/TextArea";
import ModalDrescriptionPrint from "./ModalDrescriptionPrint";
import Currency from 'currency.js';
import { FIFTEENPERCENT, TWENTYFOURPERCENT } from './../../../pages/offer_case/ploan/InstallmentTable'
import { irr } from 'node-irr'
import {
    DownloadOutlined,
    LeftOutlined,
    RightOutlined,
    RotateLeftOutlined,
    RotateRightOutlined,
    SwapOutlined,
    UndoOutlined,
    ZoomInOutlined,
    ZoomOutOutlined,
} from '@ant-design/icons';
import {
    addcheckbox, findcarcd, findlandpn, loadOJS, precontractcar, precontractland, showguarantorsdoc,
    getImagess, getImagessPre, getAllFolderGuaImg, detailBroker, getImagessGua
} from "../../file_mid/all_api";
import { ShowDataBrokersAD } from '../../file_mid/show_data_broker';
import ModalPreemptionCertificateNEW from "../../document/ModalPreemptionCertificateNEW";
import { auto } from "async";

export default function ConclusionDoc({ dt, close, getData1, sendback }) {

    // console.log(dayjs(getData1?.cars?.apCarLoanDetails?.apApprovalDate).format("YYYY-MM-DD HH:mm:ss"))
    const token = localStorage.getItem('token');
    const branch = localStorage.getItem('branch');
    const [form] = Form.useForm()
    const [formcb] = Form.useForm()
    // const [getNotification, setGetNotification] = useState();
    // const [selectedCheckbox, setSelectedCheckbox] = useState();
    const [getimages, setGetImages] = useState();
    // const [modalAddGuarantor, setModalAddGuarantor] = useState(false)
    //Print
    const [modalDrescriptionPrint, setModalDrescriptionPrint] = useState(false); // Info Print
    const [modalPreemptionCertificateNEW, setModalPreemptionCertificateNEW] = useState(false); // Info Print
    // const [modalnote, setModalNote] = useState(false)
    const [ap, setAp] = useState()
    const [guarantorData, setGuarantorData] = useState([])
    const [api, contextHolder] = notification.useNotification();
    const [imageBlobzz, setImageBlobzz] = useState([]);
    const [address, setAddresss] = useState(getData1?.address)
    const [dataPhoneCus, setDataPhoneCus] = useState(getData1?.phones);
    const [loading, setLoading] = useState(false);
    // const [addCount, setAddCount] = useState(0);
    const [dataNote, setDataNote] = useState([]);
    const [dataNoteRe, setDataNoteRe] = useState([]);
    const [resetPage, setResetPage] = useState();
    const [career, setCareer] = useState([]) // เก็บข้อมูลอาชีพ
    const [cbData, setCbData] = useState();
    const [dataMemo, setDataMemo] = useState();

    const [checkGua, setCheckGua] = useState();

    const [, setResultIrr] = useState(0.0)
    const [arr, setArr] = useState()
    const [interestt, setInterestt] = useState(0.0)
    const [installmentWithInterestt, setInstallmentWithInterestt] = useState(0.0)

    // คำนวนที่ดิน
    const [loanTermDATA, setloanTermDATA] = useState({})
    const [resultRateDATA, setresultRateDATA] = useState({})
    const [resultPriceDATA, setresultPriceDATA] = useState({})

    const [newDataLand, setNewDataLand] = useState([]);
    const [checkSendNEW, setCheckSendNEW] = useState(false);
    const [approval, setApproval] = useState();
    const [dataCarLand, setDataCarLand] = useState();
    const [dataLoan, setDataLoan] = useState();
    const [dataOldloan, setDataOldloan] = useState();
    const [monthlyPaymentCAL, setMonthlyPaymentCAL] = useState();
    const [installmentWithInterestCAL, setInstallmentWithInterestCAL] = useState();

    const [checkBroker, setCheckBroker] = useState(false)
    const [dataBroker, setDataBroker] = useState([]);
    const [brokerName, setbrokerName] = useState()
    const [data627, setData627] = useState()
    const [data630, setData630] = useState()
    const [mixBroker, setMixBroker] = useState()
    const [isu, setIsu] = useState()
    const [loanRe, setLoanRe] = useState()
    const [dataCarAun, setDataCarAun] = useState()  // เก็บข้อมูล aun car

    const [moonnee, setMoonnee] = useState()
    const [hug, setHug] = useState()
    const [kong, setKong] = useState()
    const [jaimoney, setJaimoney] = useState()
    const [yodjut, setYodjut] = useState()
    const [dateceo, setDateceo] = useState()
    const [exp, setExp] = useState()
    const [ngod, setNgod] = useState()
    const [pon, setPon] = useState()


    // image
    const [carshowMadol, setCarShowModal] = useState([]); //send to Modal Show Image
    const [openShowImage, setOpenShowImage] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    // แยก type image car
    const [carimage1, setCarImage1] = useState([]);
    const [carimagenew1, setCarImagenew1] = useState([]);
    const [carimage2, setCarImage2] = useState([]);
    const [carimagenew2, setCarImagenew2] = useState([]);
    const [carimage3, setCarImage3] = useState([]);
    const [carimagenew3, setCarImagenew3] = useState([]);
    const [carimage4, setCarImage4] = useState([]);
    const [carimagenew4, setCarImagenew4] = useState([]);
    const [carimage5, setCarImage5] = useState([]);
    const [carimagenew5, setCarImagenew5] = useState([]);
    const [carimage6, setCarImage6] = useState([]);
    const [carimagenew6, setCarImagenew6] = useState([]);
    const [carimage7, setCarImage7] = useState([]);
    const [carimagenew7, setCarImagenew7] = useState([]);
    const [carimage8, setCarImage8] = useState([]);
    const [carimagenew8, setCarImagenew8] = useState([]);
    const [carimage9, setCarImage9] = useState([]);
    const [carimagenew9, setCarImagenew9] = useState([]);
    const [carimage10, setCarImage10] = useState([]);
    const [carimagenew10, setCarImagenew10] = useState([]);
    const [carimage11, setCarImage11] = useState([]);
    const [carimagenew11, setCarImagenew11] = useState([]);
    const [carimage12, setCarImage12] = useState([]);
    const [carimagenew12, setCarImagenew12] = useState([]);
    const [carimage14, setCarImage14] = useState([]);
    const [carimagenew14, setCarImagenew14] = useState([]);
    const [nuarantorNum, setGuarantorNum] = useState(); //send to Modal Show Image
    const [nuarantorNew, setGuarantorNew] = useState([]); //send to Modal Show Image
    const [current, setCurrent] = React.useState(0);
    const [selectedImages, setSelectedImages] = useState([]);

    const { confirm } = Modal

    // console.log("dataNoteRe", dataNoteRe)
    // console.log("dataNote", dataNote)
    // console.log("getData1", getData1)

    useEffect(() => {
        loadBroker()
        loadCareer()
        loadData()
        // getImg()
        setAddresss(getData1?.address)
        setDataPhoneCus(getData1?.phones)
        if (dt?.car === "car") {
            if (getData1?.cars?.carLoanDetails?.checkGua === "") {
                setCheckGua()
            } else {
                setCheckGua(getData1?.cars?.carLoanDetails?.checkGua)
            }
            if (getData1?.cars?.carLoanDetails?.checkbox?.cbId === 0) {
                setCbData({
                    ...cbData,
                    loantype: "car",
                    loanId: getData1?.cars?.carLoanDetails?.loanId,
                    cbCar: false,
                    cbLand: false,
                    cbHome: false,
                    cbHomeRent: false,
                    cbHomeGov: false,
                    cbPark: false,
                    cbPreaaprove: false,
                    cbGps: false,
                    cbVat: false,
                    cbVdo: false,
                })
            } else {
                setCbData(getData1?.cars?.carLoanDetails?.checkbox)
                formcb.setFieldsValue({
                    ...cbData
                })
            }
            setDataNote(getData1?.cars?.carLoanDetails?.notes)
            if (getData1?.cars?.carLoanDetailsRe?.notes !== null && getData1?.cars?.carLoanDetailsRe?.notes?.length > 0) {
                setDataNoteRe(getData1?.cars?.carLoanDetailsRe?.notes)
            }
            setAp(getData1?.cars.apCarLoanDetails)
            setApproval(getData1?.cars?.carLoanDetails?.approvalStatus)
            setDataCarLand(getData1?.cars)
            setDataLoan(getData1?.cars?.carLoanDetails)
            setDataOldloan(getData1?.cars?.carOldLoanDetails)
            setIsu(getData1?.cars?.ISSUNO)
            setLoanRe(getData1?.cars?.carLoanDetailsRe)
            setDataCarAun(getData1?.cars?.carsApproveCeo)
            calYellowCard(getData1?.cars?.carsApproveCeo)
            var dataloadGua = { typeLoan: dt?.car, loanId: getData1?.cars?.carLoanDetails?.loanId }
            // console.log("dataloadGua", dataloadGua)
        } else if (dt?.car === "land") {
            loadMixData()
            if (getData1?.lands?.landLoanDetails?.checkGua === "") {
                setCheckGua()
            } else {
                setCheckGua(getData1?.lands?.landLoanDetails?.checkGua)
            }
            if (getData1?.lands?.landLoanDetails?.checkbox?.cbId === 0) {
                setCbData({
                    ...cbData,
                    loantype: "land",
                    loanId: getData1?.lands?.landLoanDetails?.landLoanId,
                    cbCar: false,
                    cbLand: false,
                    cbHome: false,
                    cbHomeRent: false,
                    cbHomeGov: false,
                    cbPark: false,
                    cbPreaaprove: false,
                    cbGps: false,
                    cbVat: false,
                    cbVdo: false,
                })
            } else {
                setCbData(getData1?.lands?.landLoanDetails?.checkbox)
                formcb.setFieldsValue({
                    ...cbData
                })
            }
            if (getData1?.lands?.landLoanDetailsRe?.notes !== null && getData1?.lands?.landLoanDetailsRe?.notes?.length > 0) {
                setDataNoteRe(getData1?.lands?.landLoanDetailsRe?.notes)
            }
            setDataNote(getData1?.lands?.landLoanDetails?.notes)
            setAp(getData1?.lands?.apLandLoanDetails)
            setApproval(getData1?.lands?.landLoanDetails?.approvalStatus)
            setDataCarLand(getData1?.lands)
            setDataLoan(getData1?.lands?.landLoanDetails)
            setDataOldloan(getData1?.lands?.landOldLoanDetails)
            setIsu(getData1?.lands?.ISSUNO)
            setLoanRe(getData1?.lands?.landLoanDetailsRe)
            var dataloadGua = { typeLoan: dt?.car, loanId: getData1?.lands?.landLoanDetails?.landLoanId }
            // console.log("dataloadGua", dataloadGua)
        }
        loadGuarantors(dataloadGua)
        var typeimg = dt?.car
        console.log("typeimg", typeimg)
        loadImage(typeimg)

    }, [getData1, resetPage])

    useEffect(() => {
        if (dataBroker?.length > 0) {
            setCheckBroker(true)
            dataBroker?.map((data) => {
                if (data.snam !== "" || data.snam !== null || data.snam !== undefined) {
                    // console.log("name", data.payamt)
                    setbrokerName(data?.snam + data?.firstname + " " + data?.lastname)
                }
                if (data.payfor === "630") {
                    // console.log("map630", data.payamt)
                    setData630(data.payamt)
                }
                if (data.payfor === "627") {
                    // console.log("map627", data.payamt)
                    setData627(data.payamt)
                }
            })
        } else {
            setCheckBroker(false)
        }

    }, [dataBroker])

    useEffect(() => {
        newDataLand.map((e, index) => {
            form.setFieldsValue({
                [`mixNote${index}`]: e?.approval?.mixNote,
                // [`storyLand${index}`]: e?.storyLand,

            })
        })
    }, [newDataLand])

    useEffect(() => {
        imageBlobzz?.map((e, index) => {
            var PreAaproveSplit = []
            PreAaproveSplit = e.pathImage.split("/")
            if (PreAaproveSplit[3] === "13") {
                setCheckSendNEW(true)
            }
        })
    }, [imageBlobzz])

    const calYellowCard = (value) => {
        // มูลหนี้ balance
        // หักส่วนลด (balance-(approvedLoanAmount+(newDamt+followPay)))
        // คงเหลือ มูลหนี้ - หักส่วนลด
        // จ่ายเงิน (newDamt+followPay)
        // ยอดจัด approvedLoanAmount
        // วันที่ approvalDate
        // ใบนี้หมดอายุ ณ วันที่ newOfferDate
        // จำนวนงวด pgLoanTerm
        // ผ่อน installment x pgLoanTerm งวด (รวมภาษีมูลค่าเพิ่ม)
        // วันที่ dayjs(dataCarAun?.approvalDate).format("DD-MM-YYYY")

        setMoonnee(currencyFormatOne(~~value?.balanc))
        setHug(currencyFormatOne(~~value?.balanc - (~~value?.approvedLoanAmount + (~~value?.newDamt + ~~value?.followPay))))
        setKong(currencyFormatOne(~~value?.balanc - (~~value?.balanc - (~~value?.approvedLoanAmount + (~~value?.newDamt + ~~value?.followPay)))))
        setJaimoney(currencyFormatOne(~~value?.newDamt + ~~value?.followPay))
        setYodjut(currencyFormatOne(~~value?.approvedLoanAmount))
        setDateceo(dayjs(value?.approvalDate).format("DD-MM-YYYY"))
        setExp(dayjs(value?.newOfferDate).format("DD-MM-YYYY"))
        setNgod(~~value?.pgLoanTerm)
        setPon(currencyFormatOne(~~value?.installment) + " x " + ~~value?.pgLoanTerm)
    }

    const loadMixData = async () => {
        await axios.post(findlandpn, { landLoanId: getData1.lands.landLoanId })
            .then(res => {
                // console.log(":",res.data)

                if (res.status === 200) {
                    setNewDataLand(res.data)
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    const loadBroker = async () => {
        const result = { loanId: getData1?.cars?.carLoanDetails?.loanId }
        setLoading(true)
        await axios.post(detailBroker, result)
            .then((res) => {
                if (res.data) {
                    // console.log("OK", res.data)
                    setDataBroker(res.data)
                }
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }
    const handleCheckCal = async (value) => {
        if (dt?.car === "car") {
            if (value.carLoanDetails?.productType === "p-loan") {
                console.log("1 = รถเล็ก p-loan")
                handleCalculate(value)
            } else if (value.carLoanDetails?.productType === "เช่าซื้อ") {
                if (value.productTypeCar === 1) {
                    console.log("2 = รถเล็ก เช่าซื้อ")
                    handleCalculateHIRE(value)
                } else {
                    console.log("3 = รถใหญ่ เช่าซื้อ")
                    car_Calculate(value)
                }
            } else if (value.carLoanDetails?.productType === "เช่าซื้อ1") {
                console.log("4 = รถเล็ก เช่าซื้อ .84")
                car_Calculate(value)
            }
        } else if (dt?.car === "land") {
            console.log("5 = ที่ดิน")
            land_Calculate(value)
        }
    }

    const handleCalculate = async (value) => { //กรณี p-loan

        var LA = value.carLoanDetails.approvedLoanAmount
        var LT = value.carLoanDetails.approvedLoanTerm

        if (LA && LT) {
            let loanAmount = 0.0
            let interest = 0.0

            TWENTYFOURPERCENT.map((value) => {
                if (value.installment === ~~LT) {
                    loanAmount = value.interest * 0.7
                }
            })

            FIFTEENPERCENT.map((value) => {
                if (value.installment === ~~LT) {
                    interest = value.interest * 0.3
                }
            })

            let installmentAmount = Math.round((interest + loanAmount) * LA)

            // IRR Calculate
            var array = []
            array.push(-LA)
            for (var i = 0; i < LT; i++) {
                array.push(installmentAmount)
            }

            let IRR = irr(array) * 100

            //console.log("IRR", IRR)

            if (IRR) {
                var TotalPrincipleBalance = LA //ยอดทั้งหมด
                var totalInterest = 0.0 // ดอกเบี้ย
                //var result = ~~LA
                var result = LA
                for (var j = 1; j <= LT; j++) {     // installmentCount จำนวนงวด
                    let principle = TotalPrincipleBalance     // เงินต้น = ยอดทั้งหมด
                    let no = j   // งวดที่ 
                    let interestAmount = Math.round((TotalPrincipleBalance * IRR) / 100) // จำนวนดอกเบี้ย = 
                    let principleAmount = installmentAmount - interestAmount    //  จำนวนงวด - จำนวนดอกเบี้ย
                    let principleBalance = principle - principleAmount          //  ดอกเบี้ยคงเหลือ = 

                    totalInterest += interestAmount // totalInterest = totalInterest + interestAmount

                    let interestBalance = totalInterest // ดอกเบี้ยคงเหลือ = ยอดรวมดอกเบี้ย

                    // TotalPrincipleBalance = TotalPrincipleBalance - principleAmount, ยอดทั้งหมด
                    TotalPrincipleBalance -= principleAmount
                }
                //console.log("result", result)
                // result += totalInterest
                result = installmentAmount * LT
                var interestNEW = result - LA
                setResultIrr(Currency(IRR, { precision: 5 }).value)
                setArr(installmentAmount)
                setInterestt(interestNEW)
                setInstallmentWithInterestt(result)

                setMonthlyPaymentCAL(installmentAmount)
                setInstallmentWithInterestCAL(result)
            }
        }
    }

    const car_Calculate = async (value) => {

        var LA = value.carLoanDetails.approvedLoanAmount
        var LT = value.carLoanDetails.approvedLoanTerm
        var IR = value.carLoanDetails.interestRate

        //แก้ใหม่
        var interestRateCar = parseFloat(IR) / 100 // อัตราดอก / 100
        var rateCar = ((parseFloat(LA) * Currency(interestRateCar, { precision: 5 }).value) * LT) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
        var loanTermCar = (rateCar + parseFloat(LA)) / parseInt(LT) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
        var newLoanTermCar = Math.ceil(Currency((loanTermCar * 0.07) + loanTermCar), { precision: 5 })// งวดละใหม่ = ( งวดละ * 0.07 ) + งวดละ
        var resultRateCar = Math.ceil(Currency(newLoanTermCar, { precision: 5 }) * parseInt(LT)) - parseFloat(LA) // งวดละใหม่ * งวด - ยอดจัด
        var newResultPriceCar = Math.ceil(Currency(newLoanTermCar) * parseInt(LT), { precision: 5 }) // งวดละใหม่ * งวด

        setArr(resultRateCar)
        setInterestt(newLoanTermCar)
        setInstallmentWithInterestt(newResultPriceCar)

        setMonthlyPaymentCAL(newLoanTermCar)
        setInstallmentWithInterestCAL(newResultPriceCar)

    }

    const handleCalculateHIRE = async (value) => {

        var LA = value.carLoanDetails.approvedLoanAmount
        var LT = value.carLoanDetails.approvedLoanTerm

        if (LA && LT) {
            const amountWithVat = LA * 1.07
            let installment = 0.0

            FIFTEENPERCENT.map((value) => {
                if (value.installment === ~~LT) {
                    installment = value.interest * amountWithVat
                }
            })

            let installmentAmount = Math.round(installment)

            // IRR Calculate
            var array = []
            array.push(-amountWithVat)
            for (var i = 0; i < LT; i++) {
                array.push(installmentAmount)
            }

            let IRR = irr(array) * 100

            if (IRR) {
                var TotalPrincipleBalance = amountWithVat //ยอดทั้งหมด
                var totalInterest = 0.0 // ดอกเบี้ย
                //var result = ~~LA
                var result = amountWithVat
                for (var j = 1; j <= LT; j++) {     // installmentCount จำนวนงวด
                    let principle = TotalPrincipleBalance     // เงินต้น = ยอดทั้งหมด
                    let no = j   // งวดที่ 
                    let interestAmount = Math.round((TotalPrincipleBalance * IRR) / 100) // จำนวนดอกเบี้ย = 
                    let principleAmount = installmentAmount - interestAmount    //  จำนวนงวด - จำนวนดอกเบี้ย
                    let principleBalance = principle - principleAmount          //  ดอกเบี้ยคงเหลือ = 

                    totalInterest += interestAmount // totalInterest = totalInterest + interestAmount

                    let interestBalance = totalInterest // ดอกเบี้ยคงเหลือ = ยอดรวมดอกเบี้ย

                    // TotalPrincipleBalance = TotalPrincipleBalance - principleAmount, ยอดทั้งหมด
                    TotalPrincipleBalance -= principleAmount
                }
                //result += totalInterest
                result = installmentAmount * LT
                var interestNEW = result - LA
                setResultIrr(Currency(IRR, { precision: 5 }).value)
                setArr(installmentAmount)
                setInterestt(interestNEW)
                // setInterestt(totalInterest)
                setInstallmentWithInterestt(result)

                setMonthlyPaymentCAL(installmentAmount)
                setInstallmentWithInterestCAL(result)
            }
        }
    }
    const land_Calculate = async (value) => {
        var LA = value.landLoanDetails.approvedLoanAmount
        var LT = value.landLoanDetails.approvedLoanTerm
        var IR = value.landLoanDetails.interestRateLand

        var interestRateLand = parseFloat(IR) / 100 // อัตราดอก / 100
        var rate = (parseFloat(LA) * Currency(interestRateLand, { precision: 3 }).value) * parseInt(LT) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
        var loanTerm = Math.ceil(Currency((rate + parseFloat(LA)) / parseInt(LT), { precision: 2 }).value) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
        var resultRate = (loanTerm * parseInt(LT)) - parseFloat(LA)
        var resultPrice = loanTerm * parseInt(LT)

        setloanTermDATA(loanTerm)
        setresultRateDATA(resultRate)
        setresultPriceDATA(resultPrice)

        setMonthlyPaymentCAL(loanTerm)
        setInstallmentWithInterestCAL(resultPrice)
    }

    // const getImg = async () => {
    //     if (dt?.car === "car") {
    //         var path = `one_images/car/${getData1?.cars?.carId}/5`
    //     } else {
    //         var path = `one_images/land/${getData1?.lands?.landId}/5`
    //     }
    //     setLoading(true)
    //     await axios.get(`${getAllFolderGuaImg}/${path}?token=${token}`)
    //         .then(res => {
    //             if (res.data) {
    //                 // console.log("NEW IMG", res.data)
    //                 setGetImages(res.data)
    //                 setLoading(false)
    //             } else {
    //                 setGetImages([])
    //             }
    //         })
    //         .catch((err) => {
    //             console.log("err", err)
    //             setLoading(false)
    //         })
    //     setLoading(false)
    // }

    const printdrescription = () => {
        setModalDrescriptionPrint(true)
    }
    const printPreemptionCertificate = () => {
        setMixBroker({ checkBroker, data627, data630, brokerName, })
        setModalPreemptionCertificateNEW(true)
    }

    const onChangePreaaprove = (e) => {
        setCbData({ ...cbData, cbPreaaprove: e.target.checked })
    };
    const onChangeCar = (e) => {
        setCbData({ ...cbData, cbCar: e.target.checked })
    };
    const onChangeLand = (e) => {
        setCbData({ ...cbData, cbLand: e.target.checked })
    };
    const onChangeHome = (e) => {
        setCbData({ ...cbData, cbHome: e.target.checked })
    };
    const onChangeHomeRent = (e) => {
        setCbData({ ...cbData, cbHomeRent: e.target.checked })
    };
    const onChangeHomeGov = (e) => {
        setCbData({ ...cbData, cbHomeGov: e.target.checked })
    };
    const onChangePark = (e) => {
        setCbData({ ...cbData, cbPark: e.target.checked })
    };
    const onChangeVat = (e) => {
        setCbData({ ...cbData, cbVat: e.target.checked })
    };
    const onChangeVDO = (e) => {
        setCbData({ ...cbData, cbVdo: e.target.checked })
    };
    const onChangeGPS = (e) => {
        setCbData({ ...cbData, cbGps: e.target.checked })
    };

    const loadCareer = async () => {
        const id = { customerId: getData1?.customerId }
        setLoading(true)
        await axios.post(loadOJS, id)
            .then((res) => {
                //console.log("res", res.data)
                setCareer(res.data)
                setLoading(false)
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }

    const loadGuarantors = async (value) => {
        await axios.post(showguarantorsdoc, value)
            .then(res => {
                if (res.status === 200) {
                    // console.log("คนค้ำ", res.data)
                    setGuarantorData(res.data)
                } else if (res.status === 204) {
                    // console.log("ไม่พบข้อมูล")
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }
    const onDownload = () => {
        const imageObj = imageBlobzz[current]; // ดึง object ของภาพที่ต้องการ
        const filename = `${Date.now()}_${imageObj.name}`; // สร้างชื่อไฟล์

        // ใช้ fetch ดึงรูปภาพจาก path
        fetch(imageObj.path)
            .then((response) => response.blob()) // เปลี่ยน response เป็น blob
            .then((blob) => {
                // สร้าง Blob URL สำหรับการดาวน์โหลด
                const blobUrl = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = filename; // ตั้งชื่อไฟล์สำหรับการดาวน์โหลด
                document.body.appendChild(link);
                link.click(); // จำลองการคลิกเพื่อดาวน์โหลด
                URL.revokeObjectURL(blobUrl); // ยกเลิก Blob URL หลังการใช้งาน
                link.remove(); // ลบ link ออกจาก DOM
            })
            .catch((error) => {
                console.error("Error downloading the image:", error);
            });
    };

    const handleSelectImage = (e, image) => {
        // ล็อกิกการเพิ่มรูปภาพเข้าใน selectedImages
        setSelectedImages(prevSelected => [...prevSelected, image]);
    };

    function extractFolderGroup(path) {
        const match = path?.pathImage?.match(/\/5\/(\d+)\//);  // ดึงตัวเลขที่อยู่ในรูปแบบ /5/x/
        return match ? match[1] : null;
    }


    // const loadImage = async (value) => {
    //     if (value === "car") {
    //         setImageBlobzz(getData1?.cars?.img)
    //     } else {
    //         setImageBlobzz(getData1?.lands?.img)
    //     }
    // }
    const loadImage = async (value) => {
        var carimg1 = [];
        var carimg2 = [];
        var carimg3 = [];
        var carimg4 = [];
        var carimg5 = [];
        var carimg6 = [];
        var carimg7 = [];
        var carimg8 = [];
        var carimg9 = [];
        var carimg10 = [];
        var carimg11 = [];
        var carimg12 = [];
        var carimg14 = [];
        var carimgnew1 = [];
        var carimgnew2 = [];
        var carimgnew3 = [];
        var carimgnew4 = [];
        var carimgnew5 = [];
        var carimgnew6 = [];
        var carimgnew7 = [];
        var carimgnew8 = [];
        var carimgnew9 = [];
        var carimgnew10 = [];
        var carimgnew11 = [];
        var carimgnew12 = [];
        var carimgnew14 = [];
        if (value === "car") {
            setImageBlobzz(getData1?.cars?.img)
            getData1?.cars?.img?.map((car, i) => {
                if (car.type === 1) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = car.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && car?.type === 1) {
                        carimg1.push(car)
                        setCarImage1(carimg1)
                    } else if (PreAaproveSplit[3] === "13" && car?.type === 1) {
                        carimgnew1.push(car)
                        setCarImagenew1(carimgnew1)
                    }
                    // carimg1.push(car)
                    // setCarImage1(carimg1)
                } else if (car.type === 2) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = car.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && car?.type === 2) {
                        console.log("22", car)
                        carimg2.push(car)
                        setCarImage2(carimg2)
                    } else if (PreAaproveSplit[3] === "13" && car?.type === 2) {
                        carimgnew2.push(car)
                        setCarImagenew2(carimgnew2)
                    }
                    // carimg2.push(car)
                    // setCarImage2(carimg2)
                } else if (car.type === 4) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = car.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && car?.type === 4) {
                        carimg4.push(car)
                        setCarImage4(carimg4)
                    } else if (PreAaproveSplit[3] === "13" && car?.type === 4) {
                        carimgnew4.push(car)
                        setCarImagenew4(carimgnew4)
                    }
                    // carimg4.push(car)
                    // setCarImage4(carimg4)
                } else if (car.type === 5) {
                    var group1 = [];
                    const group = extractFolderGroup(car);
                    if (group === '1') {
                        group1.push({ ...car, num: 1 });
                    } else if (group === '2') {
                        group1.push({ ...car, num: 2 });
                    } else if (group === '3') {
                        group1.push({ ...car, num: 3 });
                    } else if (group === '4') {
                        group1.push({ ...car, num: 4 });
                    } else if (group === '5') {
                        group1.push({ ...car, num: 5 });
                    } else if (group === '6') {
                        group1.push({ ...car, num: 6 });
                    }
                    carimg5.push(...group1)
                    setCarImage5(carimg5)
                } else if (car.type === 6) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = car.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && car?.type === 6) {
                        carimg6.push(car)
                        setCarImage6(carimg6)
                    } else if (PreAaproveSplit[3] === "13" && car?.type === 6) {
                        carimgnew6.push(car)
                        setCarImagenew6(carimgnew6)
                    }
                    // carimg6.push(car)
                    // setCarImage6(carimg6)
                } else if (car.type === 7) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = car.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && car?.type === 7) {
                        carimg7.push(car)
                        setCarImage7(carimg7)
                    } else if (PreAaproveSplit[3] === "13" && car?.type === 7) {
                        carimgnew7.push(car)
                        setCarImagenew7(carimgnew7)
                    }
                    // carimg7.push(car)
                    // setCarImage7(carimg7)
                } else if (car.type === 8) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = car.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && car?.type === 8) {
                        carimg8.push(car)
                        setCarImage8(carimg8)
                    } else if (PreAaproveSplit[3] === "13" && car?.type === 8) {
                        carimgnew8.push(car)
                        setCarImagenew8(carimgnew8)
                    }
                    // carimg8.push(car)
                    // setCarImage8(carimg8)
                } else if (car.type === 9) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = car.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && car?.type === 9) {
                        carimg9.push(car)
                        setCarImage9(carimg9)
                    } else if (PreAaproveSplit[3] === "13" && car?.type === 9) {
                        carimgnew9.push(car)
                        setCarImagenew9(carimgnew9)
                    }
                    // carimg9.push(car)
                    // setCarImage9(carimg9)
                } else if (car.type === 14) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = car.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && car?.type === 14) {
                        carimg14.push(car)
                        setCarImage14(carimg14)
                    } else if (PreAaproveSplit[3] === "13" && car?.type === 14) {
                        carimgnew14.push(car)
                        setCarImagenew14(carimgnew14)
                    }
                    // carimg14.push(car)
                    // setCarImage14(carimg14)
                } else {
                    var PreAaproveSplit = []
                    PreAaproveSplit = car.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && car?.type === 12) {
                        carimg12.push(car)
                        setCarImage12(carimg12)
                    } else if (PreAaproveSplit[3] === "13" && car?.type === 12) {
                        carimgnew12.push(car)
                        setCarImagenew12(carimgnew12)
                    }
                    // carimg12.push(car)
                    // setCarImage12(carimg12)
                }
            })
        } else {
            setImageBlobzz(getData1?.lands?.img)
            getData1?.lands?.img?.map((land, i) => {
                if (land.type === 1) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 1) {
                        carimg1.push(land)
                        setCarImage1(carimg1)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 1) {
                        carimgnew1.push(land)
                        setCarImagenew1(carimgnew4)
                    }
                    // carimg1.push(land)
                    // setCarImage1(carimg1)
                } else if (land.type === 3) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 3) {
                        carimg3.push(land)
                        setCarImage3(carimg3)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 3) {
                        carimgnew3.push(land)
                        setCarImagenew3(carimgnew3)
                    }
                    // carimg3.push(land)
                    // setCarImage3(carimg3)
                } else if (land.type === 4) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 4) {
                        carimg4.push(land)
                        setCarImage4(carimg4)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 4) {
                        carimgnew4.push(land)
                        setCarImagenew4(carimgnew4)
                    }
                    // carimg4.push(land)
                    // setCarImage4(carimg4)
                } else if (land.type === 5) {
                    var group1 = [];
                    const group = extractFolderGroup(land);
                    if (group === '1') {
                        group1.push({ ...land, num: 1 });
                    } else if (group === '2') {
                        group1.push({ ...land, num: 2 });
                    } else if (group === '3') {
                        group1.push({ ...land, num: 3 });
                    } else if (group === '4') {
                        group1.push({ ...land, num: 4 });
                    } else if (group === '5') {
                        group1.push({ ...land, num: 5 });
                    } else if (group === '6') {
                        group1.push({ ...land, num: 6 });
                    }
                    carimg5.push(...group1)
                    setCarImage5(carimg5)
                } else if (land.type === 6) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 6) {
                        carimg6.push(land)
                        setCarImage6(carimg6)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 6) {
                        carimgnew6.push(land)
                        setCarImagenew6(carimgnew6)
                    }
                    // carimg6.push(land)
                    // setCarImage6(carimg6)
                } else if (land.type === 7) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 7) {
                        carimg7.push(land)
                        setCarImage7(carimg7)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 7) {
                        carimgnew7.push(land)
                        setCarImagenew7(carimgnew7)
                    }
                    // carimg7.push(land)
                    // setCarImage7(carimg7)
                } else if (land.type === 8) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 8) {
                        carimg8.push(land)
                        setCarImage8(carimg8)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 8) {
                        carimgnew8.push(land)
                        setCarImagenew8(carimgnew8)
                    }
                    // carimg8.push(land)
                    // setCarImage8(carimg8)
                } else if (land.type === 9) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 9) {
                        carimg9.push(land)
                        setCarImage9(carimg9)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 9) {
                        carimgnew9.push(land)
                        setCarImagenew9(carimgnew9)
                    }
                    // carimg9.push(land)
                    // setCarImage9(carimg9)
                } else if (land.type === 10) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 10) {
                        carimg10.push(land)
                        setCarImage10(carimg10)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 10) {
                        carimgnew10.push(land)
                        setCarImagenew10(carimgnew10)
                    }
                    // carimg10.push(land)
                    // setCarImage10(carimg10)
                } else if (land.type === 11) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 11) {
                        carimg11.push(land)
                        setCarImage11(carimg11)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 11) {
                        carimgnew11.push(land)
                        setCarImagenew11(carimgnew11)
                    }
                    // carimg11.push(land)
                    // setCarImage11(carimg11)
                } else if (land.type === 14) {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 14) {
                        carimg14.push(land)
                        setCarImage14(carimg14)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 14) {
                        carimgnew14.push(land)
                        setCarImagenew14(carimgnew14)
                    }
                    // carimg14.push(land)
                    // setCarImage14(carimg14)
                } else {
                    var PreAaproveSplit = []
                    PreAaproveSplit = land.pathImage.split("/")
                    if (PreAaproveSplit[3] !== "13" && land?.type === 12) {
                        carimg12.push(land)
                        setCarImage12(carimg12)
                    } else if (PreAaproveSplit[3] === "13" && land?.type === 12) {
                        carimgnew12.push(land)
                        setCarImagenew12(carimgnew12)
                    }
                    // carimg12.push(land)
                    // setCarImage12(carimg12)
                }
            })
        }
    }



    const currencyFormatOne = (amount) => {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }

    const AddCheckbox = async () => {
        await axios.post(addcheckbox, cbData)
            .then(res => {
                if (res.status === 200) {
                    CheckboxSuccess("top")
                    sendback(dayjs())
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    const loadData = async () => {
        if (dt?.car === "car") {
            await axios.post(findcarcd, { carId: getData1?.cars?.carId, customerId: getData1?.customerId })
                .then(res => {
                    if (res.status === 200) {
                        // console.log("CarCar", res.data)
                        setDataMemo(res.data.cars.carMemo)
                    }
                }).catch((err) => {
                    console.log("err", err)
                })
            await handleCheckCal(getData1?.cars)
        } else {
            await handleCheckCal(getData1?.lands)
        }
    }

    const CheckboxSuccess = (placement) => {
        api.success({
            message: "เพิ่ม Checkbox แล้ว",
            placement,
        });
    };

    const handleCancel = () => {
        close()
    }

    const showConfirmOK = () => {
        confirm({
            title: 'เอกสารผ่านการตรวจสอบ...?',
            onOk() {
                var checkOK = true
                Precontract(checkOK)
            },
            onCancel() {
            },
        });
    };

    const Precontract = async (checkOK) => {
        if (dt?.car === "car") {
            if (checkOK === true) {
                var data = { ...getData1?.cars?.carLoanDetails, isAccepted: true, approvalStatus: 27 }
            }
            delete data.rejectDate
            delete data.acceptDate
            delete data.acceptDatePg
            delete data.acceptPassDate

            await axios.put(precontractcar, data)
                .then(res => {
                    if (res.status === 200) {
                        if (data.approvalStatus === 27) {
                            message.success('เปลี่ยนสถานะแล้ว')
                        }
                        setTimeout(() => {
                            close(false);
                        }, 2000)

                    }
                }).catch((err) => {
                    console.log("err", err)
                })
        } else {
            if (checkOK === true) {
                var data = { ...getData1?.lands?.landLoanDetails, isAccepted: true, approvalStatus: 27 }
            }
            delete data.rejectDate
            delete data.acceptDate
            delete data.acceptDatePg
            delete data.acceptPassDate

            // console.log("data", data)
            await axios.put(precontractland, data)
                .then(res => {
                    if (res.status === 200) {
                        message.success('เปลี่ยนสถานะแล้ว')

                        setTimeout(() => {
                            close(false);
                        }, 2000)

                    }
                }).catch((err) => {
                    console.log("err", err)
                })
        }
    };

    const renderGuarantorNewImg = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        // console.log("Item", item, index)
        var itemSplit = []
        var itemSplitnum = []
        if (item?.pathImg) {
            itemSplit = item.pathImg.split("/")
        }
        carimage5?.map((e, i) => {
            const segments = e?.pathImage.split("/")
            if (itemSplit[4] === segments[4]) {
                itemSplitnum.push(e)
            }
        })
        const count = itemSplitnum.length || 0;
        return (
            <>
                <Row style={{ margin: '3px' }} justify={'center'} >
                    <Card
                        title={"ลำดับที่ " + (item.garno)}
                        style={{ textAlign: "center" }}
                    >
                        <Col span={24} style={{ textAlign: "left" }}>
                            <Form.Item style={{ marginBottom: 0 }} label='ชื่อ-นามสกุล'>
                                <b>{item.snam + '' + item.firstname + ' ' + item.lastname}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='เบอร์โทรติดต่อ'>
                                <b>{item?.phones[0]?.telp}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='ความสัมพันธ์'>
                                <b>{item?.description}</b>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label='ที่อยู่'>
                                <b>{item.address[0]?.houseNumber} {item.address[0]?.subdistrict} {item.address[0]?.district} {item.address[0]?.province} {item.address[0]?.zipCode}</b>
                            </Form.Item>
                            <Divider />
                            {itemSplit.length > 0 ? (
                                <>
                                    <Row justify={"center"}>
                                        <Image.PreviewGroup
                                            preview={{
                                                countRender: (index, total) => {
                                                    const displayedIndex = Math.min(index, count);
                                                    const displayTotal = Math.max(count, count);
                                                    return `${displayedIndex} / ${displayTotal}`;
                                                }, // ใช้เพื่อแสดงจำนวนภาพ
                                                toolbarRender: (
                                                    _,
                                                    {
                                                        transform: { scale },
                                                        actions: {
                                                            onActive,
                                                            onFlipY,
                                                            onFlipX,
                                                            onRotateLeft,
                                                            onRotateRight,
                                                            onZoomOut,
                                                            onZoomIn,
                                                            onReset,
                                                        },
                                                    },
                                                ) => (
                                                    <>
                                                        <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                            {carimage5?.map((e, index) => {
                                                                const segments = e?.pathImage.split("/")
                                                                if (itemSplit[4] === segments[4] && e.type === 5) {
                                                                    return (
                                                                        <Image
                                                                            width={auto}
                                                                            key={index}
                                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                            src={`${getImagessGua}/${e.pathImage}?token=${token}`}
                                                                            alt={`รูปภาพ ${index + 1}`}
                                                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                        />
                                                                    )
                                                                }
                                                                return null;
                                                            }
                                                            )}
                                                        </Row>
                                                        <Row style={{ zIndex: 2 }}>
                                                            <Space size={12} className="toolbar-wrapper">
                                                                {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                <DownloadOutlined onClick={onDownload} />
                                                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                <SwapOutlined onClick={onFlipX} />
                                                                <RotateLeftOutlined onClick={onRotateLeft} />
                                                                <RotateRightOutlined onClick={onRotateRight} />
                                                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                <UndoOutlined onClick={onReset} />
                                                            </Space>
                                                        </Row>
                                                    </>
                                                ),
                                                onChange: (index) => {
                                                    setCurrent(index);
                                                },
                                            }}
                                        >
                                            <Row gutter={32} justify={'center'}>
                                                {carimage5?.map((e, index) => {
                                                    const segments = e?.pathImage.split("/")
                                                    if (itemSplit[4] === segments[4] && e.type === 5) {
                                                        return (
                                                            <Image
                                                                width={150}
                                                                key={index}
                                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                src={`${getImagessGua}/${e.pathImage}?token=${token}`}
                                                                alt={`รูปภาพ ${index + 1}`}
                                                                style={{ cursor: 'pointer' }}
                                                            />

                                                        )
                                                    }
                                                    return null;
                                                }
                                                )}
                                            </Row>
                                        </Image.PreviewGroup>
                                        {/* {
                                            imageBlobzz?.map((items, index) => {
                                                if (items.type === 5) {
                                                    const segments = items.pathImage.split("/")
                                                    if (itemSplit[4] === segments[4]) {
                                                        return (
                                                            // <Image width={'50px'} key={index} src={`data:${items?.Attr};base64,${items?.data?.toString('base64')}`} alt={`Image ${index + 1}`} />
                                                            <Image width={"50px"} key={index} src={`${getImagessGua}/${items.pathImage}?token=${token}`} alt={`รูปภาพ ${index + 1}`} />
                                                        )
                                                    }
                                                    // return null;
                                                }
                                                return null;
                                            })
                                        } */}
                                    </Row>
                                </>
                            ) : null
                            }
                        </Col>
                    </Card>
                </Row>
            </>
        )
    }
    const renderItemAddress = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        return (
            <>
                <Form
                    name='basic'
                    labelCol={{
                        span: 12,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        width: '100%',
                    }}
                    autoComplete="off"
                    initialValues={{ remember: true }}
                >
                    <Row gutter={32}>
                        <Col className='gutter-row' span={9}>
                            <Form.Item
                                style={{ marginBottom: 0, textAlign: 'left' }}
                                label='เลขทะเบียนบ้าน'
                            >
                                <b>{item.houseRegistrationNumber}</b>
                            </Form.Item>
                            <Form.Item
                                style={{ marginBottom: 0, textAlign: 'left' }}
                                label='ถนน'
                            >
                                <b>{item.road}</b>
                            </Form.Item>
                            <Form.Item
                                style={{ marginBottom: 0, textAlign: 'left' }}
                                label='ตำบล'
                            >
                                <b>{item.subdistrict}</b>
                            </Form.Item>

                        </Col>
                        <Col className='gutter-row' span={7}>
                            <Form.Item
                                style={{ marginBottom: 0, textAlign: 'left' }}
                                label='บ้านเลขที่'
                            >
                                <b>{item.houseNumber}</b>
                            </Form.Item>
                            <Form.Item
                                style={{ marginBottom: 0, textAlign: 'left' }}
                                label='หมู่บ้าน'
                            >
                                <b>{item.village}</b>
                            </Form.Item>
                            <Form.Item
                                style={{ marginBottom: 0, textAlign: 'left' }}
                                label='อำเภอ'
                            >
                                <b>{item.district}</b>
                            </Form.Item>

                        </Col>
                        <Col className='gutter-row' span={8}>
                            <Form.Item
                                style={{ marginBottom: 0, textAlign: 'left' }}
                                label='หมู่ / ซอย'
                            >
                                <b>{item.soi}</b>
                            </Form.Item>

                            <Form.Item
                                style={{ marginBottom: 0, textAlign: 'left' }}
                                label='จังหวัด'
                            >
                                <b>{item.province}</b>
                            </Form.Item>
                            <Form.Item
                                style={{ marginBottom: 0, textAlign: 'left' }}
                                label='รหัสไปรษณีย์'
                            >
                                <b>{item.zipCode}</b>
                            </Form.Item>

                        </Col>
                    </Row>
                </Form>
                <Divider style={{ margin: 5 }} />
            </>
        )
    }
    const renderItemCareer = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        return (
            <>
                <Form
                    name='basic'
                    labelCol={{
                        span: 12,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        width: '100%',
                    }}
                    autoComplete="off"
                    initialValues={{ remember: true }}
                >
                    <Row gutter={32} justify={'center'}>
                        <Col className='gutter-row' span={8}>
                            <Form.Item
                                style={{ marginBottom: 0, textAlign: 'left' }}
                                label='กลุ่มอาชีพ'
                            >
                                <b>{item?.desc}</b>
                            </Form.Item>
                            <Form.Item
                                style={{ marginBottom: 0, textAlign: 'left' }}
                                label='ชื่อบริษัท(สถานที่ทำงาน)'
                            >
                                <b>{item?.companyName}</b>
                            </Form.Item>
                        </Col>
                        <Col className='gutter-row' span={8}>
                            <Form.Item
                                style={{ marginBottom: 0, textAlign: 'left' }}
                                label='อาชีพ'
                            >
                                <b>{item?.descSub}</b>
                            </Form.Item>
                            <Form.Item
                                style={{ marginBottom: 0, textAlign: 'left' }}
                                label='รายได้ / เดือน'
                            >
                                <b>{currencyFormatOne(item?.incomeMonth)}</b>
                            </Form.Item>
                        </Col>
                        <Col className='gutter-row' span={8}>
                            <Form.Item
                                style={{ marginBottom: 0, textAlign: 'left' }}
                                label='สาขาอาชีพ'
                            >
                                <b>{item?.descSection}</b>
                            </Form.Item>
                            <Form.Item
                                style={{ marginBottom: 0, textAlign: 'left' }}
                                label='รายจ่าย / เดือน'
                            >
                                <b>{currencyFormatOne(item?.expensesMonth)}</b>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Divider style={{ margin: 5 }} />
            </>
        )
    }
    const renderItemPhones = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
        // console.log("item: " + item)
        return (
            <>
                <Form
                    name='basic'
                    labelCol={{
                        span: 15,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        width: '100%',
                    }}
                    autoComplete="off"
                    initialValues={{ remember: true }}
                >
                    <Row gutter={32}>
                        <Col className='gutter-row' span={24}>
                            <Form.Item
                                style={{ marginBottom: 0 }}
                                label={item?.phoneType}
                            >
                                <b> {item?.telp}</b>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </>
        )
    }
    const renderDataNote = ({ item, index }) => {
        // console.log("โน๊ตธรรมดา",item)
        return (
            <>
                <Row gutter={32} justify={'center'}>
                    <Col className='gutter-row' span={24}>
                        {dt?.car === "car" ?
                            <>
                                <Card
                                    key={index}
                                    style={{ width: '300px', textAlign: 'center' }}>
                                    <>
                                        <Form.Item label="สาเหตุ" style={{ marginBottom: 0 }}>
                                            <b>{item.cause}</b>
                                        </Form.Item>
                                        <Form.Item label="หมายเหตุ" style={{ marginBottom: 0 }}>
                                            {/* <b> {item.note}</b> */}
                                            {
                                                item.note ?
                                                    <b>{item.note}</b>
                                                    :
                                                    <b>-</b>
                                            }

                                        </Form.Item>
                                        <Form.Item label="ผู้สร้างหมายเหตุ" style={{ marginBottom: 0 }}>
                                            <b>{item.noteBy}</b>
                                        </Form.Item>
                                        <Form.Item label="สถานะที่หมายเหตุ" style={{ marginBottom: 0 }}>
                                            <b>{item.status}</b>
                                        </Form.Item>
                                    </>
                                </Card>
                            </>
                            :
                            <>
                                <Card
                                    key={index}
                                    style={{ width: '300px', textAlign: 'center' }}>
                                    <>
                                        <Form.Item label="สาเหตุ" style={{ marginBottom: 0 }}>
                                            <b> {item.cause}</b>
                                        </Form.Item>
                                        <Form.Item label="หมายเหตุ" style={{ marginBottom: 0 }}>
                                            {
                                                item.note ?
                                                    <b>{item.note}</b>
                                                    :
                                                    <b>-</b>
                                            }

                                        </Form.Item>
                                        <Form.Item label="ผู้สร้างหมายเหตุ" style={{ marginBottom: 0 }}>
                                            <b>{item.noteBy}</b>
                                        </Form.Item>
                                        <Form.Item label="สถานะที่หมายเหตุ" style={{ marginBottom: 0 }}>
                                            <b> {item.status}</b>
                                        </Form.Item>
                                    </>
                                </Card>
                            </>
                        }
                    </Col>
                </Row>
            </>
        )
    }
    const renderDataNoteRe = ({ item, index }) => {
        // console.log("noteReeee")
        return (
            <>
                <Row gutter={32} justify={'center'}>
                    <Col className='gutter-row' span={24}>
                        {dt?.car === "car" ?
                            <>
                                <Card
                                    key={index}
                                    style={{ width: '300px', textAlign: 'center' }}>
                                    <>
                                        <Form.Item label="สาเหตุ" style={{ marginBottom: 0 }}>
                                            <b>{item.cause}</b>
                                        </Form.Item>
                                        <Form.Item label="หมายเหตุ" style={{ marginBottom: 0 }}>
                                            {/* <b> {item.note}</b> */}
                                            {
                                                item?.note ?
                                                    <b>{item.note}</b>
                                                    :
                                                    <b>-</b>
                                            }

                                        </Form.Item>
                                        <Form.Item label="ผู้สร้างหมายเหตุ" style={{ marginBottom: 0 }}>
                                            <b>{item.noteBy}</b>
                                        </Form.Item>
                                        <Form.Item label="สถานะที่หมายเหตุ" style={{ marginBottom: 0 }}>
                                            <b>{item.status}</b>
                                        </Form.Item>
                                    </>
                                </Card>
                            </>
                            :
                            <>
                                <Card
                                    key={index}
                                    style={{ width: '300px', textAlign: 'center' }}>
                                    <>
                                        <Form.Item label="สาเหตุ" style={{ marginBottom: 0 }}>
                                            <b> {item.cause}</b>
                                        </Form.Item>
                                        <Form.Item label="หมายเหตุ" style={{ marginBottom: 0 }}>
                                            {
                                                item.note ?
                                                    <b>{item.note}</b>
                                                    :
                                                    <b>-</b>
                                            }

                                        </Form.Item>
                                        <Form.Item label="ผู้สร้างหมายเหตุ" style={{ marginBottom: 0 }}>
                                            <b>{item.noteBy}</b>
                                        </Form.Item>
                                        <Form.Item label="สถานะที่หมายเหตุ" style={{ marginBottom: 0 }}>
                                            <b> {item.status}</b>
                                        </Form.Item>
                                    </>
                                </Card>
                            </>
                        }
                    </Col>
                </Row>
            </>
        )
    }
    const renderLandData = ({ item, index }) => {
        return (
            <>
                <Row gutter={32} justify={'center'}>
                    <Card style={{ width: '1000px' }}>
                        <Row justify={'left'}><b><u>รายละเอียดที่ดิน ({item?.numberLand}) {newDataLand.length > 1 ? <>{item.landMain ? <b style={{ color: "red" }} >(ที่ดินหลัก)</b> : null}</> : null}</u></b></Row>
                        <Row gutter={32} justify={'center'}>
                            <Col className='gutter-row' span={9}>
                                <Form.Item
                                    style={{ marginBottom: 0, textAlign: 'left' }}
                                    label='กลุ่มสินค้า'
                                >
                                    <b>{getData1?.lands?.landLoanDetails?.productType}</b>
                                </Form.Item>
                                <Form.Item
                                    style={{ marginBottom: 0, textAlign: 'left' }}
                                    label='เลขที่ดินหรือระวาง'
                                >
                                    <b>{item?.numberLandlawang}</b>
                                </Form.Item>
                                <Form.Item
                                    style={{ marginBottom: 0, textAlign: 'left' }}
                                    label='พื้นที่ไร่'
                                >
                                    <b>{item?.rai}</b>
                                    <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                        ไร่
                                    </span>
                                </Form.Item>
                                <Form.Item
                                    style={{ marginBottom: 0, textAlign: 'left' }}
                                    label='ราคาประเมินที่ดิน'
                                >
                                    <b>{currencyFormatOne(item?.landPrice)}</b> บาท/ตารางวา
                                </Form.Item>
                            </Col>
                            <Col className='gutter-row' span={7}>
                                <Form.Item
                                    style={{ marginBottom: 0, textAlign: 'left' }}
                                    label='ประเภทเอกสาร'
                                >
                                    <b>{item?.landLoanDetails?.productLoanLandType}</b>
                                </Form.Item>
                                <Form.Item
                                    style={{ marginBottom: 0, textAlign: 'left' }}
                                    label='จังหวัด'
                                >
                                    <b>{item?.provinces}</b>
                                </Form.Item>
                                <Form.Item
                                    style={{ marginBottom: 0, textAlign: 'left' }}
                                    label='พื้นที่งาน'
                                >
                                    <b>{item?.workArea}</b>
                                    <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                        งาน
                                    </span>
                                </Form.Item>
                                <Form.Item
                                    style={{ marginBottom: 0, textAlign: 'left' }}
                                    label='ราคารวม'
                                >
                                    <b>{currencyFormatOne(item?.resultLandPrice)}</b>
                                    <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                        บาท
                                    </span>
                                </Form.Item>
                            </Col>
                            <Col className='gutter-row' span={8}>
                                <Form.Item
                                    style={{ marginBottom: 0, textAlign: 'left' }}
                                    label='เลขโฉนดที่ดิน'
                                >
                                    <b>{item?.numberLand}</b>
                                </Form.Item>
                                <Form.Item
                                    style={{ marginBottom: 0, textAlign: 'left' }}
                                    label='อำเภอ'
                                >
                                    <b>{item?.district}</b>
                                </Form.Item>
                                <Form.Item
                                    style={{ marginBottom: 0, textAlign: 'left' }}
                                    label='พื้นที่ตารางวา'
                                >
                                    <b>{item?.squareWaArea}</b>
                                    <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                        บาท
                                    </span>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Row>
            </>
        )

    }
    const renderApproval = ({ item, index }) => {

        return (
            <>
                <Row justify={'left'}><b><u>ราคาประเมินที่ดิน ({item?.numberLand})</u></b></Row>
                <Row style={{ width: '100%' }}>
                    <Col className='gutter-row' span={8}>
                        <Form.Item
                            style={{ marginBottom: 0, textAlign: 'left' }}
                            label='ราคาต่ำสุด'
                        >
                            <b>{currencyFormatOne(item?.approval?.minPrice)}</b>
                            <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                บาท
                            </span>
                        </Form.Item>
                    </Col>

                    <Col className='gutter-row' span={8}>
                        <Form.Item
                            style={{ marginBottom: 0, textAlign: 'left' }}
                            label='ราคาสูงสุด'
                        >
                            <b>{currencyFormatOne(item?.approval?.maxPrice)}</b>
                            <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                บาท
                            </span>
                        </Form.Item>
                    </Col>

                    <Col className='gutter-row' span={8}>
                        <Form.Item
                            style={{ marginBottom: 0, textAlign: 'left' }}
                            label='หมายเหตุ'
                        >
                            <b>{item?.approval?.note}</b>
                            <span style={{ display: 'inline-block', marginLeft: '30px' }}></span>
                        </Form.Item>
                    </Col>
                    <Divider />
                </Row>
            </>
        )
    }
    const renderMixNote = ({ item, index }) => {
        return (
            <>
                {
                    index === 0 ?
                        null :
                        <>
                            {/* <Card style={{ width: '1000px' }}> */}
                            <Row justify={'left'}><b><u>หมายเหตุจดรวมโฉนด</u></b></Row>
                            <Row gutter={32} justify={'center'}>
                                <Col className='gutter-row' span={24}>
                                    <Form.Item style={{ marginBottom: 0, textAlign: 'center' }} label='' name={`mixNote${index}`}>
                                        <TextArea rows={5} disabled name={`mixNote${index}`} style={{ color: "blue" }}></TextArea>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider />
                            {/* </Card> */}
                        </>
                }
            </>
        )
    }
    const renderTitleDeed = ({ item, index }) => { //render Land Array
        return (
            <>
                <Row justify={'left'}><b><u>รูปโฉนด/หน้า-หลัง ({item.numberLand})</u></b></Row>
                <Row gutter={32} justify={'center'}>
                    <Space>
                        <Image.PreviewGroup
                            key={index}
                            preview={{
                                countRender: (index, total) => {
                                    const imageCounts3 = carimage3?.length || 0; // นับจำนวนภาพจริง
                                    const displayIndex = Math.min(index, imageCounts3);
                                    const displayTotal = Math.min(imageCounts3, imageCounts3);
                                    return `${displayIndex} / ${displayTotal}`;
                                }, // ใช้เพื่อแสดงจำนวนภาพ
                                toolbarRender: (
                                    _,
                                    {
                                        transform: { scale },
                                        actions: {
                                            onActive, // support after 5.21.0
                                            onFlipY,
                                            onFlipX,
                                            onRotateLeft,
                                            onRotateRight,
                                            onZoomOut,
                                            onZoomIn,
                                            onReset,
                                        },
                                    },
                                ) => (
                                    <>
                                        <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                            {carimage3?.map((e, index) => {
                                                return (
                                                    <>
                                                        <Image
                                                            width={auto}
                                                            key={index}
                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                            alt={`รูปภาพ ${index + 1}`}
                                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                        />
                                                    </>
                                                );
                                            })}
                                        </Row>
                                        <Row style={{ zIndex: 2 }}>
                                            <Space size={12} className="toolbar-wrapper">
                                                {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                      <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                <DownloadOutlined onClick={onDownload} />
                                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                                <SwapOutlined onClick={onFlipX} />
                                                <RotateLeftOutlined onClick={onRotateLeft} />
                                                <RotateRightOutlined onClick={onRotateRight} />
                                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                <UndoOutlined onClick={onReset} />
                                            </Space>
                                        </Row>
                                    </>
                                ),
                                onChange: (indexs) => {
                                    setCurrent(indexs);
                                },
                            }}
                        >
                            <Row justify={'center'}>
                                {carimage3?.map((e, index) => {
                                    return (
                                        <>
                                            <Image
                                                width={150}
                                                key={index}
                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                alt={`รูปภาพ ${index + 1}`}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </>
                                    );
                                })}
                            </Row>
                        </Image.PreviewGroup>
                    </Space>
                </Row>
                <Divider />
                {
                    checkSendNEW ?
                        <>
                            <Row justify={'left'}><b><u>รูปโฉนด/หน้า-หลัง ({item.numberLand})</u> (ใหม่)</b></Row>
                            <Row gutter={32} justify={'center'}>
                                <Space>
                                    <Image.PreviewGroup
                                        preview={{
                                            countRender: (index, total) => {
                                                const imageCountnew3 = carimagenew3?.length || 0; // นับจำนวนภาพจริง
                                                const displayIndex = Math.min(index, imageCountnew3);
                                                const displayTotal = Math.min(imageCountnew3, imageCountnew3);
                                                return `${displayIndex} / ${displayTotal}`;
                                            }, // ใช้เพื่อแสดงจำนวนภาพ
                                            toolbarRender: (
                                                _,
                                                {
                                                    transform: { scale },
                                                    actions: {
                                                        onActive,
                                                        onFlipY,
                                                        onFlipX,
                                                        onRotateLeft,
                                                        onRotateRight,
                                                        onZoomOut,
                                                        onZoomIn,
                                                        onReset,
                                                    },
                                                },
                                            ) => (
                                                <>
                                                    <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                        {carimagenew3?.map((e, index) => {
                                                            return (
                                                                <>
                                                                    <Image
                                                                        width={auto}
                                                                        key={index}
                                                                        onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                        src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                        alt={`รูปภาพ ${index + 1}`}
                                                                        style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                    />
                                                                </>
                                                            );
                                                        })}
                                                    </Row>
                                                    <Row style={{ zIndex: 2 }}>
                                                        <Space size={12} className="toolbar-wrapper">
                                                            {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                            <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                            <DownloadOutlined onClick={onDownload} />
                                                            <SwapOutlined rotate={90} onClick={onFlipY} />
                                                            <SwapOutlined onClick={onFlipX} />
                                                            <RotateLeftOutlined onClick={onRotateLeft} />
                                                            <RotateRightOutlined onClick={onRotateRight} />
                                                            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                            <UndoOutlined onClick={onReset} />
                                                        </Space>
                                                    </Row>
                                                </>
                                            ),
                                            onChange: (index) => {
                                                setCurrent(index);
                                            },
                                        }}
                                    >
                                        <Row justify={'center'}>
                                            {carimagenew3?.map((e, index) => {
                                                return (
                                                    <>
                                                        <Image
                                                            width={150}
                                                            key={index}
                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                            src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                            alt={`รูปภาพ ${index + 1}`}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                    </>
                                                );
                                            })}
                                        </Row>
                                    </Image.PreviewGroup>

                                </Space>
                            </Row>
                            <Divider />
                        </>
                        : null
                }
            </>
        )
    }
    const renderDivision = ({ item, index }) => { //render Land Array
        return (
            <>
                <Row justify={'left'}><b><u>ใบแบ่งใบต่อ ({item.numberLand})</u></b></Row>
                <Row gutter={32} justify={'center'}>
                    <Space>
                        <Image.PreviewGroup
                            preview={{
                                countRender: (index, total) => {
                                    const imageCounts10 = carimage10?.length || 0; // นับจำนวนภาพจริง
                                    const displayIndex = Math.min(index, imageCounts10);
                                    const displayTotal = Math.min(imageCounts10, imageCounts10);
                                    return `${displayIndex} / ${displayTotal}`;
                                }, // ใช้เพื่อแสดงจำนวนภาพ
                                toolbarRender: (
                                    _,
                                    {
                                        transform: { scale },
                                        actions: {
                                            onActive,
                                            onFlipY,
                                            onFlipX,
                                            onRotateLeft,
                                            onRotateRight,
                                            onZoomOut,
                                            onZoomIn,
                                            onReset,
                                        },
                                    },
                                ) => (
                                    <>
                                        <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                            {carimage10?.map((e, index) => {
                                                return (
                                                    <>
                                                        <Image
                                                            width={auto}
                                                            key={index}
                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                            alt={`รูปภาพ ${index + 1}`}
                                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                        />
                                                    </>
                                                );
                                            })}
                                        </Row>
                                        <Row style={{ zIndex: 2 }}>
                                            <Space size={12} className="toolbar-wrapper">
                                                {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                      <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                <DownloadOutlined onClick={onDownload} />
                                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                                <SwapOutlined onClick={onFlipX} />
                                                <RotateLeftOutlined onClick={onRotateLeft} />
                                                <RotateRightOutlined onClick={onRotateRight} />
                                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                <UndoOutlined onClick={onReset} />
                                            </Space>
                                        </Row>
                                    </>
                                ),
                                onChange: (index) => {
                                    setCurrent(index);
                                },
                            }}
                        >
                            <Row justify={'center'}>
                                {carimage10?.map((e, index) => {
                                    return (
                                        <>
                                            <Image
                                                width={150}
                                                key={index}
                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                alt={`รูปภาพ ${index + 1}`}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </>
                                    );
                                })}
                            </Row>
                        </Image.PreviewGroup>
                    </Space>
                </Row>
                <Divider />
                {
                    checkSendNEW ?
                        <>
                            <Row justify={'left'}><b><u>ใบแบ่งใบต่อ ({item.numberLand})</u> (ใหม่)</b></Row>
                            <Row gutter={32} justify={'center'}>
                                <Space>
                                    <Image.PreviewGroup
                                        preview={{
                                            countRender: (index, total) => {
                                                const imageCounts10 = carimagenew10?.length || 0; // นับจำนวนภาพจริง
                                                const displayIndex = Math.min(index, imageCounts10);
                                                const displayTotal = Math.min(imageCounts10, imageCounts10);
                                                return `${displayIndex} / ${displayTotal}`;
                                            }, // ใช้เพื่อแสดงจำนวนภาพ
                                            toolbarRender: (
                                                _,
                                                {
                                                    transform: { scale },
                                                    actions: {
                                                        onActive,
                                                        onFlipY,
                                                        onFlipX,
                                                        onRotateLeft,
                                                        onRotateRight,
                                                        onZoomOut,
                                                        onZoomIn,
                                                        onReset,
                                                    },
                                                },
                                            ) => (
                                                <>
                                                    <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                        {carimagenew10?.map((e, index) => {
                                                            return (
                                                                <>
                                                                    <Image
                                                                        width={auto}
                                                                        key={index}
                                                                        onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                        src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                        alt={`รูปภาพ ${index + 1}`}
                                                                        style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                    />
                                                                </>
                                                            );
                                                        })}
                                                    </Row>
                                                    <Row style={{ zIndex: 2 }}>
                                                        <Space size={12} className="toolbar-wrapper">
                                                            <LeftOutlined onClick={() => onActive?.(-1)} />
                                                            <RightOutlined onClick={() => onActive?.(1)} />
                                                            <DownloadOutlined onClick={onDownload} />
                                                            <SwapOutlined rotate={90} onClick={onFlipY} />
                                                            <SwapOutlined onClick={onFlipX} />
                                                            <RotateLeftOutlined onClick={onRotateLeft} />
                                                            <RotateRightOutlined onClick={onRotateRight} />
                                                            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                            <UndoOutlined onClick={onReset} />
                                                        </Space>
                                                    </Row>
                                                </>
                                            ),
                                            onChange: (index) => {
                                                setCurrent(index);
                                            },
                                        }}
                                    >
                                        <Row justify={'center'}>
                                            {carimagenew10?.map((e, index) => {
                                                return (
                                                    <>
                                                        <Image
                                                            width={150}
                                                            key={index}
                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                            src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                            alt={`รูปภาพ ${index + 1}`}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                    </>
                                                );
                                            })}
                                        </Row>
                                    </Image.PreviewGroup>
                                </Space>
                            </Row>
                            <Divider />
                        </>
                        : null
                }
            </>
        )
    }
    const renderLawang = ({ item, index }) => { //render Land Array
        return (
            <>
                <Row justify={'left'}><b><u>ระวาง ({item.numberLand})</u></b></Row>
                <Row gutter={32} justify={'center'}>
                    <Space>
                        <Image.PreviewGroup
                            preview={{
                                countRender: (index, total) => {
                                    const imageCount11new = carimage11?.length || 0; // นับจำนวนภาพจริง
                                    const displayIndex = Math.min(index, imageCount11new);
                                    const displayTotal = Math.min(imageCount11new, imageCount11new);
                                    return `${displayIndex} / ${displayTotal}`;
                                }, // ใช้เพื่อแสดงจำนวนภาพ
                                toolbarRender: (
                                    _,
                                    {
                                        transform: { scale },
                                        actions: {
                                            onActive,
                                            onFlipY,
                                            onFlipX,
                                            onRotateLeft,
                                            onRotateRight,
                                            onZoomOut,
                                            onZoomIn,
                                            onReset,
                                        },
                                    },
                                ) => (
                                    <>
                                        <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                            {carimage11?.map((e, index) => {
                                                return (
                                                    <>
                                                        <Image
                                                            width={auto}
                                                            key={index}
                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                            alt={`รูปภาพ ${index + 1}`}
                                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                        />
                                                    </>
                                                );
                                            })}
                                        </Row>
                                        <Row style={{ zIndex: 2 }}>
                                            <Space size={12} className="toolbar-wrapper">
                                                {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                      <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                <DownloadOutlined onClick={onDownload} />
                                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                                <SwapOutlined onClick={onFlipX} />
                                                <RotateLeftOutlined onClick={onRotateLeft} />
                                                <RotateRightOutlined onClick={onRotateRight} />
                                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                <UndoOutlined onClick={onReset} />
                                            </Space>
                                        </Row>
                                    </>
                                ),
                                onChange: (index) => {
                                    setCurrent(index);
                                },
                            }}
                        >
                            <Row justify={'center'}>
                                {carimage11?.map((e, index) => {
                                    return (
                                        <>
                                            <Image
                                                width={150}
                                                key={index}
                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                alt={`รูปภาพ ${index + 1}`}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </>
                                    );
                                })}
                            </Row>
                        </Image.PreviewGroup>
                    </Space>
                </Row>
                <Divider />
                {
                    checkSendNEW ?
                        <>
                            <Row justify={'left'}><b><u>ระวาง ({item.numberLand})</u> (ใหม่)</b></Row>
                            <Row gutter={32} justify={'center'}>
                                <Space>
                                    <Image.PreviewGroup
                                        preview={{
                                            countRender: (index, total) => {
                                                const imageCount11new = carimagenew11?.length || 0; // นับจำนวนภาพจริง
                                                const displayIndex = Math.min(index, imageCount11new);
                                                const displayTotal = Math.min(imageCount11new, imageCount11new);
                                                return `${displayIndex} / ${displayTotal}`;
                                            }, // ใช้เพื่อแสดงจำนวนภาพ
                                            toolbarRender: (
                                                _,
                                                {
                                                    transform: { scale },
                                                    actions: {
                                                        onActive,
                                                        onFlipY,
                                                        onFlipX,
                                                        onRotateLeft,
                                                        onRotateRight,
                                                        onZoomOut,
                                                        onZoomIn,
                                                        onReset,
                                                    },
                                                },
                                            ) => (
                                                <>
                                                    <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                        {carimagenew11?.map((e, index) => {
                                                            return (
                                                                <>
                                                                    <Image
                                                                        width={auto}
                                                                        key={index}
                                                                        onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                        src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                        alt={`รูปภาพ ${index + 1}`}
                                                                        style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                    />
                                                                </>
                                                            );
                                                        })}
                                                    </Row>
                                                    <Row style={{ zIndex: 2 }}>
                                                        <Space size={12} className="toolbar-wrapper">
                                                            <LeftOutlined onClick={() => onActive?.(-1)} />
                                                            <RightOutlined onClick={() => onActive?.(1)} />
                                                            <DownloadOutlined onClick={onDownload} />
                                                            <SwapOutlined rotate={90} onClick={onFlipY} />
                                                            <SwapOutlined onClick={onFlipX} />
                                                            <RotateLeftOutlined onClick={onRotateLeft} />
                                                            <RotateRightOutlined onClick={onRotateRight} />
                                                            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                            <UndoOutlined onClick={onReset} />
                                                        </Space>
                                                    </Row>
                                                </>
                                            ),
                                            onChange: (index) => {
                                                setCurrent(index);
                                            },
                                        }}
                                    >
                                        <Row justify={'center'}>
                                            {carimagenew11?.map((e, index) => {
                                                return (
                                                    <>
                                                        <Image
                                                            width={150}
                                                            key={index}
                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                            src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                            alt={`รูปภาพ ${index + 1}`}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                    </>
                                                );
                                            })}
                                        </Row>
                                    </Image.PreviewGroup>
                                </Space>
                            </Row>
                            <Divider />
                        </>
                        : null
                }
            </>
        )
    }
    const renderLandBuildings = ({ item, index }) => { //render Land Array
        return (
            <>
                <Row justify={'left'}><b><u>ใบประเมิน / รูปที่ดิน / สิ่งปลูกสร้าง-ที่ดินปัจจุบัน ({item.numberLand})</u></b></Row>
                <Row gutter={32} justify={'center'}>
                    <Space>
                        <Image.PreviewGroup
                            preview={{
                                countRender: (index, total) => {
                                    const imageCount1new = carimage1?.length || 0; // นับจำนวนภาพจริง
                                    const displayIndex = Math.min(index, imageCount1new);
                                    const displayTotal = Math.min(imageCount1new, imageCount1new);
                                    return `${displayIndex} / ${displayTotal}`;
                                }, // ใช้เพื่อแสดงจำนวนภาพ
                                toolbarRender: (
                                    _,
                                    {
                                        transform: { scale },
                                        actions: {
                                            onActive,
                                            onFlipY,
                                            onFlipX,
                                            onRotateLeft,
                                            onRotateRight,
                                            onZoomOut,
                                            onZoomIn,
                                            onReset,
                                        },
                                    },
                                ) => (
                                    <>
                                        <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                            {carimage1?.map((e, index) => {
                                                return (
                                                    <>
                                                        <Image
                                                            width={auto}
                                                            key={index}
                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                            alt={`รูปภาพ ${index + 1}`}
                                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                        />
                                                    </>
                                                );
                                            })}
                                        </Row>
                                        <Row style={{ zIndex: 2 }}>
                                            <Space size={12} className="toolbar-wrapper">
                                                {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                      <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                <DownloadOutlined onClick={onDownload} />
                                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                                <SwapOutlined onClick={onFlipX} />
                                                <RotateLeftOutlined onClick={onRotateLeft} />
                                                <RotateRightOutlined onClick={onRotateRight} />
                                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                <UndoOutlined onClick={onReset} />
                                            </Space>
                                        </Row>
                                    </>
                                ),
                                onChange: (index) => {
                                    setCurrent(index);
                                },
                            }}
                        >
                            <Row justify={'center'}>
                                {carimage1?.map((e, index) => {
                                    return (
                                        <>
                                            <Image
                                                width={150}
                                                key={index}
                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                alt={`รูปภาพ ${index + 1}`}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </>
                                    );
                                })}
                            </Row>
                        </Image.PreviewGroup>
                    </Space>
                </Row>
                <Divider />
                {
                    checkSendNEW ?
                        <>
                            <Row justify={'left'}><b><u>ใบประเมิน / รูปที่ดิน / สิ่งปลูกสร้าง-ที่ดินปัจจุบัน ({item.numberLand})</u> (ใหม่)</b></Row>
                            <Row gutter={32} justify={'center'}>
                                <Space>
                                    <Image.PreviewGroup
                                        preview={{
                                            countRender: (index, total) => {
                                                const imageCount1new = carimagenew1?.length || 0; // นับจำนวนภาพจริง
                                                const displayIndex = Math.min(index, imageCount1new);
                                                const displayTotal = Math.min(imageCount1new, imageCount1new);
                                                return `${displayIndex} / ${displayTotal}`;
                                            }, // ใช้เพื่อแสดงจำนวนภาพ
                                            toolbarRender: (
                                                _,
                                                {
                                                    transform: { scale },
                                                    actions: {
                                                        onActive,
                                                        onFlipY,
                                                        onFlipX,
                                                        onRotateLeft,
                                                        onRotateRight,
                                                        onZoomOut,
                                                        onZoomIn,
                                                        onReset,
                                                    },
                                                },
                                            ) => (
                                                <>
                                                    <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                        {carimagenew1?.map((e, index) => {
                                                            return (
                                                                <>
                                                                    <Image
                                                                        width={auto}
                                                                        key={index}
                                                                        onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                        src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                        alt={`รูปภาพ ${index + 1}`}
                                                                        style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                    />
                                                                </>
                                                            );
                                                        })}
                                                    </Row>

                                                    <Row style={{ zIndex: 2 }}>
                                                        <Space size={12} className="toolbar-wrapper">
                                                            <LeftOutlined onClick={() => onActive?.(-1)} />
                                                            <RightOutlined onClick={() => onActive?.(1)} />
                                                            <DownloadOutlined onClick={onDownload} />
                                                            <SwapOutlined rotate={90} onClick={onFlipY} />
                                                            <SwapOutlined onClick={onFlipX} />
                                                            <RotateLeftOutlined onClick={onRotateLeft} />
                                                            <RotateRightOutlined onClick={onRotateRight} />
                                                            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                            <UndoOutlined onClick={onReset} />
                                                        </Space>
                                                    </Row>
                                                </>
                                            ),
                                            onChange: (index) => {
                                                setCurrent(index);
                                            },
                                        }}
                                    >
                                        <Row justify={'center'}>
                                            {carimagenew1?.map((e, index) => {
                                                return (
                                                    <>
                                                        <Image
                                                            width={150}
                                                            key={index}
                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                            src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                            alt={`รูปภาพ ${index + 1}`}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                    </>
                                                );
                                            })}
                                        </Row>
                                    </Image.PreviewGroup>
                                </Space>
                            </Row>
                            <Divider />
                        </>
                        : null
                }
            </>
        )
    }

    return (
        <>
            <Spin tip="Loading" spinning={loading}>
                <Row justify={'center'}>
                    <Col className='gutter-row' span={24} style={{ fontSize: '25px', textAlign: 'center' }}>
                        <b>รายละเอียดและคนค้ำประกัน</b>
                    </Col>
                </Row>
                <Row justify={'center'}>
                    <Divider style={{ margin: 5 }} />
                    <Form
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            width: '100%',
                        }}
                        form={form}
                        // onFinish={handleSubmit}
                        // autoComplete="off"
                        initialValues={{ remember: true }}
                    >
                        <style>
                            {`
                .toolbar-wrapper {
                    padding: 0px 24px;
                    color: #fff;
                    font-size: 20px;
                    background-color: rgba(0, 0, 0, 0.1);
                    border-radius: 100px;
                }

                .toolbar-wrapper .anticon {
                    padding: 12px;
                    cursor: pointer;
                }

                .toolbar-wrapper .anticon[disabled] {
                    cursor: not-allowed;
                    opacity: 0.3;
                }

                .toolbar-wrapper .anticon:hover {
                    opacity: 0.3;
                }
                .ant-image-preview-count {
                    display: none !important;
                }
                `}
                        </style>
                        <Row gutter={32} justify={'center'}>
                            <Card style={{ width: '1000px' }}>
                                <Row gutter={32}>
                                    <Col span={12} className='gutter-row' style={{ textAlign: 'left' }}>
                                        {
                                            dt?.car === "car" ?
                                                <>
                                                    {getData1?.cars?.ISSUNO !== '' ?
                                                        <>
                                                            <div style={{ margin: 0 }}>
                                                                เลขสัญญา : <b><Tag color="cyan">{getData1?.cars?.ISSUNO}</Tag></b>
                                                            </div>
                                                            <div style={{ margin: 0 }}>
                                                                เกรด : <b><Tag color="cyan">{getData1?.cars?.carLoanDetailsRe?.GRDCOD}</Tag></b>
                                                            </div>
                                                        </>
                                                        :
                                                        null
                                                    }
                                                </>
                                                :
                                                <>
                                                    {getData1?.lands?.ISSUNO !== '' ?
                                                        <>
                                                            <div style={{ margin: 0 }}>
                                                                เลขเลขสัญญาญญา : <b><Tag color="cyan">{getData1?.lands?.ISSUNO}</Tag></b>
                                                            </div>
                                                            <div style={{ margin: 0 }}>
                                                                เกรด : <b><Tag color="cyan">{getData1?.lands?.landLoanDetailsRe?.GRDCOD}</Tag></b>
                                                            </div>
                                                        </>

                                                        : null
                                                    }
                                                </>
                                        }
                                    </Col>
                                    <Col span={12} className='gutter-row' style={{ textAlign: 'right' }}>
                                        <Form.Item style={{ marginBottom: 0 }}>
                                            {
                                                dt?.car === "car" ?
                                                    <>
                                                        สถานะ : <b><Tag color="cyan">{getData1?.cars?.carLoanDetails?.approvalStatusTable?.name}</Tag></b>
                                                    </>
                                                    :
                                                    <>
                                                        สถานะ : <b><Tag color="cyan">{getData1?.lands?.landLoanDetails?.approvalStatusTable?.name}</Tag></b>
                                                    </>
                                            }
                                        </Form.Item>
                                        <Form.Item style={{ marginBottom: 0 }}>
                                            {
                                                dt?.car === "car" ?
                                                    <>
                                                        ผ่านการวิเคราะห์โดย : <b><Tag color="cyan">{getData1?.cars?.carLoanDetails?.reviewedBy}</Tag></b>
                                                    </>
                                                    :
                                                    <>
                                                        ผ่านการวิเคราะห์โดย : <b><Tag color="cyan">{getData1?.lands?.landLoanDetails?.reviewedBy}</Tag></b>
                                                    </>
                                            }
                                        </Form.Item>
                                    </Col>
                                </Row>
                                {
                                    getData1?.nationalId === 'นิติบุคคล' ?
                                        <>
                                            <Row justify={'left'}><b><u>รายละเอียดผู้ขอกู้</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                {/* <aside style={{ width: '90%' }}> */}
                                                <Col className='gutter-row' span={8}>
                                                    <div label='ประเภท' name='identificationId' style={{ marginBottom: 3 }}>
                                                        <span>ประเภท : </span> <b>{getData1?.nationalId}</b>
                                                    </div>
                                                    <div label='วันจดทะเบียน' name='nickname' style={{ marginBottom: 3 }}>
                                                        <span>วันจดทะเบียน : </span> <b> {getData1?.birthdate}</b>
                                                    </div>

                                                </Col>
                                                <Col className='gutter-row' span={8}>
                                                    <div label='เลขประจำตัวผู้เสียภาษี' name='identificationId' style={{ marginBottom: 3 }}>
                                                        <span>เลขประจำตัวผู้เสียภาษี : </span> <b>{getData1.identificationId}</b>
                                                    </div>
                                                    <div style={{ marginBottom: 0, }}>
                                                        <b>

                                                            {
                                                                dataPhoneCus?.map((item, index) => {
                                                                    return renderItemPhones({ item, index, key: `{item.identification} - ${index}` })
                                                                })
                                                            }
                                                        </b>
                                                    </div>
                                                </Col>
                                                <Col className='gutter-row' span={8}>
                                                    <div label='ชื่อบริษัท' name='snam' style={{ marginBottom: 3 }}>
                                                        <span>
                                                            {getData1?.snam} : </span> <b>{getData1?.firstname + ' ' + getData1?.lastname}</b>
                                                    </div>
                                                </Col>
                                                {/* </aside> */}
                                            </Row>
                                        </>
                                        :
                                        <>
                                            <Row justify={'left'}><b><u>รายละเอียดผู้ขอกู้</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <Col className='gutter-row' span={8}>
                                                    <Form.Item
                                                        style={{ marginBottom: 0, textAlign: 'left' }}
                                                        label='ชื่อ-นามสกุล'
                                                    >
                                                        <b>{getData1?.snam + '   ' + getData1?.firstname + '   ' + getData1?.lastname}</b>
                                                    </Form.Item>

                                                    <Form.Item
                                                        style={{ marginBottom: 0, textAlign: 'left' }}
                                                        label='ชื่อเล่น'
                                                    >
                                                        <b>{getData1?.nickname}</b>
                                                    </Form.Item>
                                                </Col>
                                                <Col className='gutter-row' span={7}>
                                                    <Form.Item
                                                        style={{ marginBottom: 0, textAlign: 'left' }}
                                                        label='เลขบัตรประชาชน'
                                                    >
                                                        <b>{getData1?.identificationId}</b>
                                                    </Form.Item>
                                                    <Row gutter={32}>
                                                        <Col className='gutter-row' span={24}>
                                                            <Form.Item
                                                                style={{ marginBottom: 0, textAlign: 'left' }}
                                                            >
                                                                <b>
                                                                    {
                                                                        dataPhoneCus?.map((item, index) => {
                                                                            return renderItemPhones({ item, index, key: `{item.identification} - ${index}` })
                                                                        })
                                                                    }
                                                                </b>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col className='gutter-row' span={9}>
                                                    <Form.Item
                                                        style={{ margin: 0, textAlign: 'left' }}
                                                        label='เพศ'
                                                    >
                                                        <b>{getData1?.gender === "M" ? "ชาย" : "หญิง"}</b>
                                                    </Form.Item>

                                                    {/* <Form.Item
                                                        style={{ marginBottom: 0, textAlign: 'left' }}
                                                        label={dataSocials?.socialType}
                                                    >
                                                        <b>{dataSocials?.socialData}</b>
                                                    </Form.Item> */}
                                                </Col>
                                            </Row>
                                        </>
                                }
                            </Card>
                            {address?.length >= 1 ?
                                <>
                                    <Card style={{ width: '1000px' }}>
                                        <Row justify={'left'}><b><u>รายละเอียดที่อยู่</u></b></Row>
                                        <Row justify={'center'} gutter={32}>
                                            {
                                                address?.map((item, index) => {
                                                    return renderItemAddress({ item, index })
                                                })
                                            }
                                        </Row>

                                    </Card>
                                </>
                                : null
                            }

                            {
                                dt?.car === "car" ?
                                    <>
                                        {getData1?.cars?.carLoanDetails?.productLoanType === 'ย้ายไฟแนนซ์' || getData1?.cars?.carLoanDetails?.productLoanType === 'Pre-Aaprove-ย้ายไฟแนนซ์' ?
                                            <>
                                                <Row justify={'center'} gutter={32}>
                                                    <Card style={{ width: '1000px' }}>
                                                        <Row justify={'left'}><b><u>รายละเอียดย้ายไฟแนนซ์เดิม</u></b></Row>
                                                        <Row gutter={32} justify={'center'}>
                                                            <Col className='gutter-row' span={8}>
                                                                <Form.Item
                                                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                                                    label='ปิดจากไฟแนนซ์'
                                                                >
                                                                    <b>{getData1?.cars?.carOldLoanDetails?.issuno}</b>

                                                                </Form.Item>
                                                                <Form.Item
                                                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                                                    label='ยอดปิดไฟแนนซ์'
                                                                >
                                                                    <b>{currencyFormatOne(getData1?.cars?.carOldLoanDetails?.oldLoanAmount)}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col className='gutter-row' span={8}>
                                                                <Form.Item
                                                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                                                    label='ค่างวด / เดือน'
                                                                >
                                                                    <b>{currencyFormatOne(getData1?.cars?.carOldLoanDetails?.oldMonthlyPayment)}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col className='gutter-row' span={8}>
                                                                <Form.Item
                                                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                                                    label='จำนวนงวดที่ผ่อน'
                                                                >
                                                                    <b>{getData1?.cars?.carOldLoanDetails?.oldLoanTerm}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        งวด
                                                                    </span>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                    <Card style={{ width: '1000px' }}>
                                                        <Row justify={'left'}><b><u>รายละเอียดขอกู้</u></b></Row>
                                                        <Row justify={'center'} gutter={32}>
                                                            <Col className='gutter-row' span={7}>
                                                                <Form.Item
                                                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                                                    label='ยอดกู้'
                                                                >
                                                                    <b>{currencyFormatOne(getData1?.cars?.carLoanDetails?.loanAmount)}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                                                    label='จำนวนงวด'
                                                                >
                                                                    <b>{getData1?.cars?.carLoanDetails?.loanTerm}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        งวด
                                                                    </span>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col className='gutter-row' span={7}>
                                                                <Form.Item
                                                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                                                    label='ดอกเบี้ย'
                                                                >
                                                                    <b>{currencyFormatOne(getData1?.cars?.carLoanDetails?.interest)}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                                                    label='จำนวนผ่อนต่อเดือน'
                                                                >
                                                                    <b>{currencyFormatOne(getData1?.cars?.carLoanDetails?.monthlyPayment)}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col className='gutter-row' span={10}>
                                                                <Form.Item
                                                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                                                    label='ผ่อนรวมดอกเบี้ย'
                                                                >
                                                                    <b>{currencyFormatOne(~~getData1?.cars?.carLoanDetails?.monthlyPayment * ~~getData1?.cars?.carLoanDetails?.loanTerm)}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                                                    label='ผู้เสนอเคส'
                                                                >
                                                                    <b style={{ color: 'red' }}>{getData1?.cars?.carLoanDetails?.proposalBy}</b>

                                                                </Form.Item>
                                                                <b> <Form.Item
                                                                    style={{ margin: 0, textAlign: 'center' }}
                                                                    label='เรทรถ'
                                                                >
                                                                    <b style={{ fontSize: '20px' }}><u>{currencyFormatOne(getData1?.cars?.carPrice)}</u></b>

                                                                    <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                                        บาท
                                                                    </span>
                                                                </Form.Item>
                                                                </b>
                                                            </Col>

                                                        </Row>
                                                    </Card>
                                                </Row>
                                            </>
                                            :
                                            <Row gutter={32} justify={'center'}>
                                                <Card style={{ width: '1000px' }}>
                                                    <Row justify={'left'}><b><u>รายละเอียด{' ' + getData1?.cars?.carLoanDetails?.productLoanType} ({getData1?.cars?.carLoanDetails?.productType})</u></b></Row>
                                                    <Row justify={'center'} gutter={32}>
                                                        <Col className='gutter-row' span={7}>
                                                            <Form.Item
                                                                style={{ marginBottom: 0, textAlign: 'left' }}
                                                                label='ยอดกู้'
                                                            >
                                                                <b>{currencyFormatOne(getData1?.cars?.carLoanDetails?.loanAmount)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </Form.Item>
                                                            <Form.Item
                                                                style={{ marginBottom: 0, textAlign: 'left' }}
                                                                label='จำนวนงวด'
                                                            >
                                                                <b>{getData1?.cars?.carLoanDetails?.loanTerm}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    งวด
                                                                </span>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col className='gutter-row' span={7}>
                                                            <Form.Item
                                                                style={{ marginBottom: 0, textAlign: 'left' }}
                                                                label='ดอกเบี้ย'
                                                            >
                                                                <b>{currencyFormatOne(getData1?.cars?.carLoanDetails?.interest)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </Form.Item>
                                                            <Form.Item
                                                                style={{ marginBottom: 0, textAlign: 'left' }}
                                                                label='จำนวนผ่อนต่อเดือน'
                                                            >
                                                                <b>{currencyFormatOne(getData1?.cars?.carLoanDetails?.monthlyPayment)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col className='gutter-row' span={10}>
                                                            <Form.Item
                                                                style={{ marginBottom: 0, textAlign: 'left' }}
                                                                label='ผ่อนรวมดอกเบี้ย'
                                                            >
                                                                <b>{currencyFormatOne(~~getData1?.cars?.carLoanDetails?.monthlyPayment * ~~getData1?.cars?.carLoanDetails?.loanTerm)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </Form.Item>
                                                            <Form.Item
                                                                style={{ marginBottom: 0, textAlign: 'left' }}
                                                                label='เรทรถ'
                                                            >
                                                                <b>{currencyFormatOne(getData1?.cars?.carPrice)}</b>

                                                            </Form.Item>
                                                            <b> <Form.Item
                                                                style={{ margin: 0, textAlign: 'left' }}
                                                                label='ผู้เสนอเคส'
                                                            >
                                                                <b style={{ fontSize: '20px', color: 'red' }}>{getData1?.cars?.carLoanDetails?.proposalBy}</b>
                                                            </Form.Item>
                                                            </b>
                                                        </Col>
                                                    </Row>

                                                </Card>
                                            </Row>
                                        }
                                    </>
                                    :
                                    <>
                                        {getData1?.lands?.landLoanDetails?.productLoanLandType === 'ย้ายไฟแนนซ์' || getData1?.lands?.landLoanDetails?.productLoanType === 'Pre-Aaprove-ย้ายไฟแนนซ์' ?
                                            <>
                                                <Row justify={'center'} gutter={32}>
                                                    <Card style={{ width: '1000px' }}>
                                                        <Row justify={'left'}><b><u>รายละเอียดย้ายไฟแนนซ์</u></b></Row>
                                                        <Row gutter={32} justify={'center'}>
                                                            <Col className='gutter-row' span={8}>
                                                                <Form.Item
                                                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                                                    label='ปิดจากไฟแนนซ์'
                                                                >
                                                                    <b>{getData1?.lands?.landOldLoanDetails?.issuno}</b>

                                                                </Form.Item>
                                                                <Form.Item
                                                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                                                    label='ยอดปิดไฟแนนซ์'
                                                                >
                                                                    <b>{currencyFormatOne(getData1?.lands?.landOldLoanDetails?.oldLandLoanTerm)}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col className='gutter-row' span={8}>

                                                                <Form.Item
                                                                    style={{ marginBottom: 0, textAlign: 'left' }}
                                                                    label='ค่างวด / เดือน'
                                                                >
                                                                    <b>{currencyFormatOne(getData1?.lands?.landOldLoanDetails?.oldLandMonthlyPayment)}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        บาท
                                                                    </span>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col className='gutter-row' span={8}>
                                                                <Form.Item
                                                                    style={{ marginBottom: 0, textAlign: 'left' }}
                                                                    label='จำนวนงวดที่ผ่อน'
                                                                >
                                                                    <b>{getData1?.lands?.landOldLoanDetails?.oldLandLoanTerm}</b>
                                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                        งวด
                                                                    </span>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </Row>
                                                <Card style={{ width: '1000px' }}>
                                                    <Row justify={'left'}><b><u>รายละเอียดย้ขอกู้</u></b></Row>
                                                    <Row gutter={32} justify={'center'}>
                                                        <Col className='gutter-row' span={8}>
                                                            <Form.Item
                                                                label='ยอดจัด'
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <b>{currencyFormatOne(getData1?.lands?.landLoanDetails?.loanAmountLand)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </Form.Item>
                                                            <Form.Item
                                                                label='ดอกเบี้ย'
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <b>{currencyFormatOne(getData1?.lands?.landLoanDetails?.interestLand)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col className='gutter-row' span={8}>
                                                            <Form.Item
                                                                label='จำนวนงวด'
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <b>{getData1?.lands?.landLoanDetails?.loanLandTerm}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    งวด
                                                                </span>
                                                            </Form.Item>
                                                            <Form.Item
                                                                label='ราคารวม'
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <b>{currencyFormatOne(~~getData1?.lands?.landLoanDetails?.loanLandTerm * ~~getData1?.lands?.landLoanDetails?.monthlyPaymentLand)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col className='gutter-row' span={8}>
                                                            <Form.Item
                                                                label='งวดละ'
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <b>{currencyFormatOne(getData1?.lands?.landLoanDetails?.monthlyPaymentLand)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </Form.Item>
                                                            <Form.Item
                                                                label='ผู้เสนอเคสเช่าซื้อ'
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <b>{getData1?.lands?.landLoanDetails?.proposalBy}</b>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </>
                                            :
                                            <Row gutter={32} justify={'center'}>
                                                <Card style={{ width: '1000px' }}>
                                                    <Row justify={'left'}><b><u>รายละเอียด{getData1?.lands?.landLoanDetails?.productLoanLandType}</u></b></Row>
                                                    <Row gutter={32} justify={'center'}>
                                                        <Col className='gutter-row' span={8}>
                                                            <Form.Item
                                                                label='ยอดจัด'
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <b>{currencyFormatOne(getData1?.lands?.landLoanDetails?.loanAmountLand)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </Form.Item>
                                                            <Form.Item
                                                                label='ดอกเบี้ย'
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <b>{currencyFormatOne(getData1?.lands?.landLoanDetails?.interestLand)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col className='gutter-row' span={8}>
                                                            <Form.Item
                                                                label='จำนวนงวด'
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <b>{getData1?.lands?.landLoanDetails?.loanLandTerm}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    งวด
                                                                </span>
                                                            </Form.Item>
                                                            <Form.Item
                                                                label='ราคารวม'
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <b>{currencyFormatOne(~~getData1?.lands?.landLoanDetails?.loanLandTerm * ~~getData1?.lands?.landLoanDetails?.monthlyPaymentLand)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col className='gutter-row' span={8}>
                                                            <Form.Item
                                                                label='งวดละ'
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <b>{currencyFormatOne(getData1?.lands?.landLoanDetails?.monthlyPaymentLand)}</b>
                                                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                    บาท
                                                                </span>
                                                            </Form.Item>
                                                            <Form.Item
                                                                label='ผู้เสนอเคสเช่าซื้อ'
                                                                style={{ marginBottom: 0, }}
                                                            >
                                                                <b>{getData1?.lands?.landLoanDetails?.proposalBy}</b>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Card>

                                            </Row>
                                        }
                                    </>
                            }
                            {
                                dt?.car === "car" ?
                                    <>
                                        <Row gutter={32} justify={'center'}>
                                            <Card style={{ width: '1000px' }}>
                                                {/* <Row justify={'left'}><b><u>ยอดที่อนุมัติ</u></b></Row> */}
                                                <Row justify={'left'}><b><u>อนุมัติ ({ap?.apProductType !== "" ? ap?.apProductType : getData1?.cars?.carLoanDetails?.productType}) {
                                                    ap?.apProductType === "เช่าซื้อ1" || getData1?.cars?.carLoanDetails?.productType === "เช่าซื้อ1" ?
                                                        <>
                                                            {ap?.apInterestRate !== "" ? <>({ap?.apInterestRate})</> : <>({getData1?.cars?.carLoanDetails?.interestRate})</>}
                                                        </>
                                                        : null
                                                }</u></b></Row>
                                                <Row justify={'center'} style={{ backgroundColor: "lightgreen" }} gutter={32}>
                                                    <Col className='gutter-row' span={7}>
                                                        <Form.Item style={{ marginBottom: 0, textAlign: 'left' }} label='อนุมัติยอด'>
                                                            {/* <b>{currencyFormatOne(getData1?.cars?.apCarLoanDetails?.apLoanAmount)}</b> */}
                                                            {
                                                                ap?.apLoanAmount !== "0" ?
                                                                    <b>{currencyFormatOne(ap?.apLoanAmount)}</b>
                                                                    :
                                                                    <b>{currencyFormatOne(getData1?.cars?.carLoanDetails?.approvedLoanAmount)}</b>
                                                            }
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>
                                                        <Form.Item style={{ marginBottom: 0, textAlign: 'left' }} label='จำนวนงวด'>
                                                            {
                                                                ap?.apLoanTerm !== "0" ?
                                                                    <b>{ap?.apLoanTerm}</b>
                                                                    :
                                                                    <b>{getData1?.cars?.carLoanDetails?.approvedLoanTerm}</b>
                                                            }
                                                            {/* <b>{getData1?.cars?.apCarLoanDetails?.apLoanTerm}</b> */}
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                งวด
                                                            </span>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col className='gutter-row' span={7}>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'left' }}
                                                            label='ดอกเบี้ย'
                                                        >
                                                            {
                                                                ap?.apInterest !== "0" ?
                                                                    <b>{currencyFormatOne(ap?.apInterest)}</b>
                                                                    :
                                                                    <b>{currencyFormatOne(interestt)}</b>
                                                            }
                                                            {/* <b>{currencyFormatOne(getData1?.cars?.apCarLoanDetails?.apInterest)}</b> */}
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'left' }}
                                                            label='จำนวนผ่อนต่อเดือน'
                                                        >
                                                            {
                                                                ap?.apMonthlyPayment !== "0" ?
                                                                    <b>{currencyFormatOne(ap?.apMonthlyPayment)}</b>
                                                                    :
                                                                    <b>{currencyFormatOne(arr)}</b>
                                                            }
                                                            {/* <b>{currencyFormatOne(getData1?.cars?.apCarLoanDetails?.apMonthlyPayment)}</b> */}
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col className='gutter-row' span={10}>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'left' }}
                                                            label='ผ่อนรวมดอกเบี้ย'
                                                        >
                                                            {
                                                                ap?.apInstallmentWithInterest !== "0" ?
                                                                    <b>{currencyFormatOne(ap?.apInstallmentWithInterest)}</b>
                                                                    :
                                                                    <b>{currencyFormatOne(installmentWithInterestt)}</b>
                                                            }
                                                            {/* <b>{currencyFormatOne(~~getData1?.cars?.apCarLoanDetails?.apMonthlyPayment * ~~getData1?.cars?.apCarLoanDetails?.apLoanTerm)}</b> */}
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'left' }}
                                                            label='วันที่อนุมัติ'
                                                        >
                                                            {/* <b>{dayjs(getData1?.cars?.apCarLoanDetails?.apApprovalDate).format("YYYY-MM-DD HH:mm:ss")}</b> */}
                                                            {
                                                                ap?.apApprovalDate !== null ?
                                                                    <b>{dayjs(ap?.apApprovalDate).format("YYYY-MM-DD HH:mm:ss")}</b>
                                                                    :
                                                                    <b>{dayjs(getData1?.cars?.carLoanDetails.approvalDate).format("YYYY-MM-DD HH:mm:ss")}</b>
                                                            }
                                                        </Form.Item>
                                                        <b> <Form.Item
                                                            style={{ margin: 0, textAlign: 'left' }}
                                                            label='ผู้อนุมัติ'
                                                        >
                                                            {
                                                                ap?.apApprovedBy !== "" ?
                                                                    <b style={{ fontSize: '20px', color: 'red' }}>{ap?.apApprovedBy}</b>
                                                                    :
                                                                    <b style={{ fontSize: '20px', color: 'red' }}>{getData1?.cars?.carLoanDetails.approvedBy}</b>
                                                            }
                                                            {/* <b style={{ fontSize: '20px', color: 'red' }}>{getData1?.cars?.apCarLoanDetails?.apApprovedBy}</b> */}
                                                        </Form.Item>
                                                        </b>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Row>
                                    </>
                                    :
                                    <>
                                        <Row gutter={32} justify={'center'}>
                                            <Card style={{ width: '1000px' }}>
                                                <Row justify={'left'}><b><u>ยอดที่อนุมัติ</u></b></Row>
                                                <Row justify={'center'} style={{ backgroundColor: "lightgreen" }} gutter={32}>
                                                    <Col className='gutter-row' span={7}>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'left' }}
                                                            label='อนุมัติยอด'
                                                        >
                                                            {/* <b>{currencyFormatOne(getData1?.lands?.apLandLoanDetails?.apLoanAmount)}</b> */}
                                                            {
                                                                ap?.apLoanAmount !== "0" ?
                                                                    <b>{currencyFormatOne(ap?.apLoanAmount)}</b>
                                                                    :
                                                                    <b>{currencyFormatOne(getData1?.lands?.landLoanDetails?.approvedLoanAmount)}</b>
                                                            }
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'left' }}
                                                            label='จำนวนงวด'
                                                        >
                                                            {
                                                                ap?.apLoanTerm !== "0" ?
                                                                    <b>{ap?.apLoanTerm}</b>
                                                                    :
                                                                    <b>{getData1?.lands?.landLoanDetails?.approvedLoanTerm}</b>
                                                            }
                                                            {/* <b>{getData1?.lands?.apLandLoanDetails?.apLoanTerm}</b> */}
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                งวด
                                                            </span>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col className='gutter-row' span={7}>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'left' }}
                                                            label='ดอกเบี้ย'
                                                        >
                                                            {
                                                                ap?.apInterest !== "0" ?
                                                                    <b>{currencyFormatOne(ap?.apInterest)}</b>
                                                                    :
                                                                    <b>{currencyFormatOne(resultRateDATA)}</b>
                                                            }
                                                            {/* <b>{currencyFormatOne(getData1?.lands?.apLandLoanDetails?.apInterest)}</b> */}
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'left' }}
                                                            label='จำนวนผ่อนต่อเดือน'
                                                        >
                                                            {
                                                                ap?.apMonthlyPayment !== "0" ?
                                                                    <b>{currencyFormatOne(ap?.apMonthlyPayment)}</b>
                                                                    :
                                                                    <b>{currencyFormatOne(loanTermDATA)}</b>
                                                            }
                                                            {/* <b>{currencyFormatOne(getData1?.lands?.apLandLoanDetails?.apMonthlyPayment)}</b> */}
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col className='gutter-row' span={10}>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'left' }}
                                                            label='ผ่อนรวมดอกเบี้ย'
                                                        >
                                                            {
                                                                ap?.apInstallmentWithInterest !== "0" ?
                                                                    <b>{currencyFormatOne(ap?.apInstallmentWithInterest)}</b>
                                                                    :
                                                                    <b>{currencyFormatOne(resultPriceDATA)}</b>
                                                            }
                                                            {/* <b>{currencyFormatOne(~~getData1?.lands?.apLandLoanDetails?.apMonthlyPayment * ~~getData1?.lands?.apLandLoanDetails?.apLoanTerm)}</b> */}
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Form.Item>
                                                        <Form.Item
                                                            style={{ marginBottom: 0, textAlign: 'left' }}
                                                            label='วันที่อนุมัติ'
                                                        >
                                                            {
                                                                ap?.apApprovalDate !== null ?
                                                                    <b>{dayjs(ap?.apApprovalDate).format("YYYY-MM-DD HH:mm:ss")}</b>
                                                                    :
                                                                    <b>{dayjs(getData1?.lands?.landLoanDetails.approvalDate).format("YYYY-MM-DD HH:mm:ss")}</b>
                                                            }
                                                            {/* <b>{dayjs(getData1?.lands?.apLandLoanDetails?.apApprovalDate).format("YYYY-MM-DD HH:mm:ss")}</b> */}
                                                        </Form.Item>
                                                        <b> <Form.Item
                                                            style={{ margin: 0, textAlign: 'left' }}
                                                            label='ผู้อนุมัติ'
                                                        >
                                                            {
                                                                ap?.apApprovedBy !== "" ?
                                                                    <b style={{ fontSize: '20px', color: 'red' }}>{ap?.apApprovedBy}</b>
                                                                    :
                                                                    <b style={{ fontSize: '20px', color: 'red' }}>{getData1?.lands?.landLoanDetails?.approvedBy}</b>
                                                            }
                                                            {/* <b style={{ fontSize: '20px', color: 'red' }}>{getData1?.lands?.apLandLoanDetails?.apApprovedBy}</b> */}
                                                        </Form.Item>
                                                        </b>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Row>
                                    </>

                            }

                            {
                                dt?.car === "car" ?
                                    <>
                                        {
                                            getData1?.cars?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-เปลี่ยนสัญญา" ||
                                                getData1?.cars?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-รี+เปลี่ยนสัญญา" ||
                                                getData1?.cars?.carLoanDetailsRe?.productLoanType === "เปลี่ยนสัญญา" ||
                                                getData1?.cars?.carLoanDetailsRe?.productLoanType === "รี+เปลี่ยนสัญญา"
                                                ?
                                                <Card style={{ width: '1000px' }} >
                                                    <Divider orientation={'left'}><b><u>ค่าใช้จ่ายที่ลูกค้าต้องจ่าย</u></b></Divider>
                                                    <Row style={{ width: '100%', background: '#061178', padding: '8px', color: 'white' }} >

                                                        <Col span={8} name='priceChange' style={{ marginBottom: 3 }}>
                                                            <span><b>ค่าเปลี่ยนสัญญา</b> : </span> <b> {currencyFormatOne(getData1?.cars?.carLoanDetailsRe?.priceChange)}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Col>
                                                        <Col span={8} name='overdue' style={{ marginBottom: 3 }}>
                                                            <span><b>ยอดค้าง + เบี้ยปรับ</b> : </span> <b> {currencyFormatOne(getData1?.cars?.carLoanDetailsRe?.overdue)}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Col>
                                                        <Col span={8} name='preChange' style={{ marginBottom: 3 }}>
                                                            <span><b>ค่างวดล่วงหน้า</b> : </span> <b> {currencyFormatOne(getData1?.cars?.carLoanDetailsRe?.preChange)}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span><br />
                                                            <span>ยอดรวม : </span> <b> {currencyFormatOne(~~getData1?.cars?.carLoanDetailsRe?.preChange + ~~getData1?.cars?.carLoanDetailsRe?.overdue + ~~getData1?.cars?.carLoanDetailsRe?.priceChange
                                                            )}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Col>

                                                    </Row>
                                                    <Divider style={{ margin: 3 }} />
                                                </Card>
                                                :
                                                null
                                        }
                                    </>
                                    :
                                    <>
                                        {
                                            getData1?.lands?.landLoanDetailsRe?.productLoanLandType === "Pre-Aaprove-เปลี่ยนสัญญา" ||
                                                getData1?.lands?.landLoanDetailsRe?.productLoanLandType === "Pre-Aaprove-รี+เปลี่ยนสัญญา" ||
                                                getData1?.lands?.landLoanDetailsRe?.productLoanLandType === "รี+เปลี่ยนสัญญา" ||
                                                getData1?.lands?.landLoanDetailsRe?.productLoanLandType === "เปลี่ยนสัญญา"
                                                ?
                                                <Card style={{ width: '1000px' }} >
                                                    <Divider orientation={'left'}><b><u>ค่าใช้จ่ายที่ลูกค้าต้องจ่าย</u></b></Divider>
                                                    <Row style={{ width: '100%', background: '#061178', padding: '8px', color: 'white' }} >

                                                        <Col span={8} name='priceChange' style={{ marginBottom: 3 }}>
                                                            <span><b>ค่าเปลี่ยนสัญญา</b> : </span> <b> {currencyFormatOne(getData1?.lands?.landLoanDetailsRe?.priceChange)}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Col>
                                                        <Col span={8} name='overdue' style={{ marginBottom: 3 }}>
                                                            <span><b>ยอดค้าง + เบี้ยปรับ</b> : </span> <b> {currencyFormatOne(getData1?.lands?.landLoanDetailsRe?.overdue)}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Col>
                                                        <Col span={8} name='preChange' style={{ marginBottom: 3 }}>
                                                            <span><b>ค่างวดล่วงหน้า</b> : </span> <b> {currencyFormatOne(getData1?.lands?.landLoanDetailsRe?.preChange)}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span><br />
                                                            <span>ยอดรวม : </span> <b> {currencyFormatOne(~~getData1?.lands?.landLoanDetailsRe?.preChange + ~~getData1?.lands?.landLoanDetailsRe?.overdue + ~~getData1?.lands?.landLoanDetailsRe?.priceChange
                                                            )}</b>
                                                            <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                บาท
                                                            </span>
                                                        </Col>

                                                    </Row>
                                                    <Divider style={{ margin: 3 }} />
                                                </Card>
                                                :
                                                null
                                        }
                                    </>
                            }
                            {
                                loanRe?.reLoanId > 0 ?
                                    <>
                                        {
                                            dataCarAun?.id > 0 ?
                                                <>
                                                    <Row justify={'center'} gutter={32}>
                                                        {/* <Card style={{}}> */}
                                                        <Card style={{ width: '1000px', backgroundColor: "lightyellow" }}>
                                                            <Row justify={'left'}><b><u style={{ backgroundColor: '#f39c12' }}>เงื่อนไขการปรับโครงสร้าง ({dataCarAun?.CONTNO})</u></b></Row>
                                                            <Col span={24} style={{ textAlign: 'right' }}>
                                                                <Image
                                                                    width={100}
                                                                    src={`${process.env.PUBLIC_URL}/logo.jpg`}
                                                                    alt="My Image"
                                                                />
                                                            </Col>
                                                            <Row gutter={32} justify={'center'}>
                                                                <Col className='gutter-row' span={10}>
                                                                    <Form.Item
                                                                        style={{ marginBottom: 0, textAlign: 'center' }}
                                                                        label='วันที่'
                                                                    >
                                                                        <b>{dateceo}</b>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col className='gutter-row' span={10}>
                                                                    <Form.Item
                                                                        style={{ marginBottom: 0, textAlign: 'center' }}
                                                                        label='มูลหนี้เช่าซื้อคงเหลือรวม'
                                                                    >
                                                                        <b>{moonnee}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col className='gutter-row' span={10}>
                                                                    <Form.Item
                                                                        style={{ marginBottom: 0, textAlign: 'center' }}
                                                                        label='หักส่วนลด'
                                                                    >
                                                                        <b>{hug}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col className='gutter-row' span={10}>
                                                                    <Form.Item
                                                                        style={{ marginBottom: 0, textAlign: 'center' }}
                                                                        label='คงเหลือ'
                                                                    >
                                                                        <b>{kong}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col className='gutter-row' span={10}>
                                                                    <Form.Item
                                                                        style={{ marginBottom: 0, textAlign: 'center' }}
                                                                        label='จ่ายเงิน'
                                                                    >
                                                                        <b>{jaimoney}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col className='gutter-row' span={10}>
                                                                    <Form.Item
                                                                        style={{ marginBottom: 0, textAlign: 'center' }}
                                                                        label='จำนวนงวด'
                                                                    >
                                                                        <b>{ngod}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col className='gutter-row' span={10}>
                                                                    <Form.Item
                                                                        style={{ marginBottom: 0, textAlign: 'center' }}
                                                                        label='ผ่อน'
                                                                    >
                                                                        <b>{pon}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            งวด
                                                                            (รวมภาษีมูลค่าเพิ่ม)
                                                                        </span>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col className='gutter-row' span={10}>
                                                                    <Form.Item style={{ marginBottom: 0, textAlign: 'center' }} label='ยอดจัด'>
                                                                        <b style={{ fontSize: '20px' }}><u>{yodjut}</u></b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </Form.Item>
                                                                    <Form.Item style={{ marginBottom: 0, textAlign: 'center' }} label='ใบนี้หมดอายุ ณ วันที่'>
                                                                        <b style={{ color: 'red', fontSize: '20px' }}><u>{exp}</u></b>
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Row>
                                                </>
                                                :
                                                <>
                                                    <Row justify={'center'} gutter={32}>
                                                        <Card style={{ width: '1000px' }}>
                                                            <Row justify={'left'}><b><u>รายละเอียดสัญญา ({isu})</u></b></Row>
                                                            <Row gutter={32} justify={'center'}>
                                                                <Col className='gutter-row' span={10}>
                                                                    <Form.Item
                                                                        style={{ marginBottom: 0, textAlign: 'center' }}
                                                                        label='ค่างวด'
                                                                    >
                                                                        <b>{currencyFormatOne(loanRe?.DAMT)}</b>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col className='gutter-row' span={10}>
                                                                    <Form.Item
                                                                        style={{ marginBottom: 0, textAlign: 'center' }}
                                                                        label='ยอดจัดครั้งที่แล้ว'
                                                                    >
                                                                        <b>{currencyFormatOne(loanRe?.tcshprc)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col className='gutter-row' span={10}>
                                                                    <Form.Item
                                                                        style={{ marginBottom: 0, textAlign: 'center' }}
                                                                        label='ราคาเช่าซื้อครั้งที่แล้ว'
                                                                    >
                                                                        <b>{currencyFormatOne(loanRe?.balanc)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col className='gutter-row' span={10}>
                                                                    <Form.Item
                                                                        style={{ marginBottom: 0, textAlign: 'center' }}
                                                                        label='ยอดที่ชำระมาแล้วทั้งหมด'
                                                                    >
                                                                        <b>{currencyFormatOne(loanRe?.totalpayment)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col className='gutter-row' span={10}>
                                                                    <Form.Item
                                                                        style={{ marginBottom: 0, textAlign: 'center' }}
                                                                        label='ล/น คงเหลือรวม'
                                                                    >
                                                                        <b>{currencyFormatOne(loanRe?.neekong)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col className='gutter-row' span={10}>
                                                                    <Form.Item
                                                                        style={{ marginBottom: 0, textAlign: 'center' }}
                                                                        label='เบี้ยปรับ'
                                                                    >
                                                                        <b>{currencyFormatOne(loanRe?.beeypup)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col className='gutter-row' span={10}>
                                                                    <Form.Item
                                                                        style={{ marginBottom: 0, textAlign: 'center' }}
                                                                        label='ลูกหนี้อื่นๆ'
                                                                    >
                                                                        <b>{currencyFormatOne(loanRe?.OTHR)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            บาท
                                                                        </span>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col className='gutter-row' span={10}>
                                                                    <Form.Item
                                                                        style={{ marginBottom: 0, textAlign: 'center' }}
                                                                        label='จำนวนงวดที่ผ่อนมาแล้ว'
                                                                    >
                                                                        <b>{currencyFormatOne(loanRe?.NOPAY)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            งวด
                                                                        </span>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col className='gutter-row' span={10}>
                                                                    <Form.Item
                                                                        style={{ marginBottom: 0, textAlign: 'center' }}
                                                                        label='จำนวนงวดทั้งหมด'
                                                                    >
                                                                        <b>{currencyFormatOne(loanRe?.nopays)}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            งวด
                                                                        </span>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col className='gutter-row' span={10}>
                                                                    <Form.Item
                                                                        style={{ marginBottom: 0, textAlign: 'center' }}
                                                                        label='ตัดสด งวดที่'
                                                                    >
                                                                        <b>{currencyFormatOne(loanRe?.reqNo)}</b>

                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        style={{ marginBottom: 0, textAlign: 'center', background: '#1677ff' }}
                                                                        label='จำนวนงวดที่ค้าง'
                                                                    >
                                                                        <b>{~~loanRe?.nopays - ~~loanRe?.NOPAY}</b>
                                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                                            งวด
                                                                        </span>
                                                                    </Form.Item>
                                                                    <b>
                                                                        <Form.Item style={{ margin: 0, textAlign: 'center' }} label='ยอดรีไฟแนนซ์เพื่อจัดใหม่'>
                                                                            <b style={{ fontSize: '20px' }}><u>{currencyFormatOne(loanRe?.re1)}</u></b>
                                                                            <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                                                                บาท
                                                                            </span>
                                                                        </Form.Item>
                                                                        <Form.Item style={{ margin: 0, textAlign: 'center' }} label='ใบนี้หมดอายุ ณ วันที่'>
                                                                            <b style={{ color: 'red', fontSize: '20px' }}><u>{dayjs(loanRe?.newOfferDate).format("DD-MM-YYYY")}</u></b>
                                                                        </Form.Item>
                                                                    </b>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Row>
                                                </>
                                        }
                                    </>
                                    : null
                            }
                            {dt?.car === "car" ?
                                <>
                                    <Row gutter={32} justify={'center'}>
                                        <Card style={{ width: '1000px' }}>
                                            <Row justify={'left'}><b><u>รายละเอียดรถ</u></b></Row>
                                            <Row gutter={32} justify={'center'}>
                                                <Col className='gutter-row' span={8}>
                                                    <Form.Item
                                                        style={{ marginBottom: 0, textAlign: 'left' }}
                                                        label='ยี่ห้อ'
                                                    >
                                                        <b>{getData1?.cars?.carBrand}</b>
                                                    </Form.Item>
                                                    <Form.Item
                                                        style={{ marginBottom: 0, textAlign: 'left' }}
                                                        label='เลขทะเบียน'
                                                    >
                                                        <b>{getData1?.cars?.carPlateNumber}</b>
                                                    </Form.Item>
                                                    <Form.Item
                                                        style={{ marginBottom: 0, textAlign: 'left' }}
                                                        label='เลขเครื่อง'
                                                    >
                                                        <b>{getData1?.cars?.carEngineNumber}</b>
                                                    </Form.Item>
                                                </Col>
                                                <Col className='gutter-row' span={8}>

                                                    <Form.Item
                                                        style={{ marginBottom: 0, textAlign: 'left' }}
                                                        label='รุ่นสินค้า'
                                                    >
                                                        <b>{getData1?.cars?.carModel}</b>
                                                    </Form.Item>
                                                    <Form.Item
                                                        style={{ marginBottom: 0, textAlign: 'left' }}
                                                        label='เลขคัสซี'
                                                    >
                                                        <b>{getData1?.cars?.carChassisNumber}</b>
                                                    </Form.Item>
                                                    <Form.Item
                                                        style={{ marginBottom: 0, textAlign: 'left' }}
                                                        label='สี'
                                                    >
                                                        <b>{getData1?.cars?.carColor}</b>
                                                    </Form.Item>

                                                </Col>
                                                <Col className='gutter-row' span={8}>
                                                    <Form.Item
                                                        style={{ marginBottom: 0, textAlign: 'left' }}
                                                        label='แบบรถ'
                                                    >
                                                        <b>{getData1?.cars?.carBaab}</b>
                                                    </Form.Item>
                                                    <Form.Item
                                                        style={{ marginBottom: 0, textAlign: 'left' }}
                                                        label='ปีรถ'
                                                    >
                                                        <b>{getData1?.cars?.carYear}</b>
                                                    </Form.Item>
                                                    <Form.Item
                                                        style={{ marginBottom: 0, textAlign: 'left' }}
                                                        label='จังหวัด'
                                                    >
                                                        <b>{getData1?.cars?.carProvince}</b>
                                                    </Form.Item>


                                                </Col>
                                            </Row>
                                        </Card>
                                    </Row>
                                </>
                                :
                                <>
                                    {
                                        newDataLand?.map((item, index) => {
                                            return renderLandData({ item, index })
                                        })
                                    }
                                </>
                            }

                            {getData1?.lands?.approval?.priceStatus === true ?
                                <>
                                    <Row gutter={32} justify={'center'}>
                                        <Card style={{ width: '1000px' }}>

                                            {
                                                newDataLand?.map((item, index) => {
                                                    return renderApproval({ item, index })
                                                })
                                            }
                                            {
                                                getData1?.lands?.approval?.mixNote === "" ||
                                                    getData1?.lands?.approval?.mixNote === undefined ||
                                                    getData1?.lands?.approval?.mixNote === null ?
                                                    null
                                                    :
                                                    <>
                                                        {
                                                            newDataLand?.map((item, index) => {
                                                                return renderMixNote({ item, index, key: `{item.identificationId} - ${index}` })
                                                            })
                                                        }
                                                    </>
                                            }
                                            {/* {
                                                newDataLand?.map((item, index) => {
                                                    return renderMixNote({ item, index })
                                                })
                                            } */}
                                        </Card>
                                    </Row>
                                </>
                                : null
                            }

                            {career?.length >= 1 ?
                                <>
                                    <Card style={{ width: '1000px' }}>
                                        <Row justify={'left'}><b><u>อาชีพ - รายได้</u></b></Row>
                                        <Row justify={'center'} gutter={32}>
                                            {
                                                career?.map((item, index) => {
                                                    return renderItemCareer({ item, index })
                                                })
                                            }
                                        </Row>

                                    </Card>
                                </>
                                : null
                            }
                            {
                                dataBroker?.length > 0 ?
                                    ShowDataBrokersAD(dataBroker)
                                    : null
                            }
                            <Row gutter={32} justify={'center'}>
                                <Card style={{ width: '1000px' }}>
                                    <Row justify={'left'}>
                                        <b>
                                            <u>คนค้ำประกัน</u>
                                        </b>
                                    </Row>
                                    <Row gutter={32} justify={'center'}>
                                        {
                                            guarantorData?.length > 0 ?
                                                <>
                                                    <Row justify={'center'}>
                                                        <Col span={24}><br /></Col>
                                                        <Col span={24}>
                                                            <Row gutter={32} justify={'center'}  >
                                                                {
                                                                    guarantorData?.map((item, index) => {
                                                                        return renderGuarantorNewImg({ item, index, key: `{item.identificationId} - ${index}` })
                                                                    })
                                                                }
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </> : null
                                        }
                                    </Row>
                                </Card>
                            </Row>
                            <Row gutter={32} justify={'center'} style={{ width: '1100px' }}>
                                {dt?.car === "car" ?
                                    <>
                                        <Form name="cbData" form={formcb}>
                                            <Card style={{ width: '1000px' }}>
                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                    <Checkbox onChange={onChangePreaaprove} checked={cbData?.cbPreaaprove === true ? true : false} style={{ fontSize: '20px' }}>Pre-Aaprove</Checkbox>
                                                    <Checkbox onChange={onChangeCar} checked={cbData?.cbCar === true ? true : false} style={{ fontSize: '20px' }}>รถ</Checkbox>
                                                    <Checkbox onChange={onChangeHome} checked={cbData?.cbHome === true ? true : false} style={{ fontSize: '20px' }}>บ้าน</Checkbox>
                                                    <Checkbox onChange={onChangeHomeRent} checked={cbData?.cbHomeRent === true ? true : false} style={{ fontSize: '20px' }}>บ้านเช่า</Checkbox>
                                                    <Checkbox onChange={onChangeHomeGov} checked={cbData?.cbHomeGov === true ? true : false} style={{ fontSize: '20px' }}>บ้านพักราชการ</Checkbox>
                                                    <Checkbox onChange={onChangePark} checked={cbData?.cbPark === true ? true : false} style={{ fontSize: '20px' }}>ลานจอด</Checkbox>
                                                    <Checkbox onChange={onChangeVat} checked={cbData?.cbVat === true ? true : false} style={{ fontSize: '20px' }}>ภาษี</Checkbox>
                                                    <Checkbox onChange={onChangeVDO} checked={cbData?.cbVdo === true ? true : false} style={{ fontSize: '20px' }}>VDO</Checkbox>
                                                    <Checkbox onChange={onChangeGPS} checked={cbData?.cbGps === true ? true : false} style={{ fontSize: '20px' }}>GPS</Checkbox>
                                                    <Button type="primary" onClick={AddCheckbox} style={{ backgroundColor: "green" }}>บันทึก</Button>
                                                </Col>
                                            </Card>
                                        </Form>
                                    </>
                                    :
                                    <>
                                        <Form
                                            name="cbData"
                                            form={formcb}
                                        >
                                            <Card style={{ width: '1000px' }}>
                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                    <Checkbox onChange={onChangePreaaprove} checked={cbData?.cbPreaaprove === true ? true : false} style={{ fontSize: '20px' }}>Pre-aaprove</Checkbox>
                                                    <Checkbox onChange={onChangeLand} checked={cbData?.cbLand === true ? true : false} style={{ fontSize: '20px' }}>ที่ดิน</Checkbox>
                                                    <Checkbox onChange={onChangeHome} checked={cbData?.cbHome === true ? true : false} style={{ fontSize: '20px' }}>บ้าน</Checkbox>
                                                    <Checkbox onChange={onChangeHomeRent} checked={cbData?.cbHomeRent === true ? true : false} style={{ fontSize: '20px' }}>บ้านเช่า</Checkbox>
                                                    <Checkbox onChange={onChangeHomeGov} checked={cbData?.cbHomeGov === true ? true : false} style={{ fontSize: '20px' }}>บ้านพักราชการ</Checkbox>
                                                    <Checkbox onChange={onChangePark} checked={cbData?.cbPark === true ? true : false} style={{ fontSize: '20px' }}>ลานจอด</Checkbox>
                                                    <Checkbox onChange={onChangeVat} checked={cbData?.cbVat === true ? true : false} style={{ fontSize: '20px' }}>ภาษี</Checkbox>
                                                    <Checkbox onChange={onChangeVDO} checked={cbData?.cbVdo === true ? true : false} style={{ fontSize: '20px' }}>VDO</Checkbox>
                                                    <Checkbox onChange={onChangeGPS} checked={cbData?.cbGps === true ? true : false} style={{ fontSize: '20px' }}>GPS</Checkbox>
                                                    <Button type="primary" onClick={AddCheckbox} style={{ backgroundColor: "green" }}>บันทึก</Button>
                                                </Col>
                                            </Card>
                                        </Form>
                                    </>
                                }
                            </Row>

                            <Card style={{ width: '1000px' }}>
                                <Row gutter={32} justify={'center'} style={{ marginTop: 15, marginBottom: 15, width: '1100px' }}>
                                    <>
                                        <Col span={24} style={{ textAlign: 'center' }}>
                                            <Checkbox
                                                key={"0"}
                                                value={1}
                                                style={{ fontSize: '20px' }}
                                                checked={checkGua === "ค้ำ-ไม่โอน"}
                                                isPacked={true}
                                            // onChange={() => onChangeCheckGua("ค้ำ-ไม่โอน")}
                                            >
                                                ค้ำ-ไม่โอน
                                            </Checkbox>
                                            <Checkbox
                                                key={"1"}
                                                value={2}
                                                style={{ fontSize: '20px' }}
                                                checked={checkGua === "ค้ำ-โอน"}
                                                isPacked={true}
                                            // onChange={() => onChangeCheckGua("ค้ำ-โอน")}
                                            >
                                                ค้ำ-โอน
                                            </Checkbox>
                                            <Checkbox
                                                key={"2"}
                                                value={3}
                                                style={{ fontSize: '20px' }}
                                                checked={checkGua === "ไม่ค้ำ-ไม่โอน"}
                                                isPacked={true}
                                            // onChange={() => onChangeCheckGua("ไม่ค้ำ-ไม่โอน")}
                                            >
                                                ไม่ค้ำ-ไม่โอน
                                            </Checkbox>
                                            <Checkbox
                                                key={"3"}
                                                value={4}
                                                style={{ fontSize: '20px' }}
                                                checked={checkGua === "ไม่ค้ำ-โอน"}
                                                isPacked={true}
                                            // onChange={() => onChangeCheckGua("ไม่ค้ำ-โอน")}
                                            >
                                                ไม่ค้ำ-โอน
                                            </Checkbox>
                                            {/* <Button type="primary" onClick={AddGuabox} style={{ backgroundColor: "green" }}>บันทึก</Button> */}
                                        </Col>
                                    </>
                                </Row>
                            </Card>

                            <Row gutter={32} >
                                <Card style={{ width: '1000px' }}>
                                    <Row justify={'left'}>
                                        <b>
                                            <u>หมายเหตุ</u>
                                        </b>
                                    </Row>
                                    {/* <Row gutter={32}> */}
                                    <Row gutter={32} justify={'center'}>

                                        {dataNote?.length >= 1 ?
                                            dataNote?.map((item, index) => {
                                                return renderDataNote({ item, index, key: `{item.identificationId} - ${index}` });
                                            })
                                            : <>* ยังไม่เพิ่มหมายเหตุ!</>
                                        }
                                    </Row>
                                </Card>
                            </Row>
                            <Row gutter={32} >
                                <Card style={{ width: '1000px' }}>
                                    <Row justify={'left'}>
                                        <b>
                                            <u>หมายเหตุ เคสรี/ปรับ</u>
                                        </b>
                                    </Row>
                                    {/* <Row gutter={32}> */}
                                    <Row gutter={32} justify={'center'}>

                                        {dataNoteRe?.length >= 1 ?
                                            dataNoteRe?.map((item, index) => {
                                                return renderDataNoteRe({ item, index, key: `{item.identificationId} - ${index}` });
                                            })
                                            : <>* ยังไม่เพิ่มหมายเหตุ!</>
                                        }
                                    </Row>
                                </Card>
                            </Row>
                            {
                                branch === "MIT" || branch === "UD" || branch === "LEX" || branch === "S4" || branch === "S5" ?
                                    null :
                                    <>
                                        <Row gutter={32} >
                                            <Card style={{ width: '1000px' }}>
                                                <Row justify={'left'}><b><u>Memo</u></b></Row>
                                                <Row gutter={32} justify={'center'}>
                                                    <Col className='gutter-row' span={8}>
                                                        {
                                                            dt?.car === "car" ?
                                                                <>
                                                                    <Form.Item
                                                                        style={{ marginBottom: 0, textAlign: 'left' }}
                                                                        label='Memo รถ'
                                                                    >
                                                                        <b>{getData1?.cars?.carMemo}</b>
                                                                    </Form.Item>
                                                                </>
                                                                :
                                                                <>
                                                                    <Form.Item
                                                                        style={{ marginBottom: 0, textAlign: 'left' }}
                                                                        label='Memo ที่ดิน'
                                                                    >
                                                                        <b>{getData1?.lands?.landMemo}</b>
                                                                    </Form.Item>
                                                                </>
                                                        }
                                                    </Col>
                                                    <Col className='gutter-row' span={8}>
                                                    </Col>
                                                    <Col className='gutter-row' span={8}>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Row>
                                    </>
                            }
                            {
                                dt?.car === "car" ?
                                    <>
                                        <Row gutter={32} justify={'center'}>
                                            <Card style={{ width: '1000px' }}>
                                                <Row justify={'left'}><b><u>ใบประเมิน / รูปรถ / สัญญาไฟแนนซ์เดิม / ใบเสร็จไฟแนนซ์เดิม</u></b></Row>
                                                <Row gutter={32} justify={'center'}>
                                                    <Space direction="vertical">
                                                        <Row justify={'center'}>
                                                            {dataMemo?.cars?.carMemo !== "" && dataMemo?.cars?.carMemo !== undefined ?
                                                                <>
                                                                    <Row justify={'left'}>
                                                                        <Col span={24} >
                                                                            <b>คอมเม้นรถ :  </b>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row justify={'center'}>
                                                                        <Col span={24} >
                                                                            <Form.Item name="carMemo" >
                                                                                <TextArea disabled name='carMemo' style={{ width: '800px', color: 'blue' }} rows={8}>
                                                                                    {/* <b style={{ color: 'blue' }}>{dataModalCars?.cars?.carMemo}</b> */}
                                                                                </TextArea>
                                                                            </Form.Item>
                                                                        </Col>

                                                                    </Row>

                                                                </>
                                                                : null
                                                            }
                                                        </Row>
                                                        <Row gutter={32} justify={'center'}>
                                                            <Image.PreviewGroup
                                                                preview={{
                                                                    countRender: (index, total) => {
                                                                        const imageCount1 = carimage1?.length || 0; // นับจำนวนภาพจริง
                                                                        const displayedIndex = Math.min(index, imageCount1);
                                                                        const displayTotal = Math.max(imageCount1, imageCount1);
                                                                        return `${displayedIndex} / ${displayTotal}`;
                                                                    }, // ใช้เพื่อแสดงจำนวนภาพ
                                                                    toolbarRender: (
                                                                        _,
                                                                        {
                                                                            transform: { scale },
                                                                            actions: {
                                                                                onActive,
                                                                                onFlipY,
                                                                                onFlipX,
                                                                                onRotateLeft,
                                                                                onRotateRight,
                                                                                onZoomOut,
                                                                                onZoomIn,
                                                                                onReset,
                                                                            },
                                                                        },
                                                                    ) => (
                                                                        <>
                                                                            <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                                {carimage1?.map((e, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Image
                                                                                                width={auto}
                                                                                                key={index}
                                                                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                                                alt={`รูปภาพ ${index + 1}`}
                                                                                                style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                            />
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                            </Row>
                                                                            <Row style={{ zIndex: 2 }}>
                                                                                <Space size={12} className="toolbar-wrapper">
                                                                                    {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                      <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                                    <DownloadOutlined onClick={onDownload} />
                                                                                    <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                                    <SwapOutlined onClick={onFlipX} />
                                                                                    <RotateLeftOutlined onClick={onRotateLeft} />
                                                                                    <RotateRightOutlined onClick={onRotateRight} />
                                                                                    <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                                    <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                                    <UndoOutlined onClick={onReset} />
                                                                                </Space>
                                                                            </Row>
                                                                        </>
                                                                    ),
                                                                    onChange: (index) => {
                                                                        setCurrent(index);
                                                                    },
                                                                }}
                                                            >
                                                                <Row gutter={32} justify={'center'}>
                                                                    {carimage1?.map((e, index) => {
                                                                        return (
                                                                            <>
                                                                                <Image
                                                                                    width={150}
                                                                                    key={index}
                                                                                    onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                    src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                                    alt={`รูปภาพ ${index + 1}`}
                                                                                    style={{ cursor: 'pointer' }}
                                                                                />
                                                                            </>
                                                                        );
                                                                    })}
                                                                </Row>
                                                            </Image.PreviewGroup>
                                                        </Row>
                                                    </Space>
                                                </Row>
                                                <Divider />
                                                {
                                                    checkSendNEW ?
                                                        <>
                                                            <Row justify={'left'}><b><u>ใบประเมิน / รูปรถ / สัญญาไฟแนนซ์เดิม / ใบเสร็จไฟแนนซ์เดิม</u> (ใหม่)</b></Row>
                                                            <Row gutter={32} justify={'center'}>
                                                                <Space direction="vertical">
                                                                    <Row gutter={32} justify={'center'}>
                                                                        <Image.PreviewGroup
                                                                            preview={{
                                                                                countRender: (index, total) => {
                                                                                    const imageCountnew1 = carimagenew1?.length || 0; // นับจำนวนภาพจริง
                                                                                    console.log("imageCountnew1", imageCountnew1)
                                                                                    const displayedIndex = Math.min(index, imageCountnew1);
                                                                                    const displayTotal = Math.max(imageCountnew1, imageCountnew1);
                                                                                    return `${displayedIndex} / ${displayTotal}`;
                                                                                }, // ใช้เพื่อแสดงจำนวนภาพ
                                                                                toolbarRender: (
                                                                                    _,
                                                                                    {
                                                                                        transform: { scale },
                                                                                        actions: {
                                                                                            onActive,
                                                                                            onFlipY,
                                                                                            onFlipX,
                                                                                            onRotateLeft,
                                                                                            onRotateRight,
                                                                                            onZoomOut,
                                                                                            onZoomIn,
                                                                                            onReset,
                                                                                        },
                                                                                    },
                                                                                ) => (
                                                                                    <>
                                                                                        <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                                            {carimagenew1?.map((e, index) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <Image
                                                                                                            width={auto}
                                                                                                            key={index}
                                                                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                            src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                                            alt={`รูปภาพ ${index + 1}`}
                                                                                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                                        />
                                                                                                    </>
                                                                                                );
                                                                                            })}
                                                                                        </Row>
                                                                                        <Row style={{ zIndex: 2 }}>
                                                                                            <Space size={12} className="toolbar-wrapper">
                                                                                                {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                          <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                                                <DownloadOutlined onClick={onDownload} />
                                                                                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                                                <SwapOutlined onClick={onFlipX} />
                                                                                                <RotateLeftOutlined onClick={onRotateLeft} />
                                                                                                <RotateRightOutlined onClick={onRotateRight} />
                                                                                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                                                <UndoOutlined onClick={onReset} />
                                                                                            </Space>
                                                                                        </Row>
                                                                                    </>

                                                                                ),
                                                                                onChange: (index) => {
                                                                                    setCurrent(index);
                                                                                },
                                                                            }}


                                                                        >
                                                                            <Row gutter={32} justify={'center'}>
                                                                                {carimagenew1?.map((e, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Image
                                                                                                width={150}
                                                                                                key={index}
                                                                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                                alt={`รูปภาพ ${index + 1}`}
                                                                                                style={{ cursor: 'pointer' }}
                                                                                            />
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                            </Row>
                                                                        </Image.PreviewGroup>
                                                                    </Row>
                                                                </Space>
                                                            </Row>
                                                            <Divider />
                                                        </>
                                                        : null
                                                }
                                                <Row justify={'left'}><b><u>หน้าคู่มือ</u></b></Row>
                                                <Row gutter={32} justify={'center'}>
                                                    <Space direction="vertical" >
                                                        <Row gutter={32} justify={'center'}>
                                                            <Image.PreviewGroup
                                                                preview={{
                                                                    countRender: (index, total) => {
                                                                        const imageCount2 = carimage2?.length || 0; // นับจำนวนภาพจริง
                                                                        const displayedIndex = Math.min(index, imageCount2);
                                                                        const displayTotal = Math.max(imageCount2, imageCount2);
                                                                        return `${displayedIndex} / ${displayTotal}`;
                                                                    }, // ใช้เพื่อแสดงจำนวนภาพ
                                                                    toolbarRender: (
                                                                        _,
                                                                        {
                                                                            transform: { scale },
                                                                            actions: {
                                                                                onActive,
                                                                                onFlipY,
                                                                                onFlipX,
                                                                                onRotateLeft,
                                                                                onRotateRight,
                                                                                onZoomOut,
                                                                                onZoomIn,
                                                                                onReset,
                                                                            },
                                                                        },
                                                                    ) => (
                                                                        <>
                                                                            <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                                {carimage2?.map((e, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Image
                                                                                                width={auto}
                                                                                                key={index}
                                                                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                                                alt={`รูปภาพ ${index + 1}`}
                                                                                                style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                            />
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                            </Row>
                                                                            <Row style={{ zIndex: 2 }}>
                                                                                <Space size={12} className="toolbar-wrapper">
                                                                                    {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                      <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                                    <DownloadOutlined onClick={onDownload} />
                                                                                    <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                                    <SwapOutlined onClick={onFlipX} />
                                                                                    <RotateLeftOutlined onClick={onRotateLeft} />
                                                                                    <RotateRightOutlined onClick={onRotateRight} />
                                                                                    <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                                    <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                                    <UndoOutlined onClick={onReset} />
                                                                                </Space>
                                                                            </Row>
                                                                        </>

                                                                    ),
                                                                    onChange: (index) => {
                                                                        setCurrent(index);
                                                                    },
                                                                }}
                                                            >
                                                                <Row gutter={32} justify={'center'}>
                                                                    {carimage2?.map((e, index) => {
                                                                        return (
                                                                            <>
                                                                                <Image
                                                                                    width={150}
                                                                                    key={index}
                                                                                    onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                    src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                                    alt={`รูปภาพ ${index + 1}`}
                                                                                    style={{ cursor: 'pointer' }}
                                                                                />
                                                                            </>
                                                                        );
                                                                    })}
                                                                </Row>
                                                            </Image.PreviewGroup>
                                                        </Row>
                                                    </Space>
                                                </Row>
                                                <Divider />
                                                {
                                                    checkSendNEW ?
                                                        <>
                                                            <Row justify={'left'}><b><u>หน้าคู่มือ</u> (ใหม่)</b></Row>
                                                            <Row gutter={32} justify={'center'}>
                                                                <Space direction="vertical" >
                                                                    <Row gutter={32} justify={'center'}>
                                                                        <Image.PreviewGroup
                                                                            preview={{
                                                                                countRender: (index, total) => {
                                                                                    const imageCountnew2 = carimagenew2?.length || 0; // นับจำนวนภาพจริง
                                                                                    const displayedIndex = Math.min(index, imageCountnew2);
                                                                                    const displayTotal = Math.max(imageCountnew2, imageCountnew2);
                                                                                    return `${displayedIndex} / ${displayTotal}`;
                                                                                }, // ใช้เพื่อแสดงจำนวนภาพ
                                                                                toolbarRender: (
                                                                                    _,
                                                                                    {
                                                                                        transform: { scale },
                                                                                        actions: {
                                                                                            onActive,
                                                                                            onFlipY,
                                                                                            onFlipX,
                                                                                            onRotateLeft,
                                                                                            onRotateRight,
                                                                                            onZoomOut,
                                                                                            onZoomIn,
                                                                                            onReset,
                                                                                        },
                                                                                    },
                                                                                ) => (
                                                                                    <>
                                                                                        <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                                            {carimagenew2?.map((e, index) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <Image
                                                                                                            width={auto}
                                                                                                            key={index}
                                                                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                            src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                                            alt={`รูปภาพ ${index + 1}`}
                                                                                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                                        />
                                                                                                    </>
                                                                                                );

                                                                                            })}
                                                                                        </Row>
                                                                                        <Row style={{ zIndex: 2 }}>
                                                                                            <Space size={12} className="toolbar-wrapper">
                                                                                                {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                          <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                                                <DownloadOutlined onClick={onDownload} />
                                                                                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                                                <SwapOutlined onClick={onFlipX} />
                                                                                                <RotateLeftOutlined onClick={onRotateLeft} />
                                                                                                <RotateRightOutlined onClick={onRotateRight} />
                                                                                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                                                <UndoOutlined onClick={onReset} />
                                                                                            </Space>
                                                                                        </Row>
                                                                                    </>
                                                                                ),
                                                                                onChange: (index) => {
                                                                                    setCurrent(index);
                                                                                },
                                                                            }}
                                                                        >
                                                                            <Row gutter={32} justify={'center'}>
                                                                                {carimagenew2?.map((e, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Image
                                                                                                width={150}
                                                                                                key={index}
                                                                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                                alt={`รูปภาพ ${index + 1}`}
                                                                                                style={{ cursor: 'pointer' }}
                                                                                            />
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                            </Row>
                                                                        </Image.PreviewGroup>
                                                                    </Row>
                                                                </Space>
                                                            </Row>
                                                            <Divider />
                                                        </>
                                                        : null
                                                }
                                                <Row justify={'left'}><b><u>รูปบัตรประชาชนคนกู้</u></b></Row>
                                                <Row gutter={32} justify={'center'}>
                                                    <Space direction="vertical" >
                                                        <Row gutter={32} justify={'center'}>
                                                            <Image.PreviewGroup
                                                                preview={{
                                                                    countRender: (index, total) => {
                                                                        const imageCount4 = carimage4?.length || 0; // นับจำนวนภาพจริง
                                                                        const displayedIndex = Math.min(index, imageCount4);
                                                                        const displayTotal = Math.max(imageCount4, imageCount4);
                                                                        return `${displayedIndex} / ${displayTotal}`;
                                                                    }, // ใช้เพื่อแสดงจำนวนภาพ
                                                                    toolbarRender: (
                                                                        _,
                                                                        {
                                                                            transform: { scale },
                                                                            actions: {
                                                                                onActive,
                                                                                onFlipY,
                                                                                onFlipX,
                                                                                onRotateLeft,
                                                                                onRotateRight,
                                                                                onZoomOut,
                                                                                onZoomIn,
                                                                                onReset,
                                                                            },
                                                                        },
                                                                    ) => (
                                                                        <>
                                                                            <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                                {carimage4?.map((e, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Image
                                                                                                width={auto}
                                                                                                key={index}
                                                                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                                                alt={`รูปภาพ ${index + 1}`}
                                                                                                style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                            />
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                            </Row>
                                                                            <Row style={{ zIndex: 2 }}>
                                                                                <Space size={12} className="toolbar-wrapper">
                                                                                    {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                    <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                                    <DownloadOutlined onClick={onDownload} />
                                                                                    <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                                    <SwapOutlined onClick={onFlipX} />
                                                                                    <RotateLeftOutlined onClick={onRotateLeft} />
                                                                                    <RotateRightOutlined onClick={onRotateRight} />
                                                                                    <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                                    <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                                    <UndoOutlined onClick={onReset} />
                                                                                </Space>
                                                                            </Row>
                                                                        </>
                                                                    ),
                                                                    onChange: (index) => {
                                                                        setCurrent(index);
                                                                    },
                                                                }}
                                                            >
                                                                <Row gutter={32} justify={'center'}>
                                                                    {carimage4?.map((e, index) => {
                                                                        return (
                                                                            <>
                                                                                <Image
                                                                                    width={150}
                                                                                    key={index}
                                                                                    onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                    src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                                    alt={`รูปภาพ ${index + 1}`}
                                                                                    style={{ cursor: 'pointer' }}
                                                                                />
                                                                            </>
                                                                        );
                                                                    })}
                                                                </Row>
                                                            </Image.PreviewGroup>
                                                        </Row>
                                                    </Space>
                                                </Row>
                                                <Divider />
                                                {
                                                    checkSendNEW ?
                                                        <>
                                                            <Row justify={'left'}><b><u>รูปบัตรประชาชนคนกู้</u> (ใหม่)</b></Row>
                                                            <Row gutter={32} justify={'center'}>
                                                                <Space direction="vertical" >
                                                                    <Row gutter={32} justify={'center'}>
                                                                        <Image.PreviewGroup
                                                                            preview={{
                                                                                countRender: (index, total) => {
                                                                                    const imageCount4 = carimagenew4?.length || 0; // นับจำนวนภาพจริง
                                                                                    const displayedIndex = Math.min(index, imageCount4);
                                                                                    const displayTotal = Math.max(imageCount4, imageCount4);
                                                                                    return `${displayedIndex} / ${displayTotal}`;
                                                                                }, // ใช้เพื่อแสดงจำนวนภาพ
                                                                                toolbarRender: (
                                                                                    _,
                                                                                    {
                                                                                        transform: { scale },
                                                                                        actions: {
                                                                                            onActive,
                                                                                            onFlipY,
                                                                                            onFlipX,
                                                                                            onRotateLeft,
                                                                                            onRotateRight,
                                                                                            onZoomOut,
                                                                                            onZoomIn,
                                                                                            onReset,
                                                                                        },
                                                                                    },
                                                                                ) => (
                                                                                    <>
                                                                                        <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                                            {carimagenew4?.map((e, index) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <Image
                                                                                                            width={auto}
                                                                                                            key={index}
                                                                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                            src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                                            alt={`รูปภาพ ${index + 1}`}
                                                                                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                                        />
                                                                                                    </>
                                                                                                );
                                                                                            })}
                                                                                        </Row>
                                                                                        <Row style={{ zIndex: 2 }}>
                                                                                            <Space size={12} className="toolbar-wrapper">
                                                                                                {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                                <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                                                <DownloadOutlined onClick={onDownload} />
                                                                                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                                                <SwapOutlined onClick={onFlipX} />
                                                                                                <RotateLeftOutlined onClick={onRotateLeft} />
                                                                                                <RotateRightOutlined onClick={onRotateRight} />
                                                                                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                                                <UndoOutlined onClick={onReset} />
                                                                                            </Space>
                                                                                        </Row>
                                                                                    </>
                                                                                ),
                                                                                onChange: (index) => {
                                                                                    setCurrent(index);
                                                                                },
                                                                            }}
                                                                        >
                                                                            <Row gutter={32} justify={'center'}>
                                                                                {carimagenew4?.map((e, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Image
                                                                                                width={150}
                                                                                                key={index}
                                                                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                                alt={`รูปภาพ ${index + 1}`}
                                                                                                style={{ cursor: 'pointer' }}
                                                                                            />
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                            </Row>
                                                                        </Image.PreviewGroup>
                                                                    </Row>
                                                                </Space>
                                                            </Row>
                                                            <Divider />
                                                        </>
                                                        : null
                                                }
                                                <Row justify={'left'}><b><u>ประวัติผู้กู้ / ทะเบียนบ้าน / อาชีพ</u></b></Row>
                                                <Row gutter={32} justify={'center'}>
                                                    <Space direction="vertical" >
                                                        <Row gutter={32} justify={'center'}>
                                                            <Image.PreviewGroup
                                                                preview={{
                                                                    countRender: (index, total) => {
                                                                        const imageCount6 = carimage6?.length || 0; // นับจำนวนภาพจริง
                                                                        const displayedIndex = Math.min(index, imageCount6);
                                                                        const displayTotal = Math.max(imageCount6, imageCount6);
                                                                        return `${displayedIndex} / ${displayTotal}`;
                                                                    }, // ใช้เพื่อแสดงจำนวนภาพ
                                                                    toolbarRender: (
                                                                        _,
                                                                        {
                                                                            transform: { scale },
                                                                            actions: {
                                                                                onActive,
                                                                                onFlipY,
                                                                                onFlipX,
                                                                                onRotateLeft,
                                                                                onRotateRight,
                                                                                onZoomOut,
                                                                                onZoomIn,
                                                                                onReset,
                                                                            },
                                                                        },
                                                                    ) => (
                                                                        <>
                                                                            <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                                {carimage6?.map((e, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Image
                                                                                                width={auto}
                                                                                                key={index}
                                                                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                                                alt={`รูปภาพ ${index + 1}`}
                                                                                                style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                            />
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                            </Row>
                                                                            <Row style={{ zIndex: 2 }}>
                                                                                <Space size={12} className="toolbar-wrapper">
                                                                                    {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                                    <DownloadOutlined onClick={onDownload} />
                                                                                    <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                                    <SwapOutlined onClick={onFlipX} />
                                                                                    <RotateLeftOutlined onClick={onRotateLeft} />
                                                                                    <RotateRightOutlined onClick={onRotateRight} />
                                                                                    <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                                    <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                                    <UndoOutlined onClick={onReset} />
                                                                                </Space>
                                                                            </Row>
                                                                        </>
                                                                    ),
                                                                    onChange: (index) => {
                                                                        setCurrent(index);
                                                                    },
                                                                }}
                                                            >
                                                                <Row gutter={32} justify={'center'}>
                                                                    {carimage6?.map((e, index) => {
                                                                        return (
                                                                            <>
                                                                                <Image
                                                                                    width={150}
                                                                                    key={index}
                                                                                    onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                    src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                                    alt={`รูปภาพ ${index + 1}`}
                                                                                    style={{ cursor: 'pointer' }}
                                                                                />
                                                                            </>
                                                                        );
                                                                    })}
                                                                </Row>
                                                            </Image.PreviewGroup>
                                                        </Row>
                                                    </Space>
                                                </Row>
                                                <Divider />
                                                {
                                                    checkSendNEW ?
                                                        <>
                                                            <Row justify={'left'}><b><u>ประวัติผู้กู้ / ทะเบียนบ้าน / อาชีพ</u> (ใหม่)</b></Row>
                                                            <Row gutter={32} justify={'center'}>
                                                                <Space direction="vertical" >
                                                                    <Row gutter={32} justify={'center'}>
                                                                        <Image.PreviewGroup
                                                                            preview={{
                                                                                countRender: (index, total) => {
                                                                                    const imageCount1 = carimagenew6?.length || 0; // นับจำนวนภาพจริง
                                                                                    const displayedIndex = Math.min(index, imageCount1);
                                                                                    const displayTotal = Math.max(imageCount1, imageCount1);
                                                                                    return `${displayedIndex} / ${displayTotal}`;
                                                                                }, // ใช้เพื่อแสดงจำนวนภาพ
                                                                                toolbarRender: (
                                                                                    _,
                                                                                    {
                                                                                        transform: { scale },
                                                                                        actions: {
                                                                                            onActive,
                                                                                            onFlipY,
                                                                                            onFlipX,
                                                                                            onRotateLeft,
                                                                                            onRotateRight,
                                                                                            onZoomOut,
                                                                                            onZoomIn,
                                                                                            onReset,
                                                                                        },
                                                                                    },
                                                                                ) => (
                                                                                    <>
                                                                                        <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                                            {carimagenew6?.map((e, index) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <Image
                                                                                                            width={auto}
                                                                                                            key={index}
                                                                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                            src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                                            alt={`รูปภาพ ${index + 1}`}
                                                                                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                                        />
                                                                                                    </>
                                                                                                );
                                                                                            })}
                                                                                        </Row>
                                                                                        <Row style={{ zIndex: 2 }}>
                                                                                            <Space size={12} className="toolbar-wrapper">
                                                                                                {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                            <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                                                <DownloadOutlined onClick={onDownload} />
                                                                                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                                                <SwapOutlined onClick={onFlipX} />
                                                                                                <RotateLeftOutlined onClick={onRotateLeft} />
                                                                                                <RotateRightOutlined onClick={onRotateRight} />
                                                                                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                                                <UndoOutlined onClick={onReset} />
                                                                                            </Space>
                                                                                        </Row>
                                                                                    </>
                                                                                ),
                                                                                onChange: (index) => {
                                                                                    setCurrent(index);
                                                                                },
                                                                            }}
                                                                        >
                                                                            <Row gutter={32} justify={'center'}>
                                                                                {carimagenew6?.map((e, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Image
                                                                                                width={150}
                                                                                                key={index}
                                                                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                                alt={`รูปภาพ ${index + 1}`}
                                                                                                style={{ cursor: 'pointer' }}
                                                                                            />
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                            </Row>
                                                                        </Image.PreviewGroup>
                                                                    </Row>
                                                                </Space>
                                                            </Row>
                                                            <Divider />
                                                        </>
                                                        : null
                                                }
                                                <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้ </u></b></Row>
                                                <Row gutter={32} justify={'center'}>
                                                    <Space direction="vertical" >
                                                        <Row gutter={32} justify={'center'}>
                                                            <Image.PreviewGroup
                                                                preview={{
                                                                    countRender: (index, total) => {
                                                                        const imageCount8 = carimage8?.length || 0; // นับจำนวนภาพจริง
                                                                        const displayedIndex = Math.min(index, imageCount8);
                                                                        const displayTotal = Math.max(imageCount8, imageCount8);
                                                                        return `${displayedIndex} / ${displayTotal}`;
                                                                    }, // ใช้เพื่อแสดงจำนวนภาพ
                                                                    toolbarRender: (
                                                                        _,
                                                                        {
                                                                            transform: { scale },
                                                                            actions: {
                                                                                onActive,
                                                                                onFlipY,
                                                                                onFlipX,
                                                                                onRotateLeft,
                                                                                onRotateRight,
                                                                                onZoomOut,
                                                                                onZoomIn,
                                                                                onReset,
                                                                            },
                                                                        },
                                                                    ) => (
                                                                        <>
                                                                            <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                                {carimage8?.map((e, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Image
                                                                                                width={auto}
                                                                                                key={index}
                                                                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                                                alt={`รูปภาพ ${index + 1}`}
                                                                                                style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                            />
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                            </Row>
                                                                            <Row style={{ zIndex: 2 }}>
                                                                                <Space size={12} className="toolbar-wrapper">
                                                                                    {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                                    <DownloadOutlined onClick={onDownload} />
                                                                                    <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                                    <SwapOutlined onClick={onFlipX} />
                                                                                    <RotateLeftOutlined onClick={onRotateLeft} />
                                                                                    <RotateRightOutlined onClick={onRotateRight} />
                                                                                    <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                                    <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                                    <UndoOutlined onClick={onReset} />
                                                                                </Space>
                                                                            </Row>
                                                                        </>
                                                                    ),
                                                                    onChange: (index) => {
                                                                        setCurrent(index);
                                                                    },
                                                                }}
                                                            >
                                                                <Row gutter={32} justify={'center'}>
                                                                    {carimage8?.map((e, index) => {
                                                                        return (
                                                                            <>
                                                                                <Image
                                                                                    width={150}
                                                                                    key={index}
                                                                                    onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                    src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                                    alt={`รูปภาพ ${index + 1}`}
                                                                                    style={{ cursor: 'pointer' }}
                                                                                />
                                                                            </>
                                                                        );
                                                                    })}
                                                                </Row>
                                                            </Image.PreviewGroup>
                                                        </Row>
                                                    </Space>
                                                </Row>
                                                <Divider />
                                                {
                                                    checkSendNEW ?
                                                        <>
                                                            <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้ </u> (ใหม่)</b></Row>
                                                            <Row gutter={32} justify={'center'}>
                                                                <Space direction="vertical" >
                                                                    <Row gutter={32} justify={'center'}>
                                                                        <Image.PreviewGroup
                                                                            preview={{
                                                                                countRender: (index, total) => {
                                                                                    const imageCount8 = carimagenew8?.length || 0; // นับจำนวนภาพจริง
                                                                                    const displayedIndex = Math.min(index, imageCount8);
                                                                                    const displayTotal = Math.max(imageCount8, imageCount8);
                                                                                    return `${displayedIndex} / ${displayTotal}`;
                                                                                }, // ใช้เพื่อแสดงจำนวนภาพ
                                                                                toolbarRender: (
                                                                                    _,
                                                                                    {
                                                                                        transform: { scale },
                                                                                        actions: {
                                                                                            onActive,
                                                                                            onFlipY,
                                                                                            onFlipX,
                                                                                            onRotateLeft,
                                                                                            onRotateRight,
                                                                                            onZoomOut,
                                                                                            onZoomIn,
                                                                                            onReset,
                                                                                        },
                                                                                    },
                                                                                ) => (
                                                                                    <>
                                                                                        <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                                            {carimagenew8?.map((e, index) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <Image
                                                                                                            width={auto}
                                                                                                            key={index}
                                                                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                            src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                                            alt={`รูปภาพ ${index + 1}`}
                                                                                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                                        />
                                                                                                    </>
                                                                                                );
                                                                                            })}
                                                                                        </Row>
                                                                                        <Row style={{ zIndex: 2 }}>
                                                                                            <Space size={12} className="toolbar-wrapper">
                                                                                                {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                            <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                                                <DownloadOutlined onClick={onDownload} />
                                                                                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                                                <SwapOutlined onClick={onFlipX} />
                                                                                                <RotateLeftOutlined onClick={onRotateLeft} />
                                                                                                <RotateRightOutlined onClick={onRotateRight} />
                                                                                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                                                <UndoOutlined onClick={onReset} />
                                                                                            </Space>
                                                                                        </Row>
                                                                                    </>
                                                                                ),
                                                                                onChange: (index) => {
                                                                                    setCurrent(index);
                                                                                },
                                                                            }}
                                                                        >
                                                                            <Row gutter={32} justify={'center'}>
                                                                                {carimagenew8?.map((e, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Image
                                                                                                width={150}
                                                                                                key={index}
                                                                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                                alt={`รูปภาพ ${index + 1}`}
                                                                                                style={{ cursor: 'pointer' }}
                                                                                            />
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                            </Row>
                                                                        </Image.PreviewGroup>
                                                                    </Row>
                                                                </Space>
                                                            </Row>
                                                            <Divider />
                                                        </>
                                                        : null
                                                }
                                                <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้ ไฟล์ PDF</u></b></Row>
                                                <Row gutter={32} justify={'center'}>
                                                    <Space direction="vertical" >
                                                        <Row gutter={32} justify={'center'}>
                                                            {imageBlobzz?.map((e, index) => {
                                                                var PreAaproveSplit = []
                                                                PreAaproveSplit = e.pathImage.split("/")
                                                                if (PreAaproveSplit[3] !== "13") {
                                                                    if (e.type === 7) {
                                                                        return (
                                                                            <>
                                                                                <object data={`${getImagess}/${e.pathImage}?token=${token}`} type="application/pdf" width="350px" height="450px">
                                                                                    <p>Alternative text - include a link <a href={`${getImagess}/${e.pathImage}?token=${token}`}>to the PDF!</a></p>
                                                                                </object>
                                                                                {/* <Image width={'100px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} /> */}
                                                                            </>
                                                                        );
                                                                    }
                                                                }
                                                                return null;
                                                            })}
                                                        </Row>
                                                    </Space>
                                                </Row>
                                                <Divider />
                                                {
                                                    checkSendNEW ?
                                                        <>
                                                            <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้ ไฟล์ PDF</u> (ใหม่)</b></Row>
                                                            <Row gutter={32} justify={'center'}>
                                                                <Space direction="vertical" >
                                                                    <Row gutter={32} justify={'center'}>
                                                                        {imageBlobzz?.map((e, index) => {
                                                                            var PreAaproveSplit = []
                                                                            PreAaproveSplit = e.pathImage.split("/")
                                                                            if (PreAaproveSplit[3] === "13") {
                                                                                if (e.type === 7) {
                                                                                    return (
                                                                                        <>
                                                                                            <object data={`${getImagessPre}/${e.pathImage}?token=${token}`} type="application/pdf" width="350px" height="450px">
                                                                                                <p>Alternative text - include a link <a href={`${getImagessPre}/${e.pathImage}?token=${token}`}>to the PDF!</a></p>
                                                                                            </object>
                                                                                            {/* <Image width={'100px'} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} /> */}
                                                                                        </>
                                                                                    );
                                                                                }
                                                                            }
                                                                            return null;
                                                                        })}
                                                                    </Row>
                                                                </Space>
                                                            </Row>
                                                            <Divider />
                                                        </>
                                                        : null
                                                }

                                                <Row justify={'left'}><b><u>เอกสารเพิ่มเติม</u></b></Row>
                                                <Row gutter={32} justify={'center'}>
                                                    <Space direction="vertical" >
                                                        <Row gutter={32} justify={'center'}>
                                                            <Image.PreviewGroup
                                                                preview={{
                                                                    countRender: (index, total) => {
                                                                        const imageCount12 = carimage12?.length || 0; // นับจำนวนภาพจริง
                                                                        const displayedIndex = Math.min(index, imageCount12);
                                                                        const displayTotal = Math.max(imageCount12, imageCount12);
                                                                        return `${displayedIndex} / ${displayTotal}`;
                                                                    }, // ใช้เพื่อแสดงจำนวนภาพ
                                                                    toolbarRender: (
                                                                        _,
                                                                        {
                                                                            transform: { scale },
                                                                            actions: {
                                                                                onActive,
                                                                                onFlipY,
                                                                                onFlipX,
                                                                                onRotateLeft,
                                                                                onRotateRight,
                                                                                onZoomOut,
                                                                                onZoomIn,
                                                                                onReset,
                                                                            },
                                                                        },
                                                                    ) => (
                                                                        <>
                                                                            <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                                {carimage12?.map((e, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Image
                                                                                                width={auto}
                                                                                                key={index}
                                                                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                                                alt={`รูปภาพ ${index + 1}`}
                                                                                                style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                            />
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                            </Row>
                                                                            <Row style={{ zIndex: 2 }}>
                                                                                <Space size={12} className="toolbar-wrapper">
                                                                                    {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                                    <DownloadOutlined onClick={onDownload} />
                                                                                    <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                                    <SwapOutlined onClick={onFlipX} />
                                                                                    <RotateLeftOutlined onClick={onRotateLeft} />
                                                                                    <RotateRightOutlined onClick={onRotateRight} />
                                                                                    <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                                    <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                                    <UndoOutlined onClick={onReset} />
                                                                                </Space>
                                                                            </Row>
                                                                        </>
                                                                    ),
                                                                    onChange: (index) => {
                                                                        setCurrent(index);
                                                                    },
                                                                }}
                                                            >
                                                                <Row gutter={32} justify={'center'}>
                                                                    {carimage12?.map((e, index) => {
                                                                        return (
                                                                            <>
                                                                                <Image
                                                                                    width={150}
                                                                                    key={index}
                                                                                    onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                    src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                                    alt={`รูปภาพ ${index + 1}`}
                                                                                    style={{ cursor: 'pointer' }}
                                                                                />
                                                                            </>
                                                                        );
                                                                    })}
                                                                </Row>
                                                            </Image.PreviewGroup>
                                                        </Row>
                                                    </Space>
                                                </Row>
                                                {
                                                    checkSendNEW ?
                                                        <>
                                                            <Divider />
                                                            <Row justify={'left'}><b><u>เอกสารเพิ่มเติม</u> (ใหม่)</b></Row>
                                                            <Row gutter={32} justify={'center'}>
                                                                <Space direction="vertical" >
                                                                    <Row gutter={32} justify={'center'}>
                                                                        <Image.PreviewGroup
                                                                            preview={{
                                                                                countRender: (index, total) => {
                                                                                    const imageCount12 = carimagenew12?.length || 0; // นับจำนวนภาพจริง
                                                                                    const displayedIndex = Math.min(index, imageCount12);
                                                                                    const displayTotal = Math.max(imageCount12, imageCount12);
                                                                                    return `${displayedIndex} / ${displayTotal}`;
                                                                                }, // ใช้เพื่อแสดงจำนวนภาพ
                                                                                toolbarRender: (
                                                                                    _,
                                                                                    {
                                                                                        transform: { scale },
                                                                                        actions: {
                                                                                            onActive,
                                                                                            onFlipY,
                                                                                            onFlipX,
                                                                                            onRotateLeft,
                                                                                            onRotateRight,
                                                                                            onZoomOut,
                                                                                            onZoomIn,
                                                                                            onReset,
                                                                                        },
                                                                                    },
                                                                                ) => (
                                                                                    <>
                                                                                        <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                                            {carimagenew12?.map((e, index) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <Image
                                                                                                            width={auto}
                                                                                                            key={index}
                                                                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                            src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                                            alt={`รูปภาพ ${index + 1}`}
                                                                                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                                        />
                                                                                                    </>
                                                                                                );
                                                                                            })}
                                                                                        </Row>
                                                                                        <Row style={{ zIndex: 2 }}>
                                                                                            <Space size={12} className="toolbar-wrapper">
                                                                                                {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                            <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                                                <DownloadOutlined onClick={onDownload} />
                                                                                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                                                <SwapOutlined onClick={onFlipX} />
                                                                                                <RotateLeftOutlined onClick={onRotateLeft} />
                                                                                                <RotateRightOutlined onClick={onRotateRight} />
                                                                                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                                                <UndoOutlined onClick={onReset} />
                                                                                            </Space>
                                                                                        </Row>
                                                                                    </>
                                                                                ),
                                                                                onChange: (index) => {
                                                                                    setCurrent(index);
                                                                                },
                                                                            }}
                                                                        >
                                                                            <Row gutter={32} justify={'center'}>
                                                                                {carimagenew12?.map((e, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Image
                                                                                                width={150}
                                                                                                key={index}
                                                                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                                alt={`รูปภาพ ${index + 1}`}
                                                                                                style={{ cursor: 'pointer' }}
                                                                                            />
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                            </Row>
                                                                        </Image.PreviewGroup>
                                                                    </Row>
                                                                </Space>
                                                            </Row>
                                                        </>
                                                        : null
                                                }
                                            </Card>
                                        </Row>
                                    </>
                                    :
                                    <>
                                        <Row gutter={32} justify={'center'}>
                                            <Card style={{ width: '1000px' }}>
                                                {
                                                    newDataLand?.map((item, index) => {
                                                        return renderLandBuildings({ item, index })
                                                    })
                                                }
                                                {
                                                    newDataLand?.map((item, index) => {
                                                        return renderTitleDeed({ item, index })
                                                    })
                                                }
                                                {
                                                    newDataLand?.map((item, index) => {
                                                        return renderDivision({ item, index })
                                                    })
                                                }
                                                {
                                                    newDataLand?.map((item, index) => {
                                                        return renderLawang({ item, index })
                                                    })
                                                }
                                                <Row justify={'left'}><b><u>รูปคนกู้</u></b></Row>
                                                <Row gutter={32} justify={'center'}>
                                                    <Space direction="vertical" >
                                                        <Row gutter={32} justify={'center'}>
                                                            <Image.PreviewGroup
                                                                preview={{
                                                                    countRender: (index, total) => {
                                                                        const imageCount4 = carimage4?.length || 0; // นับจำนวนภาพจริง
                                                                        const displayedIndex = Math.min(index, imageCount4);
                                                                        const displayTotal = Math.max(imageCount4, imageCount4);
                                                                        return `${displayedIndex} / ${displayTotal}`;
                                                                    }, // ใช้เพื่อแสดงจำนวนภาพ
                                                                    toolbarRender: (
                                                                        _,
                                                                        {
                                                                            transform: { scale },
                                                                            actions: {
                                                                                onActive,
                                                                                onFlipY,
                                                                                onFlipX,
                                                                                onRotateLeft,
                                                                                onRotateRight,
                                                                                onZoomOut,
                                                                                onZoomIn,
                                                                                onReset,
                                                                            },
                                                                        },
                                                                    ) => (
                                                                        <>
                                                                            <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                                {carimage4?.map((e, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Image
                                                                                                width={auto}
                                                                                                key={index}
                                                                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                                                alt={`รูปภาพ ${index + 1}`}
                                                                                                style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                            />
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                            </Row>
                                                                            <Row style={{ zIndex: 2 }}>
                                                                                <Space size={12} className="toolbar-wrapper">
                                                                                    {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                    <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                                    <DownloadOutlined onClick={onDownload} />
                                                                                    <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                                    <SwapOutlined onClick={onFlipX} />
                                                                                    <RotateLeftOutlined onClick={onRotateLeft} />
                                                                                    <RotateRightOutlined onClick={onRotateRight} />
                                                                                    <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                                    <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                                    <UndoOutlined onClick={onReset} />
                                                                                </Space>
                                                                            </Row>
                                                                        </>
                                                                    ),
                                                                    onChange: (index) => {
                                                                        setCurrent(index);
                                                                    },
                                                                }}
                                                            >
                                                                <Row gutter={32} justify={'center'}>
                                                                    {carimage4?.map((e, index) => {
                                                                        return (
                                                                            <>
                                                                                <Image
                                                                                    width={150}
                                                                                    key={index}
                                                                                    onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                    src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                                    alt={`รูปภาพ ${index + 1}`}
                                                                                    style={{ cursor: 'pointer' }}
                                                                                />
                                                                            </>
                                                                        );
                                                                    })}
                                                                </Row>
                                                            </Image.PreviewGroup>
                                                        </Row >
                                                    </Space>
                                                </Row>
                                                <Divider />
                                                {
                                                    checkSendNEW ?
                                                        <>
                                                            <Row justify={'left'}><b><u>รูปคนกู้</u> (ใหม่)</b></Row>
                                                            <Row gutter={32} justify={'center'}>
                                                                <Space direction="vertical" >
                                                                    <Row gutter={32} justify={'center'}>
                                                                        <Image.PreviewGroup
                                                                            preview={{
                                                                                countRender: (index, total) => {
                                                                                    const imageCount4 = carimagenew4?.length || 0; // นับจำนวนภาพจริง
                                                                                    const displayedIndex = Math.min(index, imageCount4);
                                                                                    const displayTotal = Math.max(imageCount4, imageCount4);
                                                                                    return `${displayedIndex} / ${displayTotal}`;
                                                                                }, // ใช้เพื่อแสดงจำนวนภาพ
                                                                                toolbarRender: (
                                                                                    _,
                                                                                    {
                                                                                        transform: { scale },
                                                                                        actions: {
                                                                                            onActive,
                                                                                            onFlipY,
                                                                                            onFlipX,
                                                                                            onRotateLeft,
                                                                                            onRotateRight,
                                                                                            onZoomOut,
                                                                                            onZoomIn,
                                                                                            onReset,
                                                                                        },
                                                                                    },
                                                                                ) => (
                                                                                    <>
                                                                                        <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                                            {carimagenew4?.map((e, index) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <Image
                                                                                                            width={auto}
                                                                                                            key={index}
                                                                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                            src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                                            alt={`รูปภาพ ${index + 1}`}
                                                                                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                                        />
                                                                                                    </>
                                                                                                );
                                                                                            })}
                                                                                        </Row>
                                                                                        <Row style={{ zIndex: 2 }}>
                                                                                            <Space size={12} className="toolbar-wrapper">
                                                                                                {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                                <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                                                <DownloadOutlined onClick={onDownload} />
                                                                                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                                                <SwapOutlined onClick={onFlipX} />
                                                                                                <RotateLeftOutlined onClick={onRotateLeft} />
                                                                                                <RotateRightOutlined onClick={onRotateRight} />
                                                                                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                                                <UndoOutlined onClick={onReset} />
                                                                                            </Space>
                                                                                        </Row>
                                                                                    </>
                                                                                ),
                                                                                onChange: (index) => {
                                                                                    setCurrent(index);
                                                                                },
                                                                            }}
                                                                        >
                                                                            <Row gutter={32} justify={'center'}>
                                                                                {carimagenew4?.map((e, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Image
                                                                                                width={150}
                                                                                                key={index}
                                                                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                                alt={`รูปภาพ ${index + 1}`}
                                                                                                style={{ cursor: 'pointer' }}
                                                                                            />
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                            </Row>
                                                                        </Image.PreviewGroup>
                                                                    </Row >
                                                                </Space>
                                                            </Row>
                                                            <Divider />
                                                        </>
                                                        : null
                                                }
                                                <Row justify={'left'}><b><u>รูปอาชีพ</u></b></Row>
                                                <Row gutter={32} justify={'center'}>
                                                    <Space direction="vertical" >
                                                        <Row gutter={32} justify={'center'}>
                                                            <Image.PreviewGroup
                                                                preview={{
                                                                    countRender: (index, total) => {
                                                                        const imageCount6 = carimage6?.length || 0; // นับจำนวนภาพจริง
                                                                        const displayedIndex = Math.min(index, imageCount6);
                                                                        const displayTotal = Math.max(imageCount6, imageCount6);
                                                                        return `${displayedIndex} / ${displayTotal}`;
                                                                    }, // ใช้เพื่อแสดงจำนวนภาพ
                                                                    toolbarRender: (
                                                                        _,
                                                                        {
                                                                            transform: { scale },
                                                                            actions: {
                                                                                onActive,
                                                                                onFlipY,
                                                                                onFlipX,
                                                                                onRotateLeft,
                                                                                onRotateRight,
                                                                                onZoomOut,
                                                                                onZoomIn,
                                                                                onReset,
                                                                            },
                                                                        },
                                                                    ) => (
                                                                        <>
                                                                            <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                                {carimage6?.map((e, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Image
                                                                                                width={auto}
                                                                                                key={index}
                                                                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                                                alt={`รูปภาพ ${index + 1}`}
                                                                                                style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                            />
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                            </Row>
                                                                            <Row style={{ zIndex: 2 }}>
                                                                                <Space size={12} className="toolbar-wrapper">
                                                                                    {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                                    <DownloadOutlined onClick={onDownload} />
                                                                                    <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                                    <SwapOutlined onClick={onFlipX} />
                                                                                    <RotateLeftOutlined onClick={onRotateLeft} />
                                                                                    <RotateRightOutlined onClick={onRotateRight} />
                                                                                    <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                                    <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                                    <UndoOutlined onClick={onReset} />
                                                                                </Space>
                                                                            </Row>
                                                                        </>
                                                                    ),
                                                                    onChange: (index) => {
                                                                        setCurrent(index);
                                                                    },
                                                                }}
                                                            >
                                                                <Row gutter={32} justify={'center'}>
                                                                    {carimage6?.map((e, index) => {
                                                                        return (
                                                                            <>
                                                                                <Image
                                                                                    width={150}
                                                                                    key={index}
                                                                                    onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                    src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                                    alt={`รูปภาพ ${index + 1}`}
                                                                                    style={{ cursor: 'pointer' }}
                                                                                />
                                                                            </>
                                                                        );
                                                                    })}
                                                                </Row>
                                                            </Image.PreviewGroup>
                                                        </Row >
                                                    </Space>
                                                </Row>
                                                <Divider />
                                                {
                                                    checkSendNEW ?
                                                        <>
                                                            <Row justify={'left'}><b><u>รูปอาชีพ</u> (ใหม่)</b></Row>
                                                            <Row gutter={32} justify={'center'}>
                                                                <Space direction="vertical" >
                                                                    <Row gutter={32} justify={'center'}>
                                                                        <Image.PreviewGroup
                                                                            preview={{
                                                                                countRender: (index, total) => {
                                                                                    const imageCount1 = carimagenew6?.length || 0; // นับจำนวนภาพจริง
                                                                                    const displayedIndex = Math.min(index, imageCount1);
                                                                                    const displayTotal = Math.max(imageCount1, imageCount1);
                                                                                    return `${displayedIndex} / ${displayTotal}`;
                                                                                }, // ใช้เพื่อแสดงจำนวนภาพ
                                                                                toolbarRender: (
                                                                                    _,
                                                                                    {
                                                                                        transform: { scale },
                                                                                        actions: {
                                                                                            onActive,
                                                                                            onFlipY,
                                                                                            onFlipX,
                                                                                            onRotateLeft,
                                                                                            onRotateRight,
                                                                                            onZoomOut,
                                                                                            onZoomIn,
                                                                                            onReset,
                                                                                        },
                                                                                    },
                                                                                ) => (
                                                                                    <>
                                                                                        <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                                            {carimagenew6?.map((e, index) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <Image
                                                                                                            width={auto}
                                                                                                            key={index}
                                                                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                            src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                                            alt={`รูปภาพ ${index + 1}`}
                                                                                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                                        />
                                                                                                    </>
                                                                                                );
                                                                                            })}
                                                                                        </Row>
                                                                                        <Row style={{ zIndex: 2 }}>
                                                                                            <Space size={12} className="toolbar-wrapper">
                                                                                                {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                            <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                                                <DownloadOutlined onClick={onDownload} />
                                                                                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                                                <SwapOutlined onClick={onFlipX} />
                                                                                                <RotateLeftOutlined onClick={onRotateLeft} />
                                                                                                <RotateRightOutlined onClick={onRotateRight} />
                                                                                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                                                <UndoOutlined onClick={onReset} />
                                                                                            </Space>
                                                                                        </Row>
                                                                                    </>
                                                                                ),
                                                                                onChange: (index) => {
                                                                                    setCurrent(index);
                                                                                },
                                                                            }}
                                                                        >
                                                                            <Row gutter={32} justify={'center'}>
                                                                                {carimagenew6?.map((e, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Image
                                                                                                width={150}
                                                                                                key={index}
                                                                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                                alt={`รูปภาพ ${index + 1}`}
                                                                                                style={{ cursor: 'pointer' }}
                                                                                            />
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                            </Row>
                                                                        </Image.PreviewGroup>
                                                                    </Row >
                                                                </Space>
                                                            </Row>
                                                            <Divider />
                                                        </>
                                                        : null
                                                }
                                                <Row justify={'left'}><b><u>รูปสเตทเมน</u></b></Row>
                                                <Row gutter={32} justify={'center'}>
                                                    <Space direction="vertical" >
                                                        <Row gutter={32} justify={'center'}>
                                                            <Image.PreviewGroup
                                                                preview={{
                                                                    countRender: (index, total) => {
                                                                        const imageCount8 = carimage8?.length || 0; // นับจำนวนภาพจริง
                                                                        const displayedIndex = Math.min(index, imageCount8);
                                                                        const displayTotal = Math.max(imageCount8, imageCount8);
                                                                        return `${displayedIndex} / ${displayTotal}`;
                                                                    }, // ใช้เพื่อแสดงจำนวนภาพ
                                                                    toolbarRender: (
                                                                        _,
                                                                        {
                                                                            transform: { scale },
                                                                            actions: {
                                                                                onActive,
                                                                                onFlipY,
                                                                                onFlipX,
                                                                                onRotateLeft,
                                                                                onRotateRight,
                                                                                onZoomOut,
                                                                                onZoomIn,
                                                                                onReset,
                                                                            },
                                                                        },
                                                                    ) => (
                                                                        <>
                                                                            <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                                {carimage8?.map((e, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Image
                                                                                                width={auto}
                                                                                                key={index}
                                                                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                                                alt={`รูปภาพ ${index + 1}`}
                                                                                                style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                            />
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                            </Row>
                                                                            <Row style={{ zIndex: 2 }}>
                                                                                <Space size={12} className="toolbar-wrapper">
                                                                                    {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                                    <DownloadOutlined onClick={onDownload} />
                                                                                    <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                                    <SwapOutlined onClick={onFlipX} />
                                                                                    <RotateLeftOutlined onClick={onRotateLeft} />
                                                                                    <RotateRightOutlined onClick={onRotateRight} />
                                                                                    <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                                    <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                                    <UndoOutlined onClick={onReset} />
                                                                                </Space>
                                                                            </Row>
                                                                        </>
                                                                    ),
                                                                    onChange: (index) => {
                                                                        setCurrent(index);
                                                                    },
                                                                }}
                                                            >
                                                                <Row gutter={32} justify={'center'}>
                                                                    {carimage8?.map((e, index) => {
                                                                        return (
                                                                            <>
                                                                                <Image
                                                                                    width={150}
                                                                                    key={index}
                                                                                    onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                    src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                                    alt={`รูปภาพ ${index + 1}`}
                                                                                    style={{ cursor: 'pointer' }}
                                                                                />
                                                                            </>
                                                                        );
                                                                    })}
                                                                </Row>
                                                            </Image.PreviewGroup>
                                                        </Row >
                                                    </Space>
                                                </Row>
                                                <Divider />
                                                {
                                                    checkSendNEW ?
                                                        <>
                                                            <Row justify={'left'}><b><u>รูปสเตทเมน</u> (ใหม่)</b></Row>
                                                            <Row gutter={32} justify={'center'}>
                                                                <Space direction="vertical" >
                                                                    <Row gutter={32} justify={'center'}>
                                                                        <Image.PreviewGroup
                                                                            preview={{
                                                                                countRender: (index, total) => {
                                                                                    const imageCount8 = carimagenew8?.length || 0; // นับจำนวนภาพจริง
                                                                                    const displayedIndex = Math.min(index, imageCount8);
                                                                                    const displayTotal = Math.max(imageCount8, imageCount8);
                                                                                    return `${displayedIndex} / ${displayTotal}`;
                                                                                }, // ใช้เพื่อแสดงจำนวนภาพ
                                                                                toolbarRender: (
                                                                                    _,
                                                                                    {
                                                                                        transform: { scale },
                                                                                        actions: {
                                                                                            onActive,
                                                                                            onFlipY,
                                                                                            onFlipX,
                                                                                            onRotateLeft,
                                                                                            onRotateRight,
                                                                                            onZoomOut,
                                                                                            onZoomIn,
                                                                                            onReset,
                                                                                        },
                                                                                    },
                                                                                ) => (
                                                                                    <>
                                                                                        <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                                            {carimagenew8?.map((e, index) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <Image
                                                                                                            width={auto}
                                                                                                            key={index}
                                                                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                            src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                                            alt={`รูปภาพ ${index + 1}`}
                                                                                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                                        />
                                                                                                    </>
                                                                                                );
                                                                                            })}
                                                                                        </Row>
                                                                                        <Row style={{ zIndex: 2 }}>
                                                                                            <Space size={12} className="toolbar-wrapper">
                                                                                                {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                            <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                                                <DownloadOutlined onClick={onDownload} />
                                                                                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                                                <SwapOutlined onClick={onFlipX} />
                                                                                                <RotateLeftOutlined onClick={onRotateLeft} />
                                                                                                <RotateRightOutlined onClick={onRotateRight} />
                                                                                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                                                <UndoOutlined onClick={onReset} />
                                                                                            </Space>
                                                                                        </Row>
                                                                                    </>
                                                                                ),
                                                                                onChange: (index) => {
                                                                                    setCurrent(index);
                                                                                },
                                                                            }}
                                                                        >
                                                                            <Row gutter={32} justify={'center'}>
                                                                                {carimagenew8?.map((e, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Image
                                                                                                width={150}
                                                                                                key={index}
                                                                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                                alt={`รูปภาพ ${index + 1}`}
                                                                                                style={{ cursor: 'pointer' }}
                                                                                            />
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                            </Row>
                                                                        </Image.PreviewGroup>
                                                                    </Row >
                                                                </Space>
                                                            </Row>
                                                            <Divider />
                                                        </>
                                                        : null
                                                }
                                                <Row justify={'left'}><b><u>เอกสารเพิ่มเติม</u></b></Row>
                                                <Row gutter={32} justify={'center'}>
                                                    <Space direction="vertical" >
                                                        <Row gutter={32} justify={'center'}>
                                                            <Image.PreviewGroup
                                                                preview={{
                                                                    countRender: (index, total) => {
                                                                        const imageCount12 = carimage9?.length || 0; // นับจำนวนภาพจริง
                                                                        const displayedIndex = Math.min(index, imageCount12);
                                                                        const displayTotal = Math.max(imageCount12, imageCount12);
                                                                        return `${displayedIndex} / ${displayTotal}`;
                                                                    }, // ใช้เพื่อแสดงจำนวนภาพ
                                                                    toolbarRender: (
                                                                        _,
                                                                        {
                                                                            transform: { scale },
                                                                            actions: {
                                                                                onActive,
                                                                                onFlipY,
                                                                                onFlipX,
                                                                                onRotateLeft,
                                                                                onRotateRight,
                                                                                onZoomOut,
                                                                                onZoomIn,
                                                                                onReset,
                                                                            },
                                                                        },
                                                                    ) => (
                                                                        <>
                                                                            <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                                {carimage9?.map((e, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Image
                                                                                                width={auto}
                                                                                                key={index}
                                                                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                                                alt={`รูปภาพ ${index + 1}`}
                                                                                                style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                            />
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                            </Row>
                                                                            <Row style={{ zIndex: 2 }}>
                                                                                <Space size={12} className="toolbar-wrapper">
                                                                                    {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                                    <DownloadOutlined onClick={onDownload} />
                                                                                    <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                                    <SwapOutlined onClick={onFlipX} />
                                                                                    <RotateLeftOutlined onClick={onRotateLeft} />
                                                                                    <RotateRightOutlined onClick={onRotateRight} />
                                                                                    <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                                    <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                                    <UndoOutlined onClick={onReset} />
                                                                                </Space>
                                                                            </Row>
                                                                        </>
                                                                    ),
                                                                    onChange: (index) => {
                                                                        setCurrent(index);
                                                                    },
                                                                }}
                                                            >
                                                                <Row gutter={32} justify={'center'}>
                                                                    {carimage9?.map((e, index) => {
                                                                        return (
                                                                            <>
                                                                                <Image
                                                                                    width={150}
                                                                                    key={index}
                                                                                    onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                    src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                                    alt={`รูปภาพ ${index + 1}`}
                                                                                    style={{ cursor: 'pointer' }}
                                                                                />
                                                                            </>
                                                                        );
                                                                    })}
                                                                </Row>
                                                            </Image.PreviewGroup>
                                                            <Image.PreviewGroup
                                                                preview={{
                                                                    countRender: (index, total) => {
                                                                        const imageCount12 = carimage12?.length || 0; // นับจำนวนภาพจริง
                                                                        const displayedIndex = Math.min(index, imageCount12);
                                                                        const displayTotal = Math.max(imageCount12, imageCount12);
                                                                        return `${displayedIndex} / ${displayTotal}`;
                                                                    }, // ใช้เพื่อแสดงจำนวนภาพ
                                                                    toolbarRender: (
                                                                        _,
                                                                        {
                                                                            transform: { scale },
                                                                            actions: {
                                                                                onActive,
                                                                                onFlipY,
                                                                                onFlipX,
                                                                                onRotateLeft,
                                                                                onRotateRight,
                                                                                onZoomOut,
                                                                                onZoomIn,
                                                                                onReset,
                                                                            },
                                                                        },
                                                                    ) => (
                                                                        <>
                                                                            <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                                {carimage12?.map((e, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Image
                                                                                                width={auto}
                                                                                                key={index}
                                                                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                                                alt={`รูปภาพ ${index + 1}`}
                                                                                                style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                            />
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                            </Row>
                                                                            <Row style={{ zIndex: 2 }}>
                                                                                <Space size={12} className="toolbar-wrapper">
                                                                                    {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                                    <DownloadOutlined onClick={onDownload} />
                                                                                    <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                                    <SwapOutlined onClick={onFlipX} />
                                                                                    <RotateLeftOutlined onClick={onRotateLeft} />
                                                                                    <RotateRightOutlined onClick={onRotateRight} />
                                                                                    <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                                    <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                                    <UndoOutlined onClick={onReset} />
                                                                                </Space>
                                                                            </Row>
                                                                        </>
                                                                    ),
                                                                    onChange: (index) => {
                                                                        setCurrent(index);
                                                                    },
                                                                }}
                                                            >
                                                                <Row gutter={32} justify={'center'}>
                                                                    {carimage12?.map((e, index) => {
                                                                        return (
                                                                            <>
                                                                                <Image
                                                                                    width={150}
                                                                                    key={index}
                                                                                    onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                    src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                                                    alt={`รูปภาพ ${index + 1}`}
                                                                                    style={{ cursor: 'pointer' }}
                                                                                />
                                                                            </>
                                                                        );
                                                                    })}
                                                                </Row>
                                                            </Image.PreviewGroup>
                                                        </Row>
                                                    </Space>
                                                </Row>
                                                {
                                                    checkSendNEW ?
                                                        <>
                                                            <Divider />
                                                            <Row justify={'left'}><b><u>เอกสารเพิ่มเติม</u> (ใหม่)</b></Row>
                                                            <Row gutter={32} justify={'center'}>
                                                                <Space direction="vertical" >
                                                                    <Row gutter={32} justify={'center'}>
                                                                        <Image.PreviewGroup
                                                                            preview={{
                                                                                countRender: (index, total) => {
                                                                                    const imageCount9 = carimagenew9?.length || 0; // นับจำนวนภาพจริง
                                                                                    const displayedIndex = Math.min(index, imageCount9);
                                                                                    const displayTotal = Math.max(imageCount9, imageCount9);
                                                                                    return `${displayedIndex} / ${displayTotal}`;
                                                                                }, // ใช้เพื่อแสดงจำนวนภาพ
                                                                                toolbarRender: (
                                                                                    _,
                                                                                    {
                                                                                        transform: { scale },
                                                                                        actions: {
                                                                                            onActive,
                                                                                            onFlipY,
                                                                                            onFlipX,
                                                                                            onRotateLeft,
                                                                                            onRotateRight,
                                                                                            onZoomOut,
                                                                                            onZoomIn,
                                                                                            onReset,
                                                                                        },
                                                                                    },
                                                                                ) => (
                                                                                    <>
                                                                                        <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                                            {carimagenew9?.map((e, index) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <Image
                                                                                                            width={auto}
                                                                                                            key={index}
                                                                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                            src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                                            alt={`รูปภาพ ${index + 1}`}
                                                                                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                                        />
                                                                                                    </>
                                                                                                );
                                                                                            })}
                                                                                        </Row>
                                                                                        <Row style={{ zIndex: 2 }}>
                                                                                            <Space size={12} className="toolbar-wrapper">
                                                                                                {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                            <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                                                <DownloadOutlined onClick={onDownload} />
                                                                                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                                                <SwapOutlined onClick={onFlipX} />
                                                                                                <RotateLeftOutlined onClick={onRotateLeft} />
                                                                                                <RotateRightOutlined onClick={onRotateRight} />
                                                                                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                                                <UndoOutlined onClick={onReset} />
                                                                                            </Space>
                                                                                        </Row>
                                                                                    </>
                                                                                ),
                                                                                onChange: (index) => {
                                                                                    setCurrent(index);
                                                                                },
                                                                            }}
                                                                        >
                                                                            <Row gutter={32} justify={'center'}>
                                                                                {carimagenew9?.map((e, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Image
                                                                                                width={150}
                                                                                                key={index}
                                                                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                                alt={`รูปภาพ ${index + 1}`}
                                                                                                style={{ cursor: 'pointer' }}
                                                                                            />
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                            </Row>
                                                                        </Image.PreviewGroup>
                                                                        <Image.PreviewGroup
                                                                            preview={{
                                                                                countRender: (index, total) => {
                                                                                    const imageCount12 = carimagenew12?.length || 0; // นับจำนวนภาพจริง
                                                                                    const displayedIndex = Math.min(index, imageCount12);
                                                                                    const displayTotal = Math.max(imageCount12, imageCount12);
                                                                                    return `${displayedIndex} / ${displayTotal}`;
                                                                                }, // ใช้เพื่อแสดงจำนวนภาพ
                                                                                toolbarRender: (
                                                                                    _,
                                                                                    {
                                                                                        transform: { scale },
                                                                                        actions: {
                                                                                            onActive,
                                                                                            onFlipY,
                                                                                            onFlipX,
                                                                                            onRotateLeft,
                                                                                            onRotateRight,
                                                                                            onZoomOut,
                                                                                            onZoomIn,
                                                                                            onReset,
                                                                                        },
                                                                                    },
                                                                                ) => (
                                                                                    <>
                                                                                        <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                                                                            {carimagenew12?.map((e, index) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <Image
                                                                                                            width={auto}
                                                                                                            key={index}
                                                                                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                            src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                                            alt={`รูปภาพ ${index + 1}`}
                                                                                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                                                                        />
                                                                                                    </>
                                                                                                );
                                                                                            })}
                                                                                        </Row>
                                                                                        <Row style={{ zIndex: 2 }}>
                                                                                            <Space size={12} className="toolbar-wrapper">
                                                                                                {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                                                            <RightOutlined onClick={() => onActive?.(1)} /> */}
                                                                                                <DownloadOutlined onClick={onDownload} />
                                                                                                <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                                                <SwapOutlined onClick={onFlipX} />
                                                                                                <RotateLeftOutlined onClick={onRotateLeft} />
                                                                                                <RotateRightOutlined onClick={onRotateRight} />
                                                                                                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                                                <UndoOutlined onClick={onReset} />
                                                                                            </Space>
                                                                                        </Row>
                                                                                    </>
                                                                                ),
                                                                                onChange: (index) => {
                                                                                    setCurrent(index);
                                                                                },
                                                                            }}
                                                                        >
                                                                            <Row gutter={32} justify={'center'}>
                                                                                {carimagenew12?.map((e, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Image
                                                                                                width={150}
                                                                                                key={index}
                                                                                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                                                                src={`${getImagessPre}/${e.pathImage}?token=${token}`}
                                                                                                alt={`รูปภาพ ${index + 1}`}
                                                                                                style={{ cursor: 'pointer' }}
                                                                                            />
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                            </Row>
                                                                        </Image.PreviewGroup>
                                                                    </Row>
                                                                </Space>
                                                            </Row>
                                                        </>
                                                        : null
                                                }
                                            </Card>
                                        </Row>
                                    </>
                            }
                        </Row>
                    </Form>
                </Row >
                <Divider style={{ margin: 5 }} />
                <Row gutter={32} justify={'center'}>
                    <Col className='gutter-row' span={6} style={{ textAlign: 'left' }}>
                    </Col>
                    <Col className='gutter-row' span={18} style={{ textAlign: 'right' }}>
                        <Space>
                            <Button
                                style={{ margin: '5px', background: '#597ef7', color: '#ffffff' }}
                                onClick={printdrescription}
                                icon={<PrinterOutlined />}
                            >
                                พิมพ์รายละเอียด
                            </Button>
                            <Button
                                style={{ margin: '5px', background: '#597ef7', color: '#ffffff' }}
                                onClick={printPreemptionCertificate}
                                icon={<PrinterOutlined />}
                            >
                                พิมพใบจอง
                            </Button>
                            <Button type='primary' style={{ margin: '5px', backgroundColor: 'green' }} onClick={showConfirmOK}>ผ่านการตรวจสอบเอกสาร</Button>
                            <Button type="primary" onClick={handleCancel} style={{ background: "grey" }}>ปิดหน้าต่าง</Button>
                        </Space>
                    </Col>
                </Row>
                {contextHolder}
            </Spin >
            {
                modalPreemptionCertificateNEW ?
                    <ModalPreemptionCertificateNEW
                        open={modalPreemptionCertificateNEW}
                        close={setModalPreemptionCertificateNEW}
                        notes={dataNote}
                        data={getData1}
                        guarantor={guarantorData}
                        typeLoan={dt?.car}
                        approval={approval}
                        dataCarLand={dataCarLand}
                        dataLoan={dataLoan}
                        dataOldloan={dataOldloan}
                        dataAP={ap}
                        monthlyPaymentCAL={monthlyPaymentCAL}
                        installmentWithInterestCAL={installmentWithInterestCAL}
                        getData={getData1}
                        mixBroker={mixBroker}
                    />
                    : null
            }
            {
                modalDrescriptionPrint ?
                    <ModalDrescriptionPrint
                        open={modalDrescriptionPrint}
                        close={setModalDrescriptionPrint}
                        getData1={getData1}
                        printData={guarantorData}
                        sendtypesDes={dt?.car}
                        //car
                        resultRateCarDATA={getData1?.cars?.apCarLoanDetails?.apInterest}
                        newLoanTermCarDATA={getData1?.cars?.apCarLoanDetails?.apMonthlyPayment}
                        newResultPriceCarDATA={getData1?.cars?.apCarLoanDetails?.apInstallmentWithInterest}
                        notes={dataNote} //ส่ง notes แบบเอาตัวล่าสุด
                        //car
                        arr={getData1?.cars?.apCarLoanDetails?.apMonthlyPayment}
                        interestt={getData1?.cars?.apCarLoanDetails?.apInterest}
                        installmentWithInterestt={getData1?.cars?.apCarLoanDetails?.apInstallmentWithInterest}
                        //land
                        loanTermDATA={getData1?.lands?.apLandLoanDetails?.apMonthlyPayment}
                        resultRateDATA={getData1?.lands?.apLandLoanDetails?.apInterest}
                        resultPriceDATA={getData1?.lands?.apLandLoanDetails?.apInstallmentWithInterest}
                    />
                    : null
            }
        </>
    )
}
