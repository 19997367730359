import React, { useState, useEffect } from 'react'
import { Button, Form, Select, Col, Row, Input, Card, Spin, Space, InputNumber, Divider } from 'antd';
import dayjs from 'dayjs';
import Currency from 'currency.js';
import '../../css/Media.css'
import { productLoanTypeLand } from "../../file_mid/all_options";

function LandLoan({ page, changepage, close }) {
  const currentTime = dayjs();
  const formattedTime = currentTime.format('YYYY-MM-DDTHH:mm:ssZ');
  const [form] = Form.useForm()
  const user = localStorage.getItem('username');
  const branch = localStorage.getItem('branch');
  const [loading, setLoading] = useState(false)
  const { Option } = Select;
  const addCustomers = localStorage.getItem('addCustomer')
  const addLandLoanDetailsRes = localStorage.getItem('addLandLoanDetailsRe')
  const addCheckGuas = localStorage.getItem('addCheckGua')
  const addLoans = localStorage.getItem('addLoan')
  const addLands = localStorage.getItem('addLand')
  const dataAddCustomer = JSON.parse(addCustomers)
  const dataAddLandLoanDetailsRes = JSON.parse(addLandLoanDetailsRes)
  const dataAddLoans = JSON.parse(addLoans)
  const dataAddLands = JSON.parse(addLands)
  const dataAddCheckGuas = JSON.parse(addCheckGuas)

  const [selectProduct, setSelectProduct] = useState({}) // 1 รถ , 2 ที่ดิน
  //////////////////// ที่ดิน /////////////////////////////
  const [dataPostLand, setDataPostLand] = useState({   // ที่ดิน
    // landTypeId: 2,
    // productTypeLand: 2,
    landInput: formattedTime,
    landDateStatus: formattedTime,
    rai: 0,
    workArea: 0,
    squareWaArea: 0,
    landPrice: 0,
    resultLandPrice: 0,
    landPriceStatus: false,
  })
  const [moneyLand, setMoneyLand] = useState({ interestRateLand: 1.1 }) //loan land
  const [moneyOldLand, setMoneyOldLand] = useState({}) //loanOld land

  const [provinces] = useState([]);
  const [district] = useState([]);


  useEffect(() => {
    setSelectProduct({ ...selectProduct, productTypeId: dataAddCustomer?.productTypeId })
    if (dataAddLoans) {
      setMoneyLand(dataAddLoans)
      form.setFieldsValue(
        {
          ...dataAddLands,
          ...dataAddLandLoanDetailsRes,
          ...dataAddLoans,

        }
      )
    } else {
      form.setFieldsValue(
        {
          ...dataAddLands,
          ...dataAddLandLoanDetailsRes,
        }
      )
    }
  }, [])

  useEffect(() => {
    if (moneyLand?.loanAmountLand && moneyLand?.loanLandTerm) {
      land_Calculate()
    }
  }, [moneyLand?.loanAmountLand, moneyLand?.loanLandTerm])

  const land_Calculate = () => {

    var interestRateLand = parseFloat(moneyLand?.interestRateLand) / 100 // อัตราดอก / 100
    var rate = (parseFloat(moneyLand?.loanAmountLand) * Currency(interestRateLand, { precision: 3 }).value) * parseInt(moneyLand?.loanLandTerm) // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
    var loanTerm = Math.ceil(Currency((rate + parseFloat(moneyLand?.loanAmountLand)) / parseInt(moneyLand?.loanLandTerm), { precision: 2 }).value) // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
    var resultRate = (loanTerm * parseInt(moneyLand?.loanLandTerm)) - parseInt(moneyLand?.loanAmountLand)
    var resultPrice = loanTerm * parseInt(moneyLand?.loanLandTerm)
    setMoneyLand({ ...moneyLand, monthlyPaymentLand: loanTerm, interestLand: resultRate, installmentWithInterestLand: resultPrice })
    form.setFieldsValue({
      monthlyPaymentLand: loanTerm,
      interestLand: resultRate,
      installmentWithInterestLand: resultPrice
    })
  }

  const handleSubmit = async () => {
    setLoading(true)
    if (moneyLand?.loanAmountLand > 999999999 || moneyLand?.interestRateLand > 100) {
      alert("กรุณาตรวจสอบยอดขอกู้อีกครั้ง")
    } else {
      if (branch === 'MIT' || branch === 'S4' || branch === 'S5' || branch === 'UD' || branch === 'LEX') {
        localStorage.setItem('addLoan', JSON.stringify(moneyLand))
        handleGo()
      }
    }
    setLoading(false)
  }

  // const currencyFormatOne = (amount) => {
  //   if (amount) {
  //     return Number(amount)
  //       .toFixed(2)
  //       .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  //   }
  // }

  const handleNumberLand = (e) => {
    setDataPostLand({ ...dataPostLand, numberLand: e.target.value })
  };

  //   // console.log("pvcodeS =", pvcodeS)
  //   // console.log("amcodeS =", amcodeS)
  //   // console.log("dataPostLand.numberLand =", dataPostLand.numberLand)
  //   //const tokens = "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjEiLCJyZWdpc3Rlcl90eXBlX3NlcSI6IjgiLCJ1c2VyX2dyb3VwX2RhdGFfbGlzdCI6IjEsMSwwLDAsMCwwLDAsMSIsInBlcnNvbmFsX2lkIjoiIiwianRpIjoiOTllYmE2OTAtMGFmOC00M2NhLTg2OTktYWUwZDI4N2ZjY2EzIiwiaWF0IjoiMDkvMjcvMjAyMyAwMzoyMDoxNSIsIm5iZiI6MTY5NTc4NDgxNSwiZXhwIjoxNjk1ODcxMjE1LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDozMDAwMS8iLCJhdWQiOiJMYW5kc01hcHMifQ.Lw3h4tjSbzjscWqkuA9FutwzXPY8UEb49D4ZdsdqKRfR0fwFunY7i4pg5Rdc70Vrw8jDR1qhtQLn5SEqxPguoQ"
  //   var provids = pvcodeS
  //   var amphurs = amcodeS
  //   var landNos = dataPostLand.numberLand
  //   var result = { pvcode: provids, amcode: amphurs, landNo: landNos }
  //   //console.log("result =", result)
  //   const headers = {
  //     "Content-Type": "application/json",
  //     // "Authorization": `Bearer ${tokens}`,
  //   }
  //   setLoading(true)
  //   await axios.post(AllData, result, { headers: headers })
  //     .then((response) => {
  //       if (response.data) {
  //         const squareWaArea = parseFloat(response.data.result[0].wa + "." + response.data.result[0].subwa)
  //         const landPricePerWa = parseInt(response.data.result[0].landprice.replace(/,/g, ""))
  //         const totalWa = (
  //           ((parseInt(response.data?.result[0].rai) * 400)) +
  //           ((parseInt(response.data?.result[0].ngan) * 100)) +
  //           parseFloat(response.data?.result[0].wa + "." + response.data?.result[0].subwa)
  //         )
  //         const totalPrice = Currency(totalWa * landPricePerWa).value
  //         // console.log("รวมตารางวาทั้งหมด =", totalPrice.toFixed(1));
  //         if (landPricePerWa > 0) {
  //           setDataPostLand({
  //             ...dataPostLand,
  //             parcellat: response.data?.result[0].parcellat,
  //             parcellon: response.data?.result[0].parcellon,
  //             numberLandLawang: response.data?.result[0].utm,
  //             rai: parseInt(response.data?.result[0].rai),
  //             workArea: parseInt(response.data?.result[0].ngan),
  //             squareWaArea: parseFloat(squareWaArea),
  //             landPrice: parseInt(response.data.result[0].landprice.replace(/,/g, "")) || 0,
  //             resultLandPrice: totalPrice || 0,
  //             landPriceStatus: true
  //           });
  //           form.setFieldsValue(
  //             {
  //               numberLandLawang: response.data?.result[0].utm,
  //               rai: parseInt(response.data?.result[0].rai),
  //               workArea: parseInt(response.data?.result[0].ngan),
  //               squareWaArea: squareWaArea,
  //               landPrice: currencyFormatOne(parseInt(response.data.result[0].landprice.replace(/,/g, ""))) || 0,
  //               resultLandPrice: currencyFormatOne(totalPrice) || 0,
  //             }
  //           )
  //         } else {
  //           setDataPostLand({
  //             ...dataPostLand,
  //             parcellat: response.data?.result[0].parcellat,
  //             parcellon: response.data?.result[0].parcellon,
  //             numberLandLawang: response.data?.result[0].utm,
  //             rai: parseInt(response.data?.result[0].rai),
  //             workArea: parseInt(response.data?.result[0].ngan),
  //             squareWaArea: parseFloat(squareWaArea),
  //             landPrice: 0,
  //             resultLandPrice: 0,
  //             landPriceStatus: false
  //           });
  //           form.setFieldsValue(
  //             {
  //               numberLandLawang: response.data?.result[0].utm,
  //               rai: parseInt(response.data?.result[0].rai),
  //               workArea: parseInt(response.data?.result[0].ngan),
  //               squareWaArea: squareWaArea,
  //               landPrice: 0,
  //               resultLandPrice: 0,
  //             }
  //           )
  //         }
  //         setLoading(false)
  //       } else {
  //         // console.log("DATA =", response.data)
  //       }
  //     })
  //     .catch((err) => {
  //       setDataPostLand({
  //         ...dataPostLand,
  //         parcellat: "-",
  //         parcellon: "-",
  //         numberLandLawang: "-",
  //         rai: 0,
  //         workArea: 0,
  //         squareWaArea: 0,
  //         landPrice: 0,
  //         resultLandPrice: 0,
  //         landPriceStatus: false
  //       });
  //       // errorLand()
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     })
  //   setLoading(false)
  // }

  /////////////////// เลือก 1 = นส.3 กับ 2 = นส.4 //////////////////////
  const handleProductLand = (value) => {
    if (value === 1) {
      setDataPostLand({
        ...dataPostLand,
        productTypeLand: value,
        landTypeId: value,
        numberLand: "-",
        numberLandLawang: "-",
        rai: 0,
        workArea: 0,
        squareWaArea: 0,
        landPrice: 0,
        resultLandPrice: 0,
        landPriceStatus: false,
      })
      form.setFieldsValue(
        {
          numberLand: "",
          numberLandLawang: "",
          rai: "",
          workArea: "",
          squareWaArea: "",
          landPrice: "",
          resultLandPrice: "",
          province: "",
          district: "",
        }
      )
    } else {
      setDataPostLand({
        ...dataPostLand,
        productTypeLand: value,
        landTypeId: value,
        numberLand: "-",
        numberLandLawang: "-",
        rai: 0,
        workArea: 0,
        squareWaArea: 0,
        landPrice: 0,
        resultLandPrice: 0,
        landPriceStatus: false,
      })
      form.setFieldsValue(
        {
          numberLand: "",
          numberLandLawang: "",
          rai: "",
          workArea: "",
          squareWaArea: "",
          landPrice: "",
          resultLandPrice: "",
          province: "",
          district: "",
        }
      )
    }
  }

  ////////////// ที่ดิน /////////////////////
  const handleChangeLandType = (value) => {
    if (value === 'ย้ายไฟแนนซ์' || value === 'Pre-Aaprove-ย้ายไฟแนนซ์') {
      setMoneyLand({ ...moneyLand, productLoanLandType: value, proposalBy: user, branch: branch, productType: "ที่ดิน", approvalStatus: 11 })
      setMoneyOldLand({ ...moneyOldLand, productLoanLandType: value })
      // setTypeMoneyLand({ ...typeMoney, productLoanLandType: 'ย้ายไฟแนนซ์', productType: "ที่ดิน" })
    }
    else {
      setMoneyLand({ ...moneyLand, productLoanLandType: value, proposalBy: user, branch: branch, productType: "ที่ดิน", approvalStatus: 11 })
      setMoneyOldLand("")
      form.setFieldsValue(
        {
          //loanAmountLand: '',
          loanLandTerm: '',
          //interestRateLand: '',
          interestLand: '',
          monthlyPaymentLand: '',
          installmentWithInterestLand: '',
          oldLandLoanAmount: '',
          oldLandMonthlyPayment: '',
          oldLandLoanTerm: '',
          oldLandKangPayment: '',
          oldLandKangPaymentTerm: '',
        }
      )
    }
  }

  const checkMoneyLandSmall = (value) => {
    setMoneyOldLand({ ...moneyOldLand, oldLandLoanAmount: parseInt(value.oldLandLoanAmount) })
  }

  const handleGo = () => {
    changepage(page + 1)
  }
  const handleBack = () => {
    if (dataAddCheckGuas === "ค้ำ-ไม่โอน" || dataAddCheckGuas === "ค้ำ-โอน") {
      changepage(page - 1)
    } else {
      changepage(page - 2)
    }

  }
  const handleClose = () => {
    close()
  }
  return (
    <Row justify={'center'}>
      <Card style={{ width: '100%' }}>
        <Spin spinning={loading} size='large' tip=" Loading... ">
          <Row style={{ textAlign: 'center', marginTop: '30px' }} >
            <Col span={24} style={{ fontSize: '30px' }} >รายละเอียด</Col>
          </Row>
          <Divider />
          <Form
            labelCol={{
              span: 12,
            }}
            wrapperCol={{
              span: 12,
            }}

            form={form}
            onFinish={handleSubmit}
            autoComplete="off"
            initialValues={{ remember: true }}
          >
            <Card style={{ width: '100%' }}>
              <Row justify={'center'}>
                <aside style={{ width: '100%', textAlign: 'center' }}>
                  <div>
                    <Form.Item label='ประเภทเอกสาร' name='productTypeLand'
                      style={{ margin: 0 }}
                      rules={[
                        {
                          required: true,
                          message: 'Please input !'
                        },]}>
                      <Select placeholder={'เลือก'}
                        disabled
                        style={{ height: '40px' }}
                        onChange={(value) => handleProductLand(value)}
                      >
                        <Option value={1}>นส.3 ก.</Option>
                        <Option value={2}>นส.4 จ.</Option>
                      </Select>
                    </Form.Item>

                    {dataPostLand?.landTypeId === 1 ?
                      <>
                        <Form.Item name='province' label='จังหวัด'
                          style={{ margin: 0 }}
                          rules={[
                            {
                              required: true,
                              message: 'Please input !'
                            },]}>
                          <Select
                            disabled
                            showSearch
                            loading={loading}
                            style={{ height: '40px' }}
                            name='province'
                            placeholder="จังหวัด"
                          // onChange={handleProvinceChange1}
                          >
                            {provinces?.result?.map((pro, index) => (
                              <Option key={pro.pvcode} value={pro.pvnamethai}>
                                {pro.pvnamethai}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item name='district' label='อำเภอ'
                          style={{ margin: 0 }}
                          rules={[
                            {
                              required: true,
                              message: 'Please input !'
                            },]}>
                          <Select
                            disabled
                            showSearch
                            style={{ height: '40px' }}
                            loading={loading}
                            name='district'
                            placeholder="อำเภอ"
                          // onChange={handleDistrictChange2}
                          >
                            {district?.map((dis, index) => (
                              <Option key={dis.amcode} value={dis.amnamethai}>
                                {dis.amnamethai}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item label='เลขที่โฉนด/เลขที่' name='numberLand'
                          style={{ margin: 0 }}
                          rules={[
                            {
                              required: true,
                              message: 'Please input !'
                            },]}>
                          <Input type='text'
                            disabled
                            style={{ height: '40px' }}
                            onChange={(e) => setDataPostLand({ ...dataPostLand, numberLand: e.target.value })}></Input>
                        </Form.Item>

                        <Form.Item label='พื้นที่ไร่' name='rai'
                          style={{ margin: 0 }}
                          rules={[
                            {
                              required: true,
                              message: 'Please input !'
                            },]}>
                          <Input type='number' suffix="ไร่"
                            disabled
                            style={{ height: '40px' }}
                            onChange={(e) => setDataPostLand({ ...dataPostLand, rai: parseInt(e.target.value) })}></Input>
                        </Form.Item>

                        <Form.Item label='พื้นที่งาน' name='workArea'
                          style={{ margin: 0 }}
                          rules={[
                            {
                              required: true,
                              message: 'Please input !'
                            },]}>
                          <Input type='number' suffix="งาน"
                            disabled
                            style={{ height: '40px' }}
                            onChange={(e) => setDataPostLand({ ...dataPostLand, workArea: parseInt(e.target.value) })}></Input>
                        </Form.Item>

                        <Form.Item label='พื้นที่ตารางวา' name='squareWaArea'
                          style={{ margin: 0 }}
                          rules={[
                            {
                              required: true,
                              message: 'Please input !'
                            },]}>
                          <Input type='number' suffix="ตารางวา"
                            disabled
                            style={{ height: '40px' }}
                            onChange={(e) => setDataPostLand({ ...dataPostLand, squareWaArea: parseFloat(e.target.value) })}></Input>
                        </Form.Item>

                        {/* </Col> */}
                      </>
                      :
                      <>
                        {/* <Col style={{ margin: 15 }}> */}
                        <Form.Item name='province' label='จังหวัด'
                          style={{ margin: 0 }}
                          rules={[
                            {
                              required: true,
                              message: 'Please input !'
                            },]}>
                          <Select
                            disabled
                            showSearch
                            style={{ height: '40px' }}
                            loading={loading}
                            name='province'
                            placeholder="จังหวัด"
                          // onChange={handleProvinceChange}
                          >
                            {provinces?.result?.map((pro, index) => (
                              <Option key={pro.pvcode} value={pro.pvnamethai}>
                                {pro.pvnamethai}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item name='district' label='อำเภอ'
                          style={{ margin: 0 }}
                          rules={[
                            {
                              required: true,
                              message: 'Please input !'
                            },]}>
                          <Select
                            disabled
                            showSearch
                            style={{ height: '40px' }}
                            loading={loading}
                            name='district'
                            placeholder="อำเภอ"
                          // onChange={handleDistrictChange}
                          >
                            {district?.map((dis, index) => (
                              <Option key={dis.amcode} value={dis.amnamethai}>
                                {dis.amnamethai}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item label='เลขโฉนดที่ดิน' name='numberLand'
                          style={{ margin: 0 }}
                          rules={[
                            {
                              required: true,
                              message: 'Please input !'
                            },]}>
                          <Input type='text'
                            disabled
                            style={{ height: '40px' }}
                            onChange={(e) => handleNumberLand(e)}></Input>
                        </Form.Item>

                        <Form.Item label='ตรวจสอบ' style={{ margin: 0 }}>
                          <Button type="primary" style={{ width: '100%' }} disabled >ตรวจสอบ</Button>
                        </Form.Item>
                        <Form.Item label='ระวาง' name='numberLandLawang'>
                          <Input type='text' disabled style={{ color: 'black', height: '40px' }}
                            onChange={(e) => setDataPostLand({ ...dataPostLand, numberLandLawang: e.target.value })}></Input>
                        </Form.Item>
                        <Form.Item label='พื้นที่ไร่' name='rai' style={{ margin: 0 }}>
                          <Input type='text' suffix="ไร่" disabled style={{ color: 'black', height: '40px' }}
                            onChange={(e) => setDataPostLand({ ...dataPostLand, rai: parseInt(e.target.value) })}></Input>
                        </Form.Item>
                        <Form.Item label='พื้นที่งาน' name='workArea' style={{ margin: 0 }}>
                          <Input type='text' suffix="งาน" disabled style={{ color: 'black', height: '40px' }}
                            onChange={(e) => setDataPostLand({ ...dataPostLand, workArea: parseInt(e.target.value) })}></Input>
                        </Form.Item>
                        <Form.Item label='พื้นที่ตารางวา' name='squareWaArea' style={{ margin: 0 }}>
                          <Input type='text' suffix="ตารางวา" disabled style={{ color: 'black', height: '40px' }}
                            onChange={(e) => setDataPostLand({ ...dataPostLand, squareWaArea: parseFloat(e.target.value) })}></Input>
                        </Form.Item>
                        <Form.Item label='ราคาประเมินที่ดิน' name='landPrice' style={{ margin: 0 }}>
                          <Input type='text' suffix="บาท / ตารางวา" disabled style={{ color: 'black', height: '40px' }}
                            onChange={(e) => setDataPostLand({ ...dataPostLand, landPrice: parseInt(e.target.value) })}></Input>
                        </Form.Item>
                        <Form.Item label='ราคารวม' name='resultLandPrice' style={{ margin: 0 }}>
                          <Input type='text' suffix="บาท" disabled style={{ color: 'black', height: '40px' }}
                            onChange={(e) => setDataPostLand({ ...dataPostLand, resultLandPrice: parseInt(e.target.value) })}></Input>
                        </Form.Item>
                        {/* </Col> */}
                      </>
                    }
                  </div>
                  <div>
                    <Form.Item label='ประเภทขอกู้ที่ดิน' name='productLoanLandType'
                      style={{ margin: 0 }}
                      rules={[
                        {
                          required: true,
                          message: 'Please input !',
                        },]}>
                      <Select
                        disabled
                        placeholder={'เลือก'}
                        style={{ height: '40px' }}
                        onChange={(value) => { handleChangeLandType(value) }}
                        options={productLoanTypeLand}
                      />
                    </Form.Item>

                    <Form.Item label='ยอดจัด' name='loanAmountLand' style={{ margin: 0 }}>

                      <InputNumber
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        size="large"
                        style={{ color: 'black', width: '100%', height: '40px' }}
                        onChange={(value) => setMoneyLand({ ...moneyLand, loanAmountLand: parseInt(value) })}
                      />
                    </Form.Item>
                    <Form.Item style={{ margin: 0 }}>
                      <span style={{ color: 'red' }}><u>ยอดจัดขั้นต่ำ 50,000 บาท</u></span>
                    </Form.Item>
                    <Form.Item label='อัตราดอกเบี้ย' name='interestRateLand' style={{ margin: 0 }}>
                      <Input defaultValue={1.1} type='number' suffix="% +VAT" disabled
                        style={{ color: 'black' }}
                        onChange={(e) => setMoneyLand({ ...moneyLand, interestRateLand: parseFloat(e.target.value) })} />
                    </Form.Item>
                    <Form.Item label='ค่าธรรมเนียม' name='fee' style={{ margin: 0 }}>
                      <Input defaultValue={3500} type='number' disabled suffix="บาท"
                        style={{ color: 'black', height: '40px' }}
                        onChange={(e) => setMoneyLand({ ...moneyLand, fee: parseFloat(e.target.value) })} />
                    </Form.Item>
                    <Form.Item label='จำนวนงวด' name='loanLandTerm'
                      style={{ margin: 0 }}
                      rules={[
                        {
                          required: true,
                          message: 'Please input !'
                        },]}>
                      <Select
                        style={{ textAlign: 'center', height: '40px' }}
                        placeholder={'เลือก'}
                        onChange={(value) => setMoneyLand({ ...moneyLand, loanLandTerm: value })}
                        label="จำนวนงวด"
                        options={[
                          { label: '12', value: 12 }, { label: 18, value: 18 }, { label: '24', value: 24 },
                          { label: '30', value: 30 }, { label: 36, value: 36 }, { label: '42', value: 42 },
                          { label: '48', value: 48 }, { label: 54, value: 54 }, { label: '60', value: 60 },
                          { label: '66', value: 66 }, { label: 72, value: 72 }, { label: '78', value: 78 },
                          { label: '84', value: 84 }, { label: 90, value: 90 }, { label: '96', value: 96 },
                          { label: '102', value: 102 }, { label: 108, value: 108 }, { label: '114', value: 114 },
                          { label: '120', value: 120 }
                        ]}
                      >
                      </Select>
                    </Form.Item>
                    <Form.Item label='งวดละ' name='monthlyPaymentLand'
                      style={{ margin: 0 }}
                      rules={[
                        {
                          required: true,
                          message: 'Please input !'
                        },]}>
                      <Input type='number' suffix="บาท" disabled
                        style={{ color: 'black', height: '40px' }}
                        onChange={(e) => setMoneyLand({ ...moneyLand, monthlyPaymentLand: parseInt(e.target.value) })} />
                    </Form.Item>
                    <Form.Item label='ดอกเบี้ย' name='interestLand'
                      style={{ margin: 0 }}
                      rules={[
                        {
                          required: true,
                          message: 'Please input !'
                        },]}>
                      <Input type='number' suffix="บาท" disabled
                        style={{ color: 'black', height: '40px' }}
                        onChange={(e) => setMoneyLand({ ...moneyLand, interestLand: parseInt(e.target.value) })} />
                    </Form.Item>
                    <Form.Item label='รวมราคา' name='installmentWithInterestLand' style={{ margin: 0 }}>
                      <Input disabled suffix="บาท"
                        style={{ color: 'black', height: '40px' }}
                        onChange={(e) => setMoneyLand({ ...moneyLand, installmentWithInterestLand: parseInt(e.target.value) })} />
                    </Form.Item>
                    {(moneyOldLand && moneyOldLand.productLoanLandType === 'ย้ายไฟแนนซ์') ||
                      (moneyOldLand && moneyOldLand.productLoanLandType === 'Pre-Aaprove-ย้ายไฟแนนซ์') ?
                      <>
                        <Form.Item label='ย้ายไฟแนนซ์จาก' name='issuno'
                          style={{ margin: 0 }}
                          rules={[
                            {
                              required: true,
                              message: 'Please input !'
                            },]}>
                          <Input type='text'
                            style={{ color: 'black', height: '40px' }}
                            onChange={(e) => setMoneyOldLand({ ...moneyOldLand, issuno: e.target.value.trim() })} />
                        </Form.Item>
                        <Form.Item label='ยอดปิดไฟแนนซ์' name='oldLandLoanAmount'
                          style={{ margin: 0 }}
                          rules={[
                            {
                              required: true,
                              message: 'Please input !'
                            },]}>
                          <InputNumber
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            size="large"
                            style={{ color: 'black', height: '40px' }}
                            onChange={(value) => checkMoneyLandSmall({ oldLandLoanAmount: value })}
                          />
                        </Form.Item>
                        <Form.Item label='ค่างวด/เดือน' name='oldLandMonthlyPayment'
                          style={{ margin: 0 }}
                          rules={[
                            {
                              required: true,
                              message: 'Please input !'
                            },]}>
                          <Input
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            size="large"
                            style={{ color: 'black', height: '40px' }}
                            onChange={(e) => setMoneyOldLand({ ...moneyOldLand, oldLandMonthlyPayment: parseInt(e.target.value) })}
                          />
                        </Form.Item>
                        <Form.Item label='จำนวนงวด' name='oldLandLoanTerm'
                          style={{ margin: 0 }}
                          rules={[
                            {
                              required: true,
                              message: 'Please input !'
                            },]}>
                          <Input
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            size="large"
                            style={{ color: 'black', height: '40px' }}
                            onChange={(e) => setMoneyOldLand({ ...moneyOldLand, oldLandLoanTerm: parseInt(e.target.value) })}
                          />
                        </Form.Item>
                      </>
                      : null}
                  </div>
                </aside>
              </Row>
            </Card>
            <Divider />
            <Col span={24} style={{ textAlign: 'center' }}>
              <Space>
                <Button type='primary' onClick={handleBack} >ย้อนกลับ</Button>
                <Button type='primary' danger onClick={handleClose} >ยกเลิก</Button>
                <Button type='primary' htmlType='submit' style={{ backgroundColor: "green" }} >ต่อไป</Button>
              </Space>
            </Col>
          </Form>
        </Spin >
      </Card >
      {/* {contextHolder} */}
    </Row >
  )
}

export default LandLoan