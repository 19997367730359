import React, { useState, useEffect, useRef } from "react";
import { HomeFilled, PhoneOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Table, Select, Spin, Tag, Space, Radio, Card, notification, Divider, DatePicker, Statistic, Modal } from 'antd';
import '../css/Media.css'
import axios from "axios";
import dayjs from "dayjs";
import { useDispatch } from 'react-redux';
import { addCustomer } from '../../redux/Customer';
import { addCar } from '../../redux/Car';
import { addAddress } from '../../redux/Address';
import { addPhone } from '../../redux/Phone';
import { addLoan } from '../../redux/Loan';
import { addOldLoan } from '../../redux/OldLoan';
import { addImage } from '../../redux/Image';
import { addLand } from '../../redux/Land';
import { addLoanLand } from '../../redux/LoanLand';
import { addOldLoanLand } from '../../redux/OldLoanLand';
import { addGuarantor } from '../../redux/Guarantor';
import { addCareerIncome } from '../../redux/CareerIncome';
import { addOccupation } from '../../redux/Occupation';
import { addEmail } from '../../redux/Email';
import { addSocial } from '../../redux/Social';
import Contno from './Modal/Contno';
import { alldataland1, getcontnoJojonoi, pgmaincountre, pgmaindata, pgmaindataksm, pgmaindatare, showre } from '../file_mid/all_api';
import { color } from "chart.js/helpers";
import { colorApprovalStatus } from "../file_mid/status_color";


const backc = {
    "ทั้งหมด": '#e6f4ff',
    "รอธุรการรับ": '#fffbe6',
    "ลูกค้าคอนเฟิร์ม": '#fffbe6',
    "รออนุมัติราคารถ": '#fff7e6',
    "รอวิเคราะห์": '#e6fffb',
    "ผ่านการวิเคราะห์": '#f0f5ff',
    "รออนุมัติ": '#fcffe6',
    "รอตรวจสอบเอกสาร": '#fcffe6',
    "อนุมัติ": '#f6ffed',
    "อนุมัติแบบมีเงื่อนไข": '#f6ffed',
    "โทรติด": '#fff0f6',
    "โทรไม่ติด(ติดต่อไม่ได้)": '#fff2e8',
    "ไม่อนุมัติ": '#fff1f0',
    "ไม่ผ่านการวิเคราะห์": '#fff1f0',
    "รออนุมัติราคาที่ดิน": '#fff1f0',
    "รอทำสัญญา": '#f6ffed',
    "ลูกค้าปฏิเสธ": '#fff1f0',
    "Reject": '#fff1f0',
    "ปัดตก": '#fff1f0',
    "รอพี่หนุ่มรับ": '#fffbe6',
    "รอเคาะราคา": '#e6fffb',
    "ขอเอกสารเพิ่ม": '#e6fffb',
    "เคาะราคาแล้ว": '#f6ffed',
    "ยื่นคำร้อง": '#fffbe6',
    "รับคำร้อง": '#e6fffb',
    "ปฏิเสธคำร้อง": '#fff1f0',
    "คำร้องไม่ถูกต้อง": '#fff1f0',
    "ลูกค้ายกเลิก": '#fff1f0',
}
const textc = {
    "ทั้งหมด": '#1677ff',
    "รอธุรการรับ": '#e39f23',
    "ลูกค้าคอนเฟิร์ม": '#e39f23',
    "รออนุมัติราคารถ": '#d86d38',
    "รอวิเคราะห์": '#3fbccf',
    "ผ่านการวิเคราะห์": '#384ecb',
    "รออนุมัติ": '#afc222',
    "รอตรวจสอบเอกสาร": '#afc222',
    "อนุมัติ": '#47ad55',
    "อนุมัติแบบมีเงื่อนไข": '#47ad55',
    "โทรติด": '#c73799',
    "โทรไม่ติด(ติดต่อไม่ได้)": '#e36729',
    "ไม่อนุมัติ": '#ff4d4f',
    "ไม่ผ่านการวิเคราะห์": '#ff4d4f',
    "รออนุมัติราคาที่ดิน": '#ff4d4f',
    "รอทำสัญญา": '#47ad55',
    "ลูกค้าปฏิเสธ": '#ff4d4f',
    "Reject": '#ff4d4f',
    "ปัดตก": '#ff4d4f',
    "รอพี่หนุ่มรับ": '#e39f23',
    "รอเคาะราคา": '#3fbccf',
    "ขอเอกสารเพิ่ม": '#3fbccf',
    "เคาะราคาแล้ว": '#47ad55',
    "ยื่นคำร้อง": '#e39f23',
    "รับคำร้อง": '#3fbccf',
    "ปฏิเสธคำร้อง": '#ff4d4f',
    "คำร้องไม่ถูกต้อง": '#ff4d4f',
    "ลูกค้ายกเลิก": '#ff4d4f',
}
const op =
    [
        {
            label: 'สัญญาทั้งหมด', value: 'สัญญาทั้งหมด',
        },
        {
            label: 'บัญชี 1', value: 'บัญชี 1',
        },
        {
            label: 'บัญชี 2', value: 'บัญชี 2',
        },
        {
            label: 'บัญชี 3', value: 'บัญชี 3',
        },
        {
            label: 'บัญชี 8', value: 'บัญชี 8',
        },
    ]

export default function MainOperators() {
    const dispatch = useDispatch()
    const nicknameSalcod = localStorage.getItem('nicknameSalcod');
    const username = localStorage.getItem('username');
    const nickname = localStorage.getItem('nickname');
    const token = localStorage.getItem('token');
    const branch = localStorage.getItem('branch');
    const { confirm } = Modal

    const [keyWord, setKeyWord] = useState("ทั้งหมด");
    const [api, contextHolder] = notification.useNotification();
    const [axiosData, setAxiosData] = useState([]);
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [arrayTable, setArrayTable] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [filteredData, setFilteredData] = useState([]);


    const [typestatus, setTypeStatus] = useState();

    const showDay1 = dayjs("2015-01-01")
    // const [showDay1, setShowDay1] = useState(dayjs()); // สมมติว่า showDay1 มีค่าเริ่มต้นเป็นวันนี้
    const datenow = dayjs()
    const today = datenow.format('YYYY-MM-DD');
    const [dataPost, setDataPost] = useState({ day1: "2015-01-01", day2: "" })
    const [loading, setLoading] = useState(false)
    const [dataCont, setDataCont] = useState()
    const [dataType, setDataType] = useState()
    const [contno, setCONTNO] = useState()
    const [modalCont, setModalCont] = useState(false)
    const [cData, setCData] = useState([]);
    const [text, setText] = useState('')
    const previousDay1 = useRef(dataPost.day1);
    const { Countdown } = Statistic;

    console.log("dataPost", dataPost)
    useEffect(() => {
        handleYG(dataPost)
    }, [dataPost?.day1]);

    const handleDateChange = (e) => {
        setSearchValue('')
        if (e) {
            const newDate = e.format('YYYY-MM-DD');
            console.log("newdata", newDate)
            setDataPost((prevDataPost) => {
                const updatedDataPost = { ...prevDataPost, day1: dataPost.day1, day2: newDate };
                setTimeout(() => {
                    handleYG(updatedDataPost);
                }, 500);
                return updatedDataPost;
            });

        }
    };

    // เดี๋ยว get API เส้นใหม่ คือ
    // รถ   uploadJojonoi
    // ที่ดิน  alldataland1

    const AlertModal = ({ des, searchValue }) => {
        console.log("des", des)
        console.log("searchValue", searchValue)
        var message
        if (dataPost?.day2 === '') {
            message = `${des ? des : ''}`;
        } else if (searchValue !== '' && des === 'ไม่พบข้อมูลที่ตรงกับการค้นหา กรุณาเช็ควันที่') {
            message = `${des ? des : ''}`;
        } else if (
            searchValue !== '' && des === 'ตอนนี้เลือกประเภทบัญชีที่ 1' ||
            searchValue !== '' && des === 'ตอนนี้เลือกประเภทบัญชีที่ 2' ||
            searchValue !== '' && des === 'ตอนนี้เลือกประเภทบัญชีที่ 3' ||
            searchValue !== '' && des === 'ตอนนี้เลือกประเภทบัญชีที่ 8'
        ) {
            message = `กรุณาเช็คประเภทบัญชีที่เลือก...? ${des ? des : ''}  แต่สัญญาที่กรอกคือ : ${searchValue ? searchValue : ''}`;
        }
        alert(message)

    }


    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        setSearchValue(inputValue);
        if (searchValue === '') {
            setArrayTable([]);
        }
    };

    const handleSearch = (e) => {
        // console.log("eeeee", e)
        console.log("searchValue", searchValue)
        if (searchValue === '') {
            setArrayTable([]);
        } else {
            const filteredData = axiosData.filter(item =>
                item.CONTNO.includes(searchValue)
            );
            // const prefix = searchValue.slice(0, 2);
            // const filteredData = axiosData.filter(item =>
            //     item.CONTNO.startsWith(prefix)
            // );
            console.log("filteredData", filteredData);
            // if (previousDay1 === 0) {
            //     const des = "ไม่พบข้อมูลที่ตรงกับการค้นหา กรุณาเช็ควันที่";
            //     AlertModal({ des, searchValue });
            // } else {
            if (typestatus === "สัญญาทั้งหมด") {
            } else if (typestatus === "บัญชี 1" && searchValue.startsWith("1-") !== filteredData[0]?.CONTNO?.startsWith("1-")) {
                console.log("1",)
                const des = "ตอนนี้เลือกประเภทบัญชีที่ 1"
                AlertModal({ des, searchValue })
            } else if (typestatus === "บัญชี 2" && searchValue.startsWith("2-") !== filteredData[0]?.CONTNO?.startsWith("2-")) {
                console.log("2",)
                const des = "ตอนนี้เลือกประเภทบัญชีที่ 2"
                AlertModal({ des, searchValue })
            } else if (typestatus === "บัญชี 3" && searchValue.startsWith("3-") !== filteredData[0]?.CONTNO?.startsWith("3-")) {
                console.log("3",)
                const des = "ตอนนี้เลือกประเภทบัญชีที่ 3"
                AlertModal({ des, searchValue })
            } else if (typestatus === "บัญชี 8" && searchValue.startsWith("8-") !== filteredData[0]?.CONTNO?.startsWith("8-")) {
                console.log("8")
                const des = "ตอนนี้เลือกประเภทบัญชีที่ 8"
                AlertModal({ des, searchValue })
            }
            setFilteredData(filteredData);
            setArrayTable(filteredData);
            // }
        }
    };

    const handleKeyPress = (e) => {
        console.log("wewe", e.target.value)
        if (e.key === 'Enter') {
            if (dataPost?.day2 === '') {
                const des = "ยังไม่ได้เลือกวันที่"
                AlertModal({ des })
            } else if (dataPost?.day2) {
                handleSearch(e);
                setText(e.target.value)
            }
        }
    };

    const handleSelectChange = (e) => {
        console.log("e", e)
        setSearchValue('')
        setTypeStatus(e)
        setLoading(true)
        let dataMapNo = [];
        // console.log("dataasasas", data)
        if (e === "สัญญาทั้งหมด") {
            data?.map((data, index) => {
                dataMapNo.push(data);
            })
        } else if (e === "บัญชี 1") {
            data?.map((data, index) => {
                if (data?.CONTNO?.startsWith("1-")) {
                    dataMapNo.push(data)
                    // console.log("dataMapNo", dataMapNo)
                }
            })
        } else if (e === "บัญชี 2") {
            data?.map((data, index) => {
                if (data?.CONTNO?.startsWith("2-")) {
                    dataMapNo.push(data)
                    // console.log("dataMapNo", dataMapNo)

                }
            })
        } else if (e === "บัญชี 3") {
            data?.map((data, index) => {
                if (data?.CONTNO?.startsWith("3-")) {
                    dataMapNo.push(data)
                    // console.log("dataMapNo", dataMapNo)
                }
            })
        } else if (e === "บัญชี 8") {
            data?.map((data, index) => {
                if (data?.CONTNO?.startsWith("8-")) {
                    dataMapNo.push(data)
                    // console.log("dataMapNo", dataMapNo)
                }
            })
        }
        // console.log("dataMapNo   ", dataMapNo);
        setAxiosData(dataMapNo)
        // นับจำนวนข้อมูลที่เกิดการกรอง
        const count = dataMapNo.length;
        console.log("Count:", count);
        setLoading(false)

    };

    const handleYG = async (updatedDataPost) => {
        console.log("updatedDataPost", updatedDataPost)
        let yourname = username + ',' + nickname + ',' + nicknameSalcod

        if (yourname) {
            if (branch === "MIT" || branch === "UD" || branch === "LEX" || branch === "S4" || branch === "S5" ) {
                setLoading(true)
                const tk = JSON.parse(token)
                const headers = {
                    "Authorization": `Bearer ${tk}`,
                    "Menu": JSON.stringify("3")
                }
                var mainData = { "day1": updatedDataPost?.day1, "day2": updatedDataPost?.day2, "SALCOD": yourname, "proposalBy": username }
                console.log("mainDataRE", mainData)
                await axios.post(showre, mainData, { headers: headers })
                    // await axios.post("http://localhost:8080/auth-mk/showre", mainData, { headers: headers })
                    .then(async (res) => {
                        console.log("res.data Conn", res.data)
                        res?.data?.map((des) => {
                            // console.log("des", des)
                            setCONTNO(des?.CONTNO)
                        })
                        // setArrayTable(res.data)
                        setAxiosData(res.data)
                        setData(res.data)
                        setLoading(false)
                    }).catch((err) => {
                        console.log(err)
                        alert("ไม่พบข้อมูล !!!")
                    })
            }
        } else {
            alert("ไม่พบข้อมูล !")
        }
    }

    const columns = [
        {
            title: "ลำดับ",
            dataIndex: "index",
            key: 'index',
            align: 'center',
            width: "10%",
            render: (text, object, index) => index + 1
        },
        {
            title: "เลขที่สัญญา",
            dataIndex: "CONTNO",
            key: 'CONTNO',
            align: 'center',
            width: "30%",
            render: (text, record) => (
                // console.log("record",record)
                <>{record.CONTNO}</>
            ),
        },
        {
            title: "approvalStatus",
            dataIndex: "approvalStatus",
            key: 'approvalStatus',
            align: 'center',
            width: "30%",
            render: (text, record) => (
                // console.log("record",record)
                <>{record.approvalStatus}</>
            ),
        },
        {
            title: "วันที่",
            dataIndex: "SDATE",
            width: "30%",
            align: 'center',
            render: (text, record) => (
                <Space size="middle">
                    {
                        record.SDATE !== "" ?
                            <Space>
                                <>{dayjs(record.SDATE).format("DD-MM-YYYY")}</>
                            </Space>
                            : null
                    }
                </Space>
            ),
            defaultSortOrder: 'descend',
        },
        {
            title: "ระยะเวลาของสัญญา",
            dataIndex: "CONTNO",
            key: 'CONTNO',
            align: 'center',
            width: "30%",
            render: (text, record) => {
                const currentDate = dayjs();
                const startDate = dayjs(record.SDATE);
                const daysPassed = currentDate.diff(startDate, 'day');
                const hoursPassed = currentDate.diff(startDate, 'hour') % 24;
                const minutesPassed = currentDate.diff(startDate, 'minute') % 60;
                const secondsPassed = currentDate.diff(startDate, 'second') % 60;

                return (
                    <>
                        <Countdown
                            title="ผ่านมาแล้ว"
                            value={dayjs().toDate()}
                            format={`${daysPassed} วัน ${hoursPassed} ชม. ${minutesPassed} นาที ${secondsPassed} วินาที`}
                        />
                    </>
                );
            },
        },
        {
            title: "Action",
            key: 'Action',
            align: 'center',
            width: "30%",
            render: (text, record) => {

                //* รี ต้อง 6 งวด / ปรับโครงสร้าง เกรด A-B ไม่ได้ *//
                const newOfferDate = dayjs(record.newOfferDate).startOf('day'); // วันที่ปลดรี แบบนับแต่วันที่
                const currentDate = dayjs().startOf('day'); // วันที่ปัจจุบัน แบบนับแต่วันที่

                let color
                if (record.approvalStatus === 6) {
                    color = "red"
                }
                if (record.approvalStatus === 5) {
                    color = "gold"
                }
                if (record.approvalStatus === 8) {
                    color = "red"
                }
                if (record.approvalStatus === 7) {
                    color = "geekblue"
                }
                if (record.approvalStatus === 2) {
                    color = "lime"
                }
                if (record.approvalStatus === 22) {
                    color = "red"
                }
                if (record.approvalStatus === 3) {
                    color = "green"
                }
                if (record.approvalStatus === 21) {
                    color = "green"
                }
                if (record.approvalStatus === 11) {
                    color = "gold"
                }

                return (
                    <>
                        {/* <Countdown title="สามารถ รี/ปรับ หลังจาก" value={dayjs(newOfferDate).toDate()} format="D วัน H ชม. m นาที s วินาที" /> */}
                        <Space>
                            <Button
                                type="primary"
                                style={{ backgroundColor: "blue" }}
                                icon={<SendOutlined />}
                                onClick={() => {
                                    setModalCont(true)
                                    setDataCont(record)
                                    setDataType("send")
                                }}
                            >
                                SEND
                            </Button>
                            <Button
                                type="primary"
                                style={{ backgroundColor: "green" }}
                                icon={<PhoneOutlined />}
                                onClick={() => {
                                    setModalCont(true)
                                    setDataCont(record)
                                    setDataType("โทร")
                                }}
                            >
                                ช่องทางติดต่อ
                            </Button>
                        </Space>

                    </>
                )
            }
        },
    ]

    return (
        <>
            <Card>
                <Row style={{ textAlign: 'center' }}>
                    <Col>
                        <Spin spinning={loading} size='large' tip=" Loading... ">

                            <Divider orientation={'center'} style={{ fontSize: '25px' }}><b><u>Operators</u></b></Divider>


                            {/* <Row>
                                <Radio.Group disabled value={keyWord} onChange={(e) => setKeyWord(e.target.value)} >
                                    {
                                        cData
                                            .sort((a, b) => {
                                                const order = [
                                                    "รอธุรการรับ",
                                                    "รออนุมัติราคารถ",
                                                    "รอวิเคราะห์",
                                                    "ผ่านการวิเคราะห์",
                                                    "ไม่ผ่านการวิเคราะห์",
                                                    "รออนุมัติ",
                                                    "อนุมัติ",
                                                    "อนุมัติแบบมีเงื่อนไข",
                                                    "ไม่อนุมัติ",
                                                    "รอตรวจสอบเอกสาร",
                                                    "รอทำสัญญา",
                                                    "ลูกค้าปฏิเสธ",
                                                    "Reject",
                                                    "ทั้งหมด",
                                                ];

                                                return order.indexOf(a.approvalStatus) - order.indexOf(b.approvalStatus);
                                            })
                                            .map((item) => (
                                                <Radio.Button
                                                    style={{
                                                        backgroundColor: backc[item.approvalStatus],
                                                        color: textc[item.approvalStatus]
                                                    }}
                                                    value={item.approvalStatus}
                                                >
                                                    {item.approvalStatus} <b>{item.totalCount}</b>
                                                </Radio.Button>
                                            ))}
                                </Radio.Group>
                            </Row> */}

                            <Row className="main" justify={'center'}>
                                <div>
                                    <Col span={24} style={{ marginTop: '10px' }}>
                                        <b>{"วันที่ : "}</b>
                                        <DatePicker format={'YYYY-MM-DD'}
                                            defaultValue={showDay1}
                                            style={{ height: '40px' }}
                                            onChange={(e) => {
                                                if (e) {

                                                    setDataPost({ ...dataPost, day1: e.format('YYYY-MM-DD') })
                                                }
                                            }} />
                                    </Col>
                                </div>
                                <div>
                                    <Col span={24} style={{ marginTop: '10px', marginLeft: '20px' }}>
                                        <b>{"ถึง : "}</b>
                                        <DatePicker format={'YYYY-MM-DD'}
                                            defaultValue={''}
                                            style={{ height: '40px' }}
                                            onChange={handleDateChange}
                                        // onChange={(e) => {
                                        //     if (e) {
                                        //         setDataPost({ ...dataPost, day2: e.format('YYYY-MM-DD') })
                                        //         setTimeout(() => {
                                        //             handleYG(e)
                                        //         }, 100)

                                        //     }
                                        // }} 
                                        />

                                        {/* <Button style={{ marginLeft: '15px' }} type="primary" onClick={() => loadData(username)}>ค้นหา</Button> */}
                                        {/* <Button style={{ marginLeft: '15px' }} type="primary" onClick={(e) => { handleYG(e) }}>ค้นหา</Button> */}
                                    </Col>
                                </div>
                            </Row>

                            <Row gutter={24} justify={'center'} style={{ margin: 10 }}>
                                <Col className='gutter-row' span={24} style={{ textAlign: 'center' }}>
                                    <Space>
                                        <b>{"เลือกประเภทบัญชี :"}</b>
                                        <Select
                                            defaultValue={"สัญญาทั้งหมด"}
                                            style={{ width: 200 }}
                                            onChange={(value) => handleSelectChange(value)}
                                            options={op}
                                        />
                                        <b>{"กรอกเลขสัญญา :"}</b>
                                        <Input
                                            style={{ width: '250px' }}
                                            placeholder="ค้นหา...."
                                            value={searchValue}
                                            onChange={handleInputChange}
                                            onKeyPress={handleKeyPress}
                                        />
                                        <Button style={{ marginLeft: '15px' }}
                                            type="primary"
                                            onClick={(e) => handleSearch(e)}
                                        >ค้นหา</Button>
                                    </Space>
                                </Col>
                            </Row>

                            <Row gutter={32}>
                                <Col span={24}>
                                    <Table
                                        rowKey={(record) => record.uid}
                                        dataSource={arrayTable}
                                        columns={columns}
                                        scroll={{
                                            y: 400,
                                        }}
                                    >
                                    </Table>
                                    <Divider />
                                </Col>
                            </Row>

                            <Col span={24} style={{ textAlign: 'center' }}>
                                <Space>
                                    <Button href="/" type="primary"><HomeFilled />หน้าหลัก</Button>
                                </Space>
                            </Col>
                        </Spin>
                    </Col>
                </Row>
            </Card >
            {
                modalCont ?
                    <Contno open={modalCont} close={setModalCont} text={text} data={dataCont} dataType={dataType} />
                    : null
            }
            {contextHolder}
        </>
    )
};





