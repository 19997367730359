const datalocat = [
  {
    key: 1,
    value: "MIT",
    label: "MIT",
    checkkey: 1,
  },
  {
    key: 2,
    value: "UD",
    label: "UD",
    checkkey: 1,
  },
  {
    key: 3,
    value: "LEX",
    label: "LEX",
    checkkey: 1,
  },
  {
    key: 4,
    value: "S4",
    label: "S4",
    checkkey: 1,
  },
  {
    key: 5,
    value: "S5",
    label: "S5",
    checkkey: 1,
  },
  {
    key: 6,
    value: "K1001",
    label: "ประตูน้ำ-ขอนแก่น",
    checkkey: 2,
  },
  {
    key: 7,
    value: "K1002",
    label: "รื่นรมย์",
    checkkey: 2,
  },
  {
    key: 8,
    value: "K1003",
    label: "บ้านไผ่",
    checkkey: 2,
  },
  {
    key: 9,
    value: "K1004",
    label: "กระนวน",
    checkkey: 2,
  },
  {
    key: 10,
    value: "K1005",
    label: "มหาสารคาม",
    checkkey: 2,
  },
  {
    key: 11,
    value: "K1006",
    label: "สกลนคร",
    checkkey: 2,
  },
  {
    key: 12,
    value: "K1007",
    label: "สุรินทร์",
    checkkey: 2,
  },
  {
    key: 13,
    value: "K1008",
    label: "โคราช-นครราชสีมา",
    checkkey: 2,
  },
  {
    key: 14,
    value: "K1009",
    label: "อุดรธานี",
    checkkey: 2,
  },
];

export default datalocat;
