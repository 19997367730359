import React, { useState, useEffect } from "react";
import { SearchOutlined, ReadOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Table, Divider, Space, Card, Radio, Tag, Select, DatePicker, Statistic, Typography, Pagination, Spin } from 'antd';
import dayjs from "dayjs";
import axios from "axios"
import MainEditDoc from "./modals/MainEditDoc";
import { maincdcount, maincdcountksm, maincddata, maincddataksm } from "../file_mid/all_api";
import ModalInfoADPGDC from "../file_mid/info/ModalInfoADPGDC";
import ModalNote from "../report_pg/Modal/Note";

const { Text } = Typography;
const { Countdown } = Statistic;

export default function Main_Documents() {

  const currentDateTime = dayjs()
  const currentDateDay2 = dayjs()
  const user = localStorage.getItem('username');
  const branch = localStorage.getItem('branch');
  const token = localStorage.getItem('token')
  const showDay1 = dayjs("2023-01-01")
  const [loading, setLoading] = useState(false)
  // const [api, contextHolder] = notification.useNotification();
  const [cData, setCData] = useState([]);
  const [axiosData, setAxiosData] = useState([]); // Table
  const [arrayTable, setArrayTable] = useState(); // Array Table
  const [getEditData, setGetEditData] = useState();
  const [isModalCheckDoc, setIsModalCheckDoc] = useState(false);
  const [dataPost, setDataPost] = useState({
    sec: 0,
    days1: dayjs("2023-01-01"),
    days2: currentDateDay2,
    approvalStatus: "ทั้งหมด"
  })
  const [testPage, setTestPage] = useState();
  const [ssPage, setSsPage] = useState({ numberPage: 1, pageSize: 10 });
  const [currentPage, setCurrentPage] = useState(1)
  const [query, setQuery] = useState("");
  const [isModalInfoADPGDC, setIsModalInfoADPGDC] = useState(false);
  const [isModalNote, setIsModalNote] = useState(false)
  const [isOpenNewNote, setIsOpenNewNote] = useState()

  useEffect(() => {
    loadData();
  }, [dataPost.approvalStatus, dataPost.days1, dataPost.days2, isModalCheckDoc, isModalInfoADPGDC, ssPage])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      console.log(query)
      searchLoadData(query)
    }, 1500)
    return () => clearTimeout(delayDebounceFn)
  }, [query])

  const searchLoadData = async (data) => {
    console.log("searchLoadData data", data)
    if (data !== "") {
      setCurrentPage(1)
      await loadData(data)
    }
  }
  const search = async (data) => {
    if (data.target.value !== "") {
      setQuery(data.target.value)
    } else {
      setSsPage({ numberPage: 1, pageSize: 10 })
      setCurrentPage(1)
      setDataPost({ sec: 0, days1: dayjs("2023-01-01"), days2: currentDateDay2, approvalStatus: "ทั้งหมด" })
      setQuery("")
    }
  };

  const loadCountData = async (data) => {
    const ez = data.filter(
      (item) =>
        item.ApprovalStatusId === dataPost?.approvalStatus
    );
    console.log("ez", ez)
    console.log("data", data)
    setTestPage(...ez)
    setCData(data)
  }

  const loadData = async (data) => {
    let mainData = {}
    setLoading(true)
    const tk = JSON.parse(token)
    const headers = {
      "Authorization": `Bearer ${tk}`,
      "Menu": JSON.stringify("21")
    }
    if (query !== "" && data !== "stop") {
      mainData = { reviewedBy: user, approvalStatus: dataPost.approvalStatus, numberPage: ssPage?.numberPage, pageSize: ssPage?.pageSize, keyword: query, day1: dataPost.days1, day2: dataPost.days2 }
    } else {
      if (data === "stop") {
        mainData = { reviewedBy: user, approvalStatus: "ทั้งหมด", numberPage: ssPage?.numberPage, pageSize: ssPage?.pageSize, day1: dataPost.days1, day2: dataPost.days2 }
      } else {
        mainData = { reviewedBy: user, approvalStatus: dataPost.approvalStatus, numberPage: ssPage?.numberPage, pageSize: ssPage?.pageSize, day1: dataPost.days1, day2: dataPost.days2 }
      }
    }
    console.log("mainData", mainData)
    if (branch === "MIT" || branch === "UD" || branch === "LEX" || branch === "S4" || branch === "S5" ) {
      await axios.post(maincddata, mainData, { headers: headers })
        .then(async (res) => {
          console.log("res.data", res.data)
          if (res.data !== null) {
            if (res.data.Countstatus) {
              await loadCountData(res.data.Countstatus);
            } else {
              setTestPage()
              setCData([])
            }
            if (res.data.ShowTablePG) {
              setArrayTable(res.data.ShowTablePG)
              setAxiosData(res.data.ShowTablePG)
            } else {
              setArrayTable([])
              setAxiosData([])
            }
          } else {
            setArrayTable([])
            setAxiosData([])
          }
          setLoading(false)
        }).catch((err) => console.log(err))
    } else {
      // await axios.post(`http://localhost:8080/mid/maincddataksm`, mainData, { headers: headers })
      await axios.post(maincddataksm, mainData, { headers: headers })
        .then(async (res) => {
          if (res.data !== null) {
            console.log("res.data KSM", res.data)
            if (res.data.Countstatus) {
              await loadCountData(res.data.Countstatus);
            }
            if (res.data.ShowTablePG) {
              setArrayTable(res.data.ShowTablePG)
              setAxiosData(res.data.ShowTablePG)
            } else {
              setArrayTable([])
              setAxiosData([])
            }
          } else {
            setArrayTable([])
            setAxiosData([])
          }
          setLoading(false)
        }).catch((err) => console.log(err))
    }
  }

  const onChangeKeyWord = (value) => {
    console.log("value", value)
    // setKeyWord(value)
    setQuery("")
    setDataPost({ ...dataPost, approvalStatus: value })
    setCurrentPage(1)
    setSsPage({ numberPage: 1, pageSize: 10 })
  }
  const onChangePagination = (e, pageSize) => {
    // console.log(e, pageSize)
    setCurrentPage(e)
    setSsPage({ numberPage: e, pageSize: pageSize })
  }

  // const search = (data) => {
  //   // อนาคต ควรหาใน database โดยตรง
  //   const greaterThanTen = axiosData.filter(
  //     (item) =>
  //       item.firstName.toLowerCase().includes(data.target.value) ||
  //       item.lastName.toLowerCase().includes(data.target.value) ||
  //       item.carPlateNumber.toLowerCase().includes(data.target.value)
  //   );
  //   setArrayTable(greaterThanTen)
  // };

  const backc = {
    "ทั้งหมด": '#e6f4ff',
    "รอตรวจสอบเอกสาร": '#fcffe6',
    "รอทำสัญญา": '#f6ffed',
  }
  const textc = {
    "ทั้งหมด": '#1677ff',
    "รอตรวจสอบเอกสาร": '#afc222',
    "รอทำสัญญา": '#47ad55',
  }

  const columns = [
    {
      fixed: true,
      title: "ลำดับ",
      dataIndex: "index",
      key: 'index',
      width: "5%",
      align: 'center',
      render: (text, object, index) => index + 1
    },
    {
      key: 'carInfo',
      title: "รายละเอียด",
      dataIndex: "carPlateNumber",
      width: "25%",
      align: 'center',
      render: (text, record) => (
        <>
          <div>{record.snam}{record.firstName} {record.lastName}</div>
          <div>
            {
              record.car === "car" ?
                <div>
                  <div>{record.carBrand} {record.carModel} ปีรถ {parseInt(record.carYear) + 543} เลขทะเบียน {record.carPlateNumber}</div>
                  <div>เสนอโดย <Space style={{ color: "red" }}><b>{record.proposalBy}</b></Space></div>
                  {
                    record.reviewedBy !== null ?
                      <>
                        <div>ธุรการที่ได้เคส <Space style={{ color: "red" }}><b>{record.reviewedBy}</b></Space></div>
                      </>
                      : null
                  }
                  {
                    record.ISSUNO ?
                      <div>มาจากสัญญา <Space style={{ color: "red" }}><b>{record.ISSUNO}</b></Space></div> : null
                  }
                </div>
                :
                <div>
                  <div>{record.carBrand} {record.carModel} ตารางวา {record.carYear} เลขโฉนด {record.carPlateNumber}</div>
                  <div>เสนอโดย <Space style={{ color: "red" }}><b>{record.proposalBy}</b></Space></div>
                  {
                    record.reviewedBy !== null ?
                      <>
                        <div>ผ่านการวิเคราะห์โดย <Space style={{ color: "red" }}><b>{record.reviewedBy}</b></Space></div>
                      </>
                      : null
                  }
                  {
                    record.ISSUNO ?
                      <div>มาจากสัญญา <Space style={{ color: "red" }}><b>{record.ISSUNO}</b></Space></div> : null
                  }
                </div>
            }
          </div >
        </>
      )
    },
    {
      key: 'productLoanType',
      title: "ประเภทขอกู้",
      dataIndex: "productLoanType",
      width: "15%",
      align: 'center',
      render: (text, record) => (
        <>
          <div>
            {
              record.car === "car" ?
                <Tag color="purple">รถ</Tag>
                :
                <Tag color="green">ที่ดิน</Tag>
            }
            {
              <Tag color="geekblue">{record.productLoanType}</Tag>
            }
          </div>
        </>
      ),
    },
    {
      key: 'loanAmount',
      title: "ยอดที่อนุมัติ",
      dataIndex: "loanAmount",
      width: "9%",
      align: 'center',
      render: (text, record) => (
        <Space size="middle">
          {
            <Space>
              <>{currencyFormatOne(record.loanAmount)}</>
            </Space>
          }
        </Space>
      ),
    },
    {
      title: "เรท",
      dataIndex: "carPrice",
      align: 'center',
      width: "9%",
      sorter: {
        compare: (a, b) => a.carPrice - b.carPrice,
        multiple: 2,
      },
      render: (text, record) => (
        <Space size="middle">
          {
            <Space>
              <>{currencyFormatOne(record.carPrice)}</>
            </Space>
          }
        </Space>
      ),
    },
    {
      title: "วันที่เสนอ",
      dataIndex: "carInput",
      align: 'center',
      width: "10%",
      // defaultSortOrder: 'descend',
      sorter: {
        compare: (a, b) => dayjs(a.carInput) - dayjs(b.carInput),
        multiple: 1,
      },
      render: (text, record) => (
        <>{dayjs(record.carInput).format("DD-MM-YYYY HH:mm")}</>
      ),

    },
    {
      title: "วันที่อนุมัติ",
      dataIndex: "approvalDate",
      width: "10%",
      align: 'center',
      sorter: {
        compare: (a, b) => dayjs(a.approvalDate) - dayjs(b.approvalDate),
        multiple: 2,
      },
      render: (text, record) => (
        <Space size="middle">
          {
            record.approvalDate !== "0001-01-01T00:00:00Z" ?
              <Space>
                <>{dayjs(record.approvalDate).format("DD-MM-YYYY HH:mm")}</>
              </Space>
              : <Text type="secondary">ยังไม่รับงาน</Text>
          }
        </Space>
      ),
    },
    {
      title: "ระยะเวลาที่คาดว่าจะเสร็จ",
      dataIndex: "",
      width: "10%",
      align: 'center',
      render: (text, record) => (
        <Space size="middle">
          {
            record.acceptDate !== "0001-01-01T00:00:00Z" ?
              <Space>
                <Countdown value={dayjs(record.acceptDate).add(3, 'hour')} />
              </Space>
              : <Text type="secondary">ยังไม่รับงาน</Text>
          }
        </Space>
      ),
    },
    {
      key: 'approvalStatus',
      title: "สถานะ",
      dataIndex: "approvalStatus",
      width: "10%",
      align: 'center',
      render: (text, record) => {
        let color
        let color2
        if (record.approvalStatus === 16) {
          color = "gold"
        }
        if (record.approvalStatus === 24) {
          color = "gold"
        }
        if (record.approvalStatus === 27) {
          color = "gold"
        }
        if (record.approvalStatus === 17) {
          color = "orange"
        }
        if (record.approvalStatus === 18) {
          color = "cyan"
        }
        if (record.approvalStatus === 19) {
          color = "geekblue"
        }
        if (record.approvalStatus === 2) {
          color = "lime"
        }
        if (record.approvalStatus === 26) {
          color = "lime"
        }
        if (record.approvalStatus === 3) {
          color = "green"
        }
        if (record.approvalStatus === 22) {
          color = "red"
        }
        if (record.approvalStatus === 23) {
          color = "red"
        }
        if (record.approvalStatus === 20) {
          color = "red"
        }
        if (record.approvalStatus === 25) {
          color = "red"
        }
        if (record.productLoanType === "ย้ายไฟแนนซ์") {
          color2 = "green"
        }
        if (record.productLoanType === "ถือเล่มมา") {
          color2 = "geekblue"
        }
        if (record.productLoanType === "ซื้อ-ขาย") {
          color2 = "geekblue"
        }
        if (record.productLoanType === "ปรับโครงสร้าง") {
          color2 = "geekblue"
        }
        if (record.productLoanType === "Pre-Aaprove") {
          color2 = "magenta"
        }
        if (record.productLoanType === "Pre-Aaprove-ถือโฉนด(ที่ดิน)") {
          color2 = "magenta"
        }
        if (record.productLoanType === "Pre-Aaprove-ถือเล่มมา") {
          color2 = "magenta"
        }
        if (record.productLoanType === "Pre-Aaprove-ซื้อ-ขาย") {
          color2 = "magenta"
        }
        if (record.productLoanType === "Pre-Aaprove-ย้ายไฟแนนซ์") {
          color2 = "magenta"
        }
        if (record.productLoanType === "Pre-Aaprove-รีโอน") {
          color2 = "magenta"
        }
        if (record.productLoanType === "เปลี่ยนสัญญา") {
          color2 = "magenta"
        }
        if (record.productLoanType === "รี+เปลี่ยนสัญญา") {
          color2 = "magenta"
        }
        if (record.productLoanType === "ปรับ+เปลี่ยนสัญญา") {
          color2 = "magenta"
        }
        if (record.productLoanType === "Pre-Aaprove-รี-Ploan") {
          color2 = "magenta"
        }
        if (record.productLoanType === "รี-3") {
          color2 = "magenta"
        }
        if (record.productLoanType === "Pre-Aaprove-ปรับโครงสร้าง") {
          color2 = "magenta"
        }
        if (record.productLoanType === "Pre-Aaprove-รี") {
          color2 = "magenta"
        }
        if (record.productLoanType === "Pre-Aaprove-รี") {
          color2 = "magenta"
        }
        if (record.productLoanType === "Pre-Aaprove-เปลี่ยนสัญญา") {
          color2 = "magenta"
        }
        if (record.productLoanType === "Pre-Aaprove-รี+เปลี่ยนสัญญา") {
          color2 = "magenta"
        }
        if (record.productLoanType === "Pre-Aaprove-Ploan") {
          color2 = "magenta"
        }
        if (record.productLoanType === "ที่ดิน+บ้าน") {
          color2 = "geekblue"
        }
        if (record.productLoanType === "ฝากโฉนด") {
          color2 = "geekblue"
        }
        if (record.productLoanType === "Pre-Aaprove-ฝากโฉนด") {
          color2 = "geekblue"
        }
        return (
          <>
            <Tag color={color}>
              {record.approvalStatusName}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Action",
      align: 'center',
      width: "7%",
      render: (record) => (
        <Space size="middle">
          {
            record.approvalStatus === 26 ?
              <Space>
                <Button
                  type="primary"
                  style={{ backgroundColor: "green" }}
                  onClick={() => {
                    setGetEditData(record)
                    setIsModalCheckDoc(true)
                  }}>ตรวจสอบ</Button>
              </Space>
              :
              <>
                <Button
                  onClick={() => {
                    setGetEditData(record)
                    setIsModalInfoADPGDC(true)
                  }}
                ><SearchOutlined /></Button>
              </>

          }
        </Space>
      ),
    },
    // {
    //   title: "หมายเหตุ",
    //   // dataIndex: "approvalStatus",
    //   align: 'center',
    //   width: "5%",
    //   render: (text, record) => (
    //     <Button
    //       onClick={() => {
    //         setIsOpenNewNote({carLandId:record.carId,typeLoan:record.car}) 
    //         setIsModalNote(true) 
    //       }}
    //     >
    //      <ReadOutlined />
    //     </Button>

    //   ),
    // },
  ];

  const currencyFormatOne = (amount) => {
    return Number(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  return (
    <>
      <Card
        bordered={false}
        style={{
          width: 'auto',
        }}
      >
        <Row style={{ textAlign: 'center' }}>
          <Col span={24}>
            <Spin spinning={loading} size='large' tip=" Loading... ">
              <div className='text-center'>
                <h2>ตรวจสอบเอกสาร</h2>
              </div>
              <Row gutter={32} justify={'center'}>
                <Row gutter={24} justify={'center'} style={{ margin: 10 }}>
                  <Col className='gutter-row' span={24} style={{ textAlign: 'center' }}>
                    <Space>
                      <>{"เลือกวันที่ต้องการแสดงเคส :"}</>
                      <DatePicker format={'YYYY-MM-DD'}
                        defaultValue={showDay1}
                        style={{ height: '40px' }}
                        onChange={(e) => {
                          if (e) {
                            setDataPost({ ...dataPost, days1: e.format('YYYY-MM-DD') })
                          }
                        }} />
                      <>{"ถึง"}</>
                      <DatePicker format={'YYYY-MM-DD'}
                        defaultValue={currentDateTime}
                        style={{ height: '40px' }}
                        onChange={(e) => {
                          if (e) {
                            setDataPost({ ...dataPost, days2: e.format('YYYY-MM-DD') })
                          }
                        }} />
                      {/* <span style={{ display: 'inline-block' }}>
                      <Button type="primary" onClick={(e) => { handleYG(e) }}>ตรวจสอบ</Button>
                    </span> */}
                    </Space>
                  </Col>
                </Row>

                <Row gutter={24} justify={'center'} style={{ margin: 10 }}>
                  <Col className='gutter-row' span={24} style={{ textAlign: 'center' }}>
                    <Space>
                      <>{"เลือกสถานะ :"}</>
                      <Select
                        // defaultValue={"ทั้งหมด"}
                        value={dataPost.approvalStatus}
                        style={{ width: 200 }}
                        // onChange={(value) => setDataPost({ ...dataPost, approvalStatus: value })}
                        onChange={(value) => onChangeKeyWord(value)}
                        options={[
                          {
                            label: 'ทั้งหมด', value: 'ทั้งหมด',
                          },
                          {
                            label: 'รอตรวจสอบเอกสาร', value: '26',
                          },
                          {
                            label: 'รอทำสัญญา', value: '27',
                          },
                        ]}
                      />
                    </Space>
                  </Col>
                </Row>
                <Divider style={{ margin: 5 }} />

                <Radio.Group disabled>
                  {
                    cData
                      .sort((a, b) => {
                        const order = [
                          "รอตรวจสอบเอกสาร",
                          "รอทำสัญญา",
                          "ทั้งหมด",
                        ];

                        return order.indexOf(a.ApprovalStatus) - order.indexOf(b.ApprovalStatus);
                      })
                      .map((item) => (
                        <Radio.Button
                          style={{
                            backgroundColor: backc[item.ApprovalStatus],
                            color: textc[item.ApprovalStatus]
                          }}
                          value={item.ApprovalStatus}
                        >
                          {item.ApprovalStatus} <b>{item.totalCount}</b>
                        </Radio.Button>
                      ))}
                </Radio.Group>
              </Row>
              <Row gutter={32} style={{ margin: 10 }}>
                <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                  <Input.Search
                    style={{ width: '250px' }}
                    placeholder="ค้นหา...."
                    onChange={search}
                    name="search"
                    id="search"
                    value={query}
                  />
                </Col>
              </Row>
              <Row gutter={32}>
                <Col span={24}>
                  <Table
                    pagination={false}
                    rowKey={(record) => record.uid}
                    dataSource={arrayTable}
                    columns={columns}
                    scroll={{
                      x: 1500,
                      y: 400,
                    }}
                    style={{ padding: 20 }}
                  >
                  </Table>
                  <Divider />
                  <Pagination
                    current={currentPage}
                    onChange={onChangePagination}
                    onShowSizeChange={onChangePagination}
                    defaultPageSize={10}
                    defaultCurrent={1}
                    total={testPage?.totalCount}
                  />
                  <Divider />
                </Col>
              </Row>
            </Spin>
          </Col>
        </Row>
      </Card>
      {
        isModalCheckDoc ?
          <MainEditDoc
            open={isModalCheckDoc}
            close={setIsModalCheckDoc}
            dataFromTable={getEditData}
          />
          : null
      }
      {
        isModalInfoADPGDC ?
          <ModalInfoADPGDC open={isModalInfoADPGDC} close={setIsModalInfoADPGDC} dataFromTable={getEditData} typeLoans={getEditData.car} checkPosi={"dc"} />
          : null
      }
      {
        isModalNote ?
          <ModalNote open={isModalNote} close={setIsModalNote} dataFromTable={isOpenNewNote} />
          : null
      }
      {/* {contextHolder} */}
    </>
  )
}

