import React, { useState, useEffect } from 'react'
import { Modal, Row, Col, Radio, Space, notification } from 'antd'
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { addLand } from '../../../redux/Land';
import { addCustomer } from '../../../redux/Customer';
import { addCar } from '../../../redux/Car';
import { addAddress } from '../../../redux/Address';
import { addPhone } from '../../../redux/Phone';
// import { addGuarantor } from '../../../redux/Guarantor';
import { addImage } from '../../../redux/Image';

import Customer from './AddToApprove/Customer';
import Address from './AddToApprove/Address';
import Contact from './AddToApprove/Contact';
import Career from './AddToApprove/Career';
import Conclusion from './AddToApprove/Conclusion';
import Product from './AddToApprove/Product';
import AddGuarantorsAd from './AddToApprove/AddGuarantorsAd';
import { findcarall, findlandall } from '../../file_mid/all_api';
import DetailBroker from './AddToApprove/DetailBroker';

function ModalPreApproveEDIT({ open, close, closeAll, dataFromTable }) {

    // const { customers } = useSelector((state) => ({ ...state }))
    // const { guarantor } = useSelector((state) => ({ ...state }))
    // const { cars } = useSelector((state) => ({ ...state }))
    const branch = localStorage.getItem('branch');
    const guarantor = useSelector(state => state.guarantor)
    const [dataMix] = useState({ carId: dataFromTable.carId, customerId: dataFromTable.customerId }); //มาจากรีดัค
    const [api, contextHolder] = notification.useNotification();
    const [getData, setGetData] = useState();
    const [count, setCount] = useState(1)
    const [resetPage, setResetPage] = useState({})

    const [checkPloan, setCheckPloan] = useState(true)
    const [dataBroker, setDataBroker] = useState([])
    const dispatch = useDispatch()

    useEffect(() => {
        if (guarantor.data.typeLoan === "car") {
            loadDataCar()
        } else if (guarantor.data.typeLoan === "land") {
            loadDataLand()
        }
    }, [resetPage, count])

    useEffect(() => {
        if (guarantor.data.typeLoan === "car") {
            if (branch === "MIT" || branch === "UD" || branch === "LEX" || branch === "S4" || branch === "S5" ) {

            } else {
                if (getData?.carLoanDetails?.productType === "p-loan") {
                    loadBroker()
                    setCheckPloan(false)
                } else {
                    setDataBroker([])
                    setCheckPloan(true)
                }
            }
        } else if (guarantor.data.typeLoan === "land") {
        }
    }, [getData])

    const loadBroker = async () => {
        const result = { loanId: getData?.carLoanDetails?.loanId }
        // setLoading(true)
        await axios.post('https://sendcase-mk-d2flonv3.an.gateway.dev/api-broker/detailbroker', result)
            .then((res) => {
                if (res.data) {
                    console.log("Broker", res.data)
                    setDataBroker(res.data)
                }
            })
            .catch((err) => console.log(err))
        // setLoading(false)
    }

    const loadDataCar = async () => {
        await axios.post(findcarall, dataMix)
            .then(res => {
                if (res.status === 200) {
                    setGetData(res.data)
                    dispatch(addCustomer(res.data.customerId))
                    dispatch(addCar(res.data.carLoanDetails.cars.carId))
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }
    const loadDataLand = async () => {
        await axios.post(findlandall, dataMix)
            .then(res => {
                if (res.status === 200) {
                    console.log("res", res.data)
                    setGetData(res.data)
                    dispatch(addCustomer(res.data.customerId))
                    dispatch(addLand(res.data.landLoanDetails.land.landId))
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    const Onclose = () => {
        close(false);
        dispatch(addCustomer())
        dispatch(addCar())
        dispatch(addLand())
        dispatch(addAddress())
        dispatch(addPhone())
        // dispatch(addGuarantor())
        dispatch(addImage())
    };

    const NotiSuccess = (placement) => {
        api.success({
            message: <b>แก้ไขข้อมูลสำเร็จ</b>,
            //   description:
            //     'สถานะถูกเปลี่ยนจาก รอธุการรับ เป็น รอพิจารณาแล้ว',
            placement,
        });
    }
    const NotiError = (placement) => {
        api.error({
            message: <b>แก้ไขข้อมูลไม่สำเร็จ</b>,
            //   description:
            //     'สถานะถูกเปลี่ยนจาก รอธุการรับ เป็น รอพิจารณาแล้ว',
            placement,
        });
    }
    const NotiSuccessAdd = (placement) => {
        api.success({
            message: <b>เพิ่มข้อมูลสำเร็จ</b>,
            //   description:
            //     'สถานะถูกเปลี่ยนจาก รอธุการรับ เป็น รอพิจารณาแล้ว',
            placement,
        });
    }
    const NotiErrorAdd = (placement) => {
        api.error({
            message: <b>เพิ่มข้อมูลไม่สำเร็จ</b>,
            //   description:
            //     'สถานะถูกเปลี่ยนจาก รอธุการรับ เป็น รอพิจารณาแล้ว',
            placement,
        });
    }
    const NotiNotFond = (placement) => {
        api.error({
            message: <b>ไม่พบข้อมูลผู้ค้ำประกัน</b>,
            placement,
        });
    }
    const NotiDelete = (placement) => {
        api.success({
            message: <b>ลบข้อมูลสำเร็จ</b>,
            //   description:
            //     'สถานะถูกเปลี่ยนจาก รอธุการรับ เป็น รอพิจารณาแล้ว',
            placement,
        });
    }
    const DeleteError = (placement) => {
        api.error({
            message: <b>ลบข้อมูลไม่สำเร็จ</b>,
            placement,
        });
    }
    const CheckAddressER = (placement) => {
        api.error({
            message: <b>เพิ่มข้อมูลไม่สำเร็จ</b>,
            description: 'กรุณาเพิ่มที่อยู่ อย่างน้อย 1 ที่',
            placement,
        });
    }

    return (
        <div>
            <Modal title="เพิ่ม/แก้ไขข้อมูล" open={open} onCancel={Onclose} width={1300} footer={null}>
                <Space
                    direction="vertical"
                    size="middle"
                    style={{
                        display: 'flex',
                    }}>


                    {
                        branch === "MIT" || branch === "UD" || branch === "LEX" || branch === "S4" || branch === "S5"  ?
                            <>
                                <Row>
                                    <Col span={24} style={{ textAlign: 'center' }}>
                                        <Radio.Group value={count} onChange={(e) => setCount(e.target.value)}>
                                            <Radio.Button value={1}>ข้อมูลส่วนตัว</Radio.Button>
                                            <Radio.Button value={2}>รายละเอียดที่อยู่</Radio.Button>
                                            <Radio.Button value={3}>รายละเอียดสินค้า</Radio.Button>
                                            <Radio.Button value={4}>ช่องทางการติดต่อ</Radio.Button>
                                            <Radio.Button value={5}>รายได้และอาชีพ</Radio.Button>
                                            <Radio.Button value={6}>คนค้ำประกัน</Radio.Button>
                                            {/* <Radio.Button value={17}>ค่าแนะนำ</Radio.Button> */}
                                            <Radio.Button value={7}>รายละเอียด</Radio.Button>
                                        </Radio.Group>
                                    </Col>
                                </Row>
                            </>
                            :
                            <>
                                <Row>
                                    <Col span={24} style={{ textAlign: 'center' }}>
                                        <Radio.Group value={count} onChange={(e) => setCount(e.target.value)}>
                                            <Radio.Button value={11}>ข้อมูลส่วนตัว</Radio.Button>
                                            <Radio.Button value={12}>รายละเอียดที่อยู่</Radio.Button>
                                            <Radio.Button value={13}>รายละเอียดสินค้า</Radio.Button>
                                            <Radio.Button value={14}>ช่องทางการติดต่อ</Radio.Button>
                                            <Radio.Button value={15}>รายได้และอาชีพ</Radio.Button>
                                            <Radio.Button value={16}>คนค้ำประกัน</Radio.Button>
                                            <Radio.Button value={17} disabled={checkPloan} >ค่าแนะนำ</Radio.Button>
                                            {/* <Radio.Button value={17} >ค่าแนะนำ</Radio.Button> */}
                                            <Radio.Button value={18}>รายละเอียด</Radio.Button>
                                        </Radio.Group>
                                    </Col>
                                </Row>
                            </>
                    }


                    {/* <Row>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Radio.Group value={count} onChange={(e) => setCount(e.target.value)}>
                                <Radio.Button value={1}>ข้อมูลส่วนตัว</Radio.Button>
                                <Radio.Button value={2}>รายละเอียดที่อยู่</Radio.Button>
                                <Radio.Button value={3}>รายละเอียดสินค้า</Radio.Button>
                                <Radio.Button value={4}>ช่องทางการติดต่อ</Radio.Button>
                                <Radio.Button value={5}>รายได้และอาชีพ</Radio.Button>
                                <Radio.Button value={6}>คนค้ำประกัน</Radio.Button>
                                <Radio.Button value={7}>รายละเอียด</Radio.Button>
                            </Radio.Group>
                        </Col>
                    </Row> */}
                    {count === 1 && (<Customer getData={getData} close={Onclose} page={count} changepage={setCount} sendback={setResetPage} notification={NotiSuccess} notification2={NotiError} />)}
                    {count === 2 && (<Address getData={getData} close={Onclose} page={count} changepage={setCount} sendback={setResetPage} notification={NotiSuccess} notification2={NotiError} notification3={NotiSuccessAdd} notification4={NotiErrorAdd} notification5={NotiDelete} />)}
                    {count === 3 && (<Product getData={getData} close={Onclose} page={count} changepage={setCount} sendback={setResetPage} deleteData={NotiDelete} editData={NotiSuccess} addImg={NotiSuccessAdd} DeleteError={DeleteError} />)}
                    {count === 4 && (<Contact dataMix={dataMix} getData={getData} close={Onclose} page={count} changepage={setCount} sendback={setResetPage} notification={NotiSuccess} notification2={NotiError} notification3={NotiSuccessAdd} notification4={NotiErrorAdd} notification5={NotiDelete} />)}
                    {count === 5 && (<Career datashow={getData.ojs} getData={getData} close={Onclose} page={count} changepage={setCount} sendback={setResetPage} />)}
                    {count === 6 && (<AddGuarantorsAd getData={getData} close={Onclose} page={count} sendback={setResetPage} changepage={setCount} />)}
                    {count === 7 && (<Conclusion getData1={getData} close={Onclose} sendback={setResetPage} closeAll={closeAll} page={count} changepage={setCount} notification={NotiSuccess} notification2={NotiError} notification3={NotiSuccessAdd} notification4={NotiErrorAdd} notification5={NotiDelete} NotiNotFond={NotiNotFond} addrER={CheckAddressER} />)}

                    {/* KSM */}
                    {count === 11 && (<Customer getData={getData} close={Onclose} page={count} changepage={setCount} sendback={setResetPage} notification={NotiSuccess} notification2={NotiError} />)}
                    {count === 12 && (<Address getData={getData} close={Onclose} page={count} changepage={setCount} sendback={setResetPage} notification={NotiSuccess} notification2={NotiError} notification3={NotiSuccessAdd} notification4={NotiErrorAdd} notification5={NotiDelete} />)}
                    {count === 13 && (<Product getData={getData} close={Onclose} page={count} changepage={setCount} sendback={setResetPage} deleteData={NotiDelete} editData={NotiSuccess} addImg={NotiSuccessAdd} DeleteError={DeleteError} />)}
                    {count === 14 && (<Contact dataMix={dataMix} getData={getData} close={Onclose} page={count} changepage={setCount} sendback={setResetPage} notification={NotiSuccess} notification2={NotiError} notification3={NotiSuccessAdd} notification4={NotiErrorAdd} notification5={NotiDelete} />)}
                    {count === 15 && (<Career datashow={getData.ojs} getData={getData} close={Onclose} page={count} changepage={setCount} sendback={setResetPage} />)}
                    {count === 16 && (<AddGuarantorsAd getData={getData} close={Onclose} page={count} sendback={setResetPage} changepage={setCount} />)}
                    {count === 17 && (<DetailBroker getData={getData} dataBroker={dataBroker} close={Onclose} page={count} setResetPage={setResetPage} changepage={setCount} notification={NotiSuccess} notification2={NotiError} />)}
                    {count === 18 && (<Conclusion getData1={getData} close={Onclose} sendback={setResetPage} closeAll={closeAll} page={count} changepage={setCount} notification={NotiSuccess} notification2={NotiError} notification3={NotiSuccessAdd} notification4={NotiErrorAdd} notification5={NotiDelete} NotiNotFond={NotiNotFond} addrER={CheckAddressER} />)}
                </Space>
            </Modal>
            {contextHolder}
        </div>
    )
}

export default ModalPreApproveEDIT