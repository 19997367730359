import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import liff from '@line/liff';

export default function Liff() {
    const [idToken, setIdToken] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [statusMessage, setStatusMessage] = useState("");
    const [userId, setUserId] = useState("");
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        console.log('sssssss')
        initLine();
    }, []);

    const logout = () => {
        liff.logout();
        // liff.closeWindow();
        window.location.reload();
    }

    const initLine = () => {
        console.log('aaaa')
        liff.init({ liffId: '2001471734-9wZq6oVk', withLoginOnExternalBrowser: true }, () => {
            if (liff.isLoggedIn()) {
                runApp();

            } else {
                liff.login();

            }
        }, err => console.error(err));
    }


    const runApp = () => {
        const idToken = liff.getIDToken();
        setIdToken(idToken);
        liff.getProfile()?.then(profile => {
            console.log(profile);
            localStorage.setItem('useridLine', profile?.userId)
            setDisplayName(profile?.displayName);

            setStatusMessage(profile?.statusMessage);
            setUserId(profile?.userId);
            //  liff.closeWindow();
            navigate('/login-line')
        }).catch(err => console.error(err));
    }
    const closeLIFF = () => {
        liff.closeWindow();
    }


    return (
        <>

        </>
    );
}
