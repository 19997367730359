import React, { useEffect, useState } from 'react';
import { Button, Modal, Input, Col, Row, Space, message, Select, Form, Spin, notification } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import axios from 'axios';
import { createuser, getdata } from '../../file_mid/all_api';

const { Option } = Select;

const ModalCreate = ({ open, close, addMember, userId, check, members }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [role, setRole] = useState([]);
    const [department, setDepartment] = useState([]);
    const [position, setPosition] = useState([]);
    const [loading, setLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [form] = Form.useForm();
    const [formData, setFormData] = useState({
        password: '',
        name: '',
        id_sep: '',
        id_department: '',
        lastname: '',
        nickname: '',
        userid_line: null,
        branch: '',
        role_id: 6,
        nickname_salcod: null,
    });

    useEffect(() => {
        const fetchData = () => {
            try {
                getData()
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        form.setFieldsValue({
            right: 6,

        })

        fetchData();
    }, []);

    const openNotification = (placement) => {
        api.info({
            message: 'บันทึกสำเร็จ',
            description: 'เพิ่มข้อมูลผู้ใช้สำเร็จ.',
            placement,
        });
    };

    const getData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${getdata}`);
            if (response.data) {
                setPosition(response.data.setEmployeePosition);
                setDepartment(response.data.setEmployeeDepartment);
                setRole(response.data.role);
                setLoading(false);
                // console.log('res',response.data)
            }
        } catch (error) {
            console.error('Error fetching positions:', error);
            setLoading(false);
        }

    };
    // console.log('userid', userId)
    // console.log('formData', formData)

    const handleSubmit = async () => {
        // console.log('formData.branch',formData.branch);
        

        let count;
        let newUserId;

        if (["K1001", "K1002", "K1003", "K1004", "K1005", "K1006", "K1007", "K1008", "K1009"].includes(formData.branch) && formData.id_sep === 3 && formData.id_department === 4) {
            // console.log('if1');
            
            count = userId["KSM"];
            newUserId = `KSM${('0' + (count + 1)).slice(-2)}`;

        } else if (["K1001", "K1002", "K1003", "K1004", "K1005", "K1006", "K1007", "K1008", "K1009"].includes(formData.branch)) {
            // console.log('if2');

            count = userId["K"];
            newUserId = `K${('000000' + (count + 1)).slice(-7)}`;

        } else {
            // console.log('if3');
            let count = Number(userId[formData.branch]);
            if (isNaN(count) || !isFinite(count)) {
                count = 0;
            }
            // count = userId[formData.branch] ? userId[formData.branch]: 0 ;
            newUserId = `${formData.branch}${('0000' + (count + 1)).slice(-5)}`;
        }
        console.log('count',count);
        console.log('newUserId',newUserId);
        
        

        const genUser = {
            ...formData,
            username: newUserId,
            active_status: check,
            nickname_salcod: formData.nickname,

        };

        if (genUser.userid_line === '') {
            delete genUser.userid_line;
        }
        // console.log('genUser',genUser);
        

        // if (genUser.nickname_salcod === '') {
        //     genUser.nickname_salcod = null;
        // }

        console.log('genUser', genUser)
        if (members.some(member => member.username === formData.username)) {
            message.error('Username นี้ถูกใช้งานไปแล้ว');
            return;
        } else {

            await axios.post(`${createuser}`, genUser)
                .then(response => {
                    addMember(response.data);
                    if (response.data === response.data) {
                        openNotification('top');
                        setTimeout(() => {
                            close(false);
                        }, 2000)
                    }
                })
                .catch(error => {
                    if (error.response && error.response.status === 400) {
                        alert('ไม่สามารถเพิ่มข้อมูลได้');
                    }
                    console.error('Error creating user:', error);
                });
        }

    };

    const handleCancel = () => {
        setFormData({
            password: '',
            name: '',
            id_sep: '',
            id_department: '',
            lastname: '',
            nickname: '',
            userid_line: '',
            branch: '',
            role_id: '',
            // nickname_salcod: '',
        });
        close(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSelectChange = (name, value) => {
        setFormData({ ...formData, [name]: value });
        // setRoleID(value);
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            {contextHolder}
            <Modal
                open={open}
                title={<div style={{ marginBottom: '30px' }}>เพิ่มสมาชิกใหม่</div>}
                footer={null}
                onCancel={handleCancel}
                width={1000}
            >
                <Spin spinning={loading} size='large' tip=' loading... '>
                    <Form layout="vertical" onFinish={handleSubmit} form={form}>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="name"
                                    label="ชื่อ:"
                                    style={{ marginBottom: 0 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'กรุณากรอกชื่อ',
                                        },
                                    ]}>
                                    <Input
                                        placeholder="ชื่อ"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        className='input'
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="nickname"
                                    label="ชื่อเล่น:"
                                    style={{ marginBottom: 0 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'กรุณากรอกชื่อเล่น',
                                        },
                                    ]}>
                                    <Input
                                        placeholder="ชื่อเล่น"
                                        name="nickname"
                                        value={formData.nickname}
                                        onChange={handleChange}
                                        className='input'
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="ID Line:"
                                    style={{ marginBottom: 0 }}>
                                    <Input
                                        placeholder="ID Line"
                                        name="userid_line"
                                        value={formData.userid_line}
                                        onChange={handleChange}
                                        className='input'
                                    />
                                </Form.Item>
                                <Form.Item
                                    name='password'
                                    label="รหัสผ่าน:"
                                    style={{ marginBottom: 0 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'กรุณากรอกรหัสผ่าน',
                                        },
                                    ]}>
                                    <Input
                                        placeholder="รหัสผ่าน"
                                        name="password"
                                        type={showPassword ? 'text' : 'password'}
                                        value={formData.password}
                                        onChange={handleChange}
                                        className='input'
                                        suffix={
                                            showPassword ? (
                                                <EyeInvisibleOutlined onClick={toggleShowPassword} />
                                            ) : (
                                                <EyeOutlined onClick={toggleShowPassword} />
                                            )
                                        }
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="position"
                                    label="ตำแหน่ง:"
                                    colon={false}
                                    style={{ marginBottom: 0 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'กรุณากรอกตำแหน่ง',
                                        },
                                    ]}>
                                    <Select
                                        placeholder="ตำแหน่ง"
                                        name="id_sep"
                                        value={formData.id_sep}
                                        onChange={value => handleSelectChange('id_sep', value)}
                                        className='input'
                                    >
                                        {position?.map(item => (
                                            <Option key={item.id_sep} value={item.id_sep}>{item.description}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="lastname"
                                    label="นามสกุล:"
                                    style={{ marginBottom: 0 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'กรุณากรอกนามสกุล',
                                        },
                                    ]}>
                                    <Input
                                        placeholder="นามสกุล"
                                        name="lastname"
                                        value={formData.lastname}
                                        onChange={handleChange}
                                        className='input'
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="branch"
                                    label="สาขา:"
                                    // colon={false} 
                                    style={{ marginBottom: 0 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'กรุณาเลือกสาขา',
                                        },
                                    ]}>
                                    <Select
                                        placeholder="สาขา"
                                        name="branch"
                                        value={formData.branch}
                                        onChange={value => handleSelectChange('branch', value)}
                                        className='input'
                                    >
                                        <Option value="LEX">LEX</Option>
                                        <Option value="UD">UD</Option>
                                        <Option value="MIT">MIT</Option>
                                        <Option value="S4">S4</Option>
                                        <Option value="S5">S5</Option>
                                        <Option value="K1001">K1001-ประตูน้ำ</Option>
                                        <Option value="K1002">K1002-รื่นรมย์</Option>
                                        <Option value="K1003">K1003-บ้านไผ่</Option>
                                        <Option value="K1004">K1004-กระนวน</Option>
                                        <Option value="K1005">K1005-สารคาม</Option>
                                        <Option value="K1006">K1006-สกลนคร</Option>
                                        <Option value="K1007">K1007-สุรินทร์</Option>
                                        <Option value="K1008">K1008-โคราช</Option>
                                        <Option value="K1009">K1009-อุดร</Option>
                                    </Select>
                                </Form.Item>
                                {/* <Form.Item
                                    name="Nickname Salcod"
                                    label="Nickname Salcod:"
                                    style={{ marginBottom: 0 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'กรุณากรอกชื่อ',
                                        },
                                    ]}>
                                    <Input
                                        placeholder="Nickname Salcod"
                                        name="nickname_salcod"
                                        value={formData.nickname_salcod}
                                        onChange={handleChange}
                                        className='input'
                                    />
                                </Form.Item> */}
                                <Form.Item
                                    name="right"
                                    label="กำหนดสิทธิ์:"
                                    colon={false}
                                    style={{ marginBottom: 0 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'กรุณากำหนดสิทธิ์',
                                        },
                                    ]}>
                                    <Select
                                        placeholder="กำหนดสิทธิ์"
                                        name="role_id"
                                        value={6}
                                        onChange={value => handleSelectChange('role_id', value)}
                                        className='input'
                                    >
                                        {role?.map(item => (
                                            <Option key={item.role_id} value={item.role_id}>{item.role_name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="dapartment"
                                    label="แผนก:"
                                    style={{ marginBottom: 0 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'กรุณากำหนดแผนก',
                                        },
                                    ]}>
                                    <Select
                                        placeholder="แผนก"
                                        name="id_department"
                                        value={formData.id_department}
                                        onChange={value => handleSelectChange('id_department', value)}
                                        className='input'
                                    >
                                        {department?.map(item => (
                                            <Option key={item.id_department} value={item.id_department}>{item.description}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Row justify="end" align="middle" style={{ height: '100%' }}>
                                    <Space >
                                        <Button
                                            key="cancel"
                                            className='button-cancel'
                                            onClick={handleCancel}
                                        >
                                            ยกเลิก
                                        </Button>
                                        <Button
                                            key="save"
                                            className='button-save'
                                            // type='htmlsubmit'
                                            // onClick={handleOk}
                                            htmlType='submit'
                                        >
                                            บันทึก
                                        </Button>
                                    </Space>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        </>
    );
};

export default ModalCreate;
