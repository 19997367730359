import React, { useState, useRef } from "react";
import axios from "axios";
import { Button, Row, Col, Table, Form, DatePicker, Select } from "antd";
import { useReactToPrint } from "react-to-print";
import DotaColums from "./dotaColums";
import DotaLocat from "./dotaLocat";
import DataCase from "./dotaCase";
import ExportExcel from "./exportExcel";
import { tablemanager } from "../../file_mid/all_api";

const { RangePicker } = DatePicker;
function TabelLoanCarLocat() {
  //token
  const token = localStorage.getItem("token");
  const tk = JSON.parse(token);
  const headers = {
    Authorization: `Bearer ${tk}`,
    Menu: JSON.stringify("7"),
  };
  //ประกาศตัวแปรรับ ค่าวันที่ ถึงวันที่
  const [inputDate, setInputDate] = useState([]);
  //ประกาศตัวแปรรับ InputSelect
  const [inputLocat, setInputLocat] = useState([]);
  //ประกาศตัวแปรรับ tabel
  const [dataTabel, setDataTabel] = useState([]);

  //F-form-1
  const onFinish = (values) => {
    console.log("Success:", values);
  };
  //F-form-2
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  //เซ็ต ค่าวันที่ ถึงวันที่
  const onChangeDate = (dateSelect2) => {
    // console.log("e1: ", e1);
    //console.log("dateSelect: ", dateSelect2);
    setInputDate(dateSelect2);
  };
  const onChangeLocal = (value) => {
    //console.log(`selected ${value}`);
    setInputLocat(value);
  };
  // Button1
  const onClickA = async () => {
    //เซ็ต InputSelectMarketing
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    let filterProposalBy;
    //let filterDataCarLocat;

    if (inputDate.length === 0) {
      alert("เลือกวันที่ - ถึงวันที่ก่อน");
    } else if (inputDate[0] > formattedDate || inputDate[2] > formattedDate) {
      alert(
        "ยังไม่มีข้อมูลวันที่" +
          JSON.stringify(inputDate[0]) +
          "-" +
          JSON.stringify(inputDate[1])
      );
    } else if (inputLocat.length === 0) {
      alert("เลือกสาขาก่อน");
    } else {
      //car
      let filterDataCar;
      await axios
        .post(
          tablemanager,
          {
            days1: inputDate[0],
            days2: inputDate[1],
            approvalStatus: "ทั้งหมด",
          },
          { headers: headers }
        )
        .then((res) => {
          filterDataCar = res.data.filter((item) => item.car.includes("car"));
          //console.log("resfilterDataCar", filterDataCar);
          const proposalBy = {};
          // นับจำนวนข้อมูลแยกตามผู้เสนอข้อเสนอและสถานะการอนุมัติ
          filterDataCar.forEach((item) => {
            let prefix = item.proposalBy.substring(0, 3);
            // แก้ไข KEY ตามที่ต้องการ
            // MIT ไม่มีการเปลี่ยนแปลง KEY จึงไม่ต้องทำอะไร
            switch (prefix) {
              case "adm":
                prefix = "admin";
                break;
              case "MIT":
                break;
              case "UD0":
                prefix = "UD";
                break;
              case "LEX":
                break;
              case "S40":
                prefix = "S4";
                break;
              case "S50":
                prefix = "S5";
                break;
              case "K00":
                prefix = "KSM";
                break;
              default:
                break;
            }

            if (!proposalBy[prefix]) {
              proposalBy[prefix] = {
                caseAll: 0,
                caseSuccess: 0,
                caseWait: 0,
                caseFail: 0,
              };
            }
            // เพิ่มจำนวนทั้งหมดของ proposalBy
            proposalBy[prefix].caseAll++;

            switch (item.approvalStatus) {
              case DataCase[0]:
              case DataCase[1]:
              case DataCase[2]:
              case DataCase[3]:
              case DataCase[4]:
              case DataCase[5]:
              case DataCase[6]:
              case DataCase[7]:
              case DataCase[8]:
              case DataCase[9]:
                proposalBy[prefix].caseSuccess++;
                break;
              case DataCase[10]:
              case DataCase[11]:
                proposalBy[prefix].caseFail++;
                break;
              case DataCase[12]:
                proposalBy[prefix].caseWait++;
                break;
              default:
                break;
            }
          });
          //รวมค่า
          const summary = Object.values(proposalBy).reduce(
            (acc, currentValue) => {
              acc.caseAll += currentValue.caseAll;
              acc.caseSuccess += currentValue.caseSuccess;
              acc.caseWait += currentValue.caseWait;
              acc.caseFail += currentValue.caseFail;
              return acc;
            },
            { caseAll: 0, caseSuccess: 0, caseWait: 0, caseFail: 0 }
          );
          //console.log("summary1111",summary)
          if (inputLocat === "ทั้งหมด") {
            //console.log("inputLocat",inputLocat)
            proposalBy["รวมเคส"] = summary;
            filterProposalBy = proposalBy;
          } else if (inputLocat !== "ทั้งหมด") {
            filterProposalBy = Object.keys(proposalBy)
              .filter((key) => key.includes(inputLocat))
              .reduce((obj, key) => {
                obj[key] = proposalBy[key];
                return obj;
              }, {});
          }
          const result = Object.entries(filterProposalBy).map(
            ([key, value]) => ({
              userMarketing: key,
              ...value,
            })
          );         
          setDataTabel(result);
        })
        .catch((err) => console.log(err.message));
    }
  };

  //ExportPDF
  const conponentPDF = useRef();
  const generatePDF = useReactToPrint({
    content: () => conponentPDF.current,
    documentTitle: "รายงานสรุปเคสขอยอดรถ-ตามสาขา",
    onAfterPrint: () => alert("Data saved in PDF"),
  });

  return (
    <>
      <center>
        <h1>รายงานสรุปเคสขอยอดรถ-ตามสาขา</h1>
        <label>สาขา :</label>{" "}
        <label style={{ color: "#0D36EE" }}>{inputLocat}</label>
        <label> วันที่ :</label>
        <label style={{ color: "#0D36EE" }}>{inputDate[0]}</label>
        <label> ถึงวันที่ :</label>{" "}
        <label style={{ color: "#0D36EE" }}>{inputDate[1]}</label>
        <br />
        <br />
      </center>
      <Row justify={"center"}>
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row>
            <Col xs={14} sm={16} md={18} lg={20} xl={24}>
              <RangePicker
                placeholder={["เลือกวันที่", "ถึงวันที่"]}
                style={{ margin: "0 4px 4px" }}
                format="YYYY-MM-DD"
                onChange={(dateSelect, dateSelect2) => {
                  onChangeDate(dateSelect2);
                }}
              />
              <Select
                style={{ width: "110px", margin: "0 4px 4px" }}
                showSearch
                placeholder="เลือกสาขา"
                optionFilterProp="children"
                onChange={(value) => {
                  onChangeLocal(value);
                }}
                options={DotaLocat}
                value={inputLocat}
              />
              <Button
                type="primary"
                // onClick={postData}
                onClick={onClickA}
                style={{ margin: "0 2px 4px" }}
              >
                ค้นหา
              </Button>
              <Button
                type="primary"
                danger
                onClick={generatePDF}
                style={{ margin: "0 2px 4px" }}
              >
                PDF
              </Button>
              <ExportExcel columns={DotaColums} dataSource={dataTabel} />
            </Col>
          </Row>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          ></Form.Item>
        </Form>
      </Row>
      <Row ref={conponentPDF} style={{ width: "100%" }} justify={"center"}>
        <style>
          {`@media print {.ant-pagination {display: none !important;}.report-name {display: block;}}           
      @media screen {.report-name {display: none;}}`}
        </style>
        <br />
        <br />
        <p className="report-name" style={{ fontSize: "18px" }}>
          {" "}
          รายงานสรุปเคสขอยอดรถ-ตามสาขา :{" "}
          <b style={{ color: "#0D36EE" }}>{inputLocat} </b>
          วันที่ : <b style={{ color: "#0D36EE" }}>{inputDate[0]} </b>
          ถึงวันที่ : <b style={{ color: "#0D36EE" }}>{inputDate[1]} </b>
        </p>
        <Table
          columns={DotaColums}
          dataSource={dataTabel.map((item, index) => ({ ...item, key: index }))}
          //dataSource={dataTabel}
          style={{ width: "1500px" }}
        />
      </Row>
    </>
  );
}
export default TabelLoanCarLocat;
