import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Divider, Row, Col, message, Space, Spin, Select, Input, Form } from "antd";
import axios from "axios";
import dayjs from 'dayjs';
import logo from "../../../assets/images/logo.png";
import "../../css/MobileActive.css";
import { approvalceo, getcontnoJojonoi, loadDetailBoss, mainexpeditethedebt, loadBunshe3, queryCarApp } from "../../file_mid/all_api";
import EditApproved from "./EditApproved";
import { loanTermMid } from "../../file_mid/all_options";
import { ExclamationCircleFilled } from "@ant-design/icons";
import moment from "moment";
import TextArea from "antd/es/input/TextArea";

const Approval = ({ open, close, data, contno, onChangeStatus, status }) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [dataArr, setDataArr] = useState([]);
  const [dataCarApp, setdataCarApp] = useState([]);
  const [isModalEdit, setIsModalEdit] = useState(null);
  const [formData, setFormData] = useState({
    newDamt: "",
    followPay: "",
    approvedLoanAmount: "",
    approvedLoanTerm: "",
    approvedLoanTermTo: "",
    Memo: "",
  });
  const [loading, setLoading] = useState(false);
  const [dataApproved, setdataApproved] = useState();
  const { confirm } = Modal;
  const user = localStorage.getItem("username");
  const token = localStorage.getItem("token");
  const [dataSendEdit, setDataSendEdit] = useState();
  const [memo1, setMemo1] = useState();
  const [isModalApproved, setIsModalApproved] = useState(null);
  const [numberRe, setNumberRe] = useState();
  const isLastIndex = dataApproved?.note?.length - 1;
  const [checkBunchi, setCheckBunchi] = useState(1);
  const [DATELOAD, setDATELOAD] = useState(dayjs().add(7, 'day').format('YYYY-MM-DD'));
  const splitCONTNO = data?.CONTNO?.split("-");


  useEffect(() => {
    setIsModalApproved(open);
    if (isModalApproved === true) {
      loadData();
      // console.log("Approval1");
      // console.log("status", status);
    }
  }, [isModalApproved]);

  useEffect(() => {
    console.log("data", data)
    if (isModalEdit === false) {
      loadData();
    }
  }, [isModalEdit]);

  useEffect(() => {
    if (!formData.followPay) {
      setFormData({ ...formData, followPay: 0 });
    }
  }, [formData.followPay]);
  const handleClose = () => {
    close(false);
  };

  const loadData = async () => {
    setLoading(true);
    if (splitCONTNO[0] === "3" || data?.DATA_TYPE === "rpsl") {
      await axios.post(loadBunshe3, { CONTNO: data?.CONTNO, DATE: DATELOAD })
        .then(async res => {
          if (res.data) {
            console.log("loadDataBunshe3", res.data)
            setDataArr(res.data);
            setCheckBunchi(3);
          } else {
            setDataArr([]);
          }
        }).catch((err) => {
          console.log("err", err)
        })
    } else {
      await axios.get(`${getcontnoJojonoi}/${data.CONTNO}`)
        .then(async (resData) => {
          if (resData.data) {
            // console.log("res.data111", resData.data)
            setDataArr(resData.data);
          } else {
            setDataArr([]);
          }
        })
        .catch((err) => console.log(err));
    }
    const tk = JSON.parse(token);
    const url = loadDetailBoss;
    const headers = {
      Authorization: `Bearer ${tk}`,
      Menu: JSON.stringify("25"),
    };

    await axios.post(url, { id: data?.id }, { headers: headers })
      .then(async (res) => {
        // console.log("1111 ", res.data)
        if (res.data) {
          setdataApproved(res.data);
          setDataSendEdit(res.data);
        } else {
          setdataApproved([]);
        }
      })
      .catch((err) => console.log(err));

    const urlCar = mainexpeditethedebt;
    await axios.get(urlCar, { headers: headers })
      .then(async (resCar) => {
        if (resCar.data) {
          // console.log("checkDataCar", resCar);
          checkDataCar(resCar.data);
        } else {
          // console.log("checkDataCar[]");
          checkDataCar([]);
        }
      })
      .catch((err) => console.log(err));

    await axios.post(queryCarApp, { CONTNO: data?.CONTNO })
      .then(async (res) => {
        if (res.data) {
          console.log("queryCarApp:", res.data)
          setdataCarApp(res.data);
        } else {
          setdataCarApp([]);
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };

  const sendApprove = async () => {
    var dataSendTo
    setLoading(true);
    // console.log("formData--->", formData);
    const tk = JSON.parse(token);
    const url = approvalceo;
    const headers = {
      Authorization: `Bearer ${tk}`,
      Menu: JSON.stringify("25"),
    };
    const result = Number(Math.round(Math.min(dataArr?.re1, dataArr?.re2)))
    // setNumberRe(String(result))
    if (data?.DATA_TYPE === "rpsl") {
      console.log("rpsl")
      dataSendTo = {
        id: data?.id,
        CONTNO: data?.CONTNO,
        ...dataArr.bu1[0],
        followPay: parseFloat(formData?.followPay),
        nopays: dataArr?.bu1[0].nopay,
        reqNo: dataArr?.bu1[0].req_no,
        re1: parseInt(result),
        GRDCOD: dataArr?.custmast[0].GRDCOD,
        offerBy: data?.offerBy,
        proposalBy: data?.proposalBy,
        approvalStatus: 3,
        approvedBy: user,
        approvedLoanAmount: parseFloat(formData?.approvedLoanAmount),
        approvedLoanTerm: formData?.approvedLoanTerm,
        approvedLoanTermTo: formData?.approvedLoanTermTo,
        newDamt: parseFloat(formData?.newDamt),
        FOLLOW: dataArr?.bu1[0]?.FOLLOW,
        INTEREST: dataArr?.bu1[0]?.INTEREST,
        TON: dataArr?.bu1[0]?.tcshprc - dataArr?.bu1[0]?.AllPAYTON,
        Memo: {
          note: formData?.Memo ? formData?.Memo : '',
        },
      }
    } else {
      console.log("no rpsl")
      dataSendTo = {
        id: data?.id,
        CONTNO: data?.CONTNO,
        ...dataArr.bu1[0],
        followPay: parseFloat(formData?.followPay),
        nopays: dataArr?.bu1[0].nopay,
        reqNo: dataArr?.bu1[0].req_no,
        re1: parseInt(result),
        GRDCOD: dataArr?.custmast[0].GRDCOD,
        offerBy: data?.offerBy,
        proposalBy: data?.proposalBy,
        approvalStatus: 3,
        approvedBy: user,
        approvedLoanAmount: parseFloat(formData?.approvedLoanAmount),
        approvedLoanTerm: formData?.approvedLoanTerm,
        approvedLoanTermTo: formData?.approvedLoanTermTo,
        newDamt: parseFloat(formData?.newDamt),
        FOLLOW: 0.00,
        INTEREST: 0.00,
        TON: 0.00,
        Memo: {
          note: formData?.Memo ? formData?.Memo : '',
        },
      }
    }
    await axios
      .post(
        url, dataSendTo,
        { headers: headers }
      )
      .then(async (res) => {
        onChangeStatus(
          {
            ...data,
            approvalStatus: 3,
            approvalDate: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
            approvedBy: user,
          },
          status
        );
        if (res.data) {
          message.success(
            `ยืนยันการอนุมัติสำเร็จ ${data.CONTNO}  ${currentDateTime()}`
          );
          setTimeout(() => {
            handleClose();
          }, 1000);
        }
      })
      .catch((err) => {
        message.error(
          `บันทึกไม่สำเร็จ ${data.CONTNO}  ${currentDateTime()}`
        );
      });
    setLoading(false);
  };

  const currentDateTime = () => {
    const date = moment().add(543, "year").format("LLL");
    return date;
  };

  const currencyFormatOne = (amount) => {
    if (amount) {
      return Number(amount)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    } else {
      return 0;
    }
  };
  const currencyFormatOnenew = (amount) => {
    if (amount) {
      return Number(amount)
        .toFixed(0)
        // .replace(/\d(?=(\d{3})+\.)/g, "$&,");
        .replace(/\d(?=(\d{3})+$)/g, "$&,"); // Format ตัวเลขด้วย comma
    } else {
      return 0;
    }
  };

  const currencyCutDash = (amount) => {
    if (amount) {
      return Number(amount).toFixed(2).replace(/-/g, "");
    }
  };

  const showConfirm = () => {
    confirm({
      title: "อนุมัติข้อมูล",
      icon: <ExclamationCircleFilled />,
      content: "คุณต้องการอนุมัติหรือไม่",
      okText: "บันทึก",
      cancelText: "ยกเลิก",
      onOk() {
        console.log("formData--->", formData);
        sendApprove();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const showConfirmLoanAmount = () => {
    confirm({
      title: "Confirm ยอดเงินต้น",
      icon: <ExclamationCircleFilled />,
      content: (
        <span>
          คุณต้องการอนุมัติหรือไม่ ยอดเงินต้นที่คุณให้มาคือ{" "}
          <b><span style={{ color: "red" }}>{approvedLoanAmountInt}</span></b> ซึ่งน้อยกว่า 40% ของยอดรีไฟแนนซ์เพื่อจัดใหม่
          <br />
          <b>ยอดรีไฟแนนซ์เพื่อจัดใหม่ :</b>
          <b><span style={{ color: "red" }}> {Number(Math.round(Math.min(dataArr?.re1, dataArr?.re2)))}
          </span></b>
        </span>
      ),
      okText: "ตกลง",
      cancelText: "ยกเลิก",
      onOk() {
        showConfirm()
      },
      onCancel() {
        console.log("Cancel")
      },
    });
  };

  // const showEdit = () => {
  //   confirm({
  //     title: "คำร้องขอแก้ไขข้อมูล",
  //     icon: <ExclamationCircleFilled />,
  //     content: "คุณต้องการที่จะแก้ไขข้อมูลใช่หรือไม่",
  //     okText: "ใช่",
  //     cancelText: "ยกเลิก",
  //     onOk() {
  //       setIsModalEdit(true);
  //     },
  //     onCancel() {
  //       console.log("Cancel");
  //     },
  //   });
  // };

  const handleOldData = (value) => {
    console.log("value", value);
    if (value) {
      const foundItem = dataCarApp.find((item) => item._id === value);
      const carsApproveve = foundItem ? foundItem.carsApproveve : null;
      console.log(carsApproveve);
      setFormData({
        ...formData,
        newDamt: carsApproveve?.newDamt,
        followPay: carsApproveve?.followPay,
        approvedLoanAmount: carsApproveve?.approvedLoanAmount,
        approvedLoanTerm: carsApproveve?.approvedLoanTerm,
        approvedLoanTermTo: carsApproveve?.approvedLoanTermTo,
      });
      form.setFieldsValue({
        newDamt: carsApproveve?.newDamt,
        followPay: carsApproveve?.followPay,
        approvedLoanAmount: carsApproveve?.approvedLoanAmount,
        approvedLoanTerm: carsApproveve?.approvedLoanTerm,
        approvedLoanTermTo: carsApproveve?.approvedLoanTermTo,
      })
    }
  }

  const handleLoanTerm = (value) => {
    if (formData.approvedLoanTermTo < value && formData.approvedLoanTermTo) {
      message.error("จำนวนงวดต้องน้อยกว่างวดถึง โปรดเลือกใหม่!!");
      setFormData({ ...formData, approvedLoanTerm: value });
    } else {
      setFormData({ ...formData, approvedLoanTerm: value });
    }
  };

  const handleLoanTermTo = (value) => {
    if (formData.approvedLoanTerm > value) {
      message.error("จำนวนงวดถึงต้องมากกว่า โปรดเลือกใหม่!!");
      setFormData({ ...formData, approvedLoanTermTo: value });
    } else {
      setFormData({ ...formData, approvedLoanTermTo: value });
    }
  };

  const checkDataCar = (value) => {
    if (value) {
      let memo = value.filter((item) => item.CONTNO === contno);
      setMemo1(memo[0]?.MEMO1);
    }
  };

  const onFinish = (values) => {
    console.log('Success:', values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  function currencyFormatOne1(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  // console.log("dataApproved", dataApproved)

  let re1Int = parseInt(dataArr.re1, 10);
  let re2Int = parseInt(dataArr.re2, 10); //แปลงยอดรีไฟแนนซ์เพื่อจัดใหม่เป็นตัวเลข
  // console.log('re1Int',re1Int)

  const smallerValue = re1Int > re2Int ? re2Int : re1Int; //เลือกค่าที่น้อยสุด
  // console.log('smallerValue',smallerValue)

  const result = smallerValue * 0.4;  //ยอด 40% ของ ยอดรีไฟแนนซ์เพื่อจัดใหม่
  // console.log('ผลลัพธ์ของ re1Int * 0.4:', result);

  let approvedLoanAmountInt = parseInt(formData.approvedLoanAmount, 10)   //แปลงเงินต้นเป็นตัวเลข
  // console.log('approvedLoanAmountInt',approvedLoanAmountInt)

  return (
    <Modal
      title="คำร้องขออนุมัติรีไฟแนนซ์"
      open={open}
      onCancel={handleClose}
      width={850}
      footer={[null]}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 11,
        }}
        wrapperCol={{
          span: 22,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Spin spinning={loading} size="large" tip=" Loading... ">
          <Row justify={'center'} style={{ margin: 8 }}>
            <Col span={22}>
              {dataArr.custmast ?
                <>
                  < Col span={24}>
                    <h5 style={{ opacity: 0.5 }}>{currentDateTime()}</h5>
                  </Col>
                  {dataArr?.custmast?.map((custmast, index) => {
                    return (
                      <>
                        <Row className="main3" justify={'center'}>
                          <Col span={22} style={{ fontSize: '16px' }}>
                            <aside>
                              <div style={{ textAlign: 'center' }}>
                                <img width={"30%"} src={logo} alt="Logo" />
                              </div>
                              <div
                                label="เลขที่สัญญา"
                                style={{
                                  fontSize: '16px',
                                  textAlign: 'center'
                                }}
                              >
                                {" "}
                                <b>เลขที่สัญญา </b>
                                <b>: </b> {!custmast.CONTNO ? "-" : custmast.CONTNO}
                              </div>
                              <div
                                label="ตัดสด ณ วันที่"
                                style={{
                                  fontSize: '16px',
                                  textAlign: 'center'
                                }}
                              >
                                {" "}
                                <b>ตัดสด ณ วันที่ </b>
                                <b>: </b>{" "}
                                {!dataApproved?.firstTime ||
                                  dataApproved?.firstTime === "0001-01-01T00:00:00Z"
                                  ? moment().add(543, "year").format("DD/MM/YYYY")
                                  : moment(dataApproved?.firstTime)
                                    .add(543, "year")
                                    .format("DD/MM/YYYY")}
                              </div>
                            </aside>
                          </Col>
                        </Row>
                        <Divider
                          orientation="center"
                          style={{
                            marginTop: "20px",
                            fontSize: "larger",
                          }}
                        >
                          <b><u>รายละเอียดผู้กู้</u></b>
                        </Divider>
                        <Row className="main3">
                          <Col span={22} style={{ fontSize: '16px' }}>
                            <aside>
                              <div label="ชื่อผู้ทำสัญญา">
                                {" "}
                                <b>ชื่อผู้ทำสัญญา : </b>
                                {!custmast.SNAM ? "-" : custmast.SNAM}{" "}
                                {!custmast.NAME1 ? "-" : custmast.NAME1}{" "}
                                {!custmast.NAME2 ? "-" : custmast.NAME2}
                              </div>
                              <div
                                label="เกรด"
                                style={{
                                  marginTop: "10px",
                                }}
                              >
                                {" "}
                                <b>เกรด : </b>
                                {!custmast.GRDCOD ? "-" : custmast.GRDCOD}
                              </div>

                              <div label="เบอร์โทรผู้ทำสัญญา">
                                {" "}
                                <b>เบอร์โทรผู้ทำสัญญา : </b>
                                {!custmast.TELP ? "-" : custmast.TELP}
                              </div>
                              <div
                                label="ชื่อผู้ขาย"
                                style={{
                                  marginTop: "10px",
                                }}
                              >
                                {" "}
                                <b>ชื่อผู้ขาย : </b>
                                {!custmast.SALCOD ? "-" : custmast.SALCOD}
                              </div>
                            </aside>
                          </Col>
                        </Row>
                        <Divider
                          orientation="center"
                          style={{
                            marginTop: "20px",
                            fontSize: "larger",
                          }}
                        >
                          <b><u>รายละเอียดรถ</u></b>
                        </Divider>
                        <Row className="main4" style={{}}>
                          <Col span={22} style={{ fontSize: '16px' }}>
                            <aside>
                              <div label="ยี่ห้อ" style={{ marginTop: "10px" }}>
                                {" "}
                                <b>ยี่ห้อ : </b>
                                {!custmast.TYPE ? "-" : custmast.TYPE}
                              </div>
                              <div label="รุ่น" style={{ marginTop: "10px" }}>
                                {" "}
                                <b>รุ่น : </b>
                                {!custmast.MODEL ? "-" : custmast.MODEL}
                              </div>
                              <div label="สี" style={{ marginTop: "10px" }}>
                                {" "}
                                <b>สี : </b>
                                {!custmast.COLOR ? "-" : custmast.COLOR}
                              </div>

                              <div label="ชนิดรถ" style={{ marginTop: "10px" }}>
                                {" "}
                                <b>ชนิดรถ : </b>
                                {!custmast.BAAB ? "-" : custmast.BAAB}
                              </div>
                              <div label="ทะเบียน" style={{ marginTop: "10px" }}>
                                {" "}
                                <b>ทะเบียน : </b>
                                {!custmast.REGNO ? "-" : custmast.REGNO}
                              </div>
                              <div label="ปีรถ" style={{ marginTop: "10px" }}>
                                {" "}
                                <b>ปีรถ : </b>
                                {!custmast.MANUYR ? "-" : custmast.MANUYR}
                              </div>
                              <div
                                label="เลขตัวถังรถ"
                                style={{
                                  marginTop: "10px",
                                }}
                              >
                                {" "}
                                <b>เลขตัวถังรถ : </b>
                                {!custmast.STRNO ? "-" : custmast.STRNO}
                              </div>
                            </aside>
                          </Col>
                        </Row>

                      </>
                    );
                  })}
                  <Divider
                    orientation="center"
                    style={{ marginTop: "20px", fontSize: "larger" }}
                  >
                    <b><u>รายละเอียดสัญญา</u></b>
                  </Divider>
                  {
                    data.approvalStatus === 6 ?
                      <>
                        <Row className="main4" >
                          <Col span={22} style={{ fontSize: '16px' }}>
                            <aside>
                              <div
                                label="ยอดจัดครั้งที่แล้ว"
                                style={{ marginTop: "20px" }}
                              >
                                <b>ยอดจัดครั้งที่แล้ว : </b>
                                {!dataApproved?.tcshprc ? "-" : currencyFormatOne(dataApproved?.tcshprc)}  <b> บาท</b>
                              </div>

                              <div label="ค่างวด" style={{ marginTop: "20px" }}>
                                <b>ค่างวดที่ต้องชำระ : </b>
                                {!dataApproved?.DAMT ? "-" : currencyFormatOne(dataApproved?.DAMT)} <b> บาท</b>
                              </div>
                              <div
                                label="ล/น คงเหลือรวม "
                                style={{ marginTop: "20px" }}
                              >
                                <b>ล/น คงเหลือรวม : </b>
                                {!dataApproved?.neekong ? "-" : currencyFormatOne(dataApproved?.neekong)} <b> บาท</b>
                              </div>

                              <div
                                label="ยอดชำระมาแล้วทั้งหมด"
                                style={{ marginTop: "20px" }}
                              >
                                <b>ชำระมาแล้วทั้งหมด : </b>
                                {!dataApproved?.totalpayment
                                  ? "-"
                                  : currencyFormatOne(dataApproved?.totalpayment)} <b> บาท</b>
                              </div>
                              <div
                                label="จำนวนงวดทั้งหมด"
                                style={{ marginTop: "20px" }}
                              >
                                <b>จำนวนงวดทั้งหมด : </b>
                                {!dataApproved?.nopays ? "-" : dataApproved?.nopays} <b> งวด</b>
                              </div>

                              <div
                                label="ราคาเช่าซื้อครั้งที่แล้ว"
                                style={{ marginTop: "20px" }}
                              >
                                <b>ราคาเช่าซื้อครั้งที่แล้ว : </b>
                                {!dataApproved?.balanc ? "-" : currencyFormatOne(dataApproved?.balanc)} <b> บาท</b>
                              </div>
                              <div
                                label="ลูกหนี้อื่น ๆ"
                                style={{ marginTop: "20px" }}
                              >
                                <b>ลูกหนี้อื่น ๆ : </b>
                                {!dataApproved?.OTHR ? "-" : currencyFormatOne(dataApproved?.OTHR)} <b> บาท</b>
                              </div>
                              {
                                checkBunchi !== 3 ?
                                  <div label="เบี้ยปรับ" style={{ marginTop: "20px" }}>
                                    <b>เบี้ยปรับ : </b>
                                    {currencyFormatOne(dataApproved?.beeypup)} <b> บาท</b>
                                  </div>
                                  :
                                  <>
                                    <div label="ค้างค่าธรรมเนียมล่าช้า" style={{ marginTop: "20px" }}>
                                      <b>ค้างค่าธรรมเนียมล่าช้า : </b>
                                      {!dataApproved?.FOLLOW ? "-" : currencyFormatOne(dataApproved?.FOLLOW)} <b> บาท</b>
                                    </div>
                                    {/* <div label="ต้นคงเหลือ" style={{ marginTop: "20px" }}>
                                      <b>ต้นคงเหลือ : </b>
                                      {!dataApproved?.FOLLOW ? "-" : currencyFormatOne(dataApproved?.FOLLOW)} <b> บาท</b>
                                    </div> */}
                                  </>
                              }
                              <div
                                label="จำนวนงวดที่ผ่อนมาแล้ว"
                                style={{ marginTop: "20px" }}
                              >
                                <b>จำนวนงวดที่ผ่อนมาแล้ว : </b>
                                {!dataApproved?.NOPAY ? "-" : dataApproved?.NOPAY} <b> งวด</b>
                              </div>
                              <div label="ตัดสด งวดที่" style={{ marginTop: "20px" }}>
                                <b>ตัดสด งวดที่ : </b>
                                {!dataApproved?.reqNo ? "-" : dataApproved?.reqNo}
                              </div>
                            </aside>
                          </Col>
                        </Row>
                        <Divider
                          orientation="center"
                          style={{ marginTop: "20px", fontSize: "larger" }}
                        >
                        </Divider>
                        <Row>
                          <Col span={22}>
                            <aside>
                              <div style={{ textAlign: 'left' }}>
                                <b style={{ color: "red" }}>
                                  ยอดรีไฟแนนซ์เพื่อจัดใหม่ {" "}
                                  <u>
                                    {currencyFormatOnenew(dataApproved?.re1)}
                                    {" บาท"}
                                  </u>
                                </b>
                              </div>
                              <div
                                className="center"
                                style={{
                                  marginTop: "10px",
                                  textWrap: "brek-word",
                                  color: "black",
                                  margin: "10px",
                                }}
                              >
                                <span>
                                  {" "}
                                  <b>หมายเหตุมากจากโปรแกรม Smart debt : </b>
                                </span>
                                {!memo1 ? "-" : memo1}
                              </div>
                            </aside>
                          </Col>
                        </Row>
                        {
                          dataApproved?.note?.length > 0 ?
                            <>
                              <Row>
                                <Col span={22}>
                                  <aside>
                                    {
                                      dataApproved?.note?.map((note, index) => {
                                        // console.log("note: ", note)
                                        // if (note.status === "1") {
                                          return (
                                            <div
                                              key={index}
                                              className="center"
                                              style={{
                                                marginTop: "10px",
                                                textWrap: "brek-word",
                                                color: "black",
                                                margin: "10px",
                                              }}
                                            >
                                              <span>
                                                {" "}
                                                <b>หมายเหตุจากเร่งรัด : </b>
                                              </span>
                                              {note?.note}
                                            </div>

                                          )
                                        // }
                                      }
                                      )
                                    }
                                  </aside>
                                </Col>
                              </Row>
                            </>
                            : null
                        }
                      </>
                      :
                      <>
                        {dataArr?.bu1?.map((bu1, index) => {
                          return (
                            <>
                              <Row className="main4" >
                                <Col span={22} style={{ fontSize: '16px' }}>
                                  <aside>
                                    <div
                                      label="ยอดจัดครั้งที่แล้ว"
                                      style={{ marginTop: "20px" }}
                                    >
                                      <b>ยอดจัดครั้งที่แล้ว : </b>
                                      {!bu1.tcshprc ? "-" : currencyFormatOne(bu1.tcshprc)}  <b> บาท</b>
                                    </div>

                                    <div label="ค่างวด" style={{ marginTop: "20px" }}>
                                      <b>ค่างวดที่ต้องชำระ : </b>
                                      {!bu1.DAMT ? "-" : currencyFormatOne(bu1.DAMT)} <b> บาท</b>
                                    </div>
                                    <div
                                      label="ล/น คงเหลือรวม "
                                      style={{ marginTop: "20px" }}
                                    >
                                      <b>ล/น คงเหลือรวม : </b>
                                      {!bu1.neekong ? "-" : currencyFormatOne(bu1.neekong)} <b> บาท</b>
                                    </div>

                                    <div
                                      label="ยอดชำระมาแล้วทั้งหมด"
                                      style={{ marginTop: "20px" }}
                                    >
                                      <b>ชำระมาแล้วทั้งหมด : </b>
                                      {!bu1.totalpayment
                                        ? "-"
                                        : currencyFormatOne(bu1.totalpayment)} <b> บาท</b>
                                    </div>
                                    <div
                                      label="จำนวนงวดทั้งหมด"
                                      style={{ marginTop: "20px" }}
                                    >
                                      <b>จำนวนงวดทั้งหมด : </b>
                                      {!bu1.nopay ? "-" : bu1.nopay} <b> งวด</b>
                                    </div>

                                    <div
                                      label="ราคาเช่าซื้อครั้งที่แล้ว"
                                      style={{ marginTop: "20px" }}
                                    >
                                      <b>ราคาเช่าซื้อครั้งที่แล้ว : </b>
                                      {!bu1.balanc ? "-" : currencyFormatOne(bu1.balanc)} <b> บาท</b>
                                    </div>
                                    <div
                                      label="ลูกหนี้อื่น ๆ"
                                      style={{ marginTop: "20px" }}
                                    >
                                      <b>ลูกหนี้อื่น ๆ : </b>
                                      {!bu1.OTHR ? "-" : currencyFormatOne(bu1.OTHR)} <b> บาท</b>
                                    </div>
                                    {
                                      bu1?.beeypup ?
                                        <div label="เบี้ยปรับ" style={{ marginTop: "20px" }}>
                                          <b>เบี้ยปรับ : </b>
                                          {!bu1.beeypup ? "-" : currencyFormatOne(bu1.beeypup)} <b> บาท</b>
                                        </div>
                                        :
                                        <>
                                          <div label="ค้างค่าธรรมเนียมล่าช้า" style={{ marginTop: "20px" }}>
                                            <b>ค้างค่าธรรมเนียมล่าช้า : </b>
                                            {!bu1.FOLLOW ? "-" : currencyFormatOne(bu1.FOLLOW)} <b> บาท</b>
                                          </div>
                                          <div label="ต้นคงเหลือ" style={{ marginTop: "20px" }}>
                                            <b>ต้นคงเหลือ : </b>
                                            {currencyFormatOne(bu1?.tcshprc - bu1?.AllPAYTON)} <b> บาท</b>
                                          </div>
                                        </>
                                    }
                                    <div
                                      label="จำนวนงวดที่ผ่อนมาแล้ว"
                                      style={{ marginTop: "20px" }}
                                    >
                                      <b>จำนวนงวดที่ผ่อนมาแล้ว : </b>
                                      {!bu1.NOPAY ? "-" : bu1.NOPAY} <b> งวด</b>
                                    </div>
                                    <div label="ตัดสด งวดที่" style={{ marginTop: "20px" }}>
                                      <b>ตัดสด งวดที่ : </b>
                                      {!bu1.req_no ? "-" : bu1.req_no}
                                    </div>


                                  </aside>
                                </Col>
                              </Row>
                              <Divider
                                orientation="center"
                                style={{ marginTop: "20px", fontSize: "larger" }}
                              >
                              </Divider>
                              <Row>
                                <Col span={22}>
                                  <aside>
                                    <div style={{ textAlign: 'center' }}>
                                      <b style={{ color: "red", fontSize: "22px" }}>
                                        ยอดรีไฟแนนซ์เพื่อจัดใหม่ {" "}
                                        <u>
                                          {~~dataArr.re1 > ~~dataArr.re2
                                            ? currencyFormatOnenew(dataArr.re2)
                                            : currencyFormatOnenew(dataArr.re1)}
                                        </u>
                                        {" บาท"}
                                      </b>
                                    </div>
                                    <div
                                      className="center"
                                      style={{
                                        marginTop: "10px",
                                        textWrap: "brek-word",
                                        color: "black",
                                        margin: "10px",
                                      }}
                                    >
                                      <span>
                                        {" "}
                                        <b>หมายเหตุมากจากโปรแกรม Smart debt : </b>
                                      </span>
                                      {!memo1 ? "-" : memo1}
                                    </div>
                                  </aside>
                                </Col>
                              </Row>
                              {
                                dataApproved?.note?.length > 0 ?
                                  <>
                                    <Row>
                                      <Col span={22}>
                                        <aside>
                                          {
                                            dataApproved?.note?.map((note, index) => {
                                              // console.log("note: ", note)
                                              // if (note.status === "15") {
                                                return (
                                                  <div
                                                    key={index}
                                                    className="center"
                                                    style={{
                                                      marginTop: "10px",
                                                      textWrap: "brek-word",
                                                      color: "black",
                                                      margin: "10px",
                                                    }}
                                                  >
                                                    <span>
                                                      {" "}
                                                      <b>หมายเหตุจากเร่งรัด : </b>
                                                    </span>
                                                    {note.note}
                                                  </div>

                                                )
                                              // }
                                            }
                                            )
                                          }
                                        </aside>
                                      </Col>
                                    </Row>
                                  </>
                                  : null
                              }
                            </>
                          );
                        })}
                      </>
                  }

                  {
                    dataCarApp?.length > 0 && !data.approvedBy ?
                      <>
                        <Divider
                          orientation="center"
                          style={{ marginTop: "30px", fontSize: "larger" }}
                        >
                          <b><u>ข้อมูลการอนุมัติ(เก่า)</u></b>
                        </Divider>
                        <>
                          {dataCarApp.map((item, index) => {
                            return (
                              <>
                                <Row className="main4" >
                                  <Col span={22} style={{ fontSize: '16px' }}>
                                    <aside>
                                      <div label="วันที่" style={{ marginTop: "20px" }}>
                                        <b>วันที่ : </b>
                                        {!item.inputDate ? "-" : dayjs(item.inputDate).format("YYYY-MM-DD")} <b></b>
                                      </div>
                                      <div label="ค่างวดที่ต้องชำระ" style={{ marginTop: "20px" }}>
                                        <b>ค่างวดที่ต้องชำระ : </b>
                                        {!item.carsApproveve?.newDamt ? "-" : currencyFormatOne(item.carsApproveve?.newDamt)} <b> บาท</b>
                                      </div>
                                      <div label="ค่าติดตาม" style={{ marginTop: "20px" }}>
                                        <b>ค่าติดตาม : </b>
                                        {!item.carsApproveve?.followPay ? "-" : currencyFormatOne(item.carsApproveve?.followPay)} <b> บาท</b>
                                      </div>
                                      <div label="เงินต้น" style={{ marginTop: "20px" }}>
                                        <b>เงินต้น : </b>
                                        {!item.carsApproveve?.approvedLoanAmount ? "-" : currencyFormatOne(item.carsApproveve?.approvedLoanAmount)} <b> บาท</b>
                                      </div>
                                      <div label="จำนวนงวด" style={{ marginTop: "20px" }}>
                                        <b>จำนวนงวด : </b>
                                        {!item.carsApproveve?.approvedLoanTerm ? "-" : currencyFormatOne(item.carsApproveve?.approvedLoanTerm)} <b> งวด</b>
                                      </div>
                                      <div label="ถึงงวดที่" style={{ marginTop: "20px" }}>
                                        <b>ถึงงวดที่ : </b>
                                        {!item.carsApproveve?.approvedLoanTermTo ? "-" : currencyFormatOne(item.carsApproveve?.approvedLoanTermTo)} <b> งวด</b>
                                      </div>
                                    </aside>
                                  </Col>
                                </Row>
                                {
                                  item.carsApproveve?.note?.length > 0 ?
                                    <Row>
                                      <Col span={22}>
                                        <aside>
                                          <div
                                            className="center"
                                            style={{
                                              marginTop: "10px",
                                              textWrap: "brek-word",
                                              color: "black",
                                              margin: "10px",
                                            }}
                                          >
                                            <span>
                                              {" "}
                                              <b>หมายเหตุ(เก่า) : </b>
                                            </span>
                                            {!item.carsApproveve?.note?.at(-1).note ? "-" : item.carsApproveve?.note?.at(-1).note}
                                          </div>
                                        </aside>
                                      </Col>
                                    </Row>
                                    : null
                                }
                                <Divider />
                              </>
                            );
                          })}
                        </>
                      </>
                      : null
                  }
                  <Divider
                    orientation="center"
                    style={{ marginTop: "30px", fontSize: "larger" }}
                  >
                    <b><u>ยอดอนุมัติ</u></b>
                  </Divider>
                  {
                    dataCarApp?.length > 0 && !data.approvedBy ?
                      <>
                        <Row className="main4" justify={'center'}>
                          <Col span={22}>
                            <aside>
                              <div style={{ margin: 5 }}>
                                <Form.Item label={<b style={{ fontSize: '16px' }}>เลือกยอดเดิม</b>} name="old">
                                  <span>
                                    <Select
                                      showSearch
                                      style={{ height: "40px", width: "200px" }}
                                      name="old"
                                      placeholder={"เลือก"}
                                      onChange={handleOldData}
                                    // options={loanTermMid}
                                    >
                                      {dataCarApp?.map((item, index) => (
                                        <Option key={index} value={item._id}>
                                          {item.inputDate} {item.CONTNO}
                                        </Option>
                                      ))}
                                    </Select>
                                  </span>
                                </Form.Item>
                              </div>
                            </aside>
                          </Col>
                        </Row>
                      </>
                      : null
                  }
                  {data?.approvalStatus === 2 ?
                    <>
                      <Row className="main4" justify={'center'}>
                        <Col span={22}>
                          <aside>
                            <div style={{ margin: 5 }}>
                              <Form.Item
                                // label='ค่างวดที่ต้องชำระ : '
                                label={<b style={{ fontSize: '16px' }}>ค่างวดที่ต้องชำระ</b>}
                                name="newDamt"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input your Memo!',
                                  },
                                ]}
                              >
                                <Input
                                  name="newDamt"
                                  onChange={(e) => {
                                    let inputValue = e.target.value;
                                    console.log("inputValue", inputValue)
                                    if (inputValue.length >= 4) {
                                      console.log("11")
                                      var rawValue = inputValue.replace(/,/g, ''); // Remove existing commas
                                      let intValue = parseInt(rawValue);
                                      let formattedValue = intValue >= 1000 ? currencyFormatOne1(intValue) : rawValue;
                                      setFormData({
                                        ...formData,
                                        newDamt: formattedValue.toString().replace(/,/g, ''),
                                      });
                                      form.setFieldsValue({
                                        newDamt: formattedValue,
                                      })
                                    } else {
                                      console.log("22")
                                      setFormData({
                                        ...formData,
                                        newDamt: parseFloat(inputValue),
                                      });
                                      form.setFieldsValue({
                                        newDamt: inputValue,
                                      })
                                    }

                                  }}
                                />
                              </Form.Item>
                            </div>
                            <div style={{ margin: 5 }}>
                              <Form.Item
                                // label='ค่าติดตาม : '
                                label={<b style={{ fontSize: '16px' }}>ค่าติดตาม</b>}
                                name="followPay"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input your Memo!',
                                  },
                                ]}
                              >
                                <Input
                                  name="followPay"
                                  // onChange={(e) =>
                                  //   setFormData({
                                  //     ...formData,
                                  //     followPay: currencyCutDash(
                                  //       parseInt(e.target.value)
                                  //     ),
                                  //   })
                                  // }
                                  onChange={(e) => {
                                    let inputValue = e.target.value;
                                    // console.log("inputValue", inputValue)
                                    if (inputValue.length >= 4) {
                                      console.log("11")
                                      var rawValue = inputValue.replace(/,/g, ''); // Remove existing commas
                                      let intValue = parseInt(rawValue);
                                      let formattedValue = intValue >= 1000 ? currencyFormatOne1(intValue) : rawValue;
                                      setFormData({
                                        ...formData,
                                        followPay: formattedValue.toString().replace(/,/g, ''),
                                      });
                                      form.setFieldsValue({
                                        followPay: formattedValue,
                                      })
                                    } else {
                                      console.log("22")
                                      setFormData({
                                        ...formData,
                                        followPay: parseFloat(inputValue),
                                      });
                                      form.setFieldsValue({
                                        followPay: inputValue,
                                      })
                                    }

                                  }}
                                />
                              </Form.Item>
                            </div>
                            <div style={{ margin: 5 }}>
                              <Form.Item
                                // label='เงินต้น : '
                                label={<b style={{ fontSize: '16px' }}>เงินต้น</b>}
                                name="approvedLoanAmount"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input your Memo!',
                                  },
                                ]}
                              >
                                <Input

                                  name="approvedLoanAmount"
                                  // onChange={(e) =>
                                  //   setFormData({
                                  //     ...formData,
                                  //     approvedLoanAmount: currencyCutDash(
                                  //       parseInt(e.target.value)
                                  //     ),
                                  //   })
                                  // }
                                  onChange={(e) => {
                                    let inputValue = e.target.value;
                                    // console.log("inputValue", inputValue)
                                    if (inputValue.length >= 4) {
                                      console.log("11")
                                      var rawValue = inputValue.replace(/,/g, ''); // Remove existing commas
                                      let intValue = parseInt(rawValue);
                                      let formattedValue = intValue >= 1000 ? currencyFormatOne1(intValue) : rawValue;
                                      setFormData({
                                        ...formData,
                                        approvedLoanAmount: formattedValue.toString().replace(/,/g, ''),
                                      });
                                      form.setFieldsValue({
                                        approvedLoanAmount: formattedValue,
                                      })
                                    } else {
                                      console.log("22")
                                      setFormData({
                                        ...formData,
                                        approvedLoanAmount: parseFloat(inputValue),
                                      });
                                      form.setFieldsValue({
                                        approvedLoanAmount: inputValue,
                                      })
                                    }

                                  }}
                                />
                              </Form.Item>
                            </div>
                            <div style={{ margin: 5 }}>
                              <Form.Item>

                              </Form.Item>
                            </div>
                            <div style={{ margin: 5 }}>
                              <Form.Item
                                // label='จำนวนงวด : '
                                label={<b style={{ fontSize: '16px' }}>จำนวนงวด</b>}
                                name="approvedLoanTerm"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input your approvedLoanTerm!',
                                  },
                                ]}
                              >
                                {/* <span> */}
                                <Select
                                  showSearch
                                  style={{ height: "40px" }}
                                  name="approvedLoanTerm"
                                  placeholder={formData.approvedLoanTerm}
                                  onChange={handleLoanTerm}
                                  options={loanTermMid}
                                />
                                {/* </span> */}
                              </Form.Item>
                            </div>

                            <div style={{ margin: 5 }}>
                              <Form.Item
                                // label='ถึงงวดที่ : '
                                label={<b style={{ fontSize: '16px' }}>ถึงงวดที่</b>}
                                name="approvedLoanTermTo"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input your approvedLoanTermTo!',
                                  },
                                ]}
                              >
                                {/* <span> */}
                                <Select
                                  showSearch
                                  style={{ height: "40px", color: "black" }}
                                  name="approvedLoanTermTo"
                                  placeholder={formData.approvedLoanTermTo}
                                  onChange={handleLoanTermTo}
                                  options={loanTermMid}
                                />
                                {/* </span> */}
                              </Form.Item>
                            </div>
                          </aside>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '15px' }}>
                        <Col span={22}>
                          <div style={{ textAlign: 'left' }}>
                            <Form.Item
                              label={<b style={{ fontSize: '16px' }}>หมายเหตุ</b>}
                              name="Memo"
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: 'Please input your Memo!',
                            //   },
                            // ]}
                            >
                              <TextArea
                                rows={4}
                                style={{
                                  width: '100%'
                                }}
                                name="Memo"
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    Memo: e.target.value,
                                  })
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </>
                    :
                    <>
                      <Row className="main4">
                        <Col span={22} style={{ fontSize: '16px' }}>
                          <aside>
                            <div style={{ margin: 5 }}>
                              <b>ค่างวดที่ต้องชำระ : </b>
                              {!dataApproved?.newDamt
                                ? "-"
                                : currencyFormatOne(dataApproved?.newDamt)}{" "}
                              บาท
                            </div>
                            <div style={{ margin: 5 }}>
                              <b>เงินต้น : </b>
                              {!dataApproved?.approvedLoanAmount
                                ? "-"
                                : currencyFormatOne(
                                  dataApproved?.approvedLoanAmount
                                )}{" "}
                              บาท
                            </div>

                            <div style={{ margin: 5 }}>
                              <b>ค่าติดตาม : </b>
                              {!dataApproved?.followPay
                                ? "-"
                                : currencyFormatOne(dataApproved?.followPay)}{" "}
                              บาท
                            </div>
                            <div style={{ margin: 5 }}>
                              <b>จำนวนงวด : </b>
                              {!dataApproved?.approvedLoanTerm
                                ? "-"
                                : dataApproved?.approvedLoanTerm}
                              <b> {" ถึง: "} </b>
                              {!dataApproved?.approvedLoanTermTo
                                ? "-"
                                : dataApproved?.approvedLoanTermTo}{" "}
                              งวด
                            </div>

                          </aside>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '15px' }}>
                        <Col span={22}>
                          <aside>
                            {/* {dataApproved?.note?.map((note, index) => {
                              const isLastIndex = index === dataApproved.note.length - 1; */}
                            {/* // return ( */}
                            <>
                              <div
                                className="center"
                                style={{
                                  marginTop: "30px",
                                  textWrap: "brek-word",
                                  color: "black",
                                  margin: "5px",
                                }}
                              >
                                <b>หมายเหตุ : </b>
                                *(ค่างวดที่ต้องชำระ + ค่าติดตาม = {""}
                                {currencyFormatOne(
                                  parseInt(dataApproved?.newDamt) +
                                  parseInt(dataApproved?.followPay)
                                )}{" "}
                                บาท ){" **"}
                                {dataApproved?.note?.length > 0 ? dataApproved?.note[isLastIndex]?.note : null}
                              </div>
                            </>
                            {/* // ); */}
                            {/* } */}
                            {/* )} */}
                          </aside>
                        </Col>
                      </Row>
                    </>
                  }
                  {data.approvalStatus === 3 || data.approvalStatus === 4 ? (
                    <>
                      <Divider></Divider>
                      <Row justify={"center"}>
                        <Space>
                          <Button
                            style={{
                              backgroundColor: "red",
                            }}
                            onClick={() => {
                              handleClose();
                              console.log("ปิด modal");
                            }}
                          >
                            ยกเลิก
                          </Button>

                          <Button
                            style={{ backgroundColor: "#ffeb3b" }}
                            onClick={setIsModalEdit}
                          >
                            แก้ไข
                          </Button>
                        </Space>
                      </Row>
                    </>
                  ) : (
                    <>
                      <Divider><b style={{ color: 'red' }}>กรอกข้อมูลให้ครบทุกช่องเพื่อเปิดปุ่มอนุมัติ</b></Divider>
                      <Row justify={"center"}>
                        <Space>
                          <Button
                            style={{
                              backgroundColor: "red",
                            }}
                            onClick={() => {
                              handleClose();
                              console.log("ปิด modal");
                            }}
                          >
                            ยกเลิก
                          </Button>
                          {formData.newDamt !== "" &&
                            formData.approvedLoanAmount !== "" &&
                            formData.followPay !== "" &&
                            formData.approvedLoanTerm &&
                            formData.approvedLoanTermTo &&
                            formData.approvedLoanTerm <=
                            formData.approvedLoanTermTo ? (
                            <Button
                              style={{
                                backgroundColor: "#76ff03",
                              }}
                              onClick={() => {
                                if (
                                  formData.approvedLoanTerm >
                                  formData.approvedLoanTermTo
                                ) {
                                  message.error(
                                    "โปรดกดเลือกจำนวนงวดและจำนวนงวดถึงใหม่อีกครั้ง"
                                  );
                                } else if (
                                  formData.newDamt === null ||
                                  formData.approvedLoanAmount === null ||
                                  formData.newDamt === undefined ||
                                  formData.approvedLoanAmount === undefined ||
                                  Number.isNaN(formData.newDamt) ||
                                  Number.isNaN(formData.approvedLoanAmount) ||
                                  formData.newDamt < 0 ||
                                  formData.approvedLoanAmount < 0
                                ) {
                                  message.error("เช็คความถูกต้องของข้อมูล");
                                } else {
                                  if (approvedLoanAmountInt < result) {
                                    // message.error("จำนวนเงินต้นน้อยกว่า 40% ของยอดรีเพื่อจัดใหม่")
                                    showConfirmLoanAmount();
                                  } else {
                                    showConfirm();
                                  }
                                }
                              }}
                            >
                              อนุมัติ
                            </Button>
                          ) : null}
                        </Space>
                      </Row>
                    </>
                  )}

                </>
                :
                <>
                  <b>ไม่พบข้อมูล</b>
                </>
              }
            </Col>
          </Row>
        </Spin>
      </Form>
      {
        isModalEdit ? (
          <EditApproved
            open={isModalEdit}
            close={setIsModalEdit}
            data={dataSendEdit}
          />
        ) : null
      }
    </Modal >
  );
};

export default Approval;