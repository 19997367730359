import React, { useState, useEffect } from "react";
import { Button, Card, Col, Divider, Form, Input, Row, Space, Spin, Table, notification, InputNumber, Tag, DatePicker, Checkbox, Select } from 'antd'
import axios from 'axios';
import dayjs from "dayjs";
// import ExcelJS from 'exceljs';
import { msError, msData, msReceipt } from "../file_mid/allFormat";
import { columnsCl } from "../loan_history/table/cm_cancel";
import ModelCancel from "./ModelCancel";
import { queryHdPayment } from "../file_mid/all_api";

export default function Main() {
  // const token = localStorage.getItem('token')
  // const currentDateTime = dayjs()
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const showDay1 = dayjs()
  const [dataSend, setDataSend] = useState({ date: dayjs().format("YYYY-MM-DD"), });
  const [dataTable, setDataTable] = useState([]);
  const [arrayTable, setArrayTable] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  // const [isModalCancel, setIsModalCancel] = useState(false);
  // const [dataDue, setDataDue] = useState();
  // useEffect(() => {
  //   handleSubmit()
  // }, [])

  const handleSubmit = async () => {
    // console.log("dataSend?.date",dataSend?.date)
    if (dataSend?.date !== "") {
      setLoading(true)
      await axios.post(queryHdPayment, dataSend)
        .then(async (res) => {
          if (res.status === 200) {
            setDataTable(res.data)
            setArrayTable(res.data)
          } else {
            setDataTable([])
            setArrayTable([])
            msData(api, "top")
          }
          console.log("res.data", res.data)
          setLoading(false)
        })
        .catch((err) => {
          console.log("err", err)
          setDataTable([])
          setArrayTable([])
          msError(api, "top")
          setLoading(false)
        })
    } else {
      msReceipt(api, "top")
    }
  }

  const search = (data) => {
    console.log("data : ", data.target.value)
    const searchValue = data.target.value.toLowerCase();  // ทำให้ค่าใน input เป็นตัวพิมพ์เล็ก
    const greaterThanTen = arrayTable.filter(
      (item) =>
        item.CONTNO.toLowerCase().includes(searchValue) ||
        item.CUSTCODE.toLowerCase().includes(searchValue)
    );
    setDataTable(greaterThanTen)
  };

  // const handleSetData = (record) => {
  //   if (record) {
  //     console.log('Record :', record);
  //     setDataDue(record)
  //     setIsModalCancel(true)
  //   }
  // };
  // const handleCancel = () => {
  //   console.log('handleCancel');
  //   setIsModalCancel(false)
  // };
  // const handleCancelLoad = async () => {
  //   console.log('handleCancelLoad');
  //   setIsModalCancel(false)
  //   await handleSubmit()
  // };
  const currencyFormatOne = (amount) => {
    return Number(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }
  const handleExpand = (expanded, record) => {
    if (expanded) {
      setExpandedRowKeys([record._id]); // เปิดแถวใหม่และปิดแถวเก่า
    } else {
      setExpandedRowKeys([]); // ปิดทุกแถว
    }
  };
  const ExpandedRow = ({ record }) => {
    return (
      <>
        <h3>New Data</h3>
        <Table
          columns={[
            { title: "TEMPBILL", dataIndex: "TEMPBILL", key: "TEMPBILL" },
            { title: "TEMPDATE", dataIndex: "TEMPDATE", key: "TEMPDATE" },
            {
              title: "TOTAMT", dataIndex: "TOTAMT", key: "TOTAMT",
              render: (text, record) => (
                <div>{currencyFormatOne(record.TOTAMT)}</div>
              )
            },
            {
              title: "BILLAMT", dataIndex: "BILLAMT", key: "BILLAMT",
              render: (text, record) => (
                <div>{currencyFormatOne(record.BILLAMT)}</div>
              )
            },
          ]}
          dataSource={record.newData}
          rowKey="TEMPBILL"
          pagination={false}
        />
        <h3>Old Data</h3>
        <Table
          columns={[
            { title: "TEMPBILL", dataIndex: "TEMPBILL", key: "TEMPBILL" },
            { title: "TEMPDATE", dataIndex: "TEMPDATE", key: "TEMPDATE" },
            {
              title: "TOTAMT", dataIndex: "TOTAMT", key: "TOTAMT",
              render: (text, record) => (
                <div>{currencyFormatOne(record.TOTAMT)}</div>
              )
            },
            {
              title: "BILLAMT", dataIndex: "BILLAMT", key: "BILLAMT",
              render: (text, record) => (
                <div>{currencyFormatOne(record.BILLAMT)}</div>
              )
            },
          ]}
          dataSource={record.oldData}
          rowKey="TEMPBILL"
          pagination={false}
        />
      </>
    );
  };
  const columns = [
    Table.EXPAND_COLUMN, // ใช้คอลัมน์ของ antd เองแทน dataIndex: 'expand'
    {
      key: 'index',
      title: "ลำดับ",
      dataIndex: "index",
      width: 50, // กำหนดเป็นค่า pixel จะควบคุมขนาดได้ดีกว่า
      align: 'center',
      render: (text, object, index) => index + 1
    },
    {
      key: 'inputDate',
      title: "วันที่",
      dataIndex: "inputDate",
      width: 80,
      align: 'center',
      render: (text, record) => (
        <div>{dayjs(record.inputDate).format("DD-MM-YY")}</div>
      )
    },
    {
      key: 'CONTNO',
      title: "รายละเอียด",
      dataIndex: "CONTNO",
      width: 250,
      align: 'center',
      render: (text, record) => (
        <div>สัญญา : {record.CONTNO} รหัสลูกค้า : {record.CUSTCODE} สาขา : {record.LOCAT}</div>
      )
    },
    {
      key: 'Action',
      title: "Action",
      align: 'center',
      width: 100,
      render: (record) => (
        <Space size="middle">
          {/* ปุ่มหรือ action ที่ต้องการ */}
        </Space>
      ),
    },
  ];

  return (
    <Card style={{ height: '100%' }}>
      <Form
        form={form}
      >
        <Row style={{ textAlign: 'center' }}>
          <Col span={24}>
            <Spin spinning={loading} size='large' tip="Loading...">
              <Col span={24}>
                <div className='text-center'>
                  <h2>รายงานรวมยอดรับฝาก (ทดสอบ)</h2>
                </div>
              </Col>
              <Divider />
              <Row gutter={16}>
                <Col span={16}>
                  <Card title="รายละเอียดสัญญา" bordered={false}>
                    <Space>
                      <Form.Item label="วันที่" name="date" style={{ height: '30px', width: '100%' }}>
                        {/* <Input onChange={(e) => setDataSend({ ...dataSend, CONTNO: e.target.value })}></Input> */}
                        <DatePicker format={'YYYY-MM-DD'}
                          defaultValue={showDay1}
                          style={{ height: '40px' }}
                          onChange={(e) => {
                            if (e) {
                              setDataSend({ ...dataSend, date: e.format('YYYY-MM-DD') })
                            }
                          }} />
                      </Form.Item>
                      <Form.Item label="" style={{ height: '30px', width: '100%' }}>
                        <Button type='primary' onClick={handleSubmit} >ตกลง</Button>
                      </Form.Item>
                    </Space>
                    <Divider />
                    <Col span={16}>
                    </Col>
                  </Card>
                </Col>
              </Row>
              <Divider />
              <Row gutter={32} style={{ margin: 10 }}>
                <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                  <Input.Search
                    style={{ width: '250px' }}
                    placeholder="ค้นหาในตาราง...."
                    onChange={search}
                    name="search"
                    id="search"
                    size="large"
                  />
                </Col>
              </Row>
              <Table
                rowKey={(record) => record._id}
                // rowSelection={{}}
                expandable={{
                  expandedRowRender: (record) => <ExpandedRow record={record} />,
                  expandIconColumnIndex: 0,
                  expandedRowKeys: expandedRowKeys, // กำหนดให้ขยายได้ทีละแถวเดียว
                  onExpand: handleExpand, // จัดการการเปิด-ปิด
                }}
                dataSource={dataTable}
                columns={columns}
                scroll={{
                  x: 1500,
                  y: 400,
                }}
                style={{ padding: 20 }}
              >
              </Table>
            </Spin>
          </Col>
        </Row >
      </Form >
      {contextHolder}
      {/* {
        isModalCancel ?
          <ModelCancel
            open={isModalCancel}
            close={handleCancel}
            dataFromTable={dataDue}
            closeload={handleCancelLoad}
          />
          : null
      } */}
    </Card >
  )
}
